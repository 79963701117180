import * as React from "react";

function SvgFishSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.358 6.444c-3.124 0-5.852 1.902-7.37 3.53L2.954 7.68c-.421-.319-1.05.02-.942.509L2.853 12l-.84 3.812c-.108.488.52.827.942.508l3.032-2.293c1.518 1.627 4.247 3.528 7.37 3.528C18.132 17.555 22 13.111 22 12c0-1.111-3.87-5.556-8.642-5.556zm3.035 6.39a.833.833 0 110-1.668.833.833 0 010 1.667z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFishSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
