import * as React from "react";

function SvgToriiGateSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.705 3.25h-9.41C5.457 3.25 3.644 2.822 2 2v3.75C2 6.44 2.56 7 3.25 7H4.5v2.5H2.625a.625.625 0 00-.625.625v1.25c0 .345.28.625.625.625H4.5v9.375c0 .345.28.625.625.625h1.25c.345 0 .625-.28.625-.625V12h10v9.375c0 .345.28.625.625.625h1.25c.345 0 .625-.28.625-.625V12h1.875c.345 0 .625-.28.625-.625v-1.25a.625.625 0 00-.625-.625H19.5V7h1.25C21.44 7 22 6.44 22 5.75V2a11.843 11.843 0 01-5.295 1.25zM7 7h3.75v2.5H7V7zm10 2.5h-3.75V7H17v2.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgToriiGateSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
