import * as React from "react";

function SvgQuestionCircleOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.478 2 2 6.48 2 12c0 5.524 4.478 10 10 10 5.523 0 10-4.476 10-10 0-5.52-4.477-10-10-10zm0 18.064A8.06 8.06 0 013.935 12 8.062 8.062 0 0112 3.935 8.062 8.062 0 0120.064 12 8.06 8.06 0 0112 20.064zm4.324-10.29c0 2.704-2.92 2.746-2.92 3.745v.255a.484.484 0 01-.484.484h-1.84a.484.484 0 01-.484-.484v-.349c0-1.441 1.092-2.018 1.918-2.48.708-.397 1.142-.667 1.142-1.193 0-.696-.887-1.157-1.604-1.157-.935 0-1.367.442-1.973 1.208a.484.484 0 01-.672.086l-1.122-.85a.485.485 0 01-.107-.66c.953-1.4 2.166-2.185 4.056-2.185 1.978 0 4.09 1.544 4.09 3.58zm-2.63 6.742c0 .934-.76 1.694-1.694 1.694-.934 0-1.694-.76-1.694-1.694 0-.934.76-1.693 1.694-1.693.934 0 1.694.76 1.694 1.693z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgQuestionCircleOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
