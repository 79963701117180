import * as React from "react";

function SvgCrowSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19 5h-.511A2.488 2.488 0 0016.5 4 2.5 2.5 0 0014 6.5v.656L2.378 16.299a.944.944 0 00.989 1.6L7.165 16h3.015l1.388 3.753a.375.375 0 00.48.224l.705-.256a.375.375 0 00.224-.481L11.78 16H13c.06 0 .117-.007.177-.01l1.39 3.763a.375.375 0 00.481.224l.705-.257a.375.375 0 00.224-.48l-1.289-3.486A5.998 5.998 0 0019 10V7.5l3-.5c0-1.105-1.343-2-3-2zm-2.5 2.25a.75.75 0 110-1.5.75.75 0 010 1.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCrowSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
