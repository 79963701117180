import * as React from "react";

function SvgGemSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.858 3.111L22 8.667h-3.51L16.087 3.11h2.77zm-4.445 0l2.403 5.556H7.184L9.587 3.11h4.826zm-9.27 0h2.77L5.51 8.667H2L5.142 3.11zM2 9.778h3.497l4.27 8.74c.052.107-.093.204-.173.114L2 9.778zm5.146 0h9.708l-4.757 11.048c-.034.084-.156.084-.19 0L7.145 9.778zm7.087 8.74l4.27-8.74H22l-7.594 8.85c-.08.094-.225-.003-.173-.11z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGemSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
