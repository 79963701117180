import * as React from "react";

function SvgMemorySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 8.092V7a1 1 0 00-1-1H3a1 1 0 00-1 1v1.092c.581.206 1 .756 1 1.408 0 .652-.419 1.202-1 1.408V14h20v-3.092c-.581-.206-1-.756-1-1.408 0-.652.419-1.202 1-1.408zM9 12H7V8h2v4zm4 0h-2V8h2v4zm4 0h-2V8h2v4zM2 18h2v-.833a.5.5 0 111 0V18h4v-.833a.5.5 0 111 0V18h4v-.833a.5.5 0 111 0V18h4v-.833a.5.5 0 111 0V18h2v-3H2v3z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMemorySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
