import * as React from "react";

function SvgSnowboardingSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.872 5.75a1.876 1.876 0 10-.001-3.751 1.876 1.876 0 000 3.751zm1.125 6a1.253 1.253 0 001.75-.25 1.248 1.248 0 00-.25-1.75l-4.352-3.262a6.261 6.261 0 00-1.773-.93l-2.489-.828-1.02-2.035A1.249 1.249 0 109.63 3.813l1.136 2.269c.223.445.61.777 1.082.938l.641.214-1.61.805a2.486 2.486 0 00-1.382 2.234v2.075l-2.895.964a1.248 1.248 0 00-.789 1.583c.067.203.192.367.34.503l-1.512-.55a1.495 1.495 0 01-.851-.782.938.938 0 00-1.7.793A3.354 3.354 0 004 16.61l14.25 5.184c.38.137.77.207 1.16.207.49 0 .974-.105 1.427-.32a.938.938 0 00-.793-1.7 1.493 1.493 0 01-1.153.051l-3.547-1.293c.34-.16.61-.46.696-.855l.855-3.985a1.88 1.88 0 00-.707-1.89l-2.031-1.524 2.578-1.191 3.262 2.457zm-5.64 2.02l-.77 3.593c-.059.278-.004.543.109.782l-6.617-2.407c.105-.008.21-.015.312-.05l3.32-1.11a1.87 1.87 0 001.282-1.777V12l2.363 1.77z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSnowboardingSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
