import * as React from "react";

function SvgShekelSignSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.071 8.071v7.5c0 .395.32.715.715.715h2.143c.394 0 .714-.32.714-.715v-7.5A6.071 6.071 0 0010.57 2h-7.5C2.48 2 2 2.48 2 3.071v18.215c0 .394.32.714.714.714h2.143c.395 0 .714-.32.714-.714V5.57h5a2.5 2.5 0 012.5 2.5zM21.286 2h-2.143a.714.714 0 00-.714.714V15.93a2.5 2.5 0 01-2.5 2.5h-5v-10a.714.714 0 00-.715-.715H8.071a.714.714 0 00-.714.715v12.5c0 .591.48 1.071 1.072 1.071h7.5A6.071 6.071 0 0022 15.929V2.714A.714.714 0 0021.286 2z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgShekelSignSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
