import * as React from "react";

function SvgTimesCircleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm4.903 12.625c.19.19.19.496 0 .685l-1.596 1.593a.484.484 0 01-.686 0L12 14.258l-2.625 2.645a.484.484 0 01-.685 0l-1.593-1.596a.484.484 0 010-.686L9.742 12 7.097 9.375a.484.484 0 010-.685l1.597-1.597c.19-.19.496-.19.685 0L12 9.742l2.625-2.645c.19-.19.496-.19.685 0l1.597 1.597c.19.19.19.496 0 .685L14.258 12l2.645 2.625z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTimesCircleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
