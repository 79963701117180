import * as React from "react";

function SvgWineBottleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.817 4.835l-2.652-2.652a.625.625 0 00-.884 0l-.884.884a.625.625 0 000 .884l-2.995 2.995c-1.82-.77-4-.42-5.483 1.064l-6.187 6.187a2.5 2.5 0 000 3.535l3.536 3.536a2.5 2.5 0 003.535 0l6.188-6.187a4.997 4.997 0 001.063-5.484l2.995-2.995c.244.244.64.244.884 0l.884-.884a.625.625 0 000-.883zM9 18.535l-3.536-3.536 4.767-4.767 3.536 3.536L9 18.535z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgWineBottleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
