import * as React from "react";

function SvgGrinTearsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M5.2 12.003c-.706.1-2.296.375-2.759.838-.597.597-.587 1.581.025 2.194.613.612 1.594.621 2.194.021.463-.462.734-2.053.838-2.76a.26.26 0 00-.297-.293zm16.358.838c-.463-.463-2.053-.735-2.76-.838a.262.262 0 00-.297.297c.1.706.375 2.297.838 2.76.6.6 1.581.59 2.194-.022.612-.613.625-1.6.025-2.197zm-4.044-.4a1.264 1.264 0 011.428-1.429c.278.041.525.085.76.129a7.749 7.749 0 00-15.401 0c.234-.044.48-.088.759-.129 1.038-.121 1.519.791 1.428 1.429-.369 2.572-.934 3.137-1.119 3.325-.028.028-.062.05-.093.078a7.741 7.741 0 0013.45-.003c-.031-.028-.065-.047-.093-.078-.185-.185-.75-.75-1.12-3.322zM14.5 8.75c.744 0 1.647.916 1.75 2.231.022.269-.337.375-.466.14l-.296-.53c-.241-.429-.6-.675-.985-.675-.384 0-.744.246-.984.675l-.297.53c-.131.232-.488.126-.466-.14.097-1.315 1-2.231 1.744-2.231zm-5 0c.744 0 1.647.916 1.75 2.231.022.269-.338.375-.466.14l-.297-.53c-.24-.429-.6-.675-.984-.675s-.744.246-.984.675l-.297.53c-.132.232-.488.126-.466-.14.097-1.315 1-2.231 1.744-2.231zM12 17.5c-1.894 0-4.203-1.197-4.494-2.915a.501.501 0 01.647-.56c.944.303 2.347.475 3.847.475s2.903-.172 3.847-.475a.502.502 0 01.647.56c-.29 1.718-2.6 2.915-4.494 2.915z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGrinTearsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
