import * as React from "react";

function SvgSmileBeamSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm-5.484 8.685c.133-1.697 1.299-2.879 2.258-2.879.96 0 2.125 1.182 2.258 2.88.028.346-.435.48-.6.18l-.384-.684c-.31-.553-.774-.872-1.27-.872s-.96.319-1.27.871l-.383.686c-.173.298-.637.161-.609-.182zm10.113 4.952A6.007 6.007 0 0112 17.807c-1.794 0-3.48-.79-4.629-2.17-.544-.657.444-1.48.992-.827A4.712 4.712 0 0012 16.512a4.725 4.725 0 003.637-1.702c.549-.653 1.536.174.992.827zm.25-4.77l-.383-.686c-.31-.552-.774-.87-1.27-.87s-.96.318-1.27.87l-.383.686c-.166.294-.63.161-.601-.182.133-1.697 1.298-2.879 2.258-2.879s2.125 1.182 2.258 2.88c.024.346-.444.48-.609.18z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSmileBeamSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
