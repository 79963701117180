import * as React from "react";

function SvgEnvelopeOpenSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 20.125C22 21.16 21.16 22 20.125 22H3.875A1.875 1.875 0 012 20.125V9.841a1.875 1.875 0 01.718-1.476c.973-.763 1.778-1.381 6.414-4.746C9.79 3.139 11.094 1.986 12 2c.906-.014 2.21 1.14 2.868 1.619 4.636 3.364 5.441 3.984 6.414 4.746A1.875 1.875 0 0122 9.841v10.284zm-2.565-7.68a.312.312 0 00-.443-.074c-.892.659-2.166 1.59-4.124 3.01-.658.48-1.962 1.633-2.868 1.619-.907.013-2.21-1.139-2.868-1.618a645.928 645.928 0 01-4.124-3.011.312.312 0 00-.443.074l-.354.516a.312.312 0 00.072.428c.894.66 2.166 1.59 4.113 3.003.792.577 2.208 1.867 3.604 1.858 1.396.01 2.811-1.28 3.604-1.858a651.343 651.343 0 004.113-3.003.313.313 0 00.072-.428l-.354-.516z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgEnvelopeOpenSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
