import * as React from "react";

function SvgBroomSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.015 10.774l2.71 3.412s-.519 3.199-2.393 4.691C8.458 20.37 2 19.943 2 19.943s.119-.723.344-1.732L5.3 14.706c.124-.147-.028-.363-.208-.297l-1.888.69c.452-1.302 1.023-2.5 1.707-3.046 1.874-1.492 5.103-1.279 5.103-1.279zM21.892 4.97l-.621-.782a.498.498 0 00-.7-.08l-7.265 5.557-1.067-1.343a.344.344 0 00-.581.069l-.792 1.705 2.71 3.412 1.838-.39a.344.344 0 00.198-.55l-1.065-1.34 7.265-5.557a.5.5 0 00.08-.701z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBroomSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
