import * as React from "react";

function SvgMugHotSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6.965 7.723c.05.3.312.527.625.527h.644c.383 0 .688-.332.637-.703a5.106 5.106 0 00-1.43-2.918 3.704 3.704 0 01-1.03-2.09A.63.63 0 005.78 2h-.64a.637.637 0 00-.637.703A5.678 5.678 0 006.09 6c.469.477.77 1.074.875 1.723zm4.375 0c.05.3.312.527.625.527h.644c.383 0 .688-.332.637-.703a5.106 5.106 0 00-1.43-2.918 3.704 3.704 0 01-1.03-2.09.63.63 0 00-.63-.539h-.64a.635.635 0 00-.637.703A5.678 5.678 0 0010.465 6c.469.477.77 1.074.875 1.723zM17.625 9.5H3.25c-.691 0-1.25.559-1.25 1.25v7.5C2 20.32 3.68 22 5.75 22h7.5c2.07 0 3.75-1.68 3.75-3.75h.625A4.378 4.378 0 0022 13.875 4.378 4.378 0 0017.625 9.5zm0 6.25H17V12h.625a1.875 1.875 0 010 3.75z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMugHotSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
