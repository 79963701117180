import * as React from "react";

function SvgFemaleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm4.66 13.835l-1.876-7.5a.938.938 0 00-.909-.71h-.444a3.433 3.433 0 01-2.862 0h-.444a.937.937 0 00-.91.71l-1.875 7.5A.938.938 0 008.25 17h2.188v4.063c0 .517.42.937.937.937h1.25c.518 0 .938-.42.938-.938V17h2.187a.938.938 0 00.91-1.165z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFemaleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
