import * as React from "react";

function SvgLeafSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.965 3.447c-.194-.434-.75-.452-.983-.042-1.076 1.872-3.003 3.039-5.205 3.039H12a6.669 6.669 0 00-6.667 6.667c0 .243.028.476.052.712 2.215-1.587 5.413-2.935 9.949-2.935.305 0 .555.25.555.556 0 .306-.25.556-.555.556C6.603 12 2.9 17.35 2.08 19.36a1.113 1.113 0 002.06.844c.052-.125.725-1.663 2.496-3.146 1.126 1.525 3.265 2.98 6.074 2.68C18.163 19.345 22 14.456 22 8.469c0-1.743-.375-3.549-1.035-5.021z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLeafSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
