import * as React from "react";

function SvgFileImageSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.5 6.763V7h-5V2h.237c.248 0 .487.099.663.275L19.225 6.1a.938.938 0 01.275.663zM14.187 8.25a.94.94 0 01-.937-.938V2H5.437a.937.937 0 00-.937.938v18.125c0 .517.42.937.938.937h13.125c.517 0 .937-.42.937-.938V8.25h-5.313zm-5.29.625a1.875 1.875 0 110 3.75 1.875 1.875 0 010-3.75zm8.124 9.375h-10l.02-1.894 1.543-1.544c.183-.182.46-.164.644.02l1.543 1.543 4.044-4.044a.469.469 0 01.663 0l1.543 1.544v4.375z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFileImageSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
