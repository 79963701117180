import * as React from "react";

function SvgAngleRightSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.014 13.062L9.517 21.56a1.493 1.493 0 01-2.118 0l-1.412-1.412a1.493 1.493 0 010-2.119l6.023-6.023-6.023-6.023a1.493 1.493 0 010-2.118L7.392 2.44a1.493 1.493 0 012.119 0l8.497 8.498c.593.587.593 1.537.006 2.124z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAngleRightSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
