import * as React from "react";

function SvgPrescriptionBottleAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.563 2H5.438a.94.94 0 00-.938.938v1.874a.94.94 0 00.938.938h13.125a.94.94 0 00.937-.938V2.938A.94.94 0 0018.562 2zM5.75 20.75c0 .688.563 1.25 1.25 1.25h10c.688 0 1.25-.563 1.25-1.25V7H5.75v13.75zm2.5-7.188c0-.171.14-.312.313-.312h2.187v-2.188c0-.171.14-.312.313-.312h1.874c.172 0 .313.14.313.313v2.187h2.188c.171 0 .312.14.312.313v1.874c0 .172-.14.313-.313.313H13.25v2.188c0 .171-.14.312-.313.312h-1.874a.313.313 0 01-.313-.313V15.75H8.562a.313.313 0 01-.312-.313v-1.874z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPrescriptionBottleAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
