import * as React from "react";

function SvgIdCardOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.333 4.222H3.667C2.747 4.222 2 4.97 2 5.89V18.11c0 .92.747 1.667 1.667 1.667h16.666c.92 0 1.667-.747 1.667-1.667V5.89c0-.92-.747-1.667-1.667-1.667zm0 13.89h-7.805c.031-.157.028.124.028-.779 0-1.104-1.046-2-2.334-2-.375 0-.65.278-1.555.278-.934 0-1.16-.278-1.556-.278-1.288 0-2.333.896-2.333 2 0 .903-.007.622.028.778h-1.14v-10h16.667v10zM14.5 15.332h3.889a.279.279 0 00.278-.277V14.5a.279.279 0 00-.278-.278H14.5a.279.279 0 00-.278.278v.556c0 .152.125.277.278.277zm0-2.222h3.889a.279.279 0 00.278-.278v-.555a.279.279 0 00-.278-.278H14.5a.279.279 0 00-.278.278v.555c0 .153.125.278.278.278zm0-2.222h3.889a.279.279 0 00.278-.278v-.556a.279.279 0 00-.278-.277H14.5a.279.279 0 00-.278.277v.556c0 .153.125.278.278.278zm-5.833 3.333A2.224 2.224 0 0010.889 12a2.224 2.224 0 00-2.222-2.222A2.224 2.224 0 006.444 12c0 1.226.997 2.222 2.223 2.222z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgIdCardOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
