import * as React from "react";

function SvgRssSquareSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.857 2H4.143C2.959 2 2 2.96 2 4.143v15.714C2 21.041 2.96 22 4.143 22h15.714C21.041 22 22 21.04 22 19.857V4.143C22 2.959 21.04 2 19.857 2zM7 19.143a2.143 2.143 0 110-4.286 2.143 2.143 0 010 4.286zm7.033 0H12.5a.51.51 0 01-.51-.475 7.145 7.145 0 00-6.658-6.657.51.51 0 01-.475-.51V9.966a.51.51 0 01.536-.51 9.696 9.696 0 019.15 9.15.51.51 0 01-.51.536zm4.6 0h-1.534a.512.512 0 01-.51-.493C16.34 12.555 11.446 7.66 5.35 7.41a.512.512 0 01-.493-.51V5.369a.51.51 0 01.526-.51c7.493.268 13.492 6.303 13.76 13.759a.51.51 0 01-.51.526z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgRssSquareSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
