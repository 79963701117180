import * as React from "react";

function SvgGraduationCapSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.448 8.788l-8.717-2.679a2.496 2.496 0 00-1.462 0L2.552 8.788a.736.736 0 000 1.424l1.52.467a2.463 2.463 0 00-.56 1.466A.987.987 0 003 13c0 .337.177.62.433.802l-.798 3.59a.5.5 0 00.488.608h1.753a.5.5 0 00.489-.608l-.798-3.59A.982.982 0 005 13a.985.985 0 00-.49-.84c.024-.469.264-.884.647-1.147l6.112 1.878a2.5 2.5 0 001.462 0l8.717-2.678a.736.736 0 000-1.425zm-8.423 5.059a3.498 3.498 0 01-2.05 0l-4.532-1.393L6 16c0 1.105 2.686 2 6 2s6-.895 6-2l-.443-3.546-4.532 1.393z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGraduationCapSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
