import * as React from "react";

function SvgCodeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.716 19.983L8.81 19.43a.376.376 0 01-.257-.465L12.82 4.272a.376.376 0 01.465-.256l1.906.553c.2.056.313.265.257.465L11.18 19.727a.374.374 0 01-.465.256zm-3.562-3.506l1.359-1.45a.375.375 0 00-.025-.537L5.657 12l2.83-2.491a.372.372 0 00.026-.537l-1.36-1.45a.375.375 0 00-.53-.016L2.12 11.725c-.16.146-.16.4 0 .546l4.502 4.222c.153.144.39.137.532-.016zm10.224.019l4.503-4.222c.159-.146.159-.4 0-.546l-4.503-4.225a.378.378 0 00-.532.016l-1.359 1.45a.375.375 0 00.025.537l2.831 2.493-2.83 2.49a.372.372 0 00-.026.538l1.36 1.45c.14.153.378.16.53.019z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCodeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
