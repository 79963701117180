import * as React from "react";

function SvgBeerSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.429 4.857h-2.143V3.071c0-.591-.48-1.071-1.072-1.071H3.071C2.48 2 2 2.48 2 3.071V20.93C2 21.52 2.48 22 3.071 22h12.143c.592 0 1.072-.48 1.072-1.071v-1.88l3.598-1.607A3.575 3.575 0 0022 14.182V8.428a3.575 3.575 0 00-3.571-3.572zm.714 9.324a.715.715 0 01-.423.653l-2.434 1.086V7.714h2.143c.393 0 .714.32.714.715v5.752zm-7.857 3.533A.714.714 0 0110.57 17V7A.714.714 0 0112 7v10c0 .395-.32.714-.714.714zm-4.286 0A.714.714 0 016.286 17V7a.714.714 0 011.428 0v10c0 .395-.32.714-.714.714z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBeerSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
