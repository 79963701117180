import * as React from "react";

function SvgFrownOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 18.064c-4.448 0-8.065-3.616-8.065-8.064S7.552 3.935 12 3.935c4.448 0 8.064 3.617 8.064 8.065 0 4.448-3.616 8.064-8.064 8.064zm-3.226-8.71c.714 0 1.29-.576 1.29-1.29 0-.713-.576-1.29-1.29-1.29-.714 0-1.29.577-1.29 1.29 0 .714.576 1.29 1.29 1.29zm6.452-2.58c-.714 0-1.29.577-1.29 1.29 0 .714.576 1.29 1.29 1.29.713 0 1.29-.576 1.29-1.29 0-.713-.576-1.29-1.29-1.29zM12 13.936a5.437 5.437 0 00-4.185 1.96.967.967 0 101.487 1.237 3.508 3.508 0 015.396 0 .97.97 0 001.363.125.97.97 0 00.125-1.363A5.437 5.437 0 0012 13.935z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFrownOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
