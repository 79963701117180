import * as React from "react";

function SvgViharaSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.778 16.522L19 15v-2l1.724-.553a.5.5 0 000-.894L17 10V8l.854-.51a.5.5 0 00-.13-.8L12 4 6.277 6.69a.5.5 0 00-.13.8L7 8v2l-3.724 1.553a.5.5 0 000 .894L5 13v2l-2.777 1.522c-.17.113-.241.301-.22.478.02.157.112.305.273.385L4 18v1.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V18h5v1.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V18h5v1.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V18l1.724-.615a.495.495 0 00.272-.385.495.495 0 00-.218-.478zM9 8h6v2H9V8zm-2 7v-2h10v2H7z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgViharaSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
