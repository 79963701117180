import * as React from "react";

function SvgSpaceShuttleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.519 10.508c-1.027-.482-2.401-.758-3.769-.758H7.823a2.12 2.12 0 00-.523-.5h6.45C9.161 8.305 8.856 5 5 5v4h-.5V5C3.672 5 3 5.895 3 7v2c-.725 0-1 .313-1 .75V11c0 .437.276.75 1 .75v.5c-.725 0-1 .313-1 .75v1.25c0 .437.276.75 1 .75v2c0 1.105.672 2 1.5 2v-4H5v4c3.856 0 4.161-3.305 8.75-4.25H7.3c.192-.126.368-.295.523-.5h8.927c1.368 0 2.742-.276 3.769-.758C21.446 13.058 22 12.5 22 12s-.554-1.058-1.481-1.492zM17.25 13.25A.25.25 0 0117 13v-2a.25.25 0 01.25-.25c.997 0 .998 2.5 0 2.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSpaceShuttleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
