import * as React from "react";

function SvgCalendarMinusOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.094 14.813a.47.47 0 01-.469-.47v-.937a.47.47 0 01.469-.469h7.812a.47.47 0 01.469.47v.937a.47.47 0 01-.469.469H8.094zM20.75 6.374v13.75c0 1.035-.84 1.875-1.875 1.875H5.125a1.875 1.875 0 01-1.875-1.875V6.375c0-1.035.84-1.875 1.875-1.875H7V2.469A.47.47 0 017.469 2H9.03a.47.47 0 01.469.469V4.5h5V2.469A.47.47 0 0114.969 2h1.562a.47.47 0 01.469.469V4.5h1.875c1.035 0 1.875.84 1.875 1.875zm-1.875 13.516V8.25H5.125v11.64c0 .13.105.235.234.235h13.282a.235.235 0 00.234-.234z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCalendarMinusOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
