import * as React from "react";

function SvgMapMarkerAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.23 21.596C5.553 13.368 4.5 12.524 4.5 9.5a7.5 7.5 0 1115 0c0 3.024-1.053 3.868-6.73 12.096a.938.938 0 01-1.54 0zm.77-8.971a3.125 3.125 0 100-6.25 3.125 3.125 0 000 6.25z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMapMarkerAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
