import * as React from "react";

function SvgSitemapSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6 15H3a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1v-3a1 1 0 00-1-1zm-.75-2.5h6V14h1.5v-1.5h6V14h1.5v-1.8c0-.661-.538-1.2-1.2-1.2h-6.3V9H14a1 1 0 001-1V5a1 1 0 00-1-1h-4a1 1 0 00-1 1v3a1 1 0 001 1h1.25v2h-6.3c-.662 0-1.2.538-1.2 1.2V14h1.5v-1.5zM13.5 15h-3a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1v-3a1 1 0 00-1-1zm7.5 0h-3a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1v-3a1 1 0 00-1-1z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSitemapSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
