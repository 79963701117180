import * as React from "react";

function SvgPhoneSquareAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.857 2H4.143A2.143 2.143 0 002 4.143v15.714A2.143 2.143 0 004.143 22h15.714A2.143 2.143 0 0022 19.857V4.143A2.143 2.143 0 0019.857 2zm-.732 13.722l-.67 2.902a.67.67 0 01-.651.519c-7.143 0-12.947-5.79-12.947-12.947a.701.701 0 01.52-.652l2.901-.67c.05-.01.1-.016.15-.017a.726.726 0 01.616.406l1.34 3.125a.798.798 0 01.053.264.759.759 0 01-.245.518L8.5 10.554a10.353 10.353 0 004.946 4.946l1.384-1.692a.759.759 0 01.518-.245c.09.002.18.02.264.053l3.125 1.34a.726.726 0 01.406.615c-.001.051-.007.101-.018.15z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPhoneSquareAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
