import * as React from "react";

function SvgFutbolSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-1.936 0v-.011l-1.05.917-2.528-2.36.663-3.4 1.384.123a8.082 8.082 0 00-4.061-2.949l.55 1.288L12 7.282 8.978 5.608l.55-1.288a8.082 8.082 0 00-4.06 2.95l1.393-.125.653 3.402-2.527 2.359-1.051-.917V12c0 1.735.544 3.385 1.55 4.758l.311-1.367 3.433.421 1.464 3.138-1.206.718c1.622.529 3.4.53 5.024 0l-1.206-.718 1.464-3.138 3.433-.421.31 1.367A8.004 8.004 0 0020.064 12zM10.06 14.805l-1.205-3.682L12 8.844l3.145 2.28-1.194 3.681h-3.89z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFutbolSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
