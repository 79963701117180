import * as React from "react";

function SvgLayerGroupSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2.485 7.782l9.099 4.128a1 1 0 00.832 0l9.099-4.128c.646-.293.646-1.27 0-1.563L12.416 2.09a1 1 0 00-.832 0l-9.1 4.127c-.646.294-.646 1.27 0 1.564zm19.03 3.449l-2.269-1.029-6.314 2.862a2.24 2.24 0 01-1.864 0l-6.314-2.862-2.27 1.029c-.646.293-.646 1.27 0 1.562l9.1 4.125c.266.12.566.12.832 0l9.1-4.125c.646-.293.646-1.27 0-1.562zm0 4.992l-2.26-1.025-6.323 2.866a2.24 2.24 0 01-1.864 0l-6.322-2.866-2.261 1.025c-.647.293-.647 1.27 0 1.562l9.099 4.125c.266.12.566.12.832 0l9.1-4.125c.646-.293.646-1.27 0-1.562z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLayerGroupSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
