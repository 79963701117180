import * as React from "react";

function SvgListUlSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.875 3.875a1.875 1.875 0 100 3.75 1.875 1.875 0 000-3.75zm0 6.25a1.875 1.875 0 100 3.75 1.875 1.875 0 000-3.75zm0 6.25a1.875 1.875 0 100 3.75 1.875 1.875 0 000-3.75zm17.5.625h-12.5a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h12.5a.624.624 0 00.625-.625v-1.25a.624.624 0 00-.625-.625zm0-12.5h-12.5a.625.625 0 00-.625.625v1.25A.625.625 0 008.875 7h12.5A.625.625 0 0022 6.375v-1.25a.625.625 0 00-.625-.625zm0 6.25h-12.5a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h12.5a.624.624 0 00.625-.625v-1.25a.624.624 0 00-.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgListUlSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
