import * as React from "react";

function SvgSmsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 3.25c-5.523 0-10 3.637-10 8.125 0 1.938.836 3.71 2.227 5.105-.489 1.97-2.122 3.723-2.141 3.743a.31.31 0 00-.059.34c.051.117.16.187.285.187 2.59 0 4.532-1.242 5.493-2.008 1.277.48 2.695.758 4.195.758 5.523 0 10-3.637 10-8.125 0-4.488-4.477-8.125-10-8.125zM7.008 13.875H6.53a.313.313 0 01-.312-.313v-.624c0-.172.14-.313.312-.313h.48c.235 0 .407-.137.407-.258 0-.05-.031-.105-.082-.148l-.856-.735a1.444 1.444 0 01-.52-1.097c0-.832.743-1.508 1.657-1.508h.477c.172 0 .312.14.312.312v.625c0 .172-.14.313-.312.313h-.48c-.235 0-.407.137-.407.258 0 .05.031.105.082.148l.856.735c.332.28.52.683.52 1.097.003.832-.743 1.508-1.657 1.508zm7.492-.313c0 .172-.14.313-.313.313h-.624a.313.313 0 01-.313-.313v-2.664l-.969 2.18a.312.312 0 01-.558 0l-.973-2.18v2.665c0 .171-.14.312-.313.312h-.624a.313.313 0 01-.313-.313V9.5c0-.344.281-.625.625-.625h.625c.238 0 .453.133.559.344L12 10.602l.691-1.383a.626.626 0 01.559-.344h.625c.344 0 .625.281.625.625v4.063zm1.887.313h-.48a.313.313 0 01-.313-.313v-.624c0-.172.14-.313.312-.313h.48c.235 0 .407-.137.407-.258 0-.05-.031-.105-.082-.148l-.855-.735a1.444 1.444 0 01-.52-1.097c0-.832.742-1.508 1.656-1.508h.477c.172 0 .312.14.312.312v.625c0 .172-.14.313-.312.313h-.48c-.235 0-.407.137-.407.258 0 .05.031.105.082.148l.855.735c.333.28.52.683.52 1.097.004.832-.738 1.508-1.652 1.508z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSmsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
