import * as React from "react";

function SvgMosqueSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 19a1 1 0 001 1h2a1 1 0 001-1V9H2v10zm18.099-6c.558-.543.901-1.167.901-1.84 0-1.653-1.306-2.932-2.747-3.842-1.311-.827-2.52-1.805-3.5-3.006L14.5 4l-.254.312c-.979 1.201-2.188 2.18-3.498 3.006C9.306 8.228 8 9.508 8 11.16c0 .674.343 1.298.901 1.841H20.1zM21 14H8a1 1 0 00-1 1v4a1 1 0 001 1h1v-2a1 1 0 012 0v2h2v-2.25c0-1.5 1.5-2.25 1.5-2.25s1.5.75 1.5 2.25V20h2v-2a1 1 0 012 0v2h1a1 1 0 001-1v-4a1 1 0 00-1-1zM4 4S2 5 2 7v1h4V7c0-2-2-3-2-3z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMosqueSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
