import * as React from "react";

function SvgChalkboardTeacherSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.5 15c-.075 0-.15.01-.22.034-.406.132-.832.216-1.28.216-.448 0-.874-.084-1.28-.216A.714.714 0 005.5 15 3.5 3.5 0 002 18.52c.004.82.68 1.48 1.5 1.48h7c.821 0 1.496-.66 1.5-1.48A3.5 3.5 0 008.5 15zM7 14a3 3 0 100-6 3 3 0 000 6zM20.5 4h-12C7.673 4 7 4.695 7 5.55V7c.732 0 1.41.212 2 .556V6h11v9h-2v-2h-4v2h-2.383a3.982 3.982 0 011.24 2H20.5c.827 0 1.5-.695 1.5-1.55v-9.9C22 4.695 21.327 4 20.5 4z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChalkboardTeacherSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
