import * as React from "react";

function SvgAmericanSignLanguageInterpretingSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.08 9.907a2.614 2.614 0 00-2.023-.121c1.33 0 2.225.64 2.674 1.58a.835.835 0 01-.738 1.19c.576 0 1.006.63.738 1.19a2.794 2.794 0 01-2.51 1.589c-.017 0-2.952-.27-2.952-.27l-2.075 1.043a.555.555 0 01-.747-.243l-1.389-2.78a.567.567 0 01.217-.737l1.815-1.034 1.285-2.318A6.915 6.915 0 017.867 4.41a.829.829 0 011.172.113.83.83 0 01-.113 1.173A5.58 5.58 0 007.91 6.807a5.225 5.225 0 012.319-.877.82.82 0 01.929.721.82.82 0 01-.72.93c-.505.06-.973.234-1.381.477.816-.179 1.834-.098 2.752.347.417.209.59.704.382 1.12a.837.837 0 01-1.111.382zm-.851 2.171a1.112 1.112 0 00-1.433-.55 1.11 1.11 0 00-.686 1.028 1.112 1.112 0 002.119.478.829.829 0 01.729-.478.83.83 0 01-.73-.478zm11.713-1.163c.13.269.035.59-.217.738l-1.815 1.033-1.285 2.319a6.915 6.915 0 01-2.492 4.585.83.83 0 01-1.172-.113.83.83 0 01.113-1.172c.39-.33.738-.703 1.016-1.112a5.224 5.224 0 01-2.319.877c-.313.079-.86-.176-.929-.72a.82.82 0 01.72-.93c.505-.06.973-.234 1.382-.477-.817.178-1.835.098-2.753-.348a.832.832 0 01-.382-1.12.837.837 0 011.111-.382c.635.317 1.38.35 2.024.122-1.332 0-2.226-.64-2.675-1.58a.835.835 0 01.738-1.19.835.835 0 01-.738-1.19 2.795 2.795 0 012.562-1.59l2.9.27 2.075-1.042a.555.555 0 01.747.243l1.389 2.779zm-7.164-.582c-.434 0-.816.243-1.007.634a.829.829 0 01-.73.477.83.83 0 01.73.478 1.112 1.112 0 001.433.55 1.109 1.109 0 00.686-1.027c0-.67-.557-1.112-1.112-1.112z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAmericanSignLanguageInterpretingSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
