import * as React from "react";

function SvgUserTimesSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.427 11.5l1.425-1.425a.504.504 0 000-.713l-.712-.712a.504.504 0 00-.713 0l-1.425 1.425-1.425-1.425a.504.504 0 00-.713 0l-.712.712a.504.504 0 000 .713l1.425 1.425-1.425 1.425a.504.504 0 000 .713l.712.712a.504.504 0 00.713 0l1.425-1.425 1.425 1.425a.504.504 0 00.713 0l.712-.712a.504.504 0 000-.713L20.427 11.5zM9.001 12a4 4 0 100-8 4 4 0 000 8zm2.8 1h-.522a5.446 5.446 0 01-4.557 0H6.2A4.202 4.202 0 002 17.2v1.3A1.5 1.5 0 003.5 20h11.002a1.5 1.5 0 001.5-1.5v-1.3c0-2.318-1.882-4.2-4.2-4.2z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgUserTimesSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
