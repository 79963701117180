import * as React from "react";

function SvgChevronUpToTopSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.994 7.903c-.326 0-.628.145-.858.386l-8.798 9.003a1.123 1.123 0 00-.338.81c0 .652.495 1.148 1.148 1.148.302 0 .604-.12.822-.338l8.024-8.218 8.036 8.218c.218.217.496.338.822.338.64 0 1.148-.495 1.148-1.148 0-.314-.12-.592-.338-.81L12.864 8.29c-.254-.253-.532-.386-.87-.386z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5.125C2 4.504 2.504 4 3.125 4h17.75a1.125 1.125 0 010 2.25H3.125A1.125 1.125 0 012 5.125z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChevronUpToTopSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
