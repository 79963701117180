import * as React from "react";

function SvgCashRegisterSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.968 16.797l-1.043-6.25a1.25 1.25 0 00-1.234-1.043h-9.562v-2.5h3.75a.627.627 0 00.624-.625V2.625A.627.627 0 0013.88 2h-10a.627.627 0 00-.625.625v3.75c0 .344.281.625.625.625h3.75v2.5H4.312c-.609 0-1.132.442-1.234 1.043l-1.043 6.25a2.424 2.424 0 00-.035.41v3.547C2 21.44 2.559 22 3.25 22h17.5c.69 0 1.25-.559 1.25-1.25v-3.543a2.077 2.077 0 00-.032-.41zm-9.027-5.11c0-.343.281-.624.625-.624h.625c.344 0 .625.28.625.625v.625a.627.627 0 01-.625.625h-.625a.627.627 0 01-.625-.625v-.625zm-1.25 2.5h.625c.344 0 .625.282.625.626v.624a.627.627 0 01-.625.626h-.625a.627.627 0 01-.625-.625v-.626c0-.343.281-.624.625-.624zm-1.25-3.124c.344 0 .625.28.625.625v.625a.627.627 0 01-.625.625h-.625a.627.627 0 01-.625-.625v-.625c0-.344.281-.625.625-.625h.625zM5.13 5.125v-1.25h7.5v1.25h-7.5zm1.562 7.813h-.625a.627.627 0 01-.625-.625v-.625c0-.344.282-.625.625-.625h.625c.344 0 .625.28.625.625v.625a.627.627 0 01-.625.625zm.625 2.5v-.626c0-.343.281-.624.625-.624h.625c.344 0 .625.28.625.624v.626a.627.627 0 01-.625.624h-.625a.627.627 0 01-.625-.625zm8.437 4.374c0 .172-.14.313-.312.313H8.566a.313.313 0 01-.312-.313v-.625c0-.172.14-.312.312-.312h6.875c.172 0 .312.14.312.312v.625zm.938-4.375a.627.627 0 01-.625.626h-.625a.627.627 0 01-.625-.625v-.626c0-.343.281-.624.625-.624h.625c.344 0 .625.28.625.624v.626zm1.875-3.124a.627.627 0 01-.625.625h-.625a.627.627 0 01-.625-.625v-.625c0-.344.281-.625.625-.625h.625c.344 0 .625.28.625.625v.625z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCashRegisterSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
