import * as React from "react";

function SvgPizzaSliceSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.205 2.006a1.288 1.288 0 00-1.38.942l-.578 2.198c7.329.215 12.287 5.103 12.597 12.384l2.224-.617c.614-.17.996-.769.923-1.402-.816-7.062-6.68-12.834-13.786-13.505zM5.921 6.381l-3.9 14.825a.633.633 0 00.781.771l14.805-4.106C17.441 11.04 12.8 6.46 5.921 6.383V6.38zM7 18.251a1.25 1.25 0 110-2.501 1.25 1.25 0 010 2.5zm1.875-5.938a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zm4.062 4.063a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPizzaSliceSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
