import * as React from "react";

function SvgStreamSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2.625 7h16.25c.345 0 .625-.28.625-.625v-2.5a.625.625 0 00-.625-.625H2.625A.625.625 0 002 3.875v2.5c0 .345.28.625.625.625zm18.75 3.125H5.125a.625.625 0 00-.625.625v2.5c0 .345.28.625.625.625h16.25c.345 0 .625-.28.625-.625v-2.5a.625.625 0 00-.625-.625zM18.875 17H2.625a.625.625 0 00-.625.625v2.5c0 .345.28.625.625.625h16.25c.345 0 .625-.28.625-.625v-2.5a.625.625 0 00-.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgStreamSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
