import * as React from "react";

function SvgShapesSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7 12a5 5 0 100 10 5 5 0 000-10zm14.804-2.143l-4.176-7.142a1.456 1.456 0 00-2.506 0l-4.177 7.142C10.39 10.81 11.085 12 12.2 12h8.352c1.114 0 1.81-1.19 1.253-2.143zM20.75 13.25H14.5a1.25 1.25 0 00-1.25 1.25v6.25A1.25 1.25 0 0014.5 22h6.25A1.25 1.25 0 0022 20.75V14.5a1.25 1.25 0 00-1.25-1.25z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgShapesSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
