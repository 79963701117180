import * as React from "react";

function SvgLongArrowRightSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.429 7.37l4.205 4.239c.488.492.488 1.29 0 1.782l-4.205 4.24a1.243 1.243 0 01-1.768 0 1.268 1.268 0 010-1.783l2.071-2.088H3.25c-.69 0-1.25-.564-1.25-1.26 0-.696.56-1.26 1.25-1.26h14.482l-2.07-2.088a1.268 1.268 0 010-1.783 1.243 1.243 0 011.767 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLongArrowRightSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
