import * as React from "react";

function SvgDownloadSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.438 2h3.124c.52 0 .938.418.938.938V9.5h3.426a.78.78 0 01.55 1.332l-5.94 5.945a.757.757 0 01-1.067 0l-5.95-5.945A.78.78 0 016.07 9.5H9.5V2.937c0-.519.418-.937.938-.937zM22 16.688v4.375c0 .519-.418.937-.938.937H2.938A.935.935 0 012 21.062v-4.375c0-.519.418-.937.938-.937h5.73l1.914 1.914c.785.785 2.05.785 2.836 0l1.914-1.914h5.73c.52 0 .938.418.938.938zm-4.844 3.437a.783.783 0 00-.781-.781.783.783 0 00-.781.781c0 .43.351.781.781.781.43 0 .781-.351.781-.781zm2.5 0a.783.783 0 00-.781-.781.783.783 0 00-.781.781c0 .43.351.781.781.781.43 0 .781-.351.781-.781z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDownloadSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
