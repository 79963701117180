import * as React from "react";

function SvgHorseHeadSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.915 14.987l-2.73-6.418a4.825 4.825 0 00-3.634-3.094c.703-.414 1.809-1.402 1.336-3.215a.363.363 0 00-.469-.238L8.52 4.979C3.402 6.819 2 11.331 2 17.577v3.172c0 .691.559 1.25 1.25 1.25h9.227c.93 0 1.535-.976 1.117-1.809L12 17v-.027a4.992 4.992 0 01-4.074-2.72.31.31 0 01.062-.363l.473-.472a.312.312 0 01.504.094 3.73 3.73 0 003.414 2.242c.672 0 1.29-.2 1.828-.516l1.797 2.496c.235.328.614.52 1.016.52h1.965c.332 0 .648-.133.883-.367l1.77-1.555c.347-.356.456-.883.277-1.344zm-7.102-4.238a.935.935 0 01-.938-.938c0-.52.418-.937.938-.937s.937.418.937.937c0 .52-.418.938-.937.938z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHorseHeadSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
