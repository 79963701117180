import * as React from "react";

function SvgMarsStrokeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.37 2.003h-4.114a.626.626 0 00-.442 1.067l.88.88-.911.911-.735-.734a.625.625 0 00-.885 0L13.69 5.595a.625.625 0 000 .886l.734.734-.937.937a7.462 7.462 0 00-3.994-1.15C5.358 7.001 2 10.36 2 14.498a7.5 7.5 0 007.498 7.498 7.5 7.5 0 006.347-11.491l.938-.938.734.735c.245.244.64.244.885 0l1.474-1.474a.625.625 0 000-.885l-.735-.734.912-.912.88.88A.625.625 0 0022 6.736V2.627a.635.635 0 00-.63-.624zM9.498 18.665a4.171 4.171 0 01-4.166-4.166 4.171 4.171 0 014.166-4.165 4.171 4.171 0 014.166 4.165 4.171 4.171 0 01-4.166 4.166z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMarsStrokeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
