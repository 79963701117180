import * as React from "react";

function SvgTrashAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M4.5 20.125A1.875 1.875 0 006.375 22h11.25a1.875 1.875 0 001.875-1.875V7h-15v13.125zm10.625-10a.625.625 0 111.25 0v8.75a.625.625 0 01-1.25 0v-8.75zm-3.75 0a.625.625 0 111.25 0v8.75a.625.625 0 01-1.25 0v-8.75zm-3.75 0a.625.625 0 011.25 0v8.75a.625.625 0 11-1.25 0v-8.75zm12.5-6.875h-4.688l-.367-.73a.938.938 0 00-.84-.52H9.766a.927.927 0 00-.836.52l-.367.73H3.875a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h16.25a.625.625 0 00.625-.625v-1.25a.625.625 0 00-.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTrashAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
