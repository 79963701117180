import * as React from "react";

function SvgVenusMarsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.583 3.111H18.84a.417.417 0 00-.295.712l.587.587L17.44 6.1a4.976 4.976 0 00-2.663-.767c-1.17 0-2.243.403-3.097 1.073a6.13 6.13 0 011.114 1.983 2.766 2.766 0 011.983-.833 2.782 2.782 0 012.778 2.777 2.782 2.782 0 01-2.778 2.778c-.774 0-1.48-.32-1.983-.833a6.069 6.069 0 01-1.114 1.982 5.001 5.001 0 007.33-6.59l1.69-1.69.587.586A.416.416 0 0022 6.271V3.528a.418.418 0 00-.417-.417zM7 5.333a5.001 5.001 0 00-1.111 9.875V17h-1.25a.418.418 0 00-.417.417v1.388c0 .23.188.417.417.417h1.25v1.25c0 .23.187.417.417.417h1.388c.23 0 .417-.188.417-.417v-1.25h1.25c.23 0 .417-.187.417-.417v-1.388A.418.418 0 009.36 17h-1.25v-1.792A5.004 5.004 0 0012 10.333c0-2.76-2.24-5-5-5zm0 7.778a2.781 2.781 0 01-2.778-2.778A2.781 2.781 0 017 7.556a2.781 2.781 0 012.778 2.777A2.781 2.781 0 017 13.111z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgVenusMarsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
