import * as React from "react";

function SvgWriteTestOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.427 3.624s.22-.44-.22-.44H3.767s-1.76-.11-1.76 1.54v14.19s-.22 1.76 1.98 1.76h14.96s1.54-.11 1.54-1.76v-7.92s-.11-.55-.55 0l-.88.88s-.11 0-.11.55v6.71H3.657v-14.3h10.23s.22 0 .44-.22l1.1-.99z"
        fill="currentColor"
      />
      <path
        d="M21.477 5.164l-1.32-1.32c-1.65-1.76-3.08-.22-3.08-.22l-9.13 9.02-.44 3.19c.11 1.76 1.65 1.54 1.65 1.54l3.08-.33 9.35-9.35c.99-1.54-.11-2.53-.11-2.53zm-10.01 10.34l-2.31.22.22-2.31 6.49-6.49 1.98 1.98-6.38 6.6zm8.8-8.69l-1.1 1.1-2.09-2.09.99-.99c.33-.33.66-.11.66-.11l1.32 1.32c.44.44.22.77.22.77zM11.246 7.474s.22-.44-.22-.44h-5.5c-.22 0-.33.11-.33.33v1.1c0 .22.11.33.33.33h4.18s.22 0 .44-.22l1.1-1.1zM14.216 16.494s-.22.44.22.44h2.97c.22 0 .33-.11.33-.33v-.99c0-.22-.11-.33-.33-.33h-.55s-1.32 0-1.54.22l-1.1.99zM6.956 15.724s.11-.44-.22-.44h-1.21c-.22 0-.33.11-.33.33v.99c0 .22.11.33.33.33h.88s.44 0 .44-.22l.11-.99zM8.276 11.654s.22-.44-.22-.44h-2.53c-.22 0-.33.11-.33.33v.99c0 .11.22.33.33.33h1.21s.22 0 .44-.22l1.1-.99z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgWriteTestOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
