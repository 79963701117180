import * as React from "react";

function SvgBalanceScaleRightSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M5 18.5v1a.5.5 0 00.5.5h7a.5.5 0 00.5-.5V8.79c.143-.063.279-.137.406-.223l4.439 1.488a.5.5 0 00.633-.315l.318-.948a.5.5 0 00-.315-.633l-4.013-1.345c.014-.104.032-.207.032-.314A2.5 2.5 0 0012 4a2.49 2.49 0 00-2.16 1.262L6.155 4.026a.5.5 0 00-.633.315l-.318.948a.5.5 0 00.315.633l4.125 1.383A2.497 2.497 0 0011 8.79V18H5.5a.5.5 0 00-.5.5zm-3-5C2 14.88 3.79 16 6 16s4-1.12 4-2.5c0-.49.064-.226-2.658-5.672-.553-1.105-2.132-1.103-2.684 0-2.7 5.4-2.657 5.167-2.657 5.672H2zm1.75-.5L6 8.5 8.25 13h-4.5zM14 17.5c0 1.38 1.79 2.5 4 2.5s4-1.12 4-2.5c0-.49.064-.226-2.658-5.672-.553-1.105-2.132-1.103-2.684 0-2.7 5.4-2.657 5.166-2.657 5.672zm1.75-.5L18 12.5l2.25 4.5h-4.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBalanceScaleRightSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
