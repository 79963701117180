import * as React from "react";

function SvgGuitarSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.635 3.524l-1.157-1.158a1.25 1.25 0 00-1.768 0l-1.808 1.81a1.373 1.373 0 00-.334.539l-.475 1.426-2.979 2.98c-.475-.343-.996-.616-1.566-.749-1.307-.304-2.617-.034-3.511.86a3.221 3.221 0 00-.79 1.307c-.235.725-.908 1.277-1.647 1.347-.926.088-1.787.447-2.446 1.11-1.783 1.778-1.463 4.982.705 7.148 2.167 2.166 5.368 2.482 7.148.706.664-.66 1.022-1.52 1.111-2.45.069-.736.62-1.412 1.345-1.646a3.226 3.226 0 001.308-.791c.893-.894 1.162-2.202.86-3.506-.133-.572-.406-1.094-.749-1.57l2.978-2.98 1.425-.474c.203-.068.388-.182.54-.333l1.809-1.81a1.25 1.25 0 00.001-1.766zM10.125 15.75a1.875 1.875 0 110-3.75 1.875 1.875 0 010 3.75z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGuitarSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
