import * as React from "react";

function SvgSplotchSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.449 9.652l-2.62-.896c-.753-.258-1.31-.817-1.49-1.496l-.624-2.361c-.453-1.71-2.991-2.232-4.296-.884L9.616 5.877c-.518.536-1.31.818-2.117.754l-2.808-.22c-2.033-.158-3.395 1.754-2.305 3.236l1.505 2.048c.433.589.5 1.323.181 1.963l-1.11 2.225c-.804 1.611.892 3.315 2.87 2.883l2.733-.596c.786-.172 1.62 0 2.23.458l2.121 1.595c1.536 1.154 3.947.295 4.08-1.454l.184-2.417c.053-.695.5-1.322 1.197-1.679l2.421-1.24c1.754-.897 1.547-3.131-.35-3.78z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSplotchSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
