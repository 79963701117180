import * as React from "react";

function SvgDizzySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm-3.871 8.33l-1.157 1.158c-.597.597-1.524-.303-.912-.911l1.158-1.158L6.06 8.262c-.604-.605.311-1.516.912-.911l1.157 1.157 1.157-1.157c.605-.605 1.516.31.912.911L9.04 9.42l1.158 1.158c.612.613-.319 1.508-.912.91L8.13 10.332zM12 18.453a2.583 2.583 0 01-2.58-2.581 2.583 2.583 0 012.58-2.58 2.583 2.583 0 012.58 2.58 2.583 2.583 0 01-2.58 2.58zm5.94-7.875c.612.613-.319 1.508-.912.91l-1.157-1.156-1.157 1.157c-.597.597-1.524-.303-.912-.911l1.158-1.158-1.158-1.157c-.604-.605.31-1.516.912-.911l1.157 1.157 1.157-1.157c.605-.605 1.516.31.912.911L16.781 9.42l1.157 1.158z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDizzySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
