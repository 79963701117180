import * as React from "react";

function SvgJointSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.886 9.66A2.61 2.61 0 0117 11.802v.947c0 .138.112.25.25.25h1.5a.25.25 0 00.25-.25v-.947a4.11 4.11 0 00-1.76-3.377C16.494 7.906 16 7.104 16 6.197V4.25a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25v2.075c0 1.365.768 2.55 1.886 3.334zm-7.793 5.558A16.406 16.406 0 002 17.5a16.447 16.447 0 008.717 2.5h2.768l-3.523-4.11a2.03 2.03 0 00-1.87-.672zM19.29 6.722a.634.634 0 01-.29-.525V4.25a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25v1.947c0 .688.318 1.357.895 1.731A4.608 4.608 0 0120 11.803v.947c0 .138.112.25.25.25h1.5a.25.25 0 00.25-.25v-.947a6.105 6.105 0 00-2.71-5.081zm-6.012 8.28l-2.757.005 3.681 4.295a1.999 1.999 0 001.519.698h2.764l-3.686-4.3a2 2 0 00-1.521-.698zM21.25 15H15.5l3.687 4.302a1.999 1.999 0 001.519.698h.544a.75.75 0 00.75-.75v-3.5a.75.75 0 00-.75-.75z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgJointSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
