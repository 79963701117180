import * as React from "react";

function SvgFireSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.938 2.932c0-.93-1.198-1.28-1.725-.51-4.838 7.072 2.037 7.39 2.037 10.828a2.5 2.5 0 01-2.533 2.5c-1.374-.018-2.467-1.163-2.467-2.537v-3.34c0-.848-1.034-1.26-1.618-.645C5.586 10.327 4.5 12.208 4.5 14.5c0 4.136 3.364 7.5 7.5 7.5s7.5-3.364 7.5-7.5c0-6.652-6.563-7.539-6.563-11.568z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFireSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
