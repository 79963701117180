import * as React from "react";

function SvgBuildingOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.25 7.781V6.22a.47.47 0 01.469-.469h1.562a.47.47 0 01.469.469V7.78a.47.47 0 01-.469.469H8.72a.47.47 0 01-.469-.469zm5.469.469h1.562a.47.47 0 00.469-.469V6.22a.47.47 0 00-.469-.469H13.72a.47.47 0 00-.469.469V7.78c0 .258.21.469.469.469zm-5 3.75h1.562a.47.47 0 00.469-.469V9.97a.47.47 0 00-.469-.469H8.72a.47.47 0 00-.469.469v1.562c0 .258.21.469.469.469zm5 0h1.562a.47.47 0 00.469-.469V9.97a.47.47 0 00-.469-.469H13.72a.47.47 0 00-.469.469v1.562c0 .258.21.469.469.469zm-2.969 3.281V13.72a.47.47 0 00-.469-.469H8.72a.47.47 0 00-.469.469v1.562c0 .258.21.469.469.469h1.562a.47.47 0 00.469-.469zm2.969.469h1.562a.47.47 0 00.469-.469V13.72a.47.47 0 00-.469-.469H13.72a.47.47 0 00-.469.469v1.562c0 .258.21.469.469.469zm7.031 4.844V22H3.25v-1.406a.47.47 0 01.469-.469h.761V2.937c0-.519.418-.937.938-.937h13.164c.52 0 .938.418.938.938v17.187h.761a.47.47 0 01.469.469zm-14.395-.508h4.395v-2.617a.47.47 0 01.469-.469h1.562a.47.47 0 01.469.469v2.617h4.395V3.914l-11.27-.039-.02 16.21z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBuildingOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
