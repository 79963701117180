import * as React from "react";

function SvgHandRockSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.156 5.123a1.875 1.875 0 00-1.906 1.875h-.313V5.78c0-1.027-.816-1.886-1.843-1.906a1.875 1.875 0 00-1.907 1.875v1.25h-.312V5.154c0-1.027-.816-1.886-1.844-1.906a1.875 1.875 0 00-1.906 1.875v1.875h-.313V5.78c0-1.027-.816-1.886-1.843-1.906a1.875 1.875 0 00-1.907 1.875v5.313l-.312-.278V8.904c0-1.027-.816-1.886-1.844-1.906A1.875 1.875 0 002 8.873v2.594c0 1.07.457 2.09 1.258 2.805L7.62 18.15c.399.355.629.867.629 1.402v.261c0 .52.418.938.938.938h9.374c.52 0 .938-.418.938-.938v-.113c0-.5.102-.996.293-1.457l1.914-4.543c.195-.46.293-.957.293-1.457V7.03c0-1.027-.816-1.89-1.844-1.906z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHandRockSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
