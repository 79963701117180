import * as React from "react";

function SvgFootballBallSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.415 4.109A2.129 2.129 0 0019.911 2.6 18.068 18.068 0 0015.685 2L22 8.323a18.114 18.114 0 00-.585-4.214zM2.585 19.89A2.13 2.13 0 004.089 21.4c.963.258 2.467.565 4.226.601L2 15.677c.032 1.755.33 3.25.585 4.214zm-.416-6.786l8.718 8.734c5.343-.782 10.032-4.787 10.948-10.944l-8.722-8.734C7.77 2.944 3.08 6.948 2.169 13.105zM14.964 8.12a.324.324 0 01.455 0l.456.456a.324.324 0 010 .455l-1.141 1.141 1.141 1.142a.324.324 0 010 .455l-.456.456a.324.324 0 01-.455 0l-1.141-1.141-.912.915 1.141 1.141a.324.324 0 010 .456l-.455.455a.324.324 0 01-.456 0L12 12.912l-.911.91 1.14 1.142a.324.324 0 010 .455l-.455.456a.324.324 0 01-.455 0l-1.142-1.141-1.14 1.141a.324.324 0 01-.456 0l-.456-.456a.324.324 0 010-.455l1.141-1.141-1.141-1.138a.324.324 0 010-.455l.456-.456a.324.324 0 01.455 0l1.141 1.141.912-.911-1.141-1.141a.324.324 0 010-.456l.455-.455a.324.324 0 01.456 0L12 11.092l.911-.91-1.14-1.142a.324.324 0 010-.455l.455-.456a.324.324 0 01.455 0l1.142 1.141 1.14-1.149z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFootballBallSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
