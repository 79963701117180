import * as React from "react";

function SvgListSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M5.125 16.375h-2.5A.625.625 0 002 17v2.5a.625.625 0 00.625.625h2.5a.625.625 0 00.625-.625V17a.625.625 0 00-.625-.625zm0-12.5h-2.5A.625.625 0 002 4.5V7a.625.625 0 00.625.625h2.5A.625.625 0 005.75 7V4.5a.625.625 0 00-.625-.625zm0 6.25h-2.5A.625.625 0 002 10.75v2.5a.625.625 0 00.625.625h2.5a.625.625 0 00.625-.625v-2.5a.625.625 0 00-.625-.625zM21.375 17h-12.5a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h12.5a.624.624 0 00.625-.625v-1.25a.624.624 0 00-.625-.625zm0-12.5h-12.5a.625.625 0 00-.625.625v1.25A.625.625 0 008.875 7h12.5A.625.625 0 0022 6.375v-1.25a.625.625 0 00-.625-.625zm0 6.25h-12.5a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h12.5a.624.624 0 00.625-.625v-1.25a.624.624 0 00-.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgListSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
