import * as React from "react";

function SvgEllipsisVSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 9.097A2.901 2.901 0 0114.903 12 2.901 2.901 0 0112 14.903 2.901 2.901 0 019.097 12 2.901 2.901 0 0112 9.097zM9.097 4.903A2.901 2.901 0 0012 7.806a2.901 2.901 0 002.903-2.903A2.901 2.901 0 0012 2a2.901 2.901 0 00-2.903 2.903zm0 14.194A2.901 2.901 0 0012 22a2.901 2.901 0 002.903-2.903A2.901 2.901 0 0012 16.193a2.901 2.901 0 00-2.903 2.904z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgEllipsisVSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
