import * as React from "react";

function SvgAdSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.153 12.625h1.444l-.722-2.079-.722 2.079zM15.75 12a.939.939 0 000 1.875.939.939 0 000-1.875zm4.375-7.5H3.875C2.84 4.5 2 5.34 2 6.375v11.25c0 1.035.84 1.875 1.875 1.875h16.25c1.035 0 1.875-.84 1.875-1.875V6.375C22 5.34 21.16 4.5 20.125 4.5zm-8.337 11.25h-.661a.626.626 0 01-.591-.42l-.288-.83H7.502l-.288.83a.625.625 0 01-.59.42h-.662a.625.625 0 01-.59-.83l2.097-6.04a.937.937 0 01.885-.63h1.042a.937.937 0 01.885.63l2.098 6.04a.625.625 0 01-.59.83zm6.774-.625c0 .345-.28.625-.625.625h-.625a.616.616 0 01-.468-.222 2.816 2.816 0 01-3.907-2.59 2.816 2.816 0 012.813-2.813c.33 0 .643.068.938.173V8.875c0-.345.28-.625.625-.625h.625c.345 0 .625.28.625.625v6.25z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAdSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
