import * as React from "react";

function SvgCarBatterySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.75 7H19.5V5.125a.625.625 0 00-.625-.625h-3.75a.625.625 0 00-.625.625V7h-5V5.125a.625.625 0 00-.625-.625h-3.75a.625.625 0 00-.625.625V7H3.25C2.56 7 2 7.56 2 8.25v10c0 .69.56 1.25 1.25 1.25h17.5c.69 0 1.25-.56 1.25-1.25v-10C22 7.56 21.44 7 20.75 7zM9.5 12.313c0 .172-.14.312-.313.312H4.814a.312.312 0 01-.313-.313v-.624c0-.173.14-.313.313-.313h4.375c.172 0 .312.14.312.313v.624zm10 0c0 .172-.14.312-.313.312h-1.562v1.563c0 .172-.14.312-.313.312h-.625a.312.312 0 01-.312-.313v-1.562h-1.563a.312.312 0 01-.312-.313v-.624c0-.173.14-.313.313-.313h1.562V9.812c0-.172.14-.312.313-.312h.625c.172 0 .312.14.312.313v1.562h1.563c.172 0 .312.14.312.313v.624z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCarBatterySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
