import * as React from "react";

function SvgTheaterMasksSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.464 11.66c-1.12.327-1.873 1.288-1.797 2.316.356-.397.9-.74 1.559-.966l.238-1.35zm-3.47 1.559l-.991-5.61c-.01-.05.008-.082.007-.083a12.202 12.202 0 015.907-1.531c.05 0 .1.005.151.006.436-.421.992-.714 1.612-.813.59-.094 1.19-.142 1.787-.166-.312-.436-.765-.757-1.305-.844a14.161 14.161 0 00-9.124 1.605c-.756.42-1.16 1.294-1.005 2.173l.991 5.61c.445 2.515 4.26 4.44 6.39 4.44.112 0 .21-.015.312-.026a8.39 8.39 0 01-1.234-2.112c-1.488-.405-3.314-1.613-3.498-2.65zm3.049-4.296a1.234 1.234 0 00-.128-.356 1.968 1.968 0 01-1.103.572 1.97 1.97 0 01-1.234-.161c-.016.123-.02.25.003.378a1.25 1.25 0 002.462-.433zm12.92-1.145a14.166 14.166 0 00-9.125-1.605c-.853.137-1.534.821-1.689 1.7l-.991 5.609c-.481 2.72 2.977 6.133 4.947 6.48 1.97.346 6.39-1.681 6.87-4.402l.992-5.61c.155-.878-.25-1.753-1.005-2.172zm-8.54 3.025a1.25 1.25 0 012.463.433c-.023.129-.07.246-.127.356a1.97 1.97 0 00-1.104-.572 1.97 1.97 0 00-1.234.162c-.016-.124-.02-.25.003-.379zm2.203 6.211c-1.74-.306-2.923-1.852-2.783-3.528.644.798 1.757 1.443 3.11 1.68 1.352.239 2.619.012 3.497-.518-.443 1.623-2.084 2.672-3.824 2.366zm4.072-4.73a1.97 1.97 0 00-1.104-.572 1.97 1.97 0 00-1.234.162c-.016-.123-.02-.25.003-.378a1.25 1.25 0 012.462.433c-.023.129-.07.246-.127.356z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTheaterMasksSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
