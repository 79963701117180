import * as React from "react";

function SvgTrashAltOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.719 18.25h.937a.469.469 0 00.469-.469V9.344a.469.469 0 00-.469-.469h-.937a.469.469 0 00-.469.469v8.437a.469.469 0 00.469.469zm6.406-13.125h-3.22L15.579 2.91A1.874 1.874 0 0013.969 2h-3.938a1.875 1.875 0 00-1.608.91L7.094 5.125H3.875a.625.625 0 00-.625.625v.625A.625.625 0 003.875 7H4.5v13.125A1.875 1.875 0 006.375 22h11.25a1.875 1.875 0 001.875-1.875V7h.625a.625.625 0 00.625-.625V5.75a.625.625 0 00-.625-.625zM9.963 3.989a.234.234 0 01.201-.114h3.672a.235.235 0 01.201.114l.682 1.136H9.281l.681-1.136zm7.662 16.136H6.375V7h11.25v13.125zM9.344 18.25h.937a.469.469 0 00.469-.469V9.344a.469.469 0 00-.469-.469h-.937a.469.469 0 00-.469.469v8.437a.469.469 0 00.469.469z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTrashAltOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
