import * as React from "react";

function SvgBabySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 8.25A3.124 3.124 0 1012 2a3.124 3.124 0 100 6.25zm-2.086 9.719l1-1.25-2.403-2-1.793 2.242a1.565 1.565 0 00-.031 1.914l1.875 2.5a1.56 1.56 0 002.188.313 1.563 1.563 0 00.312-2.188l-1.148-1.531zm5.574-3.25l-2.403 2 1 1.25-1.148 1.531a1.563 1.563 0 002.5 1.875l1.875-2.5c.426-.57.414-1.36-.031-1.914l-1.793-2.242zm3.726-7.055a1.564 1.564 0 00-2.175-.383l-1.586 1.114a6.042 6.042 0 01-6.907 0L6.96 7.285a1.564 1.564 0 00-2.175.38 1.564 1.564 0 00.379 2.175l1.585 1.113a9.27 9.27 0 002.125 1.09v1.207h6.25v-1.203a9.27 9.27 0 002.126-1.09l1.585-1.113c.707-.5.875-1.473.38-2.18z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBabySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
