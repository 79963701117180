import * as React from "react";

function SvgFileAudioSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.25 7.313V2H5.437a.935.935 0 00-.937.938v18.125c0 .519.418.937.938.937h13.125c.519 0 .937-.418.937-.938V8.25h-5.313a.94.94 0 01-.937-.938zm-2.5 10.468a.47.47 0 01-.8.332l-1.387-1.425H7.468a.47.47 0 01-.469-.47v-2.187a.47.47 0 01.469-.469h1.093l1.387-1.425a.469.469 0 01.801.332v5.312zm1.297-1.86a.932.932 0 000-1.304c-.863-.89.476-2.195 1.344-1.308a2.811 2.811 0 010 3.921c-.852.872-2.223-.406-1.344-1.308zm3.36-4.573a5.622 5.622 0 010 7.843c-.852.875-2.227-.402-1.345-1.308a3.746 3.746 0 000-5.227c-.863-.89.481-2.199 1.344-1.308zM19.5 6.762V7h-5V2h.238c.25 0 .489.098.664.273l3.825 3.829a.935.935 0 01.273.66z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFileAudioSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
