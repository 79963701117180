import * as React from "react";

function SvgIndentSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.22 16.79l4.285-4.285a.714.714 0 000-1.01L3.219 7.209C2.771 6.762 2 7.079 2 7.714v8.572c0 .639.774.95 1.22.504zm18.066 2.353H2.714a.714.714 0 00-.714.714v1.429a.714.714 0 00.714.714h18.572a.715.715 0 00.714-.714v-1.429a.714.714 0 00-.714-.714zm.141-5.714H11.144a.572.572 0 00-.573.572v1.712a.573.573 0 00.573.573h10.283a.57.57 0 00.573-.573V14a.57.57 0 00-.573-.572zm0-5.715H11.144a.573.573 0 00-.573.573V10a.573.573 0 00.573.572h10.283A.57.57 0 0022 10V8.287a.57.57 0 00-.573-.573zM21.286 2H2.714A.714.714 0 002 2.714v1.429a.714.714 0 00.714.714h18.572A.714.714 0 0022 4.143V2.714A.715.715 0 0021.286 2z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgIndentSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
