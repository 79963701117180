import * as React from "react";

function SvgMortarPestleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.591 4.38a1.394 1.394 0 00-1.822-2.101L11.81 8.25h5.912l3.87-3.87zm-.216 5.12H2.625a.625.625 0 00-.625.625v1.25c0 .345.28.625.625.625h.625a7.497 7.497 0 004.732 6.966 5.011 5.011 0 00-.975 2.292.642.642 0 00.628.742h8.73a.642.642 0 00.628-.742 5.003 5.003 0 00-.975-2.292A7.497 7.497 0 0020.75 12h.625c.345 0 .625-.28.625-.625v-1.25a.625.625 0 00-.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMortarPestleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
