import * as React from "react";

function SvgSignalBadSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.011 14.92c.666 0 1.063-.414 1.08-1.107.037-2.629.109-6.122.127-8.625 0-.675-.505-1.188-1.207-1.188-.711 0-1.215.513-1.215 1.188.027 2.494.099 5.996.135 8.625.018.693.405 1.107 1.08 1.107zM9.5 5.062C6.484 5.63 3.756 7.16 2.108 9.185c-.153.172-.144.415.027.595l1.207 1.224c.198.198.495.19.684-.018a10.466 10.466 0 015.537-3.124l-.063-2.8zm4.987.01l-.054 2.8c2.107.422 3.997 1.494 5.564 3.123.18.19.468.18.666-.009L21.86 9.78c.18-.18.18-.423.036-.595-1.665-2.016-4.366-3.574-7.409-4.114zm-.081 5.14l-.054 2.917c.801.315 1.521.819 2.07 1.404.19.207.45.19.667-.009l1.341-1.296c.171-.171.19-.405.036-.576-.927-1.108-2.377-2.017-4.06-2.44zm-4.807.009c-1.684.423-3.124 1.305-4.06 2.43-.154.171-.145.397.035.577l1.342 1.314c.216.207.504.18.711-.045a5.659 5.659 0 012.026-1.377l-.054-2.9zm2.403 9.137c.864 0 1.567-.684 1.567-1.53s-.703-1.521-1.567-1.521-1.566.675-1.566 1.521c0 .846.702 1.53 1.566 1.53z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSignalBadSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
