import * as React from "react";

function SvgTiredSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm1.363 7.65l3.226-1.936c.468-.279.968.31.62.726l-1.354 1.625 1.355 1.625c.35.419-.158 1-.621.725l-3.226-1.935a.487.487 0 010-.83zM6.79 8.44c-.346-.416.154-1.005.621-.726l3.226 1.935a.484.484 0 010 .83l-3.226 1.936c-.463.275-.967-.306-.62-.725l1.354-1.625L6.79 8.44zM12 13.29c2.093 0 4.65 1.767 4.968 4.303.068.548-.323.992-.714.822-1.044-.447-2.597-.701-4.254-.701-1.657 0-3.21.254-4.254.701-.395.17-.782-.282-.714-.822.319-2.537 2.875-4.303 4.968-4.303z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTiredSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
