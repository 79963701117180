import * as React from "react";

function SvgHollyBerrySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.88 9.497a1.875 1.875 0 10-.002-3.75 1.875 1.875 0 00.001 3.75zm4.372-1.874a1.875 1.875 0 103.75-.001 1.875 1.875 0 00-3.75 0zm-1.249-1.875A1.875 1.875 0 1012.002 2a1.875 1.875 0 00.001 3.75zm-.633 5.432a.611.611 0 00-.929-.535c-1.34.792-2.788 1.277-4.244 1.413a.561.561 0 00-.453.789 9.67 9.67 0 01.574 1.71 1.155 1.155 0 01-.789 1.37 9.286 9.286 0 01-1.768.356.564.564 0 00-.457.789c.585 1.28.894 2.713.898 4.205a.716.716 0 001.077.624 10.187 10.187 0 014.092-1.323.561.561 0 00.453-.789 9.673 9.673 0 01-.574-1.71 1.155 1.155 0 01.789-1.37 9.278 9.278 0 011.769-.356.564.564 0 00.457-.789c-.606-1.335-.91-2.83-.895-4.384zm8.872 5.095a10.003 10.003 0 01-1.77-.355 1.155 1.155 0 01-.788-1.37c.14-.594.332-1.164.574-1.71a.561.561 0 00-.453-.79c-1.456-.136-2.905-.62-4.244-1.413a.614.614 0 00-.93.535c0 .062-.007.125-.007.191.008 1.3.273 2.565.777 3.67a1.797 1.797 0 01-.024 1.52c.192.046.387.085.578.144.582.175.93.777.789 1.37a9.785 9.785 0 01-.574 1.71.561.561 0 00.453.79c1.402.132 2.796.581 4.092 1.323a.715.715 0 001.078-.625c.007-1.492.312-2.928.898-4.205.168-.34-.07-.746-.45-.785z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHollyBerrySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
