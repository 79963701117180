import * as React from "react";

function SvgClipboardOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.625 4.5H14.5C14.5 3.121 13.379 2 12 2a2.502 2.502 0 00-2.5 2.5H6.375C5.34 4.5 4.5 5.34 4.5 6.375v13.75C4.5 21.16 5.34 22 6.375 22h11.25c1.035 0 1.875-.84 1.875-1.875V6.375c0-1.035-.84-1.875-1.875-1.875zM12 3.562c.52 0 .938.418.938.938s-.418.938-.938.938a.935.935 0 01-.938-.938c0-.52.418-.938.938-.938zm5.625 16.329a.235.235 0 01-.234.234H6.609a.235.235 0 01-.234-.234V6.609c0-.129.105-.234.234-.234H8.25v1.406c0 .258.21.469.469.469h6.562a.47.47 0 00.469-.469V6.375h1.64c.13 0 .235.105.235.234v13.282z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgClipboardOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
