import * as React from "react";

function SvgTeethOpenSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19 4H5a3 3 0 00-3 3v2a2 2 0 002 2h16a2 2 0 002-2V7a3 3 0 00-3-3zM7 9.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a1.5 1.5 0 113 0v1zm4.5 0a.5.5 0 01-.5.5H8.5a.5.5 0 01-.5-.5V7.75a1.75 1.75 0 113.5 0V9.5zm4.5 0a.5.5 0 01-.5.5H13a.5.5 0 01-.5-.5V7.75a1.75 1.75 0 113.5 0V9.5zm4 0a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a1.5 1.5 0 013 0v1zm0 4.5H4a2 2 0 00-2 2v1a3 3 0 003 3h14a3 3 0 003-3v-1a2 2 0 00-2-2zM7 16.5a1.5 1.5 0 01-3 0v-1a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1zm4.5-.25a1.75 1.75 0 11-3.5 0v-.75a.5.5 0 01.5-.5H11a.5.5 0 01.5.5v.75zm4.5 0a1.75 1.75 0 11-3.5 0v-.75a.5.5 0 01.5-.5h2.5a.5.5 0 01.5.5v.75zm4 .25a1.5 1.5 0 01-3 0v-1a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTeethOpenSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
