import * as React from "react";

function SvgForwardSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.552 11.038l-7.5-6.25c-.805-.668-2.051-.11-2.051.96V18.25c0 1.07 1.246 1.632 2.05.96l7.5-6.25c.598-.5.598-1.422 0-1.922zm-10 0L4.05 4.788C3.246 4.12 2 4.678 2 5.748V18.25c0 1.07 1.246 1.632 2.05.96l7.501-6.25c.598-.5.598-1.422 0-1.922z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgForwardSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
