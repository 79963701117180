import * as React from "react";

function SvgLocationArrowSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.364 2.137L3.123 9.633c-1.874.875-1.25 3.623.75 3.623h6.87v6.872c0 1.999 2.749 2.624 3.624.75l7.496-16.242c.624-1.5-1-3.124-2.5-2.499z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLocationArrowSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
