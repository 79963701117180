import * as React from "react";

function SvgStarOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.636 4.013L12 3.282l.364.731.709-.352m-1.437.352l3.351 5.275-.18-.363-1.912-3.843-.53-1.067.708-.354m-1.437.352l-.709-.352.709.353h0zm1.437-.352c-.438-.876-1.705-.887-2.146 0L8.486 8.574m4.587-4.913l2.441 4.913H8.486m6.248.677l-.116-.232-1.91-3.844-.53-1.067s0 0 0 0a.18.18 0 00-.07-.08.219.219 0 00-.11-.028.178.178 0 00-.176.106l2.912 5.145zm0 0l-.266-.038-.143.99.896-.446-.487-.506zm-6.248-.677l-5.462.79c-.98.14-1.372 1.34-.661 2.026l6.123-2.816z"
        stroke="currentColor"
        strokeWidth={2}
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgStarOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
