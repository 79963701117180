import * as React from "react";

function SvgBicycleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.016 10c-.512-.002-1.001.092-1.451.265l-2.428-3.91A.75.75 0 0013.5 6h-2a.5.5 0 00-.5.5V7a.5.5 0 00.5.5h1.583l.465.75H10v-.5a.5.5 0 00-.5-.5H6.767A.764.764 0 006 8.014a.75.75 0 00.75.736h1.523l-.92 1.485A3.994 3.994 0 006.01 10 4.012 4.012 0 002 13.976 4 4 0 009.874 15H11.5a.75.75 0 00.638-.355l2.686-4.34.467.752A3.99 3.99 0 0014 14.01c.005 2.15 1.81 3.97 3.96 3.99A4 4 0 0022 14.063 4.013 4.013 0 0018.016 10zm-10.18 2.305c.304.33.521.74.614 1.195H7.096l.74-1.195zM6 16.5A2.503 2.503 0 013.5 14a2.503 2.503 0 013.034-2.442l-1.422 2.297A.75.75 0 005.75 15h2.54A2.503 2.503 0 016 16.5zm5.082-3H9.97A3.992 3.992 0 008.645 11l.773-1.25h3.986l-2.322 3.75zm6.783 2.996a2.507 2.507 0 01-2.363-2.398 2.489 2.489 0 01.604-1.727l1.389 2.237a.5.5 0 00.688.161l.425-.264a.5.5 0 00.161-.688l-1.39-2.239A2.503 2.503 0 0120.5 14a2.503 2.503 0 01-2.634 2.496z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBicycleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
