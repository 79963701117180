import * as React from "react";

function SvgSortNumericDownSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.928 4.857h.714v2.857h-.714a.715.715 0 00-.714.715v1.428a.715.715 0 00.714.715h4.286a.715.715 0 00.714-.715V8.43a.715.715 0 00-.714-.715H19.5v-5A.714.714 0 0018.785 2h-2.143a.715.715 0 00-.637.394l-.715 1.429a.715.715 0 00.638 1.034zm1.168 7.273a3.527 3.527 0 00-2.456 2.418 3.586 3.586 0 003.074 4.578c-.28.236-.594.43-.931.576-.338.152-.482.557-.365.908l.442.893c.128.385.56.602.933.442 2.59-1.105 3.85-2.75 3.85-5.893v-.48a3.582 3.582 0 00-4.547-3.442zm.975 4.335a.892.892 0 110-1.784.892.892 0 010 1.784zm-7.857-.179H8.07V2.714A.714.714 0 007.357 2h-1.43a.714.714 0 00-.713.714v13.572H3.07a.715.715 0 00-.504 1.22l3.571 4.285a.715.715 0 001.01 0l3.572-4.286a.715.715 0 00-.506-1.219z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSortNumericDownSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
