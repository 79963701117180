import * as React from "react";

function SvgCaretUpSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.652 17.671H3.348c-1.199 0-1.799-1.449-.951-2.296l8.652-8.652a1.345 1.345 0 011.902 0l8.652 8.652c.848.847.248 2.296-.95 2.296z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCaretUpSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
