import * as React from "react";

function SvgExclamationSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.125 18.875A3.129 3.129 0 0112 22a3.129 3.129 0 01-3.125-3.125A3.129 3.129 0 0112 15.75a3.129 3.129 0 013.125 3.125zM9.237 2.985l.531 10.624c.025.5.437.891.936.891h2.592c.5 0 .911-.392.936-.89l.531-10.626A.938.938 0 0013.827 2h-3.654a.937.937 0 00-.936.984z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgExclamationSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
