import * as React from "react";

function SvgVolumeMuteSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.4 4.775L6.923 8.25H2.937A.937.937 0 002 9.188v5.624c0 .518.42.938.937.938h3.987l3.475 3.474c.588.588 1.6.175 1.6-.662V5.438c0-.838-1.013-1.249-1.6-.663zM20.032 12l1.782-1.783a.63.63 0 000-.891l-.891-.892a.63.63 0 00-.891 0l-1.783 1.783-1.783-1.783a.63.63 0 00-.891 0l-.892.892a.63.63 0 000 .891L16.467 12l-1.782 1.782a.63.63 0 000 .892l.891.891a.63.63 0 00.891 0l1.783-1.782 1.783 1.783a.63.63 0 00.891 0l.891-.892a.63.63 0 000-.891L20.033 12z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgVolumeMuteSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
