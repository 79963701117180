import * as React from "react";

function SvgFontSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.286 19.143H20.24L14.405 2.968A1.43 1.43 0 0013.053 2h-2.106a1.429 1.429 0 00-1.352.968L3.759 19.143H2.714a.714.714 0 00-.714.714v1.429a.714.714 0 00.714.714H8.43a.715.715 0 00.714-.714v-1.429a.714.714 0 00-.714-.714h-.875l1.04-2.857h6.811l1.04 2.857h-.874a.714.714 0 00-.714.714v1.429a.714.714 0 00.714.714h5.715a.715.715 0 00.714-.714v-1.429a.714.714 0 00-.714-.714zm-11.39-6.429L12 6.934l2.105 5.78h-4.21z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFontSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
