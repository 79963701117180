import * as React from "react";

function SvgCouchSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7 11v2h10v-2c0-1.103.897-2 2-2h1a3 3 0 00-3-3H7a3 3 0 00-3 3h1c1.103 0 2 .897 2 2zm13-1h-1c-.553 0-1 .447-1 1v3H6v-3c0-.553-.447-1-1-1H4c-1.103 0-2 .897-2 2 0 .738.406 1.375 1 1.722V17.5c0 .275.225.5.5.5h2c.275 0 .5-.225.5-.5V17h12v.5c0 .275.225.5.5.5h2c.275 0 .5-.225.5-.5v-3.778c.594-.347 1-.984 1-1.722 0-1.103-.897-2-2-2z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCouchSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
