import * as React from "react";

function SvgBalanceScaleLeftSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.5 18H13V8.79a2.498 2.498 0 001.356-1.484l4.125-1.383a.5.5 0 00.315-.634l-.318-.948a.5.5 0 00-.633-.315L14.16 5.262A2.49 2.49 0 0012 4a2.5 2.5 0 00-2.5 2.5c0 .107.018.21.032.313L5.519 8.16a.5.5 0 00-.315.633l.318.948a.5.5 0 00.633.315l4.44-1.488c.127.086.263.16.405.222V19.5a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm3.5-4.5c0-.506.041-.273-2.658-5.672-.552-1.103-2.131-1.105-2.684 0-2.722 5.445-2.657 5.182-2.657 5.672H14c0 1.38 1.79 2.5 4 2.5s4-1.12 4-2.5zm-6.25-.5L18 8.5l2.25 4.5h-4.5zm-8.408-1.172c-.552-1.103-2.131-1.105-2.684 0-2.722 5.445-2.657 5.182-2.657 5.672H2C2 18.88 3.79 20 6 20s4-1.12 4-2.5c0-.506.041-.273-2.658-5.672zM3.75 17L6 12.5 8.25 17h-4.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBalanceScaleLeftSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
