import * as React from "react";

function SvgThListSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.833 10.438v3.124c0 .518-.42.938-.937.938H2.938A.937.937 0 012 13.562v-3.124c0-.518.42-.938.938-.938h3.958c.518 0 .937.42.937.938zM2 16.688v3.125c0 .517.42.937.938.937h3.958c.518 0 .937-.42.937-.938v-3.125a.937.937 0 00-.937-.937H2.938a.937.937 0 00-.938.938zM6.896 3.25H2.938A.937.937 0 002 4.188v3.125c0 .517.42.937.938.937h3.958c.518 0 .937-.42.937-.938V4.188a.937.937 0 00-.937-.937zm3.125 17.5h11.041c.518 0 .938-.42.938-.938v-3.125a.937.937 0 00-.938-.937H10.021a.937.937 0 00-.938.938v3.125c0 .517.42.937.938.937zM9.083 4.187v3.125c0 .518.42.938.938.938h11.041c.518 0 .938-.42.938-.938V4.188a.937.937 0 00-.938-.937H10.021a.937.937 0 00-.938.938zm.938 10.313h11.041c.518 0 .938-.42.938-.938v-3.124a.937.937 0 00-.938-.938H10.021a.937.937 0 00-.938.938v3.124c0 .518.42.938.938.938z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgThListSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
