import * as React from "react";

function SvgTvSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.5 4h-17A1.5 1.5 0 002 5.5v10A1.5 1.5 0 003.5 17H11v1H5.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5H13v-1h7.5a1.5 1.5 0 001.5-1.5v-10A1.5 1.5 0 0020.5 4zM20 15H4V6h16v9z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTvSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
