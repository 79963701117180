import * as React from "react";

function SvgMoneyBillAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13 13h-.5v-2.75a.25.25 0 00-.25-.25h-.424a.749.749 0 00-.416.126l-.48.32a.249.249 0 00-.069.346l.278.416a.249.249 0 00.346.07l.015-.01V13H11a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25h2a.25.25 0 00.25-.25v-.5A.25.25 0 0013 13zm8-7H3a1 1 0 00-1 1v10a1 1 0 001 1h18a1 1 0 001-1V7a1 1 0 00-1-1zM3.5 16.5v-2a2 2 0 012 2h-2zm0-7v-2h2a2 2 0 01-2 2zm8.5 6c-1.657 0-3-1.567-3-3.5s1.343-3.5 3-3.5 3 1.567 3 3.5-1.344 3.5-3 3.5zm8.5 1h-2a2 2 0 012-2v2zm0-7a2 2 0 01-2-2h2v2z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMoneyBillAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
