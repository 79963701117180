import * as React from "react";

function SvgHeartBrokenSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.503 4.882l-.093-.098a5.104 5.104 0 00-6.625-.578l1.34 4.039-3.75 2.5 1.875 5-5.625-5.625 3.75-2.5-1.117-3.38a5.1 5.1 0 00-6.668.548l-.093.094c-1.903 1.945-1.985 5.042-.286 7.116l8.285 8.539a.696.696 0 001.004 0l8.289-8.543c1.699-2.07 1.617-5.167-.286-7.112z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHeartBrokenSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
