import * as React from "react";

function SvgRouteSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.25 14.5H14.5c-.688 0-1.25-.563-1.25-1.25 0-.688.563-1.25 1.25-1.25h3.75S22 7.82 22 5.75a3.751 3.751 0 00-7.5 0c0 .996.867 2.477 1.77 3.75H14.5a3.756 3.756 0 00-3.75 3.75A3.756 3.756 0 0014.5 17h3.75c.688 0 1.25.563 1.25 1.25 0 .688-.563 1.25-1.25 1.25H9.246A26.96 26.96 0 017.398 22H18.25A3.756 3.756 0 0022 18.25a3.756 3.756 0 00-3.75-3.75zm0-10c.691 0 1.25.559 1.25 1.25S18.941 7 18.25 7 17 6.441 17 5.75s.559-1.25 1.25-1.25zM5.75 12C3.68 12 2 13.68 2 15.75 2 17.82 5.75 22 5.75 22s3.75-4.18 3.75-6.25C9.5 13.68 7.82 12 5.75 12zm0 5c-.691 0-1.25-.559-1.25-1.25s.559-1.25 1.25-1.25S7 15.059 7 15.75 6.441 17 5.75 17z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgRouteSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
