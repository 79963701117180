import * as React from "react";

function SvgRandomSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.725 16.024a.938.938 0 010 1.326L18.6 20.475c-.586.586-1.6.175-1.6-.663V18.25h-2.296a.469.469 0 01-.343-.149l-2.756-2.953 2.083-2.232 2.062 2.209H17v-1.562c0-.837 1.013-1.25 1.6-.663l3.125 3.124zM2.47 8.875H5.75l2.062 2.209 2.083-2.232L7.14 5.899a.469.469 0 00-.343-.149H2.47A.469.469 0 002 6.219v2.187c0 .26.21.469.469.469zm14.531 0v1.562c0 .838 1.014 1.25 1.6.663l3.125-3.125a.938.938 0 000-1.325L18.6 3.525c-.587-.587-1.6-.174-1.6.663V5.75h-2.296a.469.469 0 00-.343.149L5.75 15.125H2.469a.469.469 0 00-.469.469v2.187c0 .26.21.469.469.469h4.327a.47.47 0 00.343-.149l8.611-9.226H17z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgRandomSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
