import * as React from "react";

function SvgHandPointUpOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.375 5.25v3.366a4.513 4.513 0 00-.875-.085c-1.872 0-3.25 1.37-3.25 3.594 0 1.77 1.896 2.227 3.077 2.957.485.302.911.664 1.3 1.01l.005.005.006.005c.683.599.924.907.924 1.18v.104a1.322 1.322 0 00-.312.864v2.5c0 .69.504 1.25 1.125 1.25h9c.621 0 1.125-.56 1.125-1.25v-2.5c0-.335-.12-.64-.313-.864v-.105c0-1.12 1.563-2.622 1.563-4.968v-.833c0-2.443-1.51-3.859-3.56-3.903a3.627 3.627 0 00-2.453-.625 3.639 3.639 0 00-.862-.31V5.25c0-1.775-1.468-3.25-3.25-3.25-1.762 0-3.25 1.488-3.25 3.25zm4.625 0v3.556c.573-.502 1.673-.563 2.385.193.767-.438 1.782-.066 2.095.505 1.528-.255 2.395.394 2.395 1.976v.832c0 1.777-1.403 3.017-1.55 4.688h-6.904c-.116-.986-.83-1.678-1.547-2.308-.494-.44-.988-.848-1.558-1.203-.913-.565-2.191-1.02-2.191-1.364 0-.913.344-1.719 1.375-1.719 1.375 0 2.073 1.031 2.75 1.031V5.25c0-.72.645-1.375 1.375-1.375.741 0 1.375.634 1.375 1.375zm5 13.313a.938.938 0 110 1.875.938.938 0 010-1.875z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHandPointUpOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
