import * as React from "react";

function SvgBrushSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.25 2H5.75c-.69 0-1.25.56-1.25 1.25V12h15V3.25c0-.69-.56-1.25-1.25-1.25zM4.5 14.5A2.5 2.5 0 007 17h2.5v2.5a2.5 2.5 0 005 0V17H17a2.5 2.5 0 002.5-2.5v-1.25h-15v1.25zm7.5 4.063a.937.937 0 110 1.874.937.937 0 010-1.875z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBrushSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
