import * as React from "react";

function SvgCaretSquareRightSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M4.143 2h15.714C21.041 2 22 2.96 22 4.143v15.714C22 21.041 21.04 22 19.857 22H4.143A2.143 2.143 0 012 19.857V4.143C2 2.959 2.96 2 4.143 2zm6.272 15.871l5.492-5.492a.536.536 0 000-.758L10.415 6.13a.536.536 0 00-.915.379v10.984c0 .478.577.717.915.38z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCaretSquareRightSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
