import * as React from "react";

function SvgArrowCircleRightSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2c5.524 0 10 4.476 10 10s-4.476 10-10 10S2 17.524 2 12 6.476 2 12 2zm-1.165 5.79l3.044 2.92H6.516a.965.965 0 00-.968.967v.646c0 .536.432.967.968.967h7.363l-3.044 2.92c-.392.375-.4 1-.017 1.383l.444.44a.964.964 0 001.367 0l5.35-5.348a.964.964 0 000-1.367l-5.35-5.354a.964.964 0 00-1.367 0l-.444.44a.974.974 0 00.017 1.386z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgArrowCircleRightSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
