import * as React from "react";

function SvgPlayCircleOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.665 11.274L9.57 6.96a.97.97 0 00-1.44.846v8.387a.97.97 0 001.44.847l7.096-4.072a.97.97 0 000-1.694zM22 12c0-5.524-4.476-10-10-10S2 6.476 2 12s4.476 10 10 10 10-4.476 10-10zM3.935 12A8.062 8.062 0 0112 3.935 8.062 8.062 0 0120.064 12 8.062 8.062 0 0112 20.064 8.062 8.062 0 013.935 12z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPlayCircleOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
