import * as React from "react";

function SvgMicroscopeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.25 14.5h.469v.625c0 .345.28.625.625.625h1.562c.346 0 .625-.28.625-.625V14.5H12c.69 0 1.25-.56 1.25-1.25V4.5c0-.69-.56-1.25-1.25-1.25v-.625A.625.625 0 0011.375 2h-2.5a.625.625 0 00-.625.625v.625C7.56 3.25 7 3.81 7 4.5v8.75c0 .69.56 1.25 1.25 1.25zm11.875 5h-.05a7.462 7.462 0 001.925-5c0-4.136-3.364-7.5-7.5-7.5v2.5c2.757 0 5 2.243 5 5s-2.243 5-5 5H3.875C2.839 19.5 2 20.34 2 21.375c0 .345.28.625.625.625h18.75c.345 0 .625-.28.625-.625 0-1.035-.84-1.875-1.875-1.875zM6.062 18.25h8.125c.173 0 .313-.14.313-.313v-.625a.312.312 0 00-.313-.312H6.063a.312.312 0 00-.313.313v.625c0 .172.14.312.313.312z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMicroscopeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
