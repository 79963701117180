import * as React from "react";

function SvgCloudMoonRainSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M14.171 10.94a2.777 2.777 0 00-2.726-2.274c-.427 0-.83.105-1.19.278a3.322 3.322 0 00-2.699-1.389 3.334 3.334 0 00-3.334 3.334c0 .017.007.038.007.055a2.777 2.777 0 00.549 5.5h8.89a2.777 2.777 0 002.778-2.777 2.77 2.77 0 00-2.275-2.726zm7.55-.058c-2.445.462-4.689-1.4-4.689-3.848 0-1.41.76-2.708 1.997-3.406a.234.234 0 00-.073-.434 5.096 5.096 0 00-.92-.084A5.01 5.01 0 0013.022 7.9a3.87 3.87 0 012.045 2.143 3.883 3.883 0 012.438 3.014c.177.017.347.052.527.052a5.035 5.035 0 003.91-1.851c.146-.167-.006-.417-.222-.375zm-7.064 6.747a.554.554 0 00-.757.208l-1.27 2.223a.554.554 0 00.482.83c.191 0 .379-.101.483-.282l1.27-2.222a.557.557 0 00-.208-.757zm-3.333 0a.554.554 0 00-.757.208L9.296 20.06a.553.553 0 00.482.83c.191 0 .379-.101.483-.282l1.271-2.222a.557.557 0 00-.208-.757zm-3.334 0a.554.554 0 00-.757.208l-1.27 2.223a.553.553 0 00.482.83c.19 0 .378-.101.482-.282l1.271-2.222a.557.557 0 00-.208-.757zm-3.334 0a.554.554 0 00-.757.208L2.63 20.06a.553.553 0 00.482.83c.191 0 .379-.101.483-.282l1.27-2.222a.557.557 0 00-.208-.757z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCloudMoonRainSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
