import * as React from "react";

function SvgTintSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.141 2.863c-.31-1.124-1.93-1.177-2.282 0-1.827 6.162-5.734 7.837-5.734 12.18C5.125 18.89 8.2 22 12 22s6.875-3.111 6.875-6.957c0-4.365-3.898-5.99-5.734-12.18zM12 19.5a4.38 4.38 0 01-4.375-4.375.625.625 0 111.25 0A3.129 3.129 0 0012 18.25a.625.625 0 110 1.25z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTintSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
