import * as React from "react";

function SvgHorizontalTwoColumnsAOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.795 5H4.205C3.54 5 3 5.54 3 6.205v11.411c0 .666.54 1.205 1.205 1.205h15.59c.665 0 1.205-.54 1.205-1.205V6.206C21 5.54 20.46 5 19.795 5zm-.151 12.616H4.356a.15.15 0 01-.15-.15V6.355a.15.15 0 01.15-.15h15.288a.15.15 0 01.15.15v11.11a.15.15 0 01-.15.15zm-8.248-4.882v.603a.301.301 0 01-.301.301H5.932a.301.301 0 01-.301-.3v-.604c0-.166.135-.301.301-.301h5.163c.166 0 .301.135.301.301z"
        fill="currentColor"
      />
      <path
        d="M11.396 15.949v-.603a.301.301 0 00-.301-.301H5.932a.301.301 0 00-.301.301v.603c0 .166.135.301.301.301h5.163a.301.301 0 00.301-.301zM18.409 13.337v-.603a.301.301 0 00-.302-.301h-5.163a.301.301 0 00-.301.301v.603c0 .167.135.301.301.301h5.163a.301.301 0 00.302-.3zM18.409 10.926v-.602a.301.301 0 00-.302-.302h-5.163a.301.301 0 00-.301.302v.602c0 .167.135.302.301.302h5.163a.301.301 0 00.302-.302zM18.409 8.516v-.603a.301.301 0 00-.302-.301h-5.163a.301.301 0 00-.301.301v.603c0 .166.135.301.301.301h5.163a.301.301 0 00.302-.301zM18.429 15.949v-.603a.301.301 0 00-.302-.301h-5.163a.301.301 0 00-.301.301v.603c0 .166.135.301.301.301h5.163a.301.301 0 00.302-.301zM8.408 9.165a11.24 11.24 0 01-.157-.667h-.01s-.086.423-.156.667l-.223.753h.765l-.219-.753zM6.536 11.17L7.69 7.774a.24.24 0 01.229-.162h.653c.102 0 .195.066.229.162l1.155 3.397a.242.242 0 01-.23.32h-.46a.24.24 0 01-.23-.173l-.189-.64h-1.21l-.182.638a.243.243 0 01-.231.175h-.46a.243.243 0 01-.23-.32z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHorizontalTwoColumnsAOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
