import * as React from "react";

function SvgProjectDiagramSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M14 14h-4a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1v-4a1 1 0 00-1-1zM8 5a1 1 0 00-1-1H3a1 1 0 00-1 1v4a1 1 0 001 1h2.991l2.287 4.001A1.993 1.993 0 0110 13h.009L8 9.485V8h7V6H8V5zm13-1h-4a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1V5a1 1 0 00-1-1z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgProjectDiagramSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
