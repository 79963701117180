import * as React from "react";

function SvgGrinWinkSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 12c0 5.524 4.476 10 10 10s10-4.476 10-10S17.524 2 12 2 2 6.476 2 12zm8.065-1.935a1.289 1.289 0 11-2.58 0c-.001-.714.575-1.29 1.29-1.29.713 0 1.29.576 1.29 1.29zm6.774 1.008l-.383-.343c-.597-.532-1.863-.532-2.46 0l-.383.343c-.335.298-.871.016-.798-.436.16-1.016 1.379-1.697 2.415-1.697s2.254.681 2.415 1.697a.49.49 0 01-.806.436zm-9.803 3.54c1.218.391 3.029.613 4.964.613 1.935 0 3.746-.222 4.964-.613a.647.647 0 01.834.722c-.37 2.217-3.354 3.762-5.798 3.762s-5.423-1.545-5.798-3.762a.645.645 0 01.834-.722z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGrinWinkSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
