import * as React from "react";

function SvgClockSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm3.73 12.621l-.807 1.008a.645.645 0 01-.907.1l-2.701-2.004a1.612 1.612 0 01-.605-1.26V6.195a.645.645 0 01.645-.646h1.29a.645.645 0 01.645.646V12l2.339 1.714a.646.646 0 01.1.907z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgClockSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
