import * as React from "react";

function SvgHistorySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 11.981c.01 5.51-4.483 10.015-9.993 10.019a9.958 9.958 0 01-6.283-2.214.967.967 0 01-.074-1.436l.454-.454a.97.97 0 011.286-.08A7.389 7.389 0 0012 19.419 7.415 7.415 0 0019.42 12 7.415 7.415 0 0012 4.58a7.394 7.394 0 00-5.083 2.014l2.046 2.047a.645.645 0 01-.456 1.101H2.645A.645.645 0 012 9.097V3.235a.645.645 0 011.101-.456l1.991 1.99A9.966 9.966 0 0112 2c5.517 0 9.99 4.467 10 9.981zm-7.295 3.177l.396-.51a.968.968 0 00-.17-1.357l-1.64-1.277V7.806a.968.968 0 00-.968-.967h-.646a.968.968 0 00-.967.967v5.47l2.637 2.052a.968.968 0 001.358-.17z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHistorySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
