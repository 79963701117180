import * as React from "react";

function SvgScoreSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.673 2h-7.7s-.947 0-.947 1.08v9.863l1.351-2.567s.676-.81 1.351 0l1.487 2.837 3.107.405s.675.136.405.946l-2.432 2.297.54 3.107s.136 1.08-.945.81l-2.972-1.35-2.027.945v.54s.135 1.082 1.081 1.082h12.835s1.08.135 1.08-.946V8.215h-4.863s-1.351.27-1.351-1.216V2z"
        fill="currentColor"
      />
      <path
        d="M15.024 2v4.999h4.999s0-.54-.406-.946L15.834 2.27s-.27-.27-.81-.27zM6.648 11.997s.27-.405.405 0l1.216 2.162 2.432.405s.405.135.135.406l-1.757 1.62.406 2.433s.135.405-.406.27l-2.161-.946-2.162.946s-.344.112-.344-.293l.344-2.274L3 14.97s0-.228.135-.27l2.432-.541 1.08-2.162z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgScoreSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
