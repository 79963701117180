import * as React from "react";

function SvgSortSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6.022 13.608h11.956c1.075 0 1.613 1.3.854 2.06l-5.978 5.978a1.2 1.2 0 01-1.703 0l-5.983-5.979c-.76-.758-.222-2.06.854-2.06zm12.81-5.275l-5.978-5.979a1.2 1.2 0 00-1.703 0L5.168 8.333c-.76.758-.222 2.06.854 2.06h11.956c1.075 0 1.613-1.302.854-2.06z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSortSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
