import * as React from "react";

function SvgTagSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 11.848V3.875C2 2.839 2.84 2 3.875 2h7.973c.498 0 .975.198 1.326.55l8.277 8.276c.732.732.732 1.92 0 2.651l-7.974 7.974a1.875 1.875 0 01-2.651 0l-8.277-8.277A1.875 1.875 0 012 11.848zM6.375 4.5a1.875 1.875 0 100 3.75 1.875 1.875 0 000-3.75z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTagSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
