import * as React from "react";

function SvgImagesSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.667 17.556v.555c0 .92-.747 1.667-1.667 1.667H3.667c-.92 0-1.667-.746-1.667-1.667V9.222c0-.92.746-1.666 1.667-1.666h.555v7.222A2.781 2.781 0 007 17.556h11.667zM22 14.778v-8.89c0-.92-.746-1.666-1.667-1.666H7c-.92 0-1.667.746-1.667 1.667v8.889c0 .92.747 1.666 1.667 1.666h13.333c.92 0 1.667-.746 1.667-1.666zM10.889 7.556a1.667 1.667 0 11-3.333 0 1.667 1.667 0 013.333 0zm-3.333 5l1.927-1.928a.417.417 0 01.59 0L11.443 12l4.706-4.705a.417.417 0 01.59 0l3.038 3.038v3.89H7.556v-1.667z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgImagesSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
