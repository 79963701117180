import * as React from "react";

function SvgExpandArrowsAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 15.929v5A1.07 1.07 0 0120.929 22h-5c-.955 0-1.433-1.156-.76-1.83l1.617-1.616L12 13.768l-4.787 4.79L8.83 20.17c.674.674.196 1.83-.759 1.83h-5A1.07 1.07 0 012 20.929v-5c0-.956 1.156-1.434 1.83-.76l1.616 1.617L10.235 12l-4.79-4.79L3.83 8.83C3.156 9.504 2 9.027 2 8.071v-5A1.069 1.069 0 013.071 2h5c.955 0 1.433 1.156.76 1.83L7.213 5.446 12 10.232l4.787-4.79L15.17 3.83c-.674-.674-.196-1.83.759-1.83h5A1.068 1.068 0 0122 3.071v5c0 .956-1.156 1.433-1.83.76l-1.616-1.617L13.765 12l4.79 4.79 1.615-1.616c.674-.678 1.83-.2 1.83.755z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgExpandArrowsAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
