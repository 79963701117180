import * as React from "react";

function SvgEraserSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.45 12.7a1.875 1.875 0 000-2.65L15.2 3.8a1.875 1.875 0 00-2.65 0l-10 10a1.875 1.875 0 000 2.65L6.3 20.2c.35.352.828.55 1.325.55h13.906c.26 0 .469-.21.469-.469V18.72a.469.469 0 00-.469-.469h-5.63l5.55-5.55zM9.63 10.255l5.366 5.367-2.63 2.629H7.884l-3.125-3.125 4.87-4.87z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgEraserSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
