import * as React from "react";

function SvgVoteYeaSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21 14h-2v2h.7c.166 0 .3.113.3.25v.5c0 .137-.134.25-.3.25H4.3c-.166 0-.3-.113-.3-.25v-.5c0-.137.134-.25.3-.25H5v-2H3c-.553 0-1 .447-1 1v3c0 .553.447 1 1 1h18c.553 0 1-.447 1-1v-3c0-.553-.447-1-1-1zm-3 2V6.01C18 5.45 17.547 5 16.99 5H7.014C6.452 5 6 5.453 6 6.01V16h12zm-9.4-5.688l.797-.79a.333.333 0 01.475.003l1.29 1.3 2.975-2.95a.333.333 0 01.476.003l.79.797a.333.333 0 01-.003.475l-4.01 3.975a.333.333 0 01-.474-.003L8.6 10.787a.333.333 0 010-.475z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgVoteYeaSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
