import * as React from "react";

function SvgSaveOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.372 6.372l-3.744-3.744A2.143 2.143 0 0016.112 2H4.142C2.96 2 2 2.96 2 4.143v15.714C2 21.041 2.96 22 4.143 22h15.714C21.041 22 22 21.04 22 19.857V7.887c0-.568-.226-1.113-.628-1.515zm-7.23-2.23v3.572H8.43V4.143h5.714zm5.447 15.715H4.411a.268.268 0 01-.268-.268V4.411a.268.268 0 01.268-.268h1.875v4.643c0 .591.48 1.071 1.071 1.071h7.857c.592 0 1.072-.48 1.072-1.071v-4.47l3.493 3.493a.268.268 0 01.078.19v11.59a.268.268 0 01-.268.268zM12 10.93a3.933 3.933 0 00-3.929 3.928A3.933 3.933 0 0012 18.786a3.933 3.933 0 003.929-3.929A3.933 3.933 0 0012 10.93zm0 5.714a1.788 1.788 0 01-1.786-1.786c0-.984.801-1.786 1.786-1.786s1.786.802 1.786 1.786c0 .985-.801 1.786-1.786 1.786z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSaveOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
