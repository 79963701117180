import * as React from "react";

function SvgSignOutAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.723 12.674l-6.667 6.667c-.595.595-1.627.179-1.627-.675v-3.81H8.032a.95.95 0 01-.952-.952v-3.81a.95.95 0 01.952-.952h5.397v-3.81c0-.849 1.028-1.27 1.627-.674l6.667 6.667a.96.96 0 010 1.35zM9.62 19.143v-1.588a.478.478 0 00-.476-.476H5.81c-.703 0-1.27-.567-1.27-1.27V8.19c0-.702.567-1.27 1.27-1.27h3.333a.478.478 0 00.476-.475V4.856a.478.478 0 00-.476-.476H5.81A3.81 3.81 0 002 8.19v7.62a3.81 3.81 0 003.81 3.809h3.333a.478.478 0 00.476-.476z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSignOutAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
