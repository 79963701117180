import * as React from "react";

function SvgShoppingCartSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.337 13.574L21.98 6.35a.833.833 0 00-.813-1.018H7.528L7.21 3.777a.833.833 0 00-.817-.666h-3.56A.833.833 0 002 3.944V4.5c0 .46.373.833.833.833H5.26l2.439 11.925a1.943 1.943 0 00.968 3.63 1.944 1.944 0 001.36-3.333h7.28a1.945 1.945 0 102.208-.361l.191-.843a.833.833 0 00-.812-1.018h-9.32l-.228-1.11h10.179c.389 0 .726-.27.813-.65z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgShoppingCartSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
