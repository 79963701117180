import * as React from "react";

function SvgSlotsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.024 10.12H9V3.467C9 3.21 9.205 3 9.46 3h5.107c.251 0 .457.209.457.467v6.653zM2 14.657v5.7c0 .644.512 1.164 1.142 1.164H20.89A1.12 1.12 0 0022 20.392V14.66H2v-.003z"
        fill="currentColor"
      />
      <path
        d="M20.094 13.498h1.871l-1.903-4.815c-.127-.5-.57-.87-1.101-.87h-.897v.005h-1.882v2.302h1.882v.024l.524 1.088H5.328l.524-1.115h1.88V7.816h-1.88V7.81h-.868c-.526 0-.963.37-1.078.867L2 13.498H20.094z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSlotsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
