import * as React from "react";

function SvgTramSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.249 4.5c.691 0 1.25-.559 1.25-1.25S13.94 2 13.249 2c-.692 0-1.25.559-1.25 1.25s.558 1.25 1.25 1.25zm8.73-.473a.622.622 0 00-.765-.441l-18.75 5a.625.625 0 00.32 1.207l8.59-2.293v3.25H4.499c-.692 0-1.25.559-1.25 1.25v8.75c0 .691.558 1.25 1.25 1.25h15c.691 0 1.25-.559 1.25-1.25V12c0-.691-.559-1.25-1.25-1.25h-6.875V7.168l8.91-2.375a.629.629 0 00.445-.766zM8.874 17h-3.75v-3.75h3.75V17zm6.25-3.75h3.75V17h-3.75v-3.75zm-1.25 0V17h-3.75v-3.75h3.75zm-4.375-7.5c.691 0 1.25-.559 1.25-1.25s-.559-1.25-1.25-1.25c-.692 0-1.25.559-1.25 1.25s.558 1.25 1.25 1.25z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTramSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
