import * as React from "react";

function SvgPaintRollerSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.25 7V3.25C18.25 2.56 17.69 2 17 2H3.25C2.56 2 2 2.56 2 3.25V7c0 .69.56 1.25 1.25 1.25H17c.69 0 1.25-.56 1.25-1.25zm1.25-2.5v5c0 .69-.56 1.25-1.25 1.25H12a2.5 2.5 0 00-2.5 2.5v1.25c-.69 0-1.25.56-1.25 1.25v5c0 .69.56 1.25 1.25 1.25H12c.69 0 1.25-.56 1.25-1.25v-5c0-.69-.56-1.25-1.25-1.25v-1.25h6.25A3.75 3.75 0 0022 9.5V7a2.5 2.5 0 00-2.5-2.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPaintRollerSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
