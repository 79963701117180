import * as React from "react";

function SvgGrinTongueWinkSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.81 8.985a.95.95 0 00-.952.953.95.95 0 00.952.952.95.95 0 00.953-.952.95.95 0 00-.953-.953zM12 2a9.841 9.841 0 00-9.843 9.843c0 4.22 2.66 7.807 6.39 9.208a3.798 3.798 0 01-.357-1.587v-1.806c-.98-.643-1.727-1.513-1.897-2.533a.637.637 0 01.821-.71c1.199.385 2.98.603 4.886.603 1.905 0 3.687-.218 4.886-.603a.636.636 0 01.821.71c-.17 1.02-.916 1.89-1.897 2.533v1.806c0 .567-.135 1.103-.357 1.587 3.73-1.4 6.39-4.989 6.39-9.208A9.841 9.841 0 0012 2zm-2.223 8.93l-.377-.337c-.587-.524-1.833-.524-2.42 0l-.378.337c-.337.294-.857.012-.786-.428.16-1 1.358-1.671 2.378-1.671s2.218.67 2.377 1.67a.482.482 0 01-.794.43zm6.033 1.548a2.543 2.543 0 01-2.54-2.54c0-1.401 1.14-2.54 2.54-2.54 1.401 0 2.54 1.139 2.54 2.54s-1.139 2.54-2.54 2.54zm-2.02 4.072c-.571-.258-1.234.088-1.373.699l-.072.31c-.083.365-.603.365-.686 0l-.072-.31c-.139-.611-.801-.957-1.373-.699-.036.016.012-.008-.75.373v2.5c0 1.398 1.111 2.56 2.504 2.577a2.543 2.543 0 002.576-2.54v-2.54c-.774-.382-.722-.354-.754-.37z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGrinTongueWinkSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
