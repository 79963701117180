import * as React from "react";

function SvgAngleDoubleRightSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.012 12.853L5.19 19.677a1.2 1.2 0 01-1.701 0l-1.134-1.134a1.2 1.2 0 010-1.701l4.837-4.837-4.837-4.837a1.2 1.2 0 010-1.7l1.129-1.145a1.2 1.2 0 011.7 0l6.825 6.824a1.2 1.2 0 01.005 1.706zm9.634-1.706l-6.824-6.824a1.2 1.2 0 00-1.7 0l-1.134 1.134a1.2 1.2 0 000 1.701l4.836 4.837-4.837 4.837a1.2 1.2 0 000 1.701l1.134 1.134a1.2 1.2 0 001.701 0l6.824-6.824a1.193 1.193 0 000-1.696z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAngleDoubleRightSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
