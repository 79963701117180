import * as React from "react";

function SvgMarsStrokeHSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.852 11.646l-2.33-2.331a.5.5 0 00-.855.354v.997h-.992v-.834c0-.275-.225-.5-.5-.5h-1.668c-.275 0-.5.225-.5.5v.834h-1.151a6.004 6.004 0 00-10.098-2.91 6.004 6.004 0 000 8.488 6.004 6.004 0 0010.098-2.91h1.15v.834c0 .275.226.5.5.5h1.668c.276 0 .5-.225.5-.5v-.834h.997v.997c0 .446.538.667.855.354l2.33-2.33a.505.505 0 00-.004-.71zm-11.49 2.714a3.337 3.337 0 01-4.716 0 3.337 3.337 0 010-4.716c1.301-1.3 3.415-1.3 4.716 0a3.337 3.337 0 010 4.716z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMarsStrokeHSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
