import * as React from "react";

function SvgSmileBeamOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 18.064c-4.448 0-8.065-3.616-8.065-8.064S7.552 3.935 12 3.935c4.448 0 8.064 3.617 8.064 8.065 0 4.448-3.616 8.064-8.064 8.064zm3.387-5.782A4.4 4.4 0 0112 15.871a4.387 4.387 0 01-3.387-1.589.97.97 0 00-1.363-.125.97.97 0 00-.125 1.363A6.33 6.33 0 0012 17.802a6.33 6.33 0 004.875-2.282.967.967 0 10-1.488-1.238zm-7.883-4.097c.31-.552.774-.87 1.27-.87s.96.318 1.27.87l.383.686c.085.15.25.19.375.15a.323.323 0 00.23-.336C10.9 8.988 9.734 7.806 8.774 7.806s-2.125 1.182-2.258 2.88c-.012.149.085.29.23.334a.33.33 0 00.375-.149l.383-.686zm7.722-2.379c-.96 0-2.125 1.182-2.258 2.88-.012.149.084.29.23.334a.328.328 0 00.375-.149l.383-.686c.31-.552.774-.87 1.27-.87s.96.318 1.27.87l.383.686c.085.15.25.19.375.15a.323.323 0 00.23-.336c-.133-1.697-1.299-2.879-2.258-2.879z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSmileBeamOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
