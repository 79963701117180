import * as React from "react";

function SvgBasketballBallSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.56 2.093a9.94 9.94 0 00-4.927 2.17l3.121 3.12C9.875 5.94 10.5 4.11 10.56 2.093zM12 10.629l6.367-6.367A9.966 9.966 0 0012.5 2c-.048 2.577-.867 4.931-2.367 6.762L12 10.63zM4.262 5.633a9.94 9.94 0 00-2.17 4.927c2.017-.06 3.852-.685 5.291-1.806l-3.12-3.12zm10.976 8.234C17.064 12.37 19.419 11.552 22 11.5a9.966 9.966 0 00-2.262-5.867L13.37 12l1.867 1.867zM12 13.37l-6.367 6.367A9.992 9.992 0 0011.5 22c.052-2.58.871-4.936 2.367-6.762L12 13.37zm7.738 4.996a9.941 9.941 0 002.17-4.928c-2.02.065-3.852.69-5.291 1.807l3.12 3.12zM8.762 10.133C6.932 11.633 4.577 12.456 2 12.5a9.966 9.966 0 002.262 5.867L10.63 12l-1.867-1.867zm4.678 11.774a9.941 9.941 0 004.927-2.17l-3.121-3.12c-1.117 1.44-1.742 3.274-1.806 5.29z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBasketballBallSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
