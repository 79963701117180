import * as React from "react";

function SvgGlobeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.57 8.129C14.986 4.529 13.607 2 12.002 2S9.018 4.528 8.433 8.129h7.137zM8.131 12c0 .895.048 1.754.133 2.58h7.472a25.266 25.266 0 000-5.16H8.264A25.273 25.273 0 008.131 12zm13.093-3.871a10.021 10.021 0 00-6.371-5.71c.984 1.363 1.661 3.416 2.016 5.71h4.355zM9.147 2.419A10.014 10.014 0 002.78 8.13h4.355c.35-2.294 1.028-4.347 2.012-5.71zm12.508 7H17.03c.085.847.133 1.714.133 2.581s-.048 1.734-.133 2.58h4.621a9.912 9.912 0 00.347-2.58c0-.895-.125-1.754-.343-2.58zM6.841 12c0-.867.048-1.734.133-2.58H2.349A10.089 10.089 0 002.002 12c0 .895.129 1.754.347 2.58h4.62A27.3 27.3 0 016.842 12zm1.592 3.871c.585 3.6 1.964 6.129 3.569 6.129s2.984-2.528 3.568-6.129H8.433zm6.424 5.71a10.03 10.03 0 006.37-5.71h-4.354c-.355 2.294-1.032 4.347-2.016 5.71zM2.78 15.87a10.02 10.02 0 006.371 5.71c-.984-1.363-1.661-3.416-2.016-5.71H2.78z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGlobeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
