import * as React from "react";

function SvgSearchPlusSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.875 9.5v1.25a.47.47 0 01-.468.468h-2.188v2.187a.47.47 0 01-.468.469H9.5a.47.47 0 01-.469-.469v-2.187H6.845a.47.47 0 01-.469-.469V9.5a.47.47 0 01.469-.468h2.187V6.843a.47.47 0 01.469-.468h1.25a.47.47 0 01.468.468v2.188h2.188a.47.47 0 01.468.468zm7.851 11.12l-1.105 1.105a.933.933 0 01-1.324 0l-3.898-3.895a.937.937 0 01-.274-.663v-.637a8.084 8.084 0 01-5 1.718 8.123 8.123 0 01-8.124-8.124A8.123 8.123 0 0110.126 2a8.123 8.123 0 018.124 8.124c0 1.887-.64 3.62-1.719 5h.637c.25 0 .488.097.664.273l3.894 3.894a.945.945 0 010 1.328zm-6.288-10.496a5.308 5.308 0 00-5.312-5.312 5.308 5.308 0 00-5.312 5.312 5.308 5.308 0 005.312 5.312 5.308 5.308 0 005.312-5.312z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSearchPlusSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
