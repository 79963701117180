import * as React from "react";

function SvgPrintSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.5 9.5V5.018c0-.332-.132-.65-.366-.884l-1.768-1.768A1.25 1.25 0 0016.482 2H5.75c-.69 0-1.25.56-1.25 1.25V9.5A2.5 2.5 0 002 12v4.375c0 .345.28.625.625.625H4.5v3.75c0 .69.56 1.25 1.25 1.25h12.5c.69 0 1.25-.56 1.25-1.25V17h1.875c.345 0 .625-.28.625-.625V12a2.5 2.5 0 00-2.5-2.5zm-2.5 10H7v-3.75h10v3.75zm0-8.75H7V4.5h7.5v1.875c0 .345.28.625.625.625H17v3.75zm1.875 2.813a.938.938 0 110-1.876.938.938 0 010 1.876z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPrintSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
