import * as React from "react";

function SvgGrinStarsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zM5.815 8.488l1.407-.202.625-1.274a.312.312 0 01.56 0l.625 1.274 1.408.202c.25.04.358.347.173.532l-1.024.992.242 1.407a.302.302 0 01-.444.319l-1.258-.653-1.262.657a.302.302 0 01-.444-.319l.242-1.407-1.024-.992a.316.316 0 01.174-.536zM12 19.097c-2.444 0-5.423-1.545-5.798-3.762a.647.647 0 01.834-.722c1.218.391 3.029.613 4.964.613 1.935 0 3.746-.222 4.964-.613a.646.646 0 01.834.722c-.375 2.217-3.354 3.762-5.798 3.762zM18.359 9.02l-1.024.992.242 1.407a.302.302 0 01-.444.319l-1.262-.653-1.262.657a.302.302 0 01-.444-.319l.242-1.407-1.024-.992a.315.315 0 01.174-.532l1.407-.202.625-1.274a.312.312 0 01.56 0l.625 1.274 1.407.202a.31.31 0 01.178.528z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGrinStarsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
