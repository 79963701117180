import * as React from "react";

function SvgUniversalAccessSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 3.613A8.383 8.383 0 0120.387 12 8.383 8.383 0 0112 20.387 8.383 8.383 0 013.613 12 8.383 8.383 0 0112 3.613zM12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 2.258a7.742 7.742 0 100 15.484 7.742 7.742 0 000-15.484zm0 1.774a1.452 1.452 0 110 2.903 1.452 1.452 0 010-2.903zm4.748 3.953c-1.158.273-2.239.514-3.313.637.035 4.074.497 4.962 1.01 6.275a.726.726 0 01-1.352.528c-.351-.9-.689-1.638-.898-3.167h-.39c-.209 1.526-.546 2.267-.898 3.167a.726.726 0 11-1.352-.528c.513-1.312.975-2.199 1.01-6.275-1.074-.123-2.155-.364-3.313-.637a.645.645 0 11.297-1.256c3.899.92 5.011.919 8.902 0a.645.645 0 11.297 1.256z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgUniversalAccessSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
