import * as React from "react";

function SvgBibleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.75 16V3c0-.625-.375-1-1-1H7C4.875 2 3.25 3.625 3.25 5.75v12.5C3.25 20.375 4.875 22 7 22h12.75c.5 0 1-.375 1-1v-.625c0-.25-.125-.5-.375-.75-.125-.625-.125-2.375 0-2.875.25-.125.375-.375.375-.75zM8.875 7.625c0-.345.28-.625.625-.625h1.875V5.125c0-.345.28-.625.625-.625h1.25c.345 0 .625.28.625.625V7h1.875c.345 0 .625.28.625.625v1.25c0 .345-.28.625-.625.625h-1.875v4.375c0 .345-.28.625-.625.625H12a.625.625 0 01-.625-.625V9.5H9.5a.625.625 0 01-.625-.625v-1.25zm9.25 11.875H7c-.75 0-1.25-.5-1.25-1.25S6.375 17 7 17h11.125v2.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBibleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
