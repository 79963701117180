import * as React from "react";

function SvgMehRollingEyesOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 18.064c-4.448 0-8.065-3.616-8.065-8.064S7.552 3.935 12 3.935c4.448 0 8.064 3.617 8.064 8.065 0 4.448-3.616 8.064-8.064 8.064zm3.548-12.258a2.901 2.901 0 00-2.903 2.904 2.901 2.901 0 002.903 2.903 2.901 2.901 0 002.904-2.903 2.901 2.901 0 00-2.904-2.904zm0 4.517a1.612 1.612 0 01-1.613-1.613c0-.549.295-1.012.714-1.303a.919.919 0 00-.068.335c0 .536.431.968.967.968a.965.965 0 00.968-.968.936.936 0 00-.068-.335c.419.29.713.754.713 1.303 0 .89-.722 1.613-1.613 1.613zm-4.193-1.613a2.901 2.901 0 00-2.903-2.904 2.901 2.901 0 00-2.904 2.904 2.901 2.901 0 002.904 2.903 2.901 2.901 0 002.903-2.903zm-4.516 0c0-.549.294-1.012.713-1.303a.918.918 0 00-.068.335c0 .536.431.968.968.968a.965.965 0 00.967-.968.936.936 0 00-.068-.335c.42.29.713.754.713 1.303a1.612 1.612 0 11-3.225 0zm7.742 5.161H9.419a.97.97 0 00-.967.968.97.97 0 00.967.968h5.162a.97.97 0 00.967-.968.97.97 0 00-.967-.968z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMehRollingEyesOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
