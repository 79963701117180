import * as React from "react";

function SvgGrinSquintTearsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.997 6.372c.883-.125 2.87-.468 3.448-1.046.75-.75.738-1.976-.027-2.742-.765-.765-2-.777-2.745-.027-.578.578-.918 2.566-1.047 3.448a.325.325 0 00.37.367zM6 17.628c-.883.125-2.87.468-3.449 1.046-.746.746-.734 1.976.031 2.742.766.765 1.992.777 2.742.027.578-.578.918-2.566 1.047-3.448A.325.325 0 006 17.628zM18.173 7.61c-1.29.153-1.898-.98-1.785-1.784.133-.938.289-1.645.449-2.207C13.135 1.48 8.319 1.983 5.148 5.15c-3.167 3.167-3.67 7.982-1.53 11.689.562-.16 1.265-.313 2.206-.45 1.297-.152 1.898.985 1.785 1.785a16.423 16.423 0 01-.45 2.207c3.703 2.14 8.518 1.636 11.69-1.531 3.17-3.167 3.67-7.983 1.53-11.689-.562.16-1.27.313-2.206.45zm-6.186-1.468c.129-.516.874-.45.921.07l.188 2.043 2.042.187c.524.047.582.793.07.922l-3.534.882a.467.467 0 01-.566-.566l.879-3.538zm-3.55 8.994l-.188-2.042-2.042-.188c-.524-.047-.582-.793-.07-.922l3.534-.882a.467.467 0 01.566.566l-.883 3.534c-.12.516-.867.457-.917-.066zm8.423 1.726c-1.144 1.144-2.956 1.968-4.557 1.968-.738 0-1.43-.175-1.992-.574a.622.622 0 01.078-1.062c1.106-.57 2.496-1.656 3.82-2.98 1.324-1.324 2.41-2.718 2.98-3.82a.626.626 0 011.066-.077c1.261 1.769.277 4.87-1.395 6.545z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGrinSquintTearsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
