import * as React from "react";

function SvgHandScissorsOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 20.75h2.734c.2 0 .4-.023.594-.068l4.649-1.071A2.597 2.597 0 0022 17.089V9.054c0-.932-.509-1.795-1.328-2.254l-3.828-2.142c-1.345-.753-2.628-.228-3.603.966l-5.66-2.178c-1.48-.57-3.15.145-3.737 1.609A2.847 2.847 0 005.482 8.77l3.08.73-3.671-.357C3.297 9.143 2 10.425 2 12c0 1.575 1.297 2.857 2.89 2.857h3.424a2.579 2.579 0 001.185 2.54c-.52 1.663.745 3.353 2.501 3.353zm0-1.875c-.983 0-.981-1.429 0-1.429.345 0 .625-.28.625-.625v-.268a.625.625 0 00-.625-.625h-1.094c-.983 0-.981-1.428 0-1.428H12c.345 0 .625-.28.625-.625v-.268a.625.625 0 00-.625-.625H4.89c-1.344 0-1.342-1.964 0-1.964H12c.345 0 .625-.28.625-.625v-.454a.625.625 0 00-.4-.584L6.155 7.02c-1.232-.474-.524-2.316.752-1.825l6.505 2.504a.625.625 0 00.71-.188l.85-1.041a.765.765 0 01.956-.176l3.828 2.142c.228.128.369.364.369.618v8.035a.718.718 0 01-.57.695l-4.648 1.07a.766.766 0 01-.173.02H12z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHandScissorsOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
