import * as React from "react";

function SvgGrimaceOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 18.064c-4.448 0-8.065-3.616-8.065-8.064S7.552 3.935 12 3.935c4.448 0 8.064 3.617 8.064 8.065 0 4.448-3.616 8.064-8.064 8.064zm-3.226-8.71c.714 0 1.29-.576 1.29-1.29 0-.713-.576-1.29-1.29-1.29-.714 0-1.29.577-1.29 1.29 0 .714.576 1.29 1.29 1.29zm6.452 0c.713 0 1.29-.576 1.29-1.29 0-.713-.576-1.29-1.29-1.29-.714 0-1.29.577-1.29 1.29 0 .714.576 1.29 1.29 1.29zm.645.646H8.129a1.936 1.936 0 00-1.935 1.935v1.29c0 1.07.866 1.936 1.935 1.936h7.742a1.936 1.936 0 001.935-1.935v-1.29A1.936 1.936 0 0015.871 12zm-6.774 3.871h-.968a.647.647 0 01-.645-.645v-.323h1.613v.968zm0-1.613H7.484v-.322c0-.355.29-.646.645-.646h.968v.968zm2.58 1.613H9.742v-.968h1.935v.968zm0-1.613H9.742v-.968h1.935v.968zm2.581 1.613h-1.935v-.968h1.935v.968zm0-1.613h-1.935v-.968h1.935v.968zm2.258.968c0 .355-.29.645-.645.645h-.968v-.968h1.613v.323zm0-.968h-1.613v-.968h.968c.355 0 .645.29.645.646v.322z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGrimaceOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
