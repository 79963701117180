import * as React from "react";

function SvgPencilAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.45 7.548l-1.802 1.8a.47.47 0 01-.664 0l-4.336-4.335a.47.47 0 010-.664l1.8-1.801a1.88 1.88 0 012.653 0l2.348 2.348c.734.73.734 1.918 0 2.652zM13.1 5.896L2.843 16.154 2.015 20.9A.939.939 0 003.1 21.986l4.746-.832 10.258-10.258a.47.47 0 000-.664L13.77 5.896a.474.474 0 00-.668 0zm-6.254 9.379a.545.545 0 010-.774l6.016-6.015a.545.545 0 01.773 0 .545.545 0 010 .773L7.62 15.275a.545.545 0 01-.773 0zm-1.41 3.285h1.875v1.418l-2.52.442-1.215-1.215.442-2.52h1.418v1.875z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPencilAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
