import * as React from "react";

function SvgWalkingSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.873 5.75A1.875 1.875 0 1013.872 2a1.875 1.875 0 000 3.75zm3.69 5.823l-.91-.461-.378-1.148c-.574-1.742-2.176-2.96-3.992-2.965-1.406-.004-2.183.395-3.644.985a3.726 3.726 0 00-1.941 1.804l-.262.531a1.254 1.254 0 00.555 1.676c.61.308 1.351.058 1.66-.559l.261-.531c.137-.273.364-.488.645-.601l1.047-.422-.594 2.37a2.508 2.508 0 00.582 2.297l2.34 2.554c.28.309.48.68.581 1.082l.715 2.863a1.252 1.252 0 001.515.91 1.252 1.252 0 00.91-1.515l-.866-3.476a2.498 2.498 0 00-.583-1.082l-1.777-1.941.672-2.684.215.645a2.49 2.49 0 001.238 1.445l.91.46c.61.31 1.352.06 1.66-.558.3-.613.055-1.37-.558-1.68zm-8.94 5.495a2.423 2.423 0 01-.554.84l-1.953 1.957a1.252 1.252 0 000 1.769 1.247 1.247 0 001.765 0l2.32-2.32a2.54 2.54 0 00.555-.84l.527-1.32c-2.16-2.355-1.511-1.633-1.851-2.097l-.809 2.011z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgWalkingSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
