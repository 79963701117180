import * as React from "react";

function SvgBoltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.313 8.25h-4.508l1.664-5.07A.941.941 0 0013.563 2H7.938a.939.939 0 00-.93.813l-1.25 9.374a.94.94 0 00.93 1.063h4.637l-1.801 7.598a.936.936 0 001.722.683l6.875-11.875a.936.936 0 00-.808-1.406z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBoltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
