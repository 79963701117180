import * as React from "react";

function SvgBackwardSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2.448 12.96l7.5 6.251c.805.672 2.051.11 2.051-.96V5.75c0-1.07-1.246-1.633-2.05-.961l-7.5 6.25a1.254 1.254 0 000 1.922zm10 0l7.501 6.251c.805.672 2.051.11 2.051-.96V5.75c0-1.07-1.246-1.633-2.05-.961l-7.501 6.25a1.254 1.254 0 000 1.922z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBackwardSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
