import * as React from "react";

function SvgTextWidthSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.285 2H2.715A.714.714 0 002 2.714v3.572A.714.714 0 002.715 7h1.428a.714.714 0 00.714-.714V5.57h5.357v5H9.143a.714.714 0 00-.714.714v1.429a.714.714 0 00.714.714h5.714a.714.714 0 00.714-.714v-1.429a.714.714 0 00-.714-.714h-1.071v-5h5.357v.715a.714.714 0 00.714.714h1.428A.714.714 0 0022 6.286V2.714A.714.714 0 0021.285 2zM18.22 13.638c-.416-.417-1.22-.194-1.22.505v2.142H7v-2.143c0-.638-.773-.951-1.219-.504l-3.571 3.57a.714.714 0 000 1.01L5.78 21.79c.416.416 1.22.193 1.22-.505v-2.143H17v2.143c0 .639.773.952 1.219.505l3.571-3.571a.714.714 0 000-1.01l-3.571-3.571z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTextWidthSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
