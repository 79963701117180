import * as React from "react";

function SvgGripVerticalSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.143 2H6.286c-.79 0-1.429.64-1.429 1.429v2.857c0 .789.64 1.428 1.429 1.428h2.857c.789 0 1.428-.64 1.428-1.428V3.429c0-.79-.64-1.429-1.428-1.429zm0 7.143H6.286c-.79 0-1.429.64-1.429 1.428v2.858c0 .788.64 1.428 1.429 1.428h2.857c.789 0 1.428-.64 1.428-1.428V10.57c0-.788-.64-1.428-1.428-1.428zm0 7.143H6.286c-.79 0-1.429.64-1.429 1.428v2.857c0 .79.64 1.429 1.429 1.429h2.857c.789 0 1.428-.64 1.428-1.429v-2.857c0-.789-.64-1.428-1.428-1.428zM17.714 2h-2.857c-.789 0-1.428.64-1.428 1.429v2.857c0 .789.64 1.428 1.428 1.428h2.857c.79 0 1.429-.64 1.429-1.428V3.429c0-.79-.64-1.429-1.429-1.429zm0 7.143h-2.857c-.789 0-1.428.64-1.428 1.428v2.858c0 .788.64 1.428 1.428 1.428h2.857c.79 0 1.429-.64 1.429-1.428V10.57c0-.788-.64-1.428-1.429-1.428zm0 7.143h-2.857c-.789 0-1.428.64-1.428 1.428v2.857c0 .79.64 1.429 1.428 1.429h2.857c.79 0 1.429-.64 1.429-1.429v-2.857c0-.789-.64-1.428-1.429-1.428z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGripVerticalSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
