import * as React from "react";

function SvgDiceSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.5 10h-3.71a2.534 2.534 0 01-.532 2.791L12 17.05V18.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5v-7a1.5 1.5 0 00-1.5-1.5zM17 15.75a.75.75 0 110-1.5.75.75 0 010 1.5zm-1.45-5.834l-5.466-5.467a1.533 1.533 0 00-2.168 0L2.449 9.916a1.533 1.533 0 000 2.168l5.467 5.467c.598.599 1.57.599 2.168 0l5.467-5.467c.599-.599.599-1.57 0-2.168zM5 11.75a.75.75 0 110-1.5.75.75 0 010 1.5zm4 4a.75.75 0 110-1.5.75.75 0 010 1.5zm0-4a.75.75 0 110-1.5.75.75 0 010 1.5zm0-4a.75.75 0 110-1.5.75.75 0 010 1.5zm4 4a.75.75 0 110-1.5.75.75 0 010 1.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDiceSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
