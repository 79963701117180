import * as React from "react";

function SvgBookSolid1(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.75 16.063V2.938A.935.935 0 0019.812 2H7C4.93 2 3.25 3.68 3.25 5.75v12.5C3.25 20.32 4.93 22 7 22h12.813c.519 0 .937-.418.937-.938v-.625a.945.945 0 00-.348-.73c-.164-.602-.164-2.316 0-2.918a.93.93 0 00.348-.727zM8.25 7.233c0-.129.105-.234.234-.234h8.282c.129 0 .234.105.234.234v.782a.235.235 0 01-.234.234H8.484a.235.235 0 01-.234-.234v-.782zm0 2.5c0-.129.105-.234.234-.234h8.282c.129 0 .234.105.234.234v.782a.235.235 0 01-.234.234H8.484a.235.235 0 01-.234-.234v-.782zm9.898 9.766H7c-.691 0-1.25-.559-1.25-1.25 0-.688.563-1.25 1.25-1.25h11.148a14.066 14.066 0 000 2.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBookSolid1);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
