import * as React from "react";

function SvgLockSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.875 10.75h-.938V7.937A5.945 5.945 0 0012 2a5.945 5.945 0 00-5.938 5.938v2.812h-.937c-1.035 0-1.875.84-1.875 1.875v7.5C3.25 21.16 4.09 22 5.125 22h13.75c1.035 0 1.875-.84 1.875-1.875v-7.5c0-1.035-.84-1.875-1.875-1.875zm-4.063 0H9.189V7.937A2.816 2.816 0 0112 5.126a2.816 2.816 0 012.813 2.813v2.812z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLockSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
