import * as React from "react";

function SvgRoadSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.902 17.093L17.05 5.982a1.057 1.057 0 00-.963-.649h-3.388l.085.805a.278.278 0 01-.277.306h-1.012a.278.278 0 01-.277-.306l.085-.805H7.915c-.414 0-.79.253-.963.649l-4.854 11.11c-.32.736.192 1.575.964 1.575h6.834l.358-3.392a.555.555 0 01.553-.497h2.389c.284 0 .522.214.552.497l.358 3.392h6.835c.771 0 1.284-.839.962-1.574zm-10.86-9.289a.278.278 0 01.276-.248h1.364c.142 0 .262.107.277.248l.16 1.513a.417.417 0 01-.415.46h-1.407a.416.416 0 01-.415-.46l.16-1.513zm1.918 5.863h-1.92a.555.555 0 01-.552-.614l.176-1.667a.556.556 0 01.552-.497h1.568c.284 0 .522.215.552.497l.176 1.667a.555.555 0 01-.552.614z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgRoadSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
