import * as React from "react";

function SvgHandshakeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.584 5.998H12.9a1 1 0 00-.675.262L9.153 9.073c-.003.003-.006.01-.01.012a1.243 1.243 0 00-.065 1.75c.397.435 1.231.55 1.753.085.003-.003.01-.003.013-.006l2.497-2.288a.5.5 0 11.675.738l-.816.746 4.55 3.694c.09.075.172.156.247.24V7.999L16.29 6.292a.993.993 0 00-.707-.294zM19 8.004v6.997c0 .553.447 1 1 1h2V8.004h-3zm1.5 6.997a.501.501 0 01-.5-.5c0-.275.225-.5.5-.5s.5.225.5.5-.225.5-.5.5zM2 15.998h2c.553 0 1-.447 1-1V8.004H2v7.994zM3.5 14c.275 0 .5.225.5.5s-.225.5-.5.5a.5.5 0 110-1zm13.622.581l-4.666-3.787-.937.86a2.253 2.253 0 01-3.178-.138 2.251 2.251 0 01.137-3.179l2.556-2.34H8.416a.998.998 0 00-.707.294L6 7.998v6.997h.572l2.828 2.56a2 2 0 002.813-.291l.006-.007.56.485a1.161 1.161 0 001.633-.17l.982-1.205.168.137a.998.998 0 001.407-.147l.297-.365c.35-.432.284-1.06-.144-1.41z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHandshakeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
