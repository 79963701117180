import * as React from "react";

function SvgCocktailSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.278 19.222h-1.945v-4.348l5.86-5.858c.538-.54.157-1.46-.605-1.46H2.858c-.763 0-1.145.92-.606 1.46l5.86 5.858v4.348H6.166c-.767 0-1.39.622-1.39 1.389 0 .154.125.278.279.278h8.333a.278.278 0 00.278-.278c0-.767-.622-1.389-1.39-1.389zM17 3.112c-2.174 0-4.005 1.395-4.694 3.333h1.825A3.322 3.322 0 0117 4.778a3.337 3.337 0 013.333 3.333A3.337 3.337 0 0117 11.444c-.487 0-.948-.11-1.365-.3L14.41 12.37A4.954 4.954 0 0017 13.11a5 5 0 100-10z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCocktailSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
