import * as React from "react";

function SvgAppleAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.956 7.04c1.014.181 1.846.728 2.496 1.64.573.807.963 1.823 1.17 3.047a9.715 9.715 0 01-.038 3.359c-.313 1.849-.937 3.398-1.873 4.648C17.592 21.244 16.188 22 14.497 22c-.416 0-.87-.13-1.365-.39A2.116 2.116 0 0012 21.296c-.416 0-.793.104-1.132.312-.494.26-.949.391-1.365.391-1.69 0-3.095-.755-4.214-2.266-.936-1.25-1.56-2.8-1.873-4.648a9.714 9.714 0 01-.039-3.36c.208-1.223.598-2.239 1.17-3.046.651-.912 1.483-1.459 2.498-1.64.624-.105 1.482-.014 2.575.272.936.261 1.73.574 2.38.938.65-.364 1.443-.677 2.38-.938 1.093-.286 1.951-.377 2.575-.273zm-2.147-1.603c-.364.34-.845.586-1.443.743-.417.13-.871.195-1.366.195l-.585-.04a5.008 5.008 0 010-1.25c.104-.937.403-1.653.897-2.147.364-.34.846-.586 1.444-.743.416-.13.871-.195 1.365-.195l.586.04.039.585c0 .495-.066.95-.195 1.367-.156.599-.403 1.081-.742 1.446z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAppleAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
