import * as React from "react";

function SvgTractorSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.5 14.5a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zm0 3.5a.751.751 0 010-1.5.751.751 0 010 1.5zM21 9h-2V7.744c0-.442.147-.868.411-1.214a.482.482 0 00-.041-.61l-.676-.755a.509.509 0 00-.772.025A4.006 4.006 0 0017 7.744V9h-3.198L12.05 4.91A1.497 1.497 0 0010.67 4H6.5C5.673 4 5 4.673 5 5.5v4.579a.68.68 0 00-.903.045l-.972.973a.687.687 0 000 .972l.158.158c-.156.29-.28.588-.372.898h-.223a.688.688 0 00-.688.687v1.375c0 .38.308.688.688.688h.223c.092.31.216.608.372.898l-.158.158a.687.687 0 000 .972l.972.972a.688.688 0 00.972 0l.158-.158c.29.156.588.28.898.372v.224c0 .38.308.687.687.687h1.375c.38 0 .688-.308.688-.688v-.223c.31-.092.608-.216.897-.372l.158.158a.688.688 0 00.973 0l.972-.972a.687.687 0 000-.972l-.158-.158c.156-.29.28-.588.372-.898h.224c.38 0 .687-.308.687-.687V15h2.517a3.732 3.732 0 012.983-1.5c.583 0 1.128.144 1.619.382l1.588-1.589a1 1 0 00.293-.707V10a1 1 0 00-1-1zM7.5 17a2.5 2.5 0 110-5 2.5 2.5 0 010 5zm.688-8H7V6h3.34l1.286 3H8.188z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTractorSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
