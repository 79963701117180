import * as React from "react";

function SvgRemoveFormatSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.5 17h-.35l.29-.868-2.096-1.62L9.515 17H8.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm9.307 1.316l-8.026-6.204L15.318 7.5H19v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-13a.5.5 0 00-.5.5v1.372L3.42 4.106a.5.5 0 00-.701.087l-.614.79a.5.5 0 00.088.702l18.386 14.21a.498.498 0 00.702-.088l.614-.79a.5.5 0 00-.088-.701zm-10.122-7.824L9 8.417V7.5h3.682l-.997 2.992z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgRemoveFormatSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
