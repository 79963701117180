import * as React from "react";

function SvgYinYangSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 15.161a1.29 1.29 0 110-2.58 1.29 1.29 0 010 2.58zM12 12a3.871 3.871 0 100 7.742 7.742 7.742 0 110-15.484A3.871 3.871 0 1112 12zm0-5.161a1.29 1.29 0 100 2.58 1.29 1.29 0 000-2.58z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgYinYangSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
