import * as React from "react";

function SvgSwimmerSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.925 13.706c.11.102.478.294 1.075.294.598 0 .964-.192 1.075-.294.5-.462 1.078-.706 1.67-.706h.51c.592 0 1.17.244 1.67.706.11.102.477.294 1.075.294s.964-.192 1.075-.294c.464-.429.996-.66 1.543-.693l-3.526-2.519.562-.401a.506.506 0 01.395-.082l3.136.673a1.501 1.501 0 00.628-2.934l-3.135-.673a3.507 3.507 0 00-2.768.575l-2.5 1.786c-.325.231-.606.514-.836.842L7.43 13.344c.17.1.338.216.495.362zM21.5 15H21c-.814 0-1.431-.263-1.753-.56-.278-.256-.614-.44-.993-.44h-.509c-.378 0-.715.184-.993.44-.32.297-.938.56-1.752.56-.814 0-1.431-.263-1.753-.56-.278-.256-.614-.44-.993-.44h-.509c-.378 0-.715.184-.993.44-.32.297-.938.56-1.752.56-.814 0-1.431-.263-1.753-.56-.278-.256-.614-.44-.993-.44h-.509c-.378 0-.715.184-.993.44-.32.297-.938.56-1.752.56h-.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5H3c1.207 0 2.272-.38 3-.995.728.614 1.793.995 3 .995s2.273-.38 3-.995c.727.614 1.793.995 3 .995s2.273-.38 3-.995c.727.614 1.793.995 3 .995h.5a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm-16-3a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSwimmerSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
