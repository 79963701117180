import * as React from "react";

function SvgRibbonSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.486 19.356a.934.934 0 00.176 1.394l2.688 1.09a.943.943 0 001.222-.148l3.586-3.98-3.093-3.435-4.579 5.079zm17.024 0S9.104 6.676 8.97 6.46c.602-.328 1.571-.7 3.028-.7s2.426.372 3.028.7c-.13.219-2.188 2.523-2.188 2.523l3.09 3.426 1.336-1.484a3.822 3.822 0 00.445-4.512l-1.707-2.871a2.215 2.215 0 00-.656-.703c-1.59-1.078-4.977-1.16-6.695 0-.27.183-.489.422-.657.703l-1.703 2.86c-.058.097-1.45 2.429.45 4.53l9.69 10.755a.939.939 0 001.224.148l2.687-1.09a.928.928 0 00.168-1.39z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgRibbonSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
