import * as React from "react";

function SvgFirstAidSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 5.889V18.11c0 .92.747 1.667 1.667 1.667h1.666V4.222H3.667C2.747 4.222 2 4.97 2 5.89zm4.444 13.889h11.112V4.222H6.444v15.556zm2.223-8.611c0-.153.125-.278.277-.278h1.945V8.944c0-.152.125-.277.278-.277h1.666c.153 0 .278.125.278.277v1.945h1.945c.152 0 .277.125.277.278v1.666a.279.279 0 01-.277.278H13.11v1.945a.279.279 0 01-.278.277h-1.666a.279.279 0 01-.278-.277V13.11H8.944a.279.279 0 01-.277-.278v-1.666zm11.666-6.945h-1.666v15.556h1.666c.92 0 1.667-.747 1.667-1.667V5.89c0-.92-.747-1.667-1.667-1.667z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFirstAidSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
