import * as React from "react";

function SvgSwimmingPoolSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.5 17H21c-.814 0-1.431-.263-1.753-.56-.278-.256-.614-.44-.993-.44h-.509c-.378 0-.715.184-.993.44-.32.297-.938.56-1.752.56-.814 0-1.431-.263-1.753-.56-.278-.256-.614-.44-.993-.44h-.509c-.378 0-.715.184-.993.44-.32.297-.938.56-1.752.56-.814 0-1.431-.263-1.753-.56-.278-.256-.614-.44-.993-.44h-.509c-.378 0-.715.184-.993.44-.32.297-.938.56-1.752.56h-.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5H3c1.207 0 2.272-.38 3-.995.728.614 1.793.995 3 .995s2.273-.38 3-.995c.727.614 1.793.995 3 .995s2.273-.38 3-.995c.727.614 1.793.995 3 .995h.5a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zM9 16v-3h6v3c.598 0 .964-.193 1.075-.294.286-.265.6-.448.925-.565V8c0-.551.449-1 1-1 .551 0 1 .449 1 1v.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V8c0-1.654-1.346-3-3-3s-3 1.346-3 3v3H9V8c0-.551.449-1 1-1 .551 0 1 .449 1 1v.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V8c0-1.654-1.346-3-3-3S7 6.346 7 8v7.14c.325.117.639.301.925.565.11.102.478.295 1.075.295z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSwimmingPoolSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
