import * as React from "react";

function SvgHourglassOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.875 3.875h.156c.26 0 .469-.21.469-.469V2.47A.469.469 0 0019.031 2H4.97a.469.469 0 00-.469.469v.937c0 .26.21.469.469.469h.156c0 3.147 1.257 6.477 3.796 8.125-2.55 1.655-3.796 4.996-3.796 8.125h-.156a.469.469 0 00-.469.469v.937c0 .26.21.469.469.469H19.03c.26 0 .469-.21.469-.469v-.937a.469.469 0 00-.469-.469h-.156c0-3.147-1.257-6.477-3.796-8.125 2.55-1.655 3.796-4.996 3.796-8.125zM7 3.875h10c0 3.97-2.239 7.188-5 7.188S7 7.845 7 3.875zm10 16.25H7c0-3.97 2.239-7.188 5-7.188s5 3.219 5 7.188z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHourglassOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
