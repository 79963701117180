import * as React from "react";

function SvgHockeyPuckSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 8.25C2 6.18 6.477 4.5 12 4.5s10 1.68 10 3.75C22 10.32 17.523 12 12 12S2 10.32 2 8.25zm0 3.21v4.29c0 2.07 4.477 3.75 10 3.75s10-1.68 10-3.75v-4.29c-4.43 3.216-15.566 3.22-20 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHockeyPuckSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
