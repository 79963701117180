import * as React from "react";

function SvgSunOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.3 10.669l-2.335-1.582.535-2.773a1.577 1.577 0 00-1.847-1.855l-2.769.535-1.578-2.34c-.59-.87-2.027-.87-2.616 0l-1.578 2.34-2.765-.535a1.574 1.574 0 00-1.418.437 1.578 1.578 0 00-.433 1.418l.535 2.773-2.336 1.582A1.58 1.58 0 002 11.98c0 .527.262 1.015.695 1.308l2.336 1.582-.535 2.773a1.575 1.575 0 00.433 1.418c.371.37.894.535 1.418.433l2.765-.535 1.578 2.34a1.576 1.576 0 002.616.003l1.578-2.339 2.77.535a1.558 1.558 0 001.417-.433c.37-.371.531-.903.433-1.418l-.535-2.773 2.336-1.582a1.582 1.582 0 00-.004-2.624zm-4.408 3.343l.687 3.562-3.554-.688-2.027 3.004-2.027-3.008-3.55.688.687-3.562-2.999-2.03 3-2.032-.688-3.562 3.554.688 2.023-3 2.027 3.004 3.554-.688-.687 3.558 2.999 2.031-3 2.035zm-4.894-6.073A4.069 4.069 0 007.936 12a4.069 4.069 0 004.062 4.061A4.069 4.069 0 0016.06 12a4.069 4.069 0 00-4.062-4.061zm0 6.249A2.189 2.189 0 019.811 12c0-1.206.98-2.187 2.187-2.187a2.189 2.189 0 010 4.374z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSunOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
