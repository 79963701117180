import * as React from "react";

function SvgBalanceScaleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10 14.5c0-.506.041-.273-2.658-5.672-.552-1.103-2.131-1.105-2.684 0-2.722 5.445-2.657 5.182-2.657 5.672H2C2 15.88 3.79 17 6 17s4-1.12 4-2.5zm-4-5L8.25 14h-4.5L6 9.5zm16 5c0-.506.041-.273-2.658-5.672-.552-1.103-2.131-1.105-2.684 0-2.722 5.445-2.657 5.182-2.657 5.672H14c0 1.38 1.79 2.5 4 2.5s4-1.12 4-2.5zm-6.25-.5L18 9.5l2.25 4.5h-4.5zm2.75 4H13V8.79A2.502 2.502 0 0014.45 7h4.05a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-4.511A2.488 2.488 0 0012 4c-.815 0-1.533.396-1.989 1H5.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h4.05A2.502 2.502 0 0011 8.79V18H5.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBalanceScaleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
