import * as React from "react";

function SvgFlagOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.132 5.125c-1.92 0-3.645-1.25-6.325-1.25a8.67 8.67 0 00-3.153.593A1.877 1.877 0 004 2.004 1.875 1.875 0 002.937 5.5v15.876c0 .345.28.625.626.625h.624c.346 0 .625-.28.625-.625v-3.26C6.293 17.442 7.596 17 9.806 17c1.919 0 3.644 1.25 6.324 1.25 2.285 0 3.984-.884 5.022-1.562.53-.346.848-.936.848-1.569V5.748c0-1.347-1.378-2.257-2.613-1.724-1.403.605-2.87 1.101-4.255 1.101zm4.993 10c-.85.602-2.376 1.25-3.995 1.25-2.341 0-3.984-1.25-6.324-1.25-1.694 0-3.765.367-4.993.938V7c.85-.602 2.375-1.25 3.994-1.25C11.15 5.75 12.791 7 15.132 7c1.69 0 3.762-.678 4.993-1.25v9.375z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFlagOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
