import * as React from "react";

function SvgVideoOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 16.145V7.203c-.043-.734-.734-1.21-1.123-1.123-.389.086-.648.26-.648.26l-3.801 2.375v-2.03C16.428 4.957 14.613 5 14.613 5H3.814C2.13 5 2 6.685 2 6.685v9.978c0 1.598 1.814 1.685 1.814 1.685h10.843c1.684 0 1.77-1.642 1.77-1.642V14.59l3.802 2.419.648.216c1.253-.216 1.123-1.08 1.123-1.08zm-7.257.086a.433.433 0 01-.432.432H4.117a.433.433 0 01-.432-.432V7.117c0-.216.172-.432.432-.432h10.237c.216 0 .432.173.432.432v9.114h-.043zm5.572-1.08l-3.844-2.419v-1.987l3.844-2.505v6.911z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgVideoOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
