import * as React from "react";

function SvgAddressCardOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.333 4.222H3.667C2.747 4.222 2 4.97 2 5.89V18.11c0 .92.747 1.667 1.667 1.667h16.666c.92 0 1.667-.747 1.667-1.667V5.89c0-.92-.747-1.667-1.667-1.667zm0 13.89H3.667V5.888h16.666V18.11zM9.223 12a2.224 2.224 0 002.221-2.222 2.224 2.224 0 00-2.222-2.223A2.224 2.224 0 007 9.779C7 11.003 7.997 12 9.222 12zM6.11 16.444h6.222c.43 0 .778-.298.778-.666v-.667c0-1.104-1.045-2-2.333-2-.375 0-.65.278-1.556.278-.934 0-1.16-.278-1.555-.278-1.289 0-2.334.896-2.334 2v.667c0 .368.348.666.778.666zm8.389-2.222h3.889a.279.279 0 00.278-.278v-.555a.279.279 0 00-.278-.278H14.5a.279.279 0 00-.278.278v.555c0 .153.125.278.278.278zm0-2.222h3.889a.279.279 0 00.278-.278v-.555a.279.279 0 00-.278-.278H14.5a.279.279 0 00-.278.278v.555c0 .153.125.278.278.278zm0-2.222h3.889a.279.279 0 00.278-.278v-.556a.279.279 0 00-.278-.277H14.5a.279.279 0 00-.278.277V9.5c0 .153.125.278.278.278z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAddressCardOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
