import * as React from "react";

function SvgLongArrowAltUpSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.214 7.985v13.48c0 .295.24.535.536.535h2.5c.296 0 .536-.24.536-.536V7.984h2.056c.955 0 1.432-1.153.757-1.828l-3.841-3.842a1.071 1.071 0 00-1.516 0L7.4 6.156c-.674.675-.196 1.829.758 1.829h2.056z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLongArrowAltUpSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
