import * as React from "react";

function SvgVideoSlashSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.806 18.316l-1.719-1.328c.481-.044.913-.428.913-.972V7.984c0-.797-.91-1.262-1.576-.806L16 9.541v4.287l-1-.772V7.494C15 6.669 14.331 6 13.506 6H5.87l-2.45-1.894a.502.502 0 00-.703.088l-.613.787a.498.498 0 00.088.7l1.14.881L15 15.582l5.579 4.312a.502.502 0 00.703-.087l.612-.791a.495.495 0 00-.087-.7zm-18.807-1.81C3 17.331 3.668 18 4.493 18h9.013c.35 0 .668-.125.925-.328L2.999 8.834v7.672z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgVideoSlashSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
