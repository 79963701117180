import * as React from "react";

function SvgSnowplowSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M5.75 15.75a.748.748 0 00-.75.75c0 .416.335.75.75.75.416 0 .75-.334.75-.75a.748.748 0 00-.75-.75zm2.5 0a.748.748 0 00-.75.75c0 .416.335.75.75.75.416 0 .75-.334.75-.75a.748.748 0 00-.75-.75zm2.5 0a.748.748 0 00-.75.75c0 .416.335.75.75.75.416 0 .75-.334.75-.75a.748.748 0 00-.75-.75zm2.5 0a.748.748 0 00-.75.75c0 .416.335.75.75.75.416 0 .75-.334.75-.75a.748.748 0 00-.75-.75zm7.458 1.544A2.41 2.41 0 0120 15.588v-3.175c0-.641.253-1.254.707-1.707l1.147-1.147a.502.502 0 000-.706l-.707-.706a.502.502 0 00-.706 0l-1.147 1.147a4.414 4.414 0 00-1.294 3.122V13h-2v-1.59c0-.273-.056-.538-.162-.788l-2.447-5.713a1.5 1.5 0 00-1.382-.91H7.5A1.5 1.5 0 006 5.5V9h-.5A1.5 1.5 0 004 10.5v2.851A3.492 3.492 0 005.5 20h8c1.935 0 3.501-1.565 3.501-3.5 0-.54-.131-1.043-.35-1.5h1.35v.585c0 1.172.466 2.294 1.294 3.122l1.147 1.147a.502.502 0 00.706 0l.707-.707a.502.502 0 000-.706l-1.147-1.147zM8 6h3.682l2.144 5H10L8 9V6zm5.5 12h-8a1.5 1.5 0 010-3h8a1.5 1.5 0 010 3z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSnowplowSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
