import * as React from "react";

function SvgWindowCloseOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 3.25H3.875C2.84 3.25 2 4.09 2 5.125v13.75c0 1.035.84 1.875 1.875 1.875h16.25c1.035 0 1.875-.84 1.875-1.875V5.125c0-1.035-.84-1.875-1.875-1.875zm0 15.39a.235.235 0 01-.234.235H4.109a.235.235 0 01-.234-.234V5.359c0-.129.105-.234.234-.234h15.782c.129 0 .234.105.234.234v13.282zm-4.2-9.038L13.528 12l2.399 2.398c.18.18.18.473 0 .657l-.871.87a.467.467 0 01-.657 0L12 13.528l-2.398 2.399a.467.467 0 01-.657 0l-.87-.871a.467.467 0 010-.657L10.472 12 8.074 9.602a.467.467 0 010-.657l.871-.87c.18-.18.473-.18.657 0L12 10.472l2.398-2.399c.18-.18.473-.18.657 0l.87.871a.46.46 0 010 .657z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgWindowCloseOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
