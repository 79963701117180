import * as React from "react";

function SvgImageOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 4.5H3.875C2.839 4.5 2 5.34 2 6.375v11.25c0 1.035.84 1.875 1.875 1.875h16.25c1.035 0 1.875-.84 1.875-1.875V6.375c0-1.036-.84-1.875-1.875-1.875zm-.234 13.125H4.109a.235.235 0 01-.234-.234V6.609a.234.234 0 01.234-.234h15.782a.235.235 0 01.234.234v10.782a.235.235 0 01-.234.234zM7 7.937a1.563 1.563 0 100 3.126 1.563 1.563 0 000-3.126zM5.75 15.75h12.5v-3.125l-3.419-3.419a.469.469 0 00-.662 0L9.5 13.875l-1.544-1.544a.469.469 0 00-.662 0L5.75 13.875v1.875z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgImageOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
