import * as React from "react";

function SvgDumpsterFireSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.086 7.254l.007-.006-.45-2.25H11.5v4h1.9a16.972 16.972 0 011.685-1.744zM10.501 5h-3.14l-.8 4.001h3.94v-4zm5.917 2.254a18.92 18.92 0 011.597 1.61c.178-.175.356-.347.54-.51l.666-.594.666.594c.035.028.066.066.097.097-.003-.025.006-.047 0-.072l-.75-3A.495.495 0 0018.753 5h-3.091l.384 1.923.372.33zM2.5 9h3.041l.8-4h-3.09a.501.501 0 00-.485.378l-.75 3a.5.5 0 00.484.622zm10.145 1H3l.125 1H2.5c-.275 0-.5.225-.5.5v1c0 .276.225.5.5.5h.875L4 18.004v.5c0 .275.225.5.5.5h1c.276 0 .5-.225.5-.5v-.5h6.527A5.904 5.904 0 0111 14.052c0-1.122.622-2.591 1.644-4.05zm6.58-.9a13.1 13.1 0 00-1.257 1.288A16.837 16.837 0 0015.75 8c-2.194 1.96-3.75 4.51-3.75 6.05 0 2.736 2.237 4.952 5 4.952S22 16.787 22 14.052c.003-1.144-1.156-3.507-2.775-4.95zm-.582 7.17a2.781 2.781 0 01-1.64.531c-1.532 0-2.78-1.047-2.78-2.75 0-.847.516-1.594 1.545-2.872.147.175 2.097 2.753 2.097 2.753l1.244-1.469c.087.15.169.297.24.438.582 1.147.338 2.613-.706 3.369z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDumpsterFireSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
