import * as React from "react";

function SvgStickyNoteSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.929 14.857H22V3.071C22 2.478 21.522 2 20.929 2H3.07C2.478 2 2 2.478 2 3.071V20.93C2 21.522 2.478 22 3.071 22h11.786v-6.071c0-.59.482-1.072 1.072-1.072zm5.758 2.456l-4.375 4.375c-.2.2-.473.312-.758.312h-.268v-5.714H22v.272c0 .281-.112.554-.313.755z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgStickyNoteSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
