import * as React from "react";

function SvgBorderAllSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.571 2H3.43A1.429 1.429 0 002 3.429V20.57A1.429 1.429 0 003.429 22H20.57A1.429 1.429 0 0022 20.571V3.43A1.429 1.429 0 0020.571 2zm-1.428 2.857v5.714h-5.714V4.857h5.714zm-8.572 0v5.714H4.857V4.857h5.714zM4.857 19.143v-5.714h5.714v5.714H4.857zm8.572 0v-5.714h5.714v5.714h-5.714z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBorderAllSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
