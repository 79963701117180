import * as React from "react";

function SvgShoppingBagSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17 8.25V7c0-2.757-2.243-5-5-5S7 4.243 7 7v1.25H3.25v10.625C3.25 20.601 4.65 22 6.375 22h11.25c1.726 0 3.125-1.4 3.125-3.125V8.25H17zM9.5 7c0-1.379 1.121-2.5 2.5-2.5s2.5 1.121 2.5 2.5v1.25h-5V7zm6.25 4.688a.937.937 0 110-1.875.937.937 0 010 1.874zm-7.5 0a.937.937 0 110-1.875.937.937 0 010 1.874z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgShoppingBagSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
