import * as React from "react";

function SvgWonSignSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.583 9.766c.23 0 .417-.188.417-.417V7.96a.418.418 0 00-.417-.417h-1.666l.645-2.798a.418.418 0 00-.406-.51h-1.6a.413.413 0 00-.407.33l-.5 2.978h-3.816l-.684-2.986a.416.416 0 00-.406-.323h-1.528a.416.416 0 00-.406.323l-.694 2.986H6.34l-.607-2.975a.417.417 0 00-.41-.334H3.86a.415.415 0 00-.406.507l.628 2.802H2.417A.418.418 0 002 7.96V9.35c0 .23.188.417.417.417H4.58l.25 1.11H2.417a.418.418 0 00-.417.417v1.39c0 .229.188.416.417.416H5.33l1.42 6.34a.416.416 0 00.406.327h1.972a.42.42 0 00.407-.323l1.469-6.344h1.913l1.472 6.344a.42.42 0 00.406.323h1.972a.42.42 0 00.407-.323l1.461-6.344h2.948c.23 0 .417-.188.417-.417v-1.389a.418.418 0 00-.417-.416H19.15l.257-1.111h2.177zM8.382 14.974c-.215.896-.236 1.639-.254 1.639H8.09s-.059-.764-.236-1.639l-.382-1.875H8.82l-.437 1.875zm.955-4.097h-2.32l-.225-1.111h2.805l-.26 1.11zm2.184 0l.07-.299c.065-.278.12-.555.166-.812h.41c.045.257.1.534.166.812l.07.299h-.882zm4.545 4.097a15.153 15.153 0 00-.236 1.639h-.038c-.021 0-.038-.743-.254-1.639l-.43-1.875h1.357l-.399 1.875zm.875-4.097h-2.34l-.254-1.111h2.834l-.24 1.11z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgWonSignSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
