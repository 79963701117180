import * as React from "react";

function SvgSignalGoodSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.342 11.186c.19.198.495.189.684-.018 2.107-2.233 4.871-3.413 7.978-3.413 3.115 0 5.888 1.189 7.995 3.422.18.189.478.189.667-.01l1.197-1.206c.171-.18.18-.414.036-.594C19.83 6.81 15.975 5 12.004 5 8.034 5 4.17 6.81 2.108 9.367c-.153.18-.144.414.027.594l1.207 1.225zm3.575 3.538c.216.207.495.18.702-.036 1.053-1.134 2.71-1.927 4.385-1.918 1.693-.008 3.35.802 4.42 1.945.19.207.46.207.667 0l1.35-1.305a.403.403 0 00.037-.576c-1.35-1.621-3.8-2.774-6.474-2.774-2.674 0-5.123 1.153-6.474 2.774-.144.18-.135.396.036.576l1.35 1.314zm5.096 4.754c.207 0 .396-.099.747-.432l2.098-2.017c.162-.162.198-.414.054-.594-.612-.774-1.71-1.386-2.9-1.386-1.233 0-2.34.639-2.952 1.458-.109.162-.055.37.099.522l2.098 2.017c.35.324.54.432.756.432z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSignalGoodSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
