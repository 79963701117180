import * as React from "react";

function SvgFileExcelSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.25 7.313V2H5.437a.935.935 0 00-.937.938v18.125c0 .519.418.937.938.937h13.125c.519 0 .937-.418.937-.938V8.25h-5.313a.94.94 0 01-.937-.938zm2.348 4.16l-2.348 3.652 2.348 3.652a.47.47 0 01-.395.723H13.84a.47.47 0 01-.414-.246C12.66 17.84 12 16.57 12 16.57c-.25.578-.39.782-1.43 2.688a.464.464 0 01-.41.246H8.797a.47.47 0 01-.395-.723l2.356-3.652-2.356-3.652a.468.468 0 01.395-.723h1.36a.47.47 0 01.413.246c1.02 1.906.782 1.313 1.43 2.676 0 0 .238-.457 1.43-2.676a.47.47 0 01.414-.246h1.36a.466.466 0 01.394.719zM19.5 6.762V7h-5V2h.238c.25 0 .489.098.664.273l3.825 3.829a.935.935 0 01.273.66z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFileExcelSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
