import * as React from "react";

function SvgQuoteLeftSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 12H17V9.5C17 8.121 18.121 7 19.5 7h.313c.519 0 .937-.418.937-.938V4.188a.935.935 0 00-.938-.937H19.5a6.248 6.248 0 00-6.25 6.25v9.375c0 1.035.84 1.875 1.875 1.875h5c1.035 0 1.875-.84 1.875-1.875v-5C22 12.84 21.16 12 20.125 12zm-11.25 0H5.75V9.5c0-1.379 1.121-2.5 2.5-2.5h.313c.519 0 .937-.418.937-.938V4.188a.935.935 0 00-.938-.937H8.25A6.248 6.248 0 002 9.5v9.375c0 1.035.84 1.875 1.875 1.875h5c1.035 0 1.875-.84 1.875-1.875v-5C10.75 12.84 9.91 12 8.875 12z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgQuoteLeftSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
