import * as React from "react";

function SvgHammerSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.837 9.845l-.786-.786a.556.556 0 00-.785 0l-.393.393-1.004-1.003a2.213 2.213 0 00-.567-2.14L16.73 4.74a5.556 5.556 0 00-7.856 0l3.142 1.57v.651c0 .59.234 1.155.651 1.572l1.707 1.706c.58.58 1.399.763 2.139.568l1.003 1.003-.392.393a.556.556 0 000 .785l.785.786a.556.556 0 00.786 0l3.143-3.143a.556.556 0 000-.785zm-9.955-.528a3.375 3.375 0 01-.342-.415l-8.858 8.27a2.147 2.147 0 103.035 3.035l8.268-8.857a3.372 3.372 0 01-.397-.326l-1.706-1.707z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHammerSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
