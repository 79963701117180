import * as React from "react";

function SvgSyncAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.626 7.052a6.726 6.726 0 00-4.632-1.826A6.782 6.782 0 005.43 10.34a.485.485 0 01-.47.369H2.65a.484.484 0 01-.477-.572C3.046 5.505 7.113 2 12 2a9.966 9.966 0 016.908 2.77l1.44-1.44c.61-.61 1.652-.178 1.652.684v5.405a.968.968 0 01-.968.968h-5.405c-.863 0-1.294-1.042-.685-1.652l1.684-1.683zm-13.658 6.56h5.405c.863 0 1.294 1.043.685 1.653l-1.684 1.683a6.726 6.726 0 004.632 1.826 6.783 6.783 0 006.564-5.115.485.485 0 01.47-.369h2.31c.303 0 .533.275.477.572C20.954 18.495 16.887 22 12 22a9.966 9.966 0 01-6.908-2.77l-1.44 1.44c-.61.61-1.652.178-1.652-.684v-5.405c0-.535.433-.968.968-.968z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSyncAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
