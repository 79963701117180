import * as React from "react";

function SvgEthernetSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.375 9.5H19.5V7.625A.627.627 0 0018.875 7H17V5.125a.627.627 0 00-.625-.625h-8.75A.627.627 0 007 5.125V7H5.125a.627.627 0 00-.625.625V9.5H2.625a.627.627 0 00-.625.625v8.75c0 .344.281.625.625.625H5.75v-5H7v5h2.5v-5h1.25v5h2.5v-5h1.25v5H17v-5h1.25v5h3.125a.627.627 0 00.625-.625v-8.75a.627.627 0 00-.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgEthernetSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
