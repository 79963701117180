import * as React from "react";

function SvgDonateSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 18.25a8.124 8.124 0 008.125-8.125A8.124 8.124 0 0012 2a8.124 8.124 0 00-8.125 8.125A8.124 8.124 0 0012 18.25zm-.867-12.445v-.657c0-.359.289-.648.648-.648h.434c.36 0 .648.29.648.648v.665a3.08 3.08 0 011.68.601c.219.16.242.48.047.668l-.637.606c-.148.144-.371.148-.547.039-.21-.133-.445-.2-.695-.2h-1.52c-.351 0-.636.32-.636.715 0 .32.195.606.472.688l2.434.73c1.004.3 1.707 1.266 1.707 2.348 0 1.328-1.031 2.402-2.309 2.437v.657c0 .359-.289.648-.648.648h-.434a.647.647 0 01-.648-.648v-.665a3.08 3.08 0 01-1.68-.601.438.438 0 01-.047-.668l.637-.605c.149-.145.371-.149.547-.04.21.133.445.2.695.2h1.52c.351 0 .636-.32.636-.715 0-.32-.195-.606-.472-.688l-2.434-.73c-1.004-.3-1.707-1.266-1.707-2.348.004-1.328 1.031-2.402 2.309-2.437zm9.617 9.945h-1.27a9.468 9.468 0 01-2.851 2.5h2.492c.207 0 .375.14.375.313v.625c0 .171-.168.312-.375.312H4.875c-.207 0-.375-.14-.375-.313v-.625c0-.171.168-.312.375-.312h2.492a9.515 9.515 0 01-2.851-2.5H3.25C2.559 15.75 2 16.309 2 17v3.75c0 .691.559 1.25 1.25 1.25h17.5c.691 0 1.25-.559 1.25-1.25V17c0-.691-.559-1.25-1.25-1.25z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDonateSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
