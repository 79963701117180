import * as React from "react";

function SvgClipboardSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.5 6.375v13.75c0 1.035-.84 1.875-1.875 1.875H6.375A1.875 1.875 0 014.5 20.125V6.375c0-1.036.84-1.875 1.875-1.875H9.5C9.5 3.121 10.621 2 12 2s2.5 1.121 2.5 2.5h3.125c1.035 0 1.875.84 1.875 1.875zM12 3.562a.937.937 0 100 1.875.937.937 0 000-1.875zm3.75 4.454v-.782A.235.235 0 0015.516 7H8.484a.234.234 0 00-.234.234v.782a.234.234 0 00.234.234h7.032a.235.235 0 00.234-.234z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgClipboardSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
