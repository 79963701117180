import * as React from "react";

function SvgVideoSolid1(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.674 5.333H3.66A1.66 1.66 0 002 6.993v10.014c0 .917.743 1.66 1.66 1.66h10.014a1.66 1.66 0 001.66-1.66V6.993a1.66 1.66 0 00-1.66-1.66zm6.576 1.31l-3.806 2.624v5.466l3.806 2.621c.736.507 1.75-.01 1.75-.896v-8.92c0-.882-1.01-1.403-1.75-.896z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgVideoSolid1);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
