import * as React from "react";

function SvgPaperPlaneSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.593 2.126L2.49 12.57a.938.938 0 00.086 1.688l4.151 1.741 11.22-9.888c.216-.192.52.101.337.324L8.876 17.896v3.144c0 .922 1.113 1.285 1.66.617l2.48-3.018 4.866 2.038a.94.94 0 001.29-.71l2.811-16.872c.133-.79-.715-1.36-1.39-.969z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPaperPlaneSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
