import * as React from "react";

function SvgPaperPlaneOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.184 2.254l-16.248 9.37c-1.344.778-1.215 2.766.222 3.355l4.465 1.848v3.297c0 1.812 2.312 2.558 3.382 1.117l1.711-2.309 4.371 1.805a1.88 1.88 0 002.57-1.45l2.32-15.123c.238-1.566-1.441-2.687-2.793-1.91zm-9.686 17.87V17.6l1.43.59-1.43 1.934zm8.304-1.121l-6.008-2.48 5.477-7.902c.418-.606-.372-1.309-.926-.828L8.084 14.99l-4.21-1.742 16.248-9.374-2.32 15.128z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPaperPlaneOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
