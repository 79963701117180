import * as React from "react";

function SvgChessKnightSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M4.792 12.02l1.693.752a1.334 1.334 0 001.036.02l.533-.214a1.332 1.332 0 00.781-.854l.385-1.277a1 1 0 01.522-.652l.922-.462v2.097a2 2 0 01-1.105 1.79l-2.384 1.193a3.334 3.334 0 00-1.842 2.982V18h13.328v-8a8 8 0 00-7.997-8H4.5a.5.5 0 00-.5.5c0 .11.025.218.075.316L4.667 4l-.375.375A1 1 0 004 5.083V10.8a1.334 1.334 0 00.792 1.22zM6.167 6a.833.833 0 110 1.667.833.833 0 010-1.667zm13.166 13.333H4.667A.667.667 0 004 20v1.333a.667.667 0 00.667.667h14.666a.666.666 0 00.667-.667V20a.666.666 0 00-.667-.667z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChessKnightSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
