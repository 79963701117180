import * as React from "react";

function SvgSuitcaseSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7 20.75h10V5.125c0-1.035-.84-1.875-1.875-1.875h-6.25C7.84 3.25 7 4.09 7 5.125V20.75zm2.5-15h5V7h-5V5.75zM22 8.875v10c0 1.035-.84 1.875-1.875 1.875H18.25V7h1.875C21.16 7 22 7.84 22 8.875zM5.75 20.75H3.875A1.875 1.875 0 012 18.875v-10C2 7.84 2.84 7 3.875 7H5.75v13.75z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSuitcaseSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
