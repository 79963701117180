import * as React from "react";

function SvgPepperHotSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M14.917 12.278V8.773l-2.06-.946c-2.285 2.718-3.139 9.798-8.67 9.798a2.188 2.188 0 000 4.375c8.307 0 14.479-4.8 16.375-8.217l-1.448-1.505h-4.197zm5.12-5.015c1.257-2.337.603-4.229-.122-5.146a.312.312 0 00-.466-.023l-.895.898a.315.315 0 00-.034.4c.268.404.666 1.37-.055 2.825a5.58 5.58 0 00-2.233-.467c-1.094 0-2.11.333-2.982.882l2.917 1.34v3.056h3.48l2.136 2.222A6.1 6.1 0 0022 11.714a6.016 6.016 0 00-1.962-4.45z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPepperHotSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
