import * as React from "react";

function SvgHandsHelpingSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.25 10H12.5v1.75c0 1.24-1.009 2.25-2.25 2.25C9.01 14 8 12.99 8 11.75v-3.8L5.974 9.169A1.996 1.996 0 005 10.88v1.478l-2.5 1.444a.998.998 0 00-.366 1.366l2.5 4.331a1 1 0 001.366.366L9.23 18h4.27c1.103 0 2-.897 2-2h.5c.553 0 1-.447 1-1v-2h.25c.415 0 .75-.334.75-.75v-1.5a.748.748 0 00-.75-.75zm4.616-1.169l-2.5-4.331a1 1 0 00-1.365-.366L14.769 6h-3.193c-.375 0-.74.106-1.06.303l-1.047.653a.993.993 0 00-.468.847v3.947a1.25 1.25 0 102.5 0V9h5.75c.965 0 1.75.784 1.75 1.75v.89l2.5-1.443c.478-.278.64-.888.365-1.366z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHandsHelpingSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
