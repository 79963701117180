import * as React from "react";

function SvgLockOpenSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.705 3.111c-2.917.01-5.26 2.413-5.26 5.33v2.448H3.666c-.92 0-1.667.746-1.667 1.667v6.666c0 .92.747 1.667 1.667 1.667h12.222c.92 0 1.667-.747 1.667-1.667v-6.666c0-.92-.747-1.667-1.667-1.667h-1.667V8.42c0-1.375 1.1-2.517 2.476-2.531a2.504 2.504 0 012.524 2.5v2.778c0 .462.372.833.834.833h1.11a.831.831 0 00.834-.833V8.389a5.284 5.284 0 00-5.295-5.278z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLockOpenSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
