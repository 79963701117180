import * as React from "react";

function SvgCommentsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.448 9.552C16.448 6.498 13.187 4 9.231 4 5.24 4 2.014 6.498 2.014 9.552c0 1.214.485 2.29 1.318 3.192-.486 1.076-1.249 1.909-1.249 1.909-.07.069-.104.208-.07.312.07.104.14.139.278.139 1.25 0 2.29-.417 3.054-.868 1.11.555 2.463.867 3.886.867 3.956 0 7.217-2.463 7.217-5.551zm4.233 7.634C21.48 16.283 22 15.208 22 13.993c0-2.29-1.874-4.302-4.51-5.135.034.243.069.486.069.694 0 3.678-3.748 6.662-8.328 6.662-.382 0-.763 0-1.11-.035 1.075 1.978 3.643 3.366 6.662 3.366 1.422 0 2.74-.312 3.851-.868a6.027 6.027 0 003.088.868c.104 0 .209-.035.243-.139.035-.104.035-.243-.07-.312 0 0-.762-.833-1.213-1.909z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCommentsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
