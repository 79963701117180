import * as React from "react";

function SvgToothSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.593 5.76c-.43-1.767-1.84-3.206-3.594-3.661-1.257-.327-2.46.199-3.482.95-.127.094-.272.146-.41.214l1.106.712a.625.625 0 01-.676 1.05L9.61 2.503C8.802 2.078 7.916 1.86 7 2.099c-1.754.456-3.164 1.894-3.594 3.66-.396 1.627-.017 3.157.84 4.314.912 1.234 1.276 2.682 1.417 4.194.172 1.842.404 3.678.818 5.481l.305 1.326A1.19 1.19 0 007.946 22a1.19 1.19 0 001.154-.905l1.346-5.407A1.602 1.602 0 0112 14.471c.735 0 1.375.502 1.554 1.217l1.346 5.407a1.19 1.19 0 001.154.905 1.19 1.19 0 001.159-.926l.305-1.326c.414-1.803.645-3.639.818-5.481.14-1.512.505-2.96 1.417-4.194.858-1.156 1.237-2.687.841-4.313z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgToothSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
