import * as React from "react";

function SvgCheckSquareOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.857 2H4.143C2.959 2 2 2.96 2 4.143v15.714C2 21.041 2.96 22 4.143 22h15.714C21.041 22 22 21.04 22 19.857V4.143C22 2.959 21.04 2 19.857 2zm0 17.857H4.143V4.143h15.714v15.714zm-1.6-10.791l-7.706 7.643a.536.536 0 01-.757-.003L5.74 12.62a.536.536 0 01.003-.757l1.014-1.006a.536.536 0 01.758.003l2.67 2.69 6.31-6.259a.536.536 0 01.757.003l1.006 1.014c.209.21.207.55-.003.758z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCheckSquareOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
