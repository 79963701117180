import * as React from "react";

function SvgBandAidSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 9v6c0 1.103.897 2 2 2h3V7H4c-1.103 0-2 .897-2 2zm18-2h-3v10h3c1.103 0 2-.897 2-2V9c0-1.103-.897-2-2-2zM8 17h8V7H8v10zm5.5-7.25c.416 0 .75.334.75.75s-.334.75-.75.75a.748.748 0 01-.75-.75c0-.416.334-.75.75-.75zm0 3c.416 0 .75.334.75.75s-.334.75-.75.75a.748.748 0 01-.75-.75c0-.416.334-.75.75-.75zm-3-3c.416 0 .75.334.75.75s-.334.75-.75.75a.748.748 0 01-.75-.75c0-.416.334-.75.75-.75zm0 3c.416 0 .75.334.75.75s-.334.75-.75.75a.748.748 0 01-.75-.75c0-.416.334-.75.75-.75z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBandAidSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
