import * as React from "react";

function SvgParkingSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.857 2H4.143C2.96 2 2 2.96 2 4.143v15.714C2 21.04 2.96 22 4.143 22h15.714C21.04 22 22 21.04 22 19.857V4.143C22 2.96 21.04 2 19.857 2zm-7.143 12.857h-2.143V17a.716.716 0 01-.714.714H8.43A.716.716 0 017.714 17V7c0-.393.322-.714.715-.714h4.285A4.293 4.293 0 0117 10.57a4.293 4.293 0 01-4.286 4.286zm0-5.714h-2.143V12h2.143c.786 0 1.429-.643 1.429-1.429 0-.785-.643-1.428-1.429-1.428z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgParkingSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
