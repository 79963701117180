import * as React from "react";

function SvgEyeDropperSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.982 15.018a2.5 2.5 0 00-.732 1.768v1.776L2 20.75 3.25 22l2.187-1.25h1.777a2.5 2.5 0 001.768-.732l4.947-4.946-5-5-4.947 4.946zm16.92-11.92a3.749 3.749 0 00-5.303 0L12.587 6.11l-.511-.512a.937.937 0 00-1.326 0l-1.6 1.6a.938.938 0 000 1.326l6.325 6.326a.937.937 0 001.326 0l1.6-1.6a.938.938 0 000-1.326l-.511-.511L20.9 8.4a3.748 3.748 0 000-5.302z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgEyeDropperSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
