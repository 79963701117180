import * as React from "react";

function SvgToggleOnSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.333 5.333H8.667A6.668 6.668 0 002 12a6.668 6.668 0 006.667 6.667h6.666A6.668 6.668 0 0022 12a6.668 6.668 0 00-6.667-6.667zm0 11.111A4.442 4.442 0 0110.89 12a4.442 4.442 0 014.444-4.445A4.442 4.442 0 0119.778 12a4.442 4.442 0 01-4.445 4.444z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgToggleOnSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
