import * as React from "react";

function SvgDizzyOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 18.064c-4.448 0-8.065-3.616-8.065-8.064S7.552 3.935 12 3.935c4.448 0 8.064 3.617 8.064 8.065 0 4.448-3.616 8.064-8.064 8.064zm-1.363-8.786a.808.808 0 000-1.14l-.722-.719.722-.721a.808.808 0 00-1.141-1.142l-.722.722-.718-.718a.808.808 0 00-1.14 1.142l.721.721-.722.722a.808.808 0 001.141 1.141l.718-.717.718.717c.318.31.826.31 1.145-.008zM17.09 7.56a.808.808 0 00-1.141 0l-.722.718-.718-.718a.808.808 0 00-1.141 1.142l.722.721-.722.722a.808.808 0 001.141 1.141l.718-.717.717.717a.808.808 0 001.142-1.14l-.718-.727.722-.721a.81.81 0 000-1.138zM12 12.645a2.583 2.583 0 00-2.58 2.58A2.583 2.583 0 0012 17.808a2.583 2.583 0 002.58-2.581 2.583 2.583 0 00-2.58-2.58z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDizzyOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
