import * as React from "react";

function SvgSquareOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <mask id="path-1-inside-1" fill="#fff">
        <rect x={2} y={2} width={20} height={20} rx={1} />
      </mask>
      <rect
        x={2}
        y={2}
        width={20}
        height={20}
        rx={1}
        stroke="currentColor"
        strokeWidth={4}
        mask="url(#path-1-inside-1)"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSquareOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
