import * as React from "react";

function SvgHippoSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.16 7.006c-.865-.004-1.64.55-2.394.832A3 3 0 0015 6c-.352 0-.686.072-1 .184V5.75a.75.75 0 00-.75-.75h-.5a.75.75 0 00-.75.75v1.53C10.938 6.488 9.539 6 8 6c-3.314 0-6 2.239-6 5v7.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2.212c1.01.449 2.21.712 3.5.712s2.49-.263 3.5-.712V18.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V13h4v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a1 1 0 001-1V9.124c0-1.065-.775-2.112-1.84-2.118zM16 9.5a.5.5 0 110-1 .5.5 0 010 1z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHippoSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
