import * as React from "react";

function SvgUserGraduateSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.726 14.524L12 18.25l-3.726-3.726c-2.793.12-5.024 2.406-5.024 5.226v.375C3.25 21.16 4.09 22 5.125 22h13.75c1.035 0 1.875-.84 1.875-1.875v-.375c0-2.82-2.23-5.105-5.024-5.226zM3.782 5.118l.25.059v2.28a.927.927 0 00-.47.794c0 .328.18.601.434.77l-.609 2.433c-.066.27.082.546.297.546h1.633c.215 0 .363-.277.297-.546l-.61-2.434a.923.923 0 00.434-.77.927.927 0 00-.469-.792V5.403l2.578.621A4.961 4.961 0 007 8.251a5 5 0 1010 0c0-.805-.207-1.555-.547-2.227l3.761-.906c.711-.172.711-1.059 0-1.23L12.777 2.09a3.355 3.355 0 00-1.55 0L3.782 3.884c-.707.172-.707 1.062 0 1.234z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgUserGraduateSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
