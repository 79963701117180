import * as React from "react";

function SvgVolumeUpSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.466 5.578L6.377 8.666H2.833A.833.833 0 002 9.5v5c0 .46.373.833.833.833h3.544l3.09 3.089c.521.521 1.422.155 1.422-.59V6.167c0-.745-.901-1.11-1.423-.59zm8.102-1.774a.84.84 0 10-.923 1.405A8.103 8.103 0 0120.32 12a8.103 8.103 0 01-3.675 6.791.84.84 0 10.923 1.405A9.78 9.78 0 0022 12a9.78 9.78 0 00-4.432-8.196zM18.667 12a6.41 6.41 0 00-2.979-5.425.83.83 0 00-1.15.259.843.843 0 00.258 1.158A4.738 4.738 0 0117 12a4.738 4.738 0 01-2.204 4.007.843.843 0 00-.257 1.159.831.831 0 001.15.259A6.41 6.41 0 0018.666 12zm-4.923-2.67a.834.834 0 00-.804 1.46c.448.247.727.71.727 1.21s-.279.962-.727 1.208a.834.834 0 00.804 1.46A3.054 3.054 0 0015.334 12c0-1.107-.61-2.129-1.59-2.668z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgVolumeUpSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
