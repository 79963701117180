import * as React from "react";

function SvgCalenderTodaySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M4 19.313C4 20.262 4.738 21 5.688 21h12.375c.914 0 1.687-.738 1.687-1.688V9.75H4v9.563zm2.25-6.75c0-.282.246-.563.563-.563h3.375c.28 0 .562.281.562.563v3.374a.578.578 0 01-.563.563H6.813a.555.555 0 01-.563-.563v-3.374zM18.063 5.25h-1.688V3.562c0-.28-.281-.562-.563-.562h-1.124a.578.578 0 00-.563.563V5.25h-4.5V3.562c0-.28-.281-.562-.563-.562H7.938a.578.578 0 00-.563.563V5.25H5.687C4.739 5.25 4 6.023 4 6.938v1.687h15.75V6.937c0-.914-.773-1.687-1.688-1.687z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCalenderTodaySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
