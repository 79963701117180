import * as React from "react";

function SvgPlugSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17 3.25a1.25 1.25 0 00-2.5 0V7H17V3.25zm1.875 5H5.125a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h.625V12a6.253 6.253 0 005 6.125V22h2.5v-3.875a6.252 6.252 0 005-6.125v-1.25h.625a.624.624 0 00.625-.625v-1.25a.625.625 0 00-.625-.625zm-9.375-5a1.25 1.25 0 00-2.5 0V7h2.5V3.25z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPlugSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
