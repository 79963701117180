import * as React from "react";

function SvgVolleyballBallSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.008 11.494a11.512 11.512 0 00-.915-4.262c-3.66 1.71-6.35 4.935-7.27 8.74a10.038 10.038 0 002.295 3.27 13.46 13.46 0 015.89-7.748zM9.52 6.075a11.46 11.46 0 00-2.314-2.85c-3.669 2.008-5.838 6.164-5.04 10.57 1.347-3.35 3.968-6.127 7.354-7.72zm7.564 6.656c.346-4.023-1.101-7.962-3.931-10.66a9.742 9.742 0 00-3.987.343 13.46 13.46 0 013.77 8.98 11.49 11.49 0 004.148 1.337zm-5.028.383a11.543 11.543 0 00-3.233 2.927c3.306 2.31 7.438 3.028 11.188 1.927a9.964 9.964 0 001.7-3.624 13.556 13.556 0 01-3.26.42c-2.202-.005-4.391-.57-6.395-1.65zm-3.963 4.02A11.468 11.468 0 006.8 20.529a9.961 9.961 0 0011.651-.892c-4.551.65-8.196-1-10.357-2.503zm7.27-14.538c2.229 2.838 3.325 6.499 3.007 10.224 1.228.003 2.449-.19 3.616-.572 0-.081.012-.162.012-.242 0-4.346-2.77-8.027-6.636-9.41z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgVolleyballBallSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
