import * as React from "react";

function SvgBikingSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M14.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm-.125 3.781c.177.142.398.22.625.219h2a1 1 0 000-2h-1.65l-2.225-1.781a.998.998 0 00-1.275.021l-3.5 3a1 1 0 00.096 1.591L11 13.535V17a1 1 0 002 0v-4a1 1 0 00-.445-.832l-1.293-.861 1.82-1.56 1.293 1.034zM18 12a4 4 0 100 8 4 4 0 000-8zm0 6a2 2 0 110-4 2 2 0 010 4zM6 12a4 4 0 100 8 4 4 0 000-8zm0 6a2 2 0 110-4 2 2 0 010 4z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBikingSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
