import * as React from "react";

function SvgGrinBeamSweatSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.095 7.08C21.146 7.08 22 6.247 22 5.215c0-.793-1.131-2.397-1.651-3.087a.316.316 0 00-.508 0c-.52.69-1.65 2.294-1.65 3.087 0 1.032.852 1.865 1.904 1.865zm0 1.27c-1.75 0-3.175-1.405-3.175-3.135 0-.174.012-.563.322-1.278a9.798 9.798 0 00-5.4-1.619A9.84 9.84 0 002 12.16 9.84 9.84 0 0011.841 22 9.84 9.84 0 0020.87 8.247c-.25.06-.504.103-.774.103zm-5.08-.318c.945 0 2.092 1.163 2.223 2.834.028.34-.429.476-.591.178l-.377-.674c-.306-.544-.762-.857-1.25-.857s-.945.313-1.25.857l-.377.674c-.163.294-.62.159-.591-.178.123-1.67 1.27-2.834 2.214-2.834zm-6.348 0c.944 0 2.091 1.163 2.222 2.834.028.34-.429.476-.591.178l-.377-.674c-.306-.544-.762-.857-1.25-.857s-.945.313-1.25.857l-.377.674c-.167.294-.62.159-.591-.178.123-1.67 1.27-2.834 2.214-2.834zm3.174 11.111c-2.404 0-5.337-1.52-5.706-3.702a.636.636 0 01.822-.71c1.198.384 2.98.603 4.884.603 1.905 0 3.687-.219 4.885-.604a.637.637 0 01.821.71c-.369 2.183-3.301 3.703-5.706 3.703z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGrinBeamSweatSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
