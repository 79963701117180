import * as React from "react";

function SvgGripLinesSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.375 13.25H2.625a.627.627 0 00-.625.625v1.25c0 .344.281.625.625.625h18.75a.627.627 0 00.625-.625v-1.25a.627.627 0 00-.625-.625zm0-5H2.625A.627.627 0 002 8.875v1.25c0 .344.281.625.625.625h18.75a.627.627 0 00.625-.625v-1.25a.627.627 0 00-.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGripLinesSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
