import * as React from "react";

function SvgBriefcaseSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M14.5 15.125c0 .345-.28.625-.625.625h-3.75a.625.625 0 01-.625-.625V13.25H2v5.625c0 1 .875 1.875 1.875 1.875h16.25c1 0 1.875-.875 1.875-1.875V13.25h-7.5v1.875zM20.125 7H17V5.125c0-1-.875-1.875-1.875-1.875h-6.25c-1 0-1.875.875-1.875 1.875V7H3.875C2.875 7 2 7.875 2 8.875V12h20V8.875c0-1-.875-1.875-1.875-1.875zM14.5 7h-5V5.75h5V7z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBriefcaseSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
