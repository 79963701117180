import * as React from "react";

function SvgCheckSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.793 19.164l-6.5-6.5a1 1 0 010-1.414l1.414-1.414a1 1 0 011.414 0L9.5 14.214l9.379-9.378a1 1 0 011.414 0l1.414 1.414a1 1 0 010 1.414l-11.5 11.5a1 1 0 01-1.414 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCheckSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
