import * as React from "react";

function SvgCalendarDaySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.25 20.125C3.25 21.16 4.09 22 5.125 22h13.75c1.035 0 1.875-.84 1.875-1.875V9.5H3.25v10.625zm2.5-7.5c0-.344.281-.625.625-.625h3.75c.344 0 .625.281.625.625v3.75a.627.627 0 01-.625.625h-3.75a.627.627 0 01-.625-.625v-3.75zM18.875 4.5H17V2.625A.627.627 0 0016.375 2h-1.25a.627.627 0 00-.625.625V4.5h-5V2.625A.627.627 0 008.875 2h-1.25A.627.627 0 007 2.625V4.5H5.125c-1.035 0-1.875.84-1.875 1.875V8.25h17.5V6.375c0-1.035-.84-1.875-1.875-1.875z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCalendarDaySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
