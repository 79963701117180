import * as React from "react";

function SvgMarsStrokeVSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.255 11.726a5.99 5.99 0 00-2.919-1.614V9.05h.836a.503.503 0 00.502-.502V6.876a.503.503 0 00-.502-.502h-.836V5.337h1c.447 0 .668-.539.355-.857l-2.337-2.333a.502.502 0 00-.71 0L9.305 4.484a.502.502 0 00.355.858h1v1.032h-.837a.503.503 0 00-.501.502v1.672c0 .276.225.502.501.502h.837v1.062a6.02 6.02 0 00-2.918 10.126 6.02 6.02 0 008.511 0 6.013 6.013 0 000-8.512zm-1.89 6.622a3.346 3.346 0 01-4.729 0 3.346 3.346 0 010-4.728 3.346 3.346 0 014.729 0 3.346 3.346 0 010 4.728z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMarsStrokeVSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
