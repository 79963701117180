import * as React from "react";

function SvgGrinHeartsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zM5.645 9.08c.27-.709 1.077-1.076 1.81-.882l.287.076.08-.286c.202-.73.92-1.246 1.674-1.125a1.427 1.427 0 011.161 1.794l-.782 2.819a.346.346 0 01-.423.242l-2.831-.734a1.43 1.43 0 01-.976-1.903zM12 19.098c-2.444 0-5.423-1.545-5.798-3.762a.646.646 0 01.834-.722c1.218.391 3.029.613 4.964.613 1.935 0 3.746-.222 4.964-.613a.649.649 0 01.834.722c-.375 2.217-3.354 3.762-5.798 3.762zm5.379-8.117l-2.83.734a.35.35 0 01-.424-.242l-.782-2.819a1.427 1.427 0 011.161-1.794c.75-.121 1.468.395 1.673 1.125l.081.286.286-.076c.734-.19 1.54.173 1.81.883.311.818-.152 1.69-.975 1.903z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGrinHeartsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
