import * as React from "react";

function SvgKeyboardSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.333 18.667H3.667C2.747 18.667 2 17.92 2 17V7c0-.92.746-1.667 1.667-1.667h16.666C21.253 5.333 22 6.08 22 7v10c0 .92-.746 1.667-1.667 1.667zM6.444 9.36V7.972a.417.417 0 00-.416-.416h-1.39a.417.417 0 00-.416.416v1.39c0 .23.187.416.417.416h1.389c.23 0 .416-.187.416-.417zm3.334 0V7.972a.417.417 0 00-.417-.416H7.972a.417.417 0 00-.416.416v1.39c0 .23.186.416.416.416h1.39c.23 0 .416-.187.416-.417zm3.333 0V7.972a.417.417 0 00-.417-.416h-1.388a.417.417 0 00-.417.416v1.39c0 .23.187.416.417.416h1.388c.23 0 .417-.187.417-.417zm3.333 0V7.972a.417.417 0 00-.416-.416h-1.39a.417.417 0 00-.416.416v1.39c0 .23.187.416.417.416h1.389c.23 0 .416-.187.416-.417zm3.334 0V7.972a.417.417 0 00-.417-.416h-1.389a.417.417 0 00-.416.416v1.39c0 .23.186.416.416.416h1.39c.23 0 .416-.187.416-.417zM8.11 12.694v-1.388a.417.417 0 00-.417-.417H6.306a.417.417 0 00-.417.417v1.388c0 .23.186.417.417.417h1.388c.23 0 .417-.187.417-.417zm3.333 0v-1.388a.417.417 0 00-.416-.417h-1.39a.417.417 0 00-.416.417v1.388c0 .23.187.417.417.417h1.389c.23 0 .416-.187.416-.417zm3.334 0v-1.388a.417.417 0 00-.417-.417h-1.389a.417.417 0 00-.416.417v1.388c0 .23.186.417.416.417h1.39c.23 0 .416-.187.416-.417zm3.333 0v-1.388a.417.417 0 00-.417-.417h-1.388a.417.417 0 00-.417.417v1.388c0 .23.187.417.417.417h1.388c.23 0 .417-.187.417-.417zM6.444 16.028v-1.39a.417.417 0 00-.416-.416h-1.39a.417.417 0 00-.416.417v1.389c0 .23.187.416.417.416h1.389c.23 0 .416-.186.416-.416zm10 0v-1.39a.417.417 0 00-.416-.416H7.972a.417.417 0 00-.416.417v1.389c0 .23.186.416.416.416h8.056c.23 0 .416-.186.416-.416zm3.334 0v-1.39a.417.417 0 00-.417-.416h-1.389a.417.417 0 00-.416.417v1.389c0 .23.186.416.416.416h1.39c.23 0 .416-.186.416-.416z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgKeyboardSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
