import * as React from "react";

function SvgSadTearSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm3.226 6.774a1.289 1.289 0 110 2.58c-.714 0-1.29-.576-1.29-1.29 0-.713.576-1.29 1.29-1.29zm-7.097 9.678c-1.069 0-1.935-.847-1.935-1.896 0-.806 1.149-2.435 1.677-3.137a.321.321 0 01.516 0c.528.702 1.678 2.331 1.678 3.137 0 1.049-.867 1.896-1.936 1.896zm.645-7.097c-.714 0-1.29-.577-1.29-1.29 0-.714.576-1.29 1.29-1.29.714 0 1.29.576 1.29 1.29 0 .713-.576 1.29-1.29 1.29zm6.863 6.218A4.73 4.73 0 0012 15.87c-.855 0-.855-1.29 0-1.29a6 6 0 014.625 2.169c.556.661-.452 1.472-.988.823z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSadTearSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
