import * as React from "react";

function SvgAirFreshenerSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.302 14.555l-3.68-3.805h1.922c.597 0 .924-.648.541-1.075l-4.427-4.942c.134-.258.217-.547.217-.858a1.875 1.875 0 00-3.75 0c0 .31.083.6.217.858L5.915 9.675c-.383.427-.056 1.075.541 1.075H8.38l-3.681 3.805c-.46.475-.068 1.195.65 1.195h5.402V17H6.375a.625.625 0 00-.625.625v3.75c0 .345.28.625.625.625h11.25c.345 0 .625-.28.625-.625v-3.75a.625.625 0 00-.625-.625H13.25v-1.25h5.402c.717 0 1.11-.72.65-1.195zM12 3.25a.626.626 0 110 1.252.626.626 0 010-1.252zm4.375 15.626v1.25h-8.75v-1.25h8.75z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAirFreshenerSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
