import * as React from "react";

function SvgPhoneAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.43 16.133l-4.375-1.875a.937.937 0 00-1.094.27l-1.938 2.366a14.48 14.48 0 01-6.921-6.921l2.367-1.938a.935.935 0 00.27-1.093L7.862 2.566a.944.944 0 00-1.074-.543l-4.062.938A.938.938 0 002 3.875C2 13.895 10.121 22 20.125 22a.938.938 0 00.914-.727l.938-4.062a.949.949 0 00-.547-1.078z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPhoneAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
