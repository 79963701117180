import * as React from "react";

function SvgLifeRingSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm7.004 4.82l-2.556 2.557a5.22 5.22 0 00-1.825-1.824l2.556-2.557a8.81 8.81 0 011.825 1.825zM12 15.872a3.871 3.871 0 110-7.742 3.871 3.871 0 010 7.742zM6.82 4.996l2.557 2.557a5.219 5.219 0 00-1.824 1.824L4.996 6.821a8.805 8.805 0 011.825-1.825zM4.997 17.18l2.557-2.556a5.22 5.22 0 001.824 1.825l-2.556 2.556a8.81 8.81 0 01-1.825-1.825zm12.183 1.825l-2.556-2.556a5.22 5.22 0 001.825-1.825l2.556 2.556a8.812 8.812 0 01-1.825 1.825z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLifeRingSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
