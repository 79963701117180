import * as React from "react";

function SvgCompressSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.464 9.143H15.93a1.069 1.069 0 01-1.072-1.072V2.536c0-.295.241-.536.536-.536h1.786c.294 0 .535.241.535.536v3.75h3.75c.295 0 .536.24.536.535v1.786a.537.537 0 01-.536.536zM9.143 8.07V2.536A.537.537 0 008.607 2H6.821a.537.537 0 00-.535.536v3.75h-3.75A.537.537 0 002 6.82v1.786c0 .295.241.536.536.536H8.07c.594 0 1.072-.478 1.072-1.072zm0 13.393V15.93c0-.594-.478-1.072-1.072-1.072H2.536a.537.537 0 00-.536.536v1.786c0 .294.241.535.536.535h3.75v3.75c0 .295.24.536.535.536h1.786a.537.537 0 00.536-.536zm8.571 0v-3.75h3.75A.537.537 0 0022 17.18v-1.786a.537.537 0 00-.536-.536H15.93c-.594 0-1.072.478-1.072 1.072v5.535c0 .295.241.536.536.536h1.786a.537.537 0 00.535-.536z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCompressSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
