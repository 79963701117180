import * as React from "react";

function SvgOtterSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21 5h-1l-.414-.414A1.999 1.999 0 0018.172 4h-.64c-.349 0-.69.09-.993.263L11.75 7H10a6 6 0 00-6 6v.05c0 1.024-.5 1.942-1.236 2.653a2.497 2.497 0 00-.725 2.244C2.25 19.159 3.38 20 4.61 20H8a1 1 0 000-2H4.5a.5.5 0 010-1h7a.5.5 0 00.5-.5V16a1 1 0 00-1-1H9l4.672-2.516L16 17h2.5a.5.5 0 00.5-.5V16a1 1 0 00-1-1h-.882l-1.722-3.444L18.286 10H19a3 3 0 003-3V6a1 1 0 00-1-1zm-3 .5a.5.5 0 110 1 .5.5 0 010-1zm1 3h-1.093l-3.182 1.714-.43-.861L18 7.5h2.408c-.207.58-.757 1-1.408 1z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgOtterSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
