import * as React from "react";

function SvgCompassOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.03 6.914l-5.82 2.66a1.288 1.288 0 00-.636.636l-2.66 5.82c-.307.671.384 1.363 1.056 1.056l5.82-2.66c.282-.129.508-.354.636-.636l2.66-5.82c.307-.672-.384-1.363-1.056-1.056zm-3.12 5.997a1.288 1.288 0 11-1.82-1.822 1.288 1.288 0 011.82 1.822zM12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18.064c-4.447 0-8.065-3.617-8.065-8.064S7.553 3.935 12 3.935c4.447 0 8.064 3.618 8.064 8.065 0 4.447-3.617 8.064-8.064 8.064z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCompassOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
