import * as React from "react";

function SvgHandPaperSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.218 7c-.876-.016-1.593.717-1.593 1.593V12h-.313V5.117c0-.876-.716-1.61-1.593-1.593a1.562 1.562 0 00-1.531 1.562V12h-.313V3.593c0-.876-.717-1.61-1.593-1.593a1.562 1.562 0 00-1.532 1.563V12h-.313V5.156c0-.876-.717-1.61-1.593-1.593a1.563 1.563 0 00-1.531 1.562v9.218l-1.237-1.7a1.563 1.563 0 00-2.527 1.838l4.906 6.747A1.875 1.875 0 009.972 22h7.718c.87 0 1.626-.599 1.825-1.445l1.036-4.401c.132-.563.199-1.14.199-1.718V8.563c0-.853-.683-1.546-1.532-1.563z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHandPaperSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
