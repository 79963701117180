import * as React from "react";

function SvgHeadsetSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.5 10.125c0-.69-.56-1.25-1.25-1.25h-.625a2.5 2.5 0 00-2.5 2.5v1.875a2.5 2.5 0 002.5 2.5h.625c.69 0 1.25-.56 1.25-1.25v-4.375zm6.875 5.625a2.5 2.5 0 002.5-2.5v-1.875a2.5 2.5 0 00-2.5-2.5h-.625c-.69 0-1.25.56-1.25 1.25V14.5c0 .69.56 1.25 1.25 1.25h.625zM12 2C6.421 2 2.179 6.642 2 12v.625c0 .345.28.625.625.625h.625c.345 0 .625-.28.625-.625V12c0-4.48 3.645-8.125 8.125-8.125S20.125 7.52 20.125 12h-.005c.003.095.005 6.473.005 6.473 0 .913-.74 1.652-1.652 1.652H14.5c0-1.035-.84-1.875-1.875-1.875h-1.25a1.875 1.875 0 000 3.75h7.098A3.527 3.527 0 0022 18.473V12c-.179-5.358-4.421-10-10-10z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHeadsetSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
