import * as React from "react";

function SvgGripHorizontalSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6.286 13.428H3.429c-.79 0-1.429.64-1.429 1.43v2.856c0 .79.64 1.429 1.429 1.429h2.857c.789 0 1.428-.64 1.428-1.429v-2.857c0-.789-.64-1.428-1.428-1.428zm7.143 0H10.57c-.788 0-1.428.64-1.428 1.43v2.856c0 .79.64 1.429 1.428 1.429h2.858c.788 0 1.428-.64 1.428-1.429v-2.857c0-.789-.64-1.428-1.428-1.428zm7.142 0h-2.857c-.789 0-1.428.64-1.428 1.43v2.856c0 .79.64 1.429 1.428 1.429h2.857c.79 0 1.429-.64 1.429-1.429v-2.857c0-.789-.64-1.428-1.429-1.428zM6.286 4.858H3.429C2.639 4.857 2 5.496 2 6.285v2.857c0 .789.64 1.428 1.429 1.428h2.857c.789 0 1.428-.64 1.428-1.428V6.286c0-.79-.64-1.429-1.428-1.429zm7.143 0H10.57c-.788 0-1.428.639-1.428 1.428v2.857c0 .789.64 1.428 1.428 1.428h2.858c.788 0 1.428-.64 1.428-1.428V6.286c0-.79-.64-1.429-1.428-1.429zm7.142 0h-2.857c-.789 0-1.428.639-1.428 1.428v2.857c0 .789.64 1.428 1.428 1.428h2.857c.79 0 1.429-.64 1.429-1.428V6.286c0-.79-.64-1.429-1.429-1.429z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGripHorizontalSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
