import * as React from "react";

function SvgPeopleCarrySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm12 0a1.5 1.5 0 10-.001-3 1.5 1.5 0 000 3zm3.928 11.628l-1.375-3.438-1.285 1.45-.062.57.866 2.162a1 1 0 101.856-.744zm-1.069-6.556l-.578-2.506a2.025 2.025 0 00-1.172-1.403 1.928 1.928 0 00-1.753.103 3.424 3.424 0 00-1.503 1.856l-.353 1.06-.5.318v-3c0-.275-.225-.5-.5-.5h-5c-.275 0-.5.225-.5.5v3l-.503-.319-.353-1.059A3.4 3.4 0 006.64 8.266a1.929 1.929 0 00-1.753-.103 2.025 2.025 0 00-1.172 1.403l-.575 2.506a2.002 2.002 0 00.45 1.772l2.1 2.371.316 2.894a1 1 0 00.993.89.998.998 0 00.994-1.11l-.316-2.899a1.997 1.997 0 00-.487-1.093l-1.353-1.531.55-2.197.212.637c.128.39.372.732.766 1.019l1.597 1.016c.143.09.378.143.537.156h5c.16-.013.394-.066.537-.156l1.597-1.016c.394-.287.638-.625.766-1.019l.212-.637.55 2.197-1.353 1.53c-.272.31-.44.688-.487 1.094l-.316 2.9a1 1 0 00.884 1.103 1 1 0 001.103-.884l.316-2.894 2.1-2.371a2.011 2.011 0 00.45-1.772zM3.447 15.19l-1.375 3.437a1 1 0 101.857.744l.865-2.163-.062-.569-1.285-1.45z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPeopleCarrySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
