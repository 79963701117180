import * as React from "react";

function SvgRupeeSignSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.607 4.857c.296 0 .536-.24.536-.536V2.536A.536.536 0 0018.607 2H5.393a.536.536 0 00-.536.536v1.997c0 .296.24.536.536.536H9.2c1.22 0 2.155.445 2.722 1.217h-6.53a.536.536 0 00-.535.535v1.786c0 .296.24.536.536.536h7.087c-.277 1.61-1.471 2.617-3.337 2.617h-3.75a.536.536 0 00-.536.536v2.367c0 .15.063.292.173.393l7.368 6.802a.536.536 0 00.363.142h3.686a.536.536 0 00.363-.93l-6.735-6.217c3.415-.104 5.854-2.384 6.175-5.71h2.357c.296 0 .536-.24.536-.536V6.821a.536.536 0 00-.536-.535h-2.62a6.119 6.119 0 00-.636-1.429h3.256z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgRupeeSignSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
