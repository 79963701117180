import * as React from "react";

function SvgBaseballBallSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.86 16.35l1.162-.56a8.782 8.782 0 001.778 2.456A9.966 9.966 0 0021.998 12a9.97 9.97 0 00-2.186-6.234 8.67 8.67 0 00-1.758 2.4l-1.157-.57a9.943 9.943 0 012.049-2.781A9.959 9.959 0 0012.002 2a9.953 9.953 0 00-6.94 2.81 9.972 9.972 0 012.045 2.775l-1.157.568a8.696 8.696 0 00-1.754-2.395A9.952 9.952 0 002.002 12c0 2.363.823 4.528 2.194 6.238a8.69 8.69 0 001.786-2.46l1.161.56a9.95 9.95 0 01-2.076 2.851A9.936 9.936 0 0012.002 22a9.951 9.951 0 006.931-2.802 9.994 9.994 0 01-2.072-2.847zm-9.205-1.29l-1.23-.395c.601-1.87.513-3.782-.024-5.403l1.226-.403c.605 1.839.726 4.028.028 6.201zm8.722-6.185l1.226.403a8.783 8.783 0 00-.024 5.403l-1.23.396a10.06 10.06 0 01.028-6.202z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBaseballBallSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
