import * as React from "react";

function SvgDollyFlatbedSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.5 14h12c.275 0 .5-.225.5-.5v-8c0-.275-.225-.5-.5-.5H16v4l-1.5-1L13 9V5H8.5c-.275 0-.5.225-.5.5v8c0 .275.225.5.5.5zm13 2H6V4.5c0-.275-.225-.5-.5-.5h-3c-.275 0-.5.225-.5.5v1c0 .275.225.5.5.5H4v11.5c0 .275.225.5.5.5h2.59c-.056.156-.09.325-.09.5a1.5 1.5 0 003 0c0-.175-.037-.344-.09-.5h6.184c-.056.156-.09.325-.09.5a1.5 1.5 0 003 0c0-.175-.038-.344-.091-.5H21.5c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDollyFlatbedSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
