import * as React from "react";

function SvgHikingSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.662 20.446a1.25 1.25 0 002.426.607l.986-3.944-2.062-2.062-1.35 5.4zm.582-7.66L9.852 6.57a.613.613 0 00-.466-.744c-1.714-.411-3.45.588-3.88 2.233l-.986 3.833a.613.613 0 00.465.745l2.483.595a.642.642 0 00.776-.447zM18.875 8.25h-.625a.625.625 0 00-.625.625V9.5h-1.357L14.44 7.673A2.279 2.279 0 0012.817 7a2.293 2.293 0 00-2.228 1.739l-1.051 4.208a1.25 1.25 0 00.329 1.187l3.383 3.383v3.233a1.25 1.25 0 002.5 0v-3.233c0-.667-.26-1.295-.732-1.767l-1.83-1.83c.007-.019.02-.034.025-.054l.777-3.108.876.876c.235.234.553.366.884.366h1.875v9.375c0 .345.28.625.625.625h.625c.345 0 .625-.28.625-.625v-12.5a.624.624 0 00-.625-.625zm-5-2.5a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHikingSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
