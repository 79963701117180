import * as React from "react";

function SvgPawSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 10.75c-3.102 0-7.5 4.795-7.5 7.822 0 1.364 1.048 2.178 2.803 2.178 1.907 0 3.167-.98 4.697-.98 1.543 0 2.807.98 4.698.98 1.755 0 2.802-.814 2.802-2.178 0-3.027-4.398-7.822-7.5-7.822zm-5.753-.492c-.406-1.354-1.658-2.23-2.795-1.959-1.138.272-1.73 1.59-1.324 2.943.406 1.354 1.658 2.23 2.795 1.958 1.138-.271 1.73-1.589 1.324-2.942zm3.31-.812c1.208-.318 1.813-1.95 1.35-3.647-.462-1.696-1.817-2.813-3.026-2.495-1.208.318-1.813 1.95-1.35 3.647.462 1.696 1.817 2.813 3.025 2.495zM20.547 8.3c-1.137-.272-2.388.604-2.795 1.958-.406 1.354.186 2.671 1.324 2.943 1.137.272 2.388-.605 2.795-1.958.406-1.354-.186-2.671-1.324-2.943zm-6.104 1.146c1.208.318 2.563-.799 3.026-2.495.462-1.696-.142-3.329-1.351-3.647-1.209-.318-2.563.799-3.026 2.495-.462 1.696.142 3.329 1.35 3.647z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPawSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
