import * as React from "react";

function SvgLiraSignSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.035 12h-2.143c-.283 0-.535.22-.535.502 0 4.498-3.653 6.64-7.5 6.64v-7.107l6.01-1.335a.536.536 0 00.419-.523v-1.83a.536.536 0 00-.652-.523L9.857 9.108V7.749l6.01-1.335a.536.536 0 00.419-.523V4.06a.536.536 0 00-.652-.522L9.857 4.822V2.536A.536.536 0 009.321 2h-2.5a.536.536 0 00-.535.536v3.08l-2.438.542a.536.536 0 00-.42.523V8.51c0 .343.318.597.653.523l2.205-.49v1.359l-2.438.541a.536.536 0 00-.42.523v1.83c0 .342.318.597.653.523l2.205-.49v8.635c0 .296.24.536.535.536h3.553c5.986 0 9.967-3.475 10.197-9.446a.535.535 0 00-.536-.554z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLiraSignSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
