import * as React from "react";

function SvgArrowAltCircleRightSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2c5.524 0 10 4.476 10 10s-4.476 10-10 10S2 17.524 2 12 6.476 2 12 2zM7.323 13.774H12v2.86c0 .43.524.648.827.342l4.609-4.633a.48.48 0 000-.682l-4.61-4.637a.484.484 0 00-.826.343v2.859H7.323a.485.485 0 00-.484.484v2.58c0 .267.217.484.484.484z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgArrowAltCircleRightSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
