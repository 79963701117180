import * as React from "react";

function SvgQuestionSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.018 2C8.9 2 6.881 3.277 5.295 5.556a.938.938 0 00.203 1.284l1.685 1.278a.937.937 0 001.299-.162c.978-1.226 1.704-1.932 3.232-1.932 1.202 0 2.689.773 2.689 1.939 0 .88-.728 1.333-1.914 1.998-1.384.776-3.215 1.742-3.215 4.157v.382c0 .518.42.938.938.938h2.83c.518 0 .938-.42.938-.938v-.226c0-1.674 4.893-1.743 4.893-6.274 0-3.412-3.539-6-6.855-6zm-.391 14.588a2.709 2.709 0 00-2.706 2.706A2.709 2.709 0 0011.627 22a2.709 2.709 0 002.706-2.706 2.709 2.709 0 00-2.706-2.706z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgQuestionSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
