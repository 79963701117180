import * as React from "react";

function SvgSmileWinkOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 18.064c-4.448 0-8.065-3.616-8.065-8.064S7.552 3.935 12 3.935c4.448 0 8.064 3.617 8.064 8.065 0 4.448-3.616 8.064-8.064 8.064zm4.75-5.903a.967.967 0 00-1.363.125A4.4 4.4 0 0112 15.875a4.387 4.387 0 01-3.387-1.589.97.97 0 00-1.363-.125.97.97 0 00-.125 1.363A6.33 6.33 0 0012 17.807a6.33 6.33 0 004.875-2.283.967.967 0 00-.125-1.363zm-7.976-2.806c.714 0 1.29-.577 1.29-1.29 0-.714-.576-1.29-1.29-1.29-.714 0-1.29.576-1.29 1.29 0 .713.576 1.29 1.29 1.29zm6.452-2.42c-1.037 0-2.254.682-2.416 1.698a.483.483 0 00.799.436l.383-.343c.597-.533 1.863-.533 2.46 0l.383.342c.342.299.87.013.798-.435-.153-1.016-1.37-1.698-2.407-1.698z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSmileWinkOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
