import * as React from "react";

function SvgUserSecretSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.246 14.043l.934-2.446a.624.624 0 00-.586-.847h-2.285c.43-.739.695-1.586.695-2.5v-.012c1.531-.305 2.5-.746 2.5-1.238 0-.52-1.066-.98-2.738-1.29-.36-1.28-1.055-2.57-1.586-3.234a1.254 1.254 0 00-1.543-.344l-1.078.54a1.25 1.25 0 01-1.118 0l-1.078-.54a1.254 1.254 0 00-1.543.344c-.527.664-1.226 1.953-1.586 3.234C5.566 6.02 4.5 6.48 4.5 7c0 .492.969.933 2.5 1.238v.012c0 .914.266 1.761.695 2.5H5.45c-.45 0-.75.457-.574.87l1.008 2.352c-1.567.91-2.633 2.586-2.633 4.528v1.75c0 .965.785 1.75 1.75 1.75h14c.965 0 1.75-.785 1.75-1.75V18.5c0-1.89-1.008-3.531-2.504-4.457zm-8.121 6.707L8.5 13.25l1.938 1.25.937 1.562-1.25 4.688zm3.75 0l-1.25-4.688.938-1.562 1.937-1.25-1.625 7.5zm1.629-11.66c-.152.464-.273.96-.644 1.304-.395.364-1.876.875-2.5-.976-.11-.329-.602-.329-.715 0-.665 1.96-2.188 1.265-2.5.976-.372-.344-.497-.84-.645-1.305-.031-.097-.246-.222-.246-.226V8.44c1.105.14 2.383.227 3.75.227a29.35 29.35 0 003.75-.227v.422c-.004.004-.219.125-.25.226z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgUserSecretSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
