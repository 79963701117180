import * as React from "react";

function SvgArrowAltCircleLeftOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 12c0 5.524 4.476 10 10 10s10-4.476 10-10S17.524 2 12 2 2 6.476 2 12zm18.064 0A8.062 8.062 0 0112 20.064 8.062 8.062 0 013.935 12 8.062 8.062 0 0112 3.935 8.062 8.062 0 0120.064 12zm-2.903-.806v1.612a.485.485 0 01-.484.484H12v2.702c0 .431-.52.645-.827.343l-3.992-3.992a.484.484 0 010-.686l3.992-3.992a.484.484 0 01.827.343v2.702h4.677c.267 0 .484.217.484.483z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgArrowAltCircleLeftOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
