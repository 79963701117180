import * as React from "react";

function SvgSortAmountDownAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.375 5.75h2.5a.625.625 0 00.625-.625v-1.25a.625.625 0 00-.625-.625h-2.5a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625zm0 5h5a.625.625 0 00.625-.625v-1.25a.625.625 0 00-.625-.625h-5a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625zm10 7.5h-10a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h10a.625.625 0 00.625-.625v-1.25a.625.625 0 00-.625-.625zm-10-2.5h7.5a.625.625 0 00.625-.625v-1.25a.625.625 0 00-.625-.625h-7.5a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625zm-2.5 0H7V3.875a.625.625 0 00-.625-.625h-1.25a.625.625 0 00-.625.625V15.75H2.625a.625.625 0 00-.441 1.067l3.125 3.75a.625.625 0 00.884 0l3.125-3.75a.626.626 0 00-.443-1.067z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSortAmountDownAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
