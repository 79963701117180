import * as React from "react";

function SvgUserAstronautSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M5.75 10.75h.527A6.243 6.243 0 0012 14.5a6.243 6.243 0 005.723-3.75h.527a.627.627 0 00.625-.625v-3.75a.627.627 0 00-.625-.625h-.527A6.243 6.243 0 0012 2a6.243 6.243 0 00-5.723 3.75H5.75a.627.627 0 00-.625.625v3.75c0 .344.281.625.625.625zm1.563-3.438c0-.863.84-1.562 1.875-1.562h5.624c1.036 0 1.876.7 1.876 1.563v.937c0 2.07-1.68 3.75-3.75 3.75h-1.876c-2.07 0-3.75-1.68-3.75-3.75v-.938zm2.812 2.813l.469-1.406L12 8.25l-1.406-.469-.469-1.406-.469 1.406-1.406.469 1.406.469.469 1.406zm5.922 4.43A7.435 7.435 0 0112 15.75a7.435 7.435 0 01-4.047-1.195c-2.637.277-4.703 2.484-4.703 5.195v.375C3.25 21.16 4.09 22 5.125 22H8.25v-2.5c0-.691.559-1.25 1.25-1.25h5c.691 0 1.25.559 1.25 1.25V22h3.125c1.035 0 1.875-.84 1.875-1.875v-.375c0-2.71-2.066-4.918-4.703-5.195zM13.875 19.5a.627.627 0 00-.625.625c0 .344.281.625.625.625a.627.627 0 00.625-.625.627.627 0 00-.625-.625zm-3.75 0a.627.627 0 00-.625.625V22h1.25v-1.875a.627.627 0 00-.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgUserAstronautSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
