import * as React from "react";

function SvgRedoCircleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.3 3.447V1.333L5.484 4.152l2.818 2.817V4.855a4.23 4.23 0 014.226 4.226 4.23 4.23 0 01-3.522 4.163v1.423a5.633 5.633 0 00-.704-11.22zM4.076 9.08c0-1.162.472-2.218 1.24-2.986l-1-1A5.645 5.645 0 002.665 9.08a5.627 5.627 0 004.93 5.586v-1.423a4.23 4.23 0 01-3.521-4.163z"
        fill="#A1A4B1"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgRedoCircleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
