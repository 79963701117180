import * as React from "react";

function SvgSocksSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.385 14.149L13.25 12V5.75H7v6.875l-3.385 2.524c-1.539 1.155-2.104 3.298-1.14 4.963A3.78 3.78 0 005.752 22c.783 0 1.572-.244 2.247-.75l.854-.64c-1.166-2.163-.529-4.916 1.531-6.461zM13.25 3.25c0-.432.12-.832.313-1.187-.102-.027-.202-.063-.313-.063h-5C7.56 2 7 2.56 7 3.25V4.5h6.25V3.25zM20.75 2h-5c-.69 0-1.25.56-1.25 1.25V4.5H22V3.25C22 2.56 21.44 2 20.75 2zM14.5 12.625l-3.364 2.524c-1.54 1.155-2.104 3.298-1.141 4.963A3.747 3.747 0 0015.5 21.25l4.5-3.375a4.999 4.999 0 002-4V5.75h-7.5v6.875z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSocksSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
