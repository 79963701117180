import * as React from "react";

function SvgShipSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.52 15.645l2.187-2.188a1 1 0 00-.401-1.659L18 11.378V7a1 1 0 00-1-1h-2V4.75a.75.75 0 00-.75-.75h-4.5a.75.75 0 00-.75.75V6H7a1 1 0 00-1 1v4.378l-1.306.42a1 1 0 00-.401 1.66l2.188 2.187C5.932 17.009 4.675 18 2.75 18a.75.75 0 00-.75.75v.5c0 .414.336.75.75.75 1.907 0 3.36-.644 4.477-1.856A3.005 3.005 0 0010 20h4c1.25 0 2.323-.768 2.773-1.856C17.89 19.356 19.343 20 21.25 20a.75.75 0 00.75-.75v-.5a.75.75 0 00-.75-.75c-1.9 0-3.173-.969-3.73-2.355zM8 8h8v2.735l-3.694-1.187a1 1 0 00-.612 0L8 10.735V8z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgShipSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
