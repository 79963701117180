import * as React from "react";

function SvgCertificateSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.915 11.997l1.796-1.758c.536-.507.286-1.408-.416-1.576l-2.448-.624.69-2.423c.195-.697-.462-1.354-1.158-1.159l-2.422.69-.624-2.448c-.166-.691-1.076-.944-1.575-.417L12 4.092l-1.757-1.81C9.749 1.761 8.835 2 8.667 2.7l-.624 2.448-2.422-.69c-.696-.195-1.353.462-1.158 1.16l.69 2.422-2.448.624c-.702.168-.951 1.07-.416 1.576l1.796 1.758-1.796 1.758c-.536.507-.286 1.408.416 1.576l2.448.624-.69 2.423c-.195.697.462 1.354 1.158 1.159l2.422-.69.624 2.448c.174.725 1.082.938 1.576.416L12 19.915l1.757 1.796c.489.527 1.406.293 1.576-.416l.624-2.448 2.422.69c.696.195 1.353-.463 1.158-1.16l-.69-2.422 2.448-.624c.702-.168.951-1.07.416-1.576l-1.796-1.758z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCertificateSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
