import * as React from "react";

function SvgToolsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.575 17.456L17 12.882a2.9 2.9 0 00-3.336-.543L9.502 8.175V5.75l-5-3.75-2.5 2.5 3.75 5h2.426l4.163 4.163a2.906 2.906 0 00.543 3.335l4.574 4.574c.57.57 1.492.57 2.058 0l2.059-2.058a1.46 1.46 0 000-2.058zm-6.617-6.668A4.11 4.11 0 0117.884 12l.757.758a5.528 5.528 0 001.711-1.152 5.617 5.617 0 001.48-5.34.468.468 0 00-.785-.215l-2.906 2.906-2.652-.441-.441-2.652 2.906-2.906a.472.472 0 00-.223-.789 5.626 5.626 0 00-5.335 1.48c-1.113 1.113-1.637 2.582-1.61 4.047l3.207 3.207c.317-.075.645-.114.965-.114zM10.9 13.991l-2.215-2.215-5.952 5.957a2.499 2.499 0 103.535 3.535l4.827-4.828c-.297-.777-.386-1.625-.195-2.449zm-6.398 6.445a.94.94 0 01-.937-.938c0-.52.418-.937.937-.937a.938.938 0 110 1.875z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgToolsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
