import * as React from "react";

function SvgSyringeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.871 8.83l2.176 2.18a.313.313 0 010 .441l-.442.442a.314.314 0 01-.44 0l-2.176-2.18-1.77 1.77 2.18 2.179a.314.314 0 010 .441l-.442.442a.314.314 0 01-.441 0l-2.18-2.184-1.03 1.031c-.676.676-1 1.606-.899 2.555l.277 2.484-2.593 2.593a.314.314 0 000 .442l.441.441c.121.121.32.121.442 0l2.59-2.59 2.483.278a3.11 3.11 0 002.555-.898l7.104-7.105-5.3-5.3L9.871 8.83zM21.91 5.186l-3.093-3.093a.313.313 0 00-.442 0l-.441.441a.314.314 0 000 .441l1.105 1.106-1.769 1.77-2.21-2.212-.665-.664a.314.314 0 00-.441 0L12.629 4.3a.314.314 0 000 .44l.664.665 5.3 5.308.664.664c.121.121.32.121.441 0l1.325-1.328a.314.314 0 000-.441l-2.871-2.871 1.77-1.77 1.104 1.106c.122.12.32.12.442 0l.441-.442a.318.318 0 000-.445z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSyringeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
