import * as React from "react";

function SvgPoopSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.631 16.42a2.797 2.797 0 001.119-2.233 2.813 2.813 0 00-2.813-2.812h-.55A2.484 2.484 0 0018.25 9.5a2.5 2.5 0 00-2.5-2.5h-.23c.14-.393.23-.81.23-1.25A3.75 3.75 0 0012 2c-.202 0-.396.029-.59.06.368.512.59 1.136.59 1.815C12 5.601 10.6 7 8.875 7H8.25a2.5 2.5 0 00-2.5 2.5c0 .75.338 1.417.862 1.875h-.55a2.812 2.812 0 00-2.812 2.813c0 .914.443 1.718 1.119 2.231A2.806 2.806 0 002 19.188 2.813 2.813 0 004.813 22h14.375A2.813 2.813 0 0022 19.187a2.806 2.806 0 00-2.369-2.768z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPoopSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
