import * as React from "react";

function SvgChartLineSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.375 17H4.5V5.125a.625.625 0 00-.625-.625h-1.25A.625.625 0 002 5.125V18.25c0 .69.56 1.25 1.25 1.25h18.125c.345 0 .625-.28.625-.625v-1.25a.625.625 0 00-.625-.625zm-1.25-11.25h-4.612c-.835 0-1.253 1.01-.663 1.6l1.266 1.266-2.866 2.866-2.866-2.866a1.25 1.25 0 00-1.768 0L5.933 11.3a.625.625 0 000 .884l.884.883c.244.244.64.244.884 0l1.799-1.8 2.866 2.867a1.25 1.25 0 001.768 0l3.75-3.75 1.265 1.265c.59.59 1.6.173 1.6-.663V6.375a.624.624 0 00-.624-.625z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChartLineSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
