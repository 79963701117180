import * as React from "react";

function SvgBullhornSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 11.333c0-.82-.45-1.529-1.111-1.914V4.111c0-.303-.242-1.111-1.111-1.111-.247 0-.493.083-.694.244l-2.952 2.362A8.433 8.433 0 0112 7.37v7.927a8.432 8.432 0 014.131 1.764l2.953 2.362c.197.158.441.243.693.244.866 0 1.111-.791 1.111-1.111v-5.308A2.213 2.213 0 0022 11.333zm-11 3.89H9.914a4.202 4.202 0 00-.153 1.11c0 1.01.338 1.942.908 2.69.548.719.005 1.755-.9 1.755H7.19c-.412 0-.806-.221-.986-.593a8.796 8.796 0 01-.887-3.852c0-.377.027-.747.075-1.11h-1.17A2.222 2.222 0 012 13V9.667c0-1.228.995-2.223 2.222-2.223H11v7.779z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBullhornSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
