import * as React from "react";

function SvgDrumSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.85 6.767l2.871-1.852a.625.625 0 00.174-.867l-.347-.52a.625.625 0 00-.866-.173l-4.3 2.773A27.067 27.067 0 0012 5.75c-1.424 0-10 .178-10 4.38v6.26c0 1.182 1.074 2.253 2.813 3.041v-3.98a.937.937 0 111.875 0v4.645c1.29.356 2.776.589 4.375.654v-4.047a.938.938 0 011.874 0v4.047c1.6-.065 3.085-.298 4.376-.654v-4.645a.937.937 0 111.875 0v3.98C20.925 18.643 22 17.572 22 16.39v-6.26c0-1.691-1.397-2.724-3.15-3.363zM12 12.634c-4.487 0-8.125-1.12-8.125-2.503S7.513 7.628 12 7.628c.67 0 1.318.027 1.94.074l-2.843 1.836a.625.625 0 00-.174.867l.347.52a.625.625 0 00.867.173L16.77 8.11c2.03.453 3.354 1.19 3.354 2.022 0 1.383-3.638 2.503-8.125 2.503z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDrumSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
