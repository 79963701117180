import * as React from "react";

function SvgLifeRingOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm-4.17-3.093l2.154-2.154a5.175 5.175 0 004.032 0l2.153 2.154a8.08 8.08 0 01-8.338 0zM15.227 12A3.23 3.23 0 0112 15.226 3.23 3.23 0 018.774 12 3.23 3.23 0 0112 8.774 3.23 3.23 0 0115.226 12zm3.68 4.17l-2.153-2.154a5.175 5.175 0 000-4.032l2.154-2.153a8.08 8.08 0 010 8.338zM16.17 5.093l-2.153 2.153a5.175 5.175 0 00-4.032 0L7.831 5.094a8.08 8.08 0 018.338 0zM5.094 7.83l2.153 2.153a5.175 5.175 0 000 4.032l-2.153 2.153a8.08 8.08 0 010-8.338z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLifeRingOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
