import * as React from "react";

function SvgMoonSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.063 22a9.98 9.98 0 007.768-3.703.47.47 0 00-.452-.756c-4.852.924-9.307-2.796-9.307-7.693a7.83 7.83 0 013.965-6.812.47.47 0 00-.147-.869A10.084 10.084 0 0013.063 2c-5.52 0-10 4.473-10 10 0 5.52 4.473 10 10 10z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMoonSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
