import * as React from "react";

function SvgFistRaisedSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M14.5 8.25V2.625A.625.625 0 0013.873 2h-1.25a.625.625 0 00-.625.625v5.74c.196-.07.404-.115.624-.115h1.876zm5 3.75A2.5 2.5 0 0017 9.5h-4.38a.62.62 0 00-.622.62v.021c0 1.026.832 1.859 1.858 1.859h1.378c.378 0 .515.14.515.313v.632c0 .168-.14.304-.308.313-1.739.089-2.506.965-3.752 2.834l-.246.37a.313.313 0 01-.433.086l-.52-.346a.314.314 0 01-.087-.434l.247-.37c.614-.921 1.18-1.69 1.848-2.268a2.513 2.513 0 01-1.519-1.346 1.851 1.851 0 01-.854.216h-1.25c-.482 0-.918-.188-1.25-.488-.333.3-.768.488-1.25.488h-1.25c-.22 0-.429-.045-.625-.115v3.044a5 5 0 001.464 3.536L7 19.5V22h9.999v-2.498l1.403-1.404a3.751 3.751 0 001.098-2.652V12zm-1.251-3.52V3.875a.625.625 0 00-.625-.625h-1.25a.625.625 0 00-.625.625V8.25h1.25c.44 0 .857.09 1.25.23zM5.125 10.75h1.25c.345 0 .625-.28.625-.625v-5a.625.625 0 00-.625-.625h-1.25a.625.625 0 00-.625.625v5c0 .345.28.625.625.625zm3.75 0h1.25c.345 0 .625-.28.625-.625v-6.25a.625.625 0 00-.625-.625h-1.25a.625.625 0 00-.625.625v6.25c0 .345.28.625.625.625z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFistRaisedSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
