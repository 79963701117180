import * as React from "react";

function SvgDrumSteelpanSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 4.222c-5.523 0-10 1.99-10 4.445v6.666c0 2.455 4.477 4.445 10 4.445s10-1.99 10-4.445V8.667c0-2.455-4.477-4.445-10-4.445zM9.118 9.721a2.535 2.535 0 01-.983 1.405c-2.655-.464-4.468-1.39-4.468-2.46 0-1.047 1.74-1.957 4.307-2.431l.889 1.54c.342.593.433 1.284.255 1.946zM12 11.444c-.732 0-1.438-.034-2.114-.093A2.218 2.218 0 0112 9.778c.998 0 1.834.665 2.114 1.573-.676.059-1.382.093-2.114.093zm2.222-5A2.225 2.225 0 0112 8.667a2.225 2.225 0 01-2.222-2.223v-.452a24.227 24.227 0 014.444 0v.452zm1.63 4.684a2.537 2.537 0 01-.73-3.353l.89-1.542c2.575.472 4.321 1.385 4.321 2.434 0 1.07-1.82 1.998-4.481 2.461z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDrumSteelpanSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
