import * as React from "react";

function SvgSkiingNordicSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.667 6.444a1.667 1.667 0 10-.001-3.334 1.667 1.667 0 000 3.334zm7.5 11.111a.834.834 0 00-.834.834.836.836 0 01-.833.833h-2.413l.885-6.194a1.11 1.11 0 00-.413-2.139h-1.528l-.906-1.847a3.337 3.337 0 00-2.146-1.768l-2.472-.736a3.324 3.324 0 00-2.806.594L6.323 8.187a1.111 1.111 0 00-.205 1.56c.024.03.06.045.083.072l-1.878 9.403h-1.49a.834.834 0 000 1.667H19.5c1.378 0 2.5-1.122 2.5-2.5a.836.836 0 00-.833-.834zm-9.046 1.667H8.758l1.497-3.16-.764-.45c-.42-.25-.76-.588-1.025-.966l-2.17 4.576h-.84l1.816-9.076c.142-.035.281-.1.406-.195l1.379-1.055c.267-.205.604-.278.878-.212l.51.153-1.302 3.034a2.228 2.228 0 00.914 2.789l2.95 1.743-.885 2.82zm3.84 0h-1.513l.82-2.621a1.675 1.675 0 00-.75-1.89l-2.126-1.253 1.087-2.718.705 1.437a1.68 1.68 0 001.497.934h1.156l-.875 6.111z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSkiingNordicSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
