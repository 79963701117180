import * as React from "react";

function SvgCurveSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5.077V3.538a1.538 1.538 0 113.077 0v1.539c0 7.647 6.2 13.846 13.846 13.846h1.539a1.538 1.538 0 110 3.077h-1.539C9.577 22 2 14.423 2 5.077z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCurveSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
