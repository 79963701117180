import * as React from "react";

function SvgUnderlineSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M4.5 4.5h1.25v6.25A6.257 6.257 0 0012 17a6.257 6.257 0 006.25-6.25V4.5h1.25a.625.625 0 00.625-.625v-1.25A.625.625 0 0019.5 2h-5.625a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h1.25v6.25a3.125 3.125 0 11-6.25 0V4.5h1.25a.625.625 0 00.625-.625v-1.25A.625.625 0 0010.125 2H4.5a.625.625 0 00-.625.625v1.25A.625.625 0 004.5 4.5zm15.625 15H3.875a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h16.25a.624.624 0 00.625-.625v-1.25a.624.624 0 00-.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgUnderlineSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
