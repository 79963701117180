import * as React from "react";

function SvgFileVideoOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.95 5.826l-3.276-3.277A1.875 1.875 0 0014.348 2H6.375C5.339 2 4.5 2.84 4.5 3.875v16.25C4.5 21.16 5.34 22 6.375 22h11.25c1.035 0 1.875-.84 1.875-1.875V7.152c0-.498-.198-.975-.55-1.326zM17.474 7H14.5V4.027L17.473 7zM6.375 20.125V3.875h6.25v4.063c0 .517.42.937.938.937h4.062v11.25H6.375zm8.933-8.254l-2.058 2.058v-1.46a.781.781 0 00-.781-.781H8.406a.781.781 0 00-.781.78v4.063c0 .432.35.782.781.782h4.063c.431 0 .781-.35.781-.782v-1.46l2.058 2.058a.625.625 0 001.067-.442v-4.374a.625.625 0 00-1.067-.442z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFileVideoOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
