import * as React from "react";

function SvgUserOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.5 13.875c-1.121 0-1.66.625-3.5.625s-2.375-.625-3.5-.625a5.251 5.251 0 00-5.25 5.25v1C3.25 21.16 4.09 22 5.125 22h13.75c1.035 0 1.875-.84 1.875-1.875v-1a5.251 5.251 0 00-5.25-5.25zm3.375 6.25H5.125v-1A3.38 3.38 0 018.5 15.75c.57 0 1.496.625 3.5.625 2.02 0 2.926-.625 3.5-.625a3.38 3.38 0 013.375 3.375v1zM12 13.25a5.626 5.626 0 005.625-5.625A5.626 5.626 0 0012 2a5.626 5.626 0 00-5.625 5.625A5.626 5.626 0 0012 13.25zm0-9.375a3.756 3.756 0 013.75 3.75 3.756 3.756 0 01-3.75 3.75 3.756 3.756 0 01-3.75-3.75A3.756 3.756 0 0112 3.875z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgUserOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
