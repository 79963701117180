import * as React from "react";

function SvgVialsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M4.25 6H5v7.5C5 14.878 6.122 16 7.5 16s2.5-1.122 2.5-2.5V6h.75a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25h-6.5a.25.25 0 00-.25.25v1.5c0 .138.112.25.25.25zM6.5 6h2v3h-2V6zm15 12h-19c-.275 0-.5.225-.5.5v1c0 .275.225.5.5.5h19c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5zM13.25 6H14v7.5c0 1.378 1.122 2.5 2.5 2.5s2.5-1.122 2.5-2.5V6h.75a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25h-6.5a.25.25 0 00-.25.25v1.5c0 .138.113.25.25.25zm2.25 0h2v3h-2V6z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgVialsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
