import * as React from "react";

function SvgCircleNotchSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.29 3.11v.673c0 .435.294.813.714.93a7.418 7.418 0 015.415 7.146c0 4.1-3.318 7.419-7.419 7.419-4.1 0-7.42-3.318-7.42-7.42a7.418 7.418 0 015.416-7.145.968.968 0 00.714-.93V3.11a.969.969 0 00-1.212-.937C5.17 3.29 1.976 7.23 2 11.912a9.979 9.979 0 0010.021 9.947c5.514-.012 9.979-4.485 9.979-10 0-4.663-3.191-8.58-7.508-9.687a.966.966 0 00-1.202.939z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCircleNotchSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
