import * as React from "react";

function SvgBackstageManagementSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.9 15.9l-.8-.4c0-.2.1-.4.1-.6 0-.2 0-.5-.1-.7l.8-.4s.3-.1-.3-1.2c-.7-1.1-.9-.9-.9-.9l-.8.3c-.3-.3-.7-.5-1.1-.6v-1s.1-.3-1.2-.3-1.2.3-1.2.3v1c-.4.1-.7.4-1.1.6l-.9-.5s-.2-.2-.9.9c-.6 1.1-.3 1.2-.3 1.2l.9.5c0 .2-.1.4-.1.6v.6l-.9.5s-.3.1.3 1.2c.7 1.1.9.9.9.9l.9-.5c.3.3.7.5 1.1.7v.9s-.1.3 1.2.3 1.2-.3 1.2-.3v-.9c.4-.1.8-.4 1.1-.7l.8.5s.2.2.9-.9c.8-1 .4-1.1.4-1.1zm-4.3.4c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5z"
        fill="currentColor"
      />
      <path
        d="M15.3 18.9c-.2.1-1.4 1-2.6-1.2-.9-1.6-.4-2.4 0-2.7H4.2V5.2h15.3v4.4c.5.3.5.8.5 1 0 0 .8-.7 1.8.1v-6C21.7 3.8 21 3 20 3H3.7C2.8 3 2 3.8 2 4.7v10.9c0 .9.8 1.7 1.7 1.7h6.6L9.7 19H7.2c-.4 0-.8.4-.8.8s.4.8.8.8h9.3c.1 0 .2 0 .3-.1-1.5-.3-1.5-1.3-1.5-1.6z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBackstageManagementSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
