import * as React from "react";

function SvgCommentAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.5 2h-15A2.502 2.502 0 002 4.5v11.25c0 1.378 1.122 2.499 2.5 2.499h3.75v3.281a.47.47 0 00.746.379l4.879-3.66H19.5c1.378 0 2.5-1.121 2.5-2.5V4.5C22 3.122 20.877 2 19.5 2z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCommentAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
