import * as React from "react";

function SvgWineGlassSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M14.813 20.125H13.25v-4.578a5.65 5.65 0 004.352-6.022l-.623-6.957A.62.62 0 0016.364 2h-8.73a.62.62 0 00-.613.568l-.624 6.958a5.65 5.65 0 004.352 6.022v4.577H9.187c-.862 0-1.562.7-1.562 1.563 0 .172.14.312.312.312h8.125c.173 0 .313-.14.313-.313 0-.862-.7-1.562-1.563-1.562z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgWineGlassSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
