import * as React from "react";

function SvgNetworkWiredSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 12.25v-.5a.5.5 0 00-.5-.5h-8.75V10H15a1 1 0 001-1V5a1 1 0 00-1-1H9a1 1 0 00-1 1v4a1 1 0 001 1h2.25v1.25H2.5a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h3.25V14H4a1 1 0 00-1 1v4a1 1 0 001 1h5a1 1 0 001-1v-4a1 1 0 00-1-1H7.25v-1.25h9.5V14H15a1 1 0 00-1 1v4a1 1 0 001 1h5a1 1 0 001-1v-4a1 1 0 00-1-1h-1.75v-1.25h3.25a.5.5 0 00.5-.5zM10 8V6h4v2h-4zM8 18H5v-2h3v2zm11 0h-3v-2h3v2z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgNetworkWiredSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
