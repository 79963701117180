import * as React from "react";

function SvgJournalWhillsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.375 16.75c-.125.5-.125 2.25 0 2.875.25.25.375.5.375.75V21c0 .625-.5 1-1 1H7c-2.125 0-3.75-1.625-3.75-3.75V5.75C3.25 3.625 4.875 2 7 2h12.75c.625 0 1 .375 1 1v13c0 .375-.125.625-.375.75zm-2.25.25H7c-.625 0-1.25.5-1.25 1.25S6.25 19.5 7 19.5h11.125V17zM8.25 8.876c0 .02.005.038.005.058l1.45 1.269a.312.312 0 01-.206.547.359.359 0 01-.206-.078l-.921-.806a4.37 4.37 0 004.252 3.384 4.372 4.372 0 004.253-3.384l-.922.806a.313.313 0 01-.205.077h-.001a.357.357 0 01-.235-.106.362.362 0 01-.078-.206.356.356 0 01.107-.234l1.45-1.269c0-.02.006-.039.006-.059 0-.432-.082-.84-.198-1.234l-.83.83a.313.313 0 01-.443-.442l1.032-1.031a4.368 4.368 0 00-1.81-1.925 2.462 2.462 0 01-.55 3.228 2.18 2.18 0 01-1.122 3.65l-.055-1.34.495.337a.155.155 0 00.087.027.155.155 0 00.135-.235l-.333-.56.699-.145a.157.157 0 000-.306l-.7-.145.334-.56a.156.156 0 00-.135-.235.184.184 0 00-.087.026l-.555.377-.183-4.386a.156.156 0 00-.312 0l-.18 4.328-.47-.318a.156.156 0 00-.222.21l.333.558-.699.145a.156.156 0 000 .306l.7.146-.334.559a.185.185 0 00-.022.08.18.18 0 00.049.113.157.157 0 00.195.016l.405-.275-.053 1.278a2.183 2.183 0 01-1.122-3.65 2.462 2.462 0 01-.549-3.23A4.372 4.372 0 008.69 6.998L9.72 8.029a.312.312 0 01-.442.442l-.83-.83a4.31 4.31 0 00-.198 1.235z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgJournalWhillsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
