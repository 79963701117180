import * as React from "react";

function SvgBongSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.066 22a2.53 2.53 0 002.188-1.276A7.43 7.43 0 0018.25 17c0-1.41-.394-2.727-1.072-3.852l1.697-1.697.366.366c.245.244.64.244.884 0l.442-.442a.625.625 0 000-.884l-2.058-2.058a.625.625 0 00-.884 0l-.442.442a.625.625 0 000 .884l.366.366-1.54 1.54a7.56 7.56 0 00-1.509-1.153V4.49h.624c.344-.001.624-.28.624-.625l.002-1.24A.625.625 0 0015.124 2l-8.748.01a.625.625 0 00-.624.624l-.002 1.24c0 .346.28.626.626.625H7v6.012A7.491 7.491 0 003.25 17c0 1.357.364 2.628.996 3.724A2.53 2.53 0 006.434 22h8.633zM7.94 12.134l.935-.542V4.496l3.75-.004v7.1l.935.542a5.642 5.642 0 012.21 2.366H5.73a5.645 5.645 0 012.21-2.366z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBongSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
