import * as React from "react";

function SvgFlagUsaSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.25 2.001C2.56 2.001 2 2.56 2 3.251v18.124c0 .344.282.625.625.625h1.25a.627.627 0 00.625-.625V3.251C4.5 2.56 3.942 2 3.25 2zM13.715 13.86c-2.235-.59-4.363-1.125-7.965.433V17c7.254-3.601 8.66 2.082 15.527-.902.445-.195.723-.645.723-1.125v-1.406c-1.703.676-3.133.941-4.38.941-1.46-.004-2.69-.328-3.905-.648zm0-3.75c-2.235-.59-4.363-1.125-7.965.434v2.402c3.703-1.469 6.04-.887 8.285-.297 2.234.59 4.363 1.125 7.965-.434V9.813c-1.703.676-3.133.941-4.38.941-1.46 0-2.69-.324-3.905-.644zm.37-4.918c2.024.61 3.805 1.133 7.915-.785V3.204c0-.98-1.047-1.488-1.93-1.039-6.691 3.41-6.14-2.594-14.32 1.102V9.2c3.703-1.465 6.04-.887 8.285-.297 2.234.586 4.363 1.121 7.965-.434V5.78c-2.094.917-3.645 1.226-4.926 1.226-1.281 0-2.305-.305-3.348-.621a21.49 21.49 0 00-.472-.137V4.95c.281.078.558.16.832.242zM8.25 7.005a.624.624 0 11-.001-1.249.624.624 0 01.001 1.249zm0-2.18a.624.624 0 11-.001-1.249.624.624 0 01.001 1.25zm2.5 1.871a.624.624 0 11-.001-1.248.624.624 0 01.001 1.248zm0-2.183a.624.624 0 11-.001-1.249.624.624 0 01.001 1.249z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFlagUsaSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
