import * as React from "react";

function SvgSimCardSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M4.5 4.5v15C4.5 20.879 5.621 22 7 22h10c1.379 0 2.5-1.121 2.5-2.5V7l-5-5H7a2.502 2.502 0 00-2.5 2.5zm8.75 7.5h-2.5V9.5h2.5V12zM17 12h-2.5V9.5h1.25c.691 0 1.25.559 1.25 1.25V12zm-2.5 5H17v1.25c0 .691-.559 1.25-1.25 1.25H14.5V17zm-3.75 0h2.5v2.5h-2.5V17zM7 17h2.5v2.5H8.25c-.691 0-1.25-.559-1.25-1.25V17zm0-3.75h10v2.5H7v-2.5zm0-2.5c0-.691.559-1.25 1.25-1.25H9.5V12H7v-1.25z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSimCardSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
