import * as React from "react";

function SvgChevronUpOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2.338 15.378l8.798-9.003c.242-.242.532-.375.87-.375.326 0 .628.133.858.375l8.798 9.003c.217.217.338.495.338.81 0 .652-.495 1.16-1.148 1.16a1.15 1.15 0 01-.822-.339l-8.024-8.217-8.036 8.217a1.111 1.111 0 01-.822.338c-.64 0-1.148-.507-1.148-1.16 0-.314.12-.604.338-.81z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChevronUpOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
