import * as React from "react";

function SvgFileMedicalSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.227 6.102l-3.825-3.829A.937.937 0 0014.738 2H14.5v5h5v-.238a.935.935 0 00-.273-.66zm-5.977 1.21V2H5.437a.935.935 0 00-.937.938v18.125c0 .519.418.937.938.937h13.125c.519 0 .937-.418.937-.938V8.25h-5.313a.94.94 0 01-.937-.938zm2.5 6.25v1.876c0 .171-.14.312-.313.312H13.25v2.188c0 .171-.14.312-.313.312h-1.874a.313.313 0 01-.313-.313V15.75H8.562a.313.313 0 01-.312-.313v-1.874c0-.172.14-.313.313-.313h2.187v-2.188c0-.171.14-.312.313-.312h1.874c.172 0 .313.14.313.313v2.187h2.188c.171 0 .312.14.312.313z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFileMedicalSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
