import * as React from "react";

function SvgFileAddSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.763 7.06V2h-7.44a.89.89 0 00-.893.893v17.262a.89.89 0 00.893.893h10.535v-.953H13.43c-.789 0-1.429-.64-1.429-1.428v-.953c0-.789.64-1.428 1.429-1.428h1.428v-1.429c0-.789.64-1.428 1.43-1.428h.951c.167 0 .328.028.477.08V7.953h-5.06a.895.895 0 01-.893-.892zm5.953-.525v.227h-4.762V2h.227c.238 0 .465.093.632.26l3.642 3.646a.89.89 0 01.26.629zm-1.905 8.322c0-.263.213-.476.476-.476h.953c.262 0 .476.213.476.476v2.381h2.38c.264 0 .477.213.477.476v.953a.476.476 0 01-.476.476h-2.381v2.38a.476.476 0 01-.477.477h-.952a.476.476 0 01-.476-.476v-2.381H13.43a.476.476 0 01-.476-.476v-.953c0-.263.213-.476.476-.476h2.38v-2.38z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFileAddSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
