import * as React from "react";

function SvgCameraRetroSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.875 3.25C2.84 3.25 2 4.09 2 5.125v13.75c0 1.035.84 1.875 1.875 1.875h16.25c1.035 0 1.875-.84 1.875-1.875V5.125c0-1.035-.84-1.875-1.875-1.875H3.875zm0 1.25h4.14c.13 0 .235.105.235.234v.782a.235.235 0 01-.234.234H3.484a.235.235 0 01-.234-.234v-.391c0-.344.281-.625.625-.625zm16.64 3.75H3.486a.235.235 0 01-.235-.234V6.609c0-.129.105-.234.234-.234h5.391l1.18-1.77a.233.233 0 01.195-.105h9.875c.344 0 .625.281.625.625v2.89a.235.235 0 01-.234.235zM12 18.563a4.691 4.691 0 01-4.688-4.688A4.691 4.691 0 0112 9.187a4.691 4.691 0 014.688 4.688A4.691 4.691 0 0112 18.563zm0-8.125a3.443 3.443 0 00-3.438 3.437A3.443 3.443 0 0012 17.313a3.443 3.443 0 003.438-3.438A3.443 3.443 0 0012 10.437zM10.125 14.5a.627.627 0 01-.625-.625c0-1.379 1.121-2.5 2.5-2.5.344 0 .625.281.625.625a.627.627 0 01-.625.625c-.688 0-1.25.563-1.25 1.25a.627.627 0 01-.625.625z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCameraRetroSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
