import * as React from "react";

function SvgHeadphonesAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.25 13.25h-.625c-1.38 0-2.5 1.121-2.5 2.505v2.49a2.502 2.502 0 002.5 2.505h.625c.69 0 1.25-.56 1.25-1.252v-4.996c0-.692-.56-1.252-1.25-1.252zm8.125 0h-.625c-.69 0-1.25.56-1.25 1.252v4.996c0 .691.56 1.252 1.25 1.252h.625c1.38 0 2.5-1.122 2.5-2.505v-2.49a2.502 2.502 0 00-2.5-2.505zM12 3.25c-5.59 0-9.821 4.654-10 10v4.375c0 .345.28.625.625.625h.625c.345 0 .625-.28.625-.625V13.25c0-4.48 3.646-8.117 8.125-8.118 4.48 0 8.125 3.639 8.125 8.118v4.375c0 .345.28.625.625.625h.625c.345 0 .625-.28.625-.625V13.25c-.178-5.346-4.41-10-10-10z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHeadphonesAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
