import * as React from "react";

function SvgImageSolid1(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 19.5H3.875A1.875 1.875 0 012 17.625V6.375C2 5.339 2.84 4.5 3.875 4.5h16.25C21.16 4.5 22 5.34 22 6.375v11.25c0 1.035-.84 1.875-1.875 1.875zM6.375 6.687a2.188 2.188 0 100 4.376 2.188 2.188 0 000-4.376zM4.5 17h15v-4.375l-3.419-3.419a.469.469 0 00-.662 0L10.125 14.5l-2.169-2.169a.469.469 0 00-.662 0L4.5 15.125V17z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgImageSolid1);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
