import * as React from "react";

function SvgGlassesSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.934 12.846l-1.575-6.31A3.064 3.064 0 0018.866 4.6a3.062 3.062 0 00-2.437-.22l-.53.177a.555.555 0 00-.35.702l.175.528a.555.555 0 00.702.351l.458-.152c.378-.126.799-.124 1.151.06.358.186.61.505.708.896l1.335 5.341a9.608 9.608 0 00-2.82-.433 9.108 9.108 0 00-3.987.929H10.73a9.1 9.1 0 00-3.988-.929c-1.091 0-2.05.196-2.82.433L5.257 6.94c.097-.39.35-.71.708-.896.353-.184.774-.186 1.151-.06l.457.153a.555.555 0 00.703-.352l.175-.527a.555.555 0 00-.351-.703l-.53-.176a3.06 3.06 0 00-2.437.22A3.065 3.065 0 003.64 6.536l-1.574 6.31a2.23 2.23 0 00-.066.538v2.44c0 2.183 1.791 3.954 4 3.954h1.289c2.093 0 3.832-1.595 3.989-3.659l.101-1.341h1.242l.102 1.341c.156 2.064 1.895 3.659 3.988 3.659H18c2.209 0 4-1.77 4-3.954v-2.44c0-.181-.022-.362-.066-.538zM9.062 15.951c-.069.9-.847 1.605-1.773 1.605H6c-.98 0-1.778-.777-1.778-1.733V14.52a7.539 7.539 0 012.52-.449c.829 0 1.641.15 2.428.449l-.108 1.431zm10.716-.127c0 .955-.798 1.732-1.778 1.732h-1.289c-.926 0-1.705-.705-1.773-1.605l-.109-1.431a6.816 6.816 0 012.43-.449c1.01 0 1.89.224 2.518.45v1.303z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGlassesSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
