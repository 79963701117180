import * as React from "react";

function SvgSubwaySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.75 5.75v10c0 2.024-2.407 3.75-5.079 3.75l2.46 1.942a.313.313 0 01-.194.558H6.063a.313.313 0 01-.193-.558l2.46-1.942c-2.664 0-5.079-1.72-5.079-3.75v-10c0-2.071 2.5-3.75 5-3.75h7.5c2.54 0 5 1.679 5 3.75zm-9.688 5.313V6.686a.937.937 0 00-.937-.937H6.062a.937.937 0 00-.937.938v4.375c0 .517.42.937.938.937h4.062c.518 0 .938-.42.938-.938zm7.813 0V6.686a.937.937 0 00-.938-.937h-4.062a.937.937 0 00-.938.938v4.375c0 .517.42.937.938.937h4.063c.517 0 .937-.42.937-.938zM17 13.25A1.875 1.875 0 1017 17a1.875 1.875 0 000-3.75zm-10 0A1.875 1.875 0 107 17a1.875 1.875 0 000-3.75z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSubwaySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
