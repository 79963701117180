import * as React from "react";

function SvgBedSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.5 12c1.378 0 2.5-1.122 2.5-2.5S8.878 7 7.5 7A2.503 2.503 0 005 9.5C5 10.878 6.122 12 7.5 12zm11-4h-7a.5.5 0 00-.5.5V13H4V6.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v11a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V16h16v1.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-6A3.5 3.5 0 0018.5 8z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBedSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
