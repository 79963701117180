import * as React from "react";

function SvgSearchSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.726 19.291l-3.894-3.894a.937.937 0 00-.664-.273h-.636a8.084 8.084 0 001.718-5A8.123 8.123 0 0010.126 2a8.123 8.123 0 00-8.124 8.124 8.123 8.123 0 008.124 8.124c1.886 0 3.62-.64 5-1.718v.636c0 .25.097.489.273.665l3.894 3.894a.933.933 0 001.324 0l1.105-1.106a.942.942 0 00.004-1.328zm-11.6-4.167c-2.762 0-5-2.234-5-5 0-2.761 2.235-5 5-5 2.761 0 5 2.235 5 5 0 2.762-2.235 5-5 5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSearchSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
