import * as React from "react";

function SvgClassSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M5 15a2 2 0 100-4 2 2 0 000 4zM6.2 16h-.224a1.86 1.86 0 01-.976.286c-.348 0-.678-.104-.976-.286H3.8C2.806 16 2 17.075 2 18.4v.743c0 .473.288.857.643.857h4.714c.355 0 .643-.384.643-.857V18.4c0-1.325-.806-2.4-1.8-2.4zM12 15a2 2 0 100-4 2 2 0 000 4zM13.2 16h-.224a1.86 1.86 0 01-.976.286c-.348 0-.678-.104-.976-.286H10.8C9.806 16 9 17.075 9 18.4v.743c0 .473.288.857.643.857h4.714c.355 0 .643-.384.643-.857V18.4c0-1.325-.806-2.4-1.8-2.4zM19 15a2 2 0 100-4 2 2 0 000 4zM20.2 16h-.224a1.86 1.86 0 01-.976.286c-.348 0-.678-.104-.976-.286H17.8c-.994 0-1.8 1.075-1.8 2.4v.743c0 .473.288.857.643.857h4.714c.355 0 .643-.384.643-.857V18.4c0-1.325-.806-2.4-1.8-2.4zM3 4h18v6.764A2.989 2.989 0 0019 10V6H5v4a2.99 2.99 0 00-2 .764V4z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgClassSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
