import * as React from "react";

function SvgGhostSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.77 2.003C7.664 2.127 4.5 5.708 4.5 9.814v10.31c0 .557.673.835 1.067.442l.973-.724a.625.625 0 01.84.086l1.678 1.889c.244.244.64.244.884 0l1.59-1.791a.625.625 0 01.935 0l1.59 1.79c.245.245.64.245.885 0l1.678-1.888a.625.625 0 01.84-.086l.973.724a.625.625 0 001.067-.442V9.5a7.5 7.5 0 00-7.73-7.497zM9.5 10.75a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zm5 0a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGhostSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
