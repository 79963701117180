import * as React from "react";

function SvgBookDeadSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.875 7.313a.627.627 0 00.625-.625.627.627 0 00-.625-.625.627.627 0 00-.625.625c0 .343.281.625.625.625zM20.75 16V3c0-.625-.375-1-1-1H7C4.875 2 3.25 3.625 3.25 5.75v12.5C3.25 20.375 4.875 22 7 22h12.75c.5 0 1-.375 1-1v-.625c0-.25-.125-.5-.375-.75-.125-.625-.125-2.375 0-2.875.25-.125.375-.375.375-.75zM12.625 4.187c1.727 0 3.125 1.122 3.125 2.5 0 .817-.496 1.532-1.25 1.989v.511a.627.627 0 01-.625.626h-2.5a.627.627 0 01-.625-.626v-.511C9.996 8.219 9.5 7.504 9.5 6.687c0-1.378 1.398-2.5 3.125-2.5zm-4.5 6.536l.246-.575c.066-.16.25-.23.41-.164l3.84 1.645 3.844-1.645a.311.311 0 01.41.164l.246.575a.311.311 0 01-.164.41l-2.746 1.18 2.746 1.175c.16.067.23.25.164.41l-.246.575a.311.311 0 01-.41.164l-3.84-1.645-3.84 1.649a.311.311 0 01-.41-.164l-.246-.575a.311.311 0 01.164-.41l2.75-1.176-2.754-1.183a.311.311 0 01-.164-.41zm10 8.777H7c-.75 0-1.25-.5-1.25-1.25S6.375 17 7 17h11.125v2.5zm-6.75-12.188A.627.627 0 0012 6.688a.627.627 0 00-.625-.625.627.627 0 00-.625.625c0 .344.281.625.625.625z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBookDeadSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
