import * as React from "react";

function SvgPodcastSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.696 21.084c-.2.743-.96.916-1.696.916-.737 0-1.495-.173-1.696-.916-.299-1.11-.804-3.893-.804-5.188 0-1.373 1.216-1.709 2.5-1.709s2.5.336 2.5 1.71c0 1.287-.503 4.069-.804 5.187zm-4.318-7.812a3.739 3.739 0 01-1.125-2.836 3.76 3.76 0 013.599-3.59 3.754 3.754 0 013.898 3.748 3.739 3.739 0 01-1.128 2.678.235.235 0 00.025.358c.364.265.643.6.83.991a.233.233 0 00.368.074 5.61 5.61 0 001.778-4.24 5.639 5.639 0 00-5.46-5.484 5.631 5.631 0 00-5.788 5.623 5.61 5.61 0 001.78 4.1c.115.11.3.07.369-.073.186-.391.465-.726.83-.99a.235.235 0 00.024-.36zM12 2a8.745 8.745 0 00-8.75 8.75 8.75 8.75 0 004.912 7.867c.169.083.362-.06.333-.246a24.662 24.662 0 01-.211-1.732.235.235 0 00-.105-.176 6.874 6.874 0 01-3.054-5.756c.023-3.76 3.097-6.822 6.857-6.832a6.883 6.883 0 016.893 6.875c0 2.42-1.256 4.55-3.15 5.776-.04.548-.12 1.195-.22 1.845a.234.234 0 00.333.247 8.749 8.749 0 004.912-7.868A8.745 8.745 0 0012 2zm0 6.25a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPodcastSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
