import * as React from "react";

function SvgCommentSlashSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M4 11.502c0 1.55.668 2.968 1.78 4.084-.393 1.572-1.697 2.975-1.712 2.994a.25.25 0 00.181.422c2.072 0 3.625-.994 4.394-1.607a9.53 9.53 0 003.356.607c.856 0 1.678-.113 2.45-.313L4.277 9.827A5.363 5.363 0 004 11.502zm17.805 6.815l-3.575-2.762C19.33 14.442 20 13.039 20 11.502c0-3.591-3.581-6.5-8-6.5-2.034 0-3.881.628-5.294 1.647L3.421 4.107a.502.502 0 00-.703.087l-.613.788a.498.498 0 00.088.7l18.387 14.21a.502.502 0 00.703-.088l.613-.79a.495.495 0 00-.09-.698z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCommentSlashSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
