import * as React from "react";

function SvgObjectGroupOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.531 7c.26 0 .469-.21.469-.469V3.72a.469.469 0 00-.469-.469H18.72a.469.469 0 00-.469.469v.469H5.75v-.47a.469.469 0 00-.469-.468H2.47A.469.469 0 002 3.719V6.53c0 .26.21.469.469.469h.469v10h-.47a.469.469 0 00-.468.469v2.812c0 .26.21.469.469.469H5.28c.26 0 .469-.21.469-.469v-.468h12.5v.468c0 .26.21.469.469.469h2.812c.26 0 .469-.21.469-.469V17.47a.469.469 0 00-.469-.469h-.468V7h.468zM19.5 4.5h1.25v1.25H19.5V4.5zm-16.25 0H4.5v1.25H3.25V4.5zm1.25 15H3.25v-1.25H4.5v1.25zm16.25 0H19.5v-1.25h1.25v1.25zM19.187 17h-.468a.469.469 0 00-.469.469v.468H5.75v-.468A.469.469 0 005.281 17h-.468V7h.468c.26 0 .469-.21.469-.469v-.468h12.5v.468c0 .26.21.469.469.469h.468v10zm-1.406-7.5H14.5V7.469A.469.469 0 0014.031 7H6.22a.469.469 0 00-.469.469v6.562c0 .26.21.469.469.469H9.5v2.031c0 .26.21.469.469.469h7.812c.26 0 .469-.21.469-.469V9.97a.469.469 0 00-.469-.469zM7.313 8.562h5.625v4.376H7.311V8.561zm9.375 6.876h-5.625V14.5h2.968c.26 0 .469-.21.469-.469v-2.969h2.188v4.376z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgObjectGroupOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
