import * as React from "react";

function SvgSunSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 8.25A3.756 3.756 0 008.25 12 3.756 3.756 0 0012 15.75 3.756 3.756 0 0015.75 12 3.756 3.756 0 0012 8.25zm9.625 3.145l-3.699-1.848 1.309-3.922a.675.675 0 00-.856-.855l-3.922 1.308-1.851-3.703a.676.676 0 00-1.211 0l-1.848 3.7-3.925-1.31a.675.675 0 00-.856.856l1.309 3.922-3.7 1.852c-.5.25-.5.96 0 1.21l3.7 1.848-1.309 3.926a.675.675 0 00.856.855l3.921-1.308 1.848 3.699c.25.5.961.5 1.211 0l1.848-3.7 3.922 1.31a.675.675 0 00.855-.856l-1.308-3.922 3.699-1.848a.677.677 0 00.008-1.214zm-6.09 4.14a5.005 5.005 0 01-7.07 0 5.005 5.005 0 010-7.07 5.005 5.005 0 017.07 0 5.005 5.005 0 010 7.07z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSunSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
