import * as React from "react";

function SvgHandPointDownOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.625 22c1.782 0 3.25-1.475 3.25-3.25v-1.392c.3-.067.589-.171.862-.31a3.627 3.627 0 002.453-.625c2.05-.044 3.56-1.46 3.56-3.903v-.832c0-2.347-1.563-3.849-1.563-4.97v-.104c.194-.224.313-.529.313-.864v-2.5c0-.69-.504-1.25-1.125-1.25h-9c-.621 0-1.125.56-1.125 1.25v2.5c0 .335.12.64.313.864v.105c0 .272-.242.58-.925 1.179l-.006.005-.006.005c-.388.346-.814.708-1.298 1.01-1.182.73-3.078 1.187-3.078 2.957 0 2.224 1.378 3.594 3.25 3.594.314 0 .605-.032.875-.085v3.366c0 1.762 1.488 3.25 3.25 3.25zm0-1.875c-.73 0-1.375-.655-1.375-1.375v-6.188c-.677 0-1.375 1.032-2.75 1.032-1.031 0-1.375-.806-1.375-1.719 0-.344 1.278-.799 2.191-1.364.57-.355 1.064-.763 1.558-1.203.718-.63 1.431-1.322 1.547-2.308h6.904c.147 1.671 1.55 2.91 1.55 4.688v.832c0 1.582-.867 2.23-2.395 1.976-.313.57-1.328.944-2.095.505-.712.756-1.812.695-2.385.193v3.556c0 .741-.634 1.375-1.375 1.375zM16.063 4.5a.937.937 0 111.874 0 .937.937 0 01-1.875 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHandPointDownOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
