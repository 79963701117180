import * as React from "react";

function SvgKissOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.774 8.774c-.714 0-1.29.577-1.29 1.29 0 .714.576 1.29 1.29 1.29.714 0 1.29-.576 1.29-1.29 0-.713-.576-1.29-1.29-1.29zm5.484 5.323c0-.774-1.161-1.674-2.883-1.774a.314.314 0 00-.33.25.322.322 0 00.189.37l.681.29c.525.223.839.545.839.868 0 .322-.314.645-.835.867l-.685.29c-.23.097-.242.492 0 .597l.681.29c.525.222.839.544.839.867 0 .323-.314.645-.835.867l-.685.29a.324.324 0 00.125.62h.02c1.726-.1 2.883-.999 2.883-1.773 0-.524-.54-1.1-1.42-1.451.876-.367 1.416-.944 1.416-1.468zM12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 18.064c-4.448 0-8.065-3.616-8.065-8.064S7.552 3.935 12 3.935c4.448 0 8.064 3.617 8.064 8.065 0 4.448-3.616 8.064-8.064 8.064zm3.226-11.29c-.714 0-1.29.577-1.29 1.29 0 .714.576 1.29 1.29 1.29.713 0 1.29-.576 1.29-1.29 0-.713-.576-1.29-1.29-1.29z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgKissOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
