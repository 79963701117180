import * as React from "react";

function SvgBookmarkOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.625 2H6.375C5.339 2 4.5 2.84 4.5 3.875V22l7.5-4.375L19.5 22V3.875C19.5 2.839 18.66 2 17.625 2zm0 16.735L12 15.455l-5.625 3.28V4.11a.234.234 0 01.234-.234h10.782c.13 0 .234.105.234.234v14.626z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBookmarkOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
