import * as React from "react";

function SvgDumpsterSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.444 8.667c.362 0 .625-.34.539-.691l-.834-3.334a.557.557 0 00-.538-.42h-3.434l.889 4.445h3.378zm-10-4.445h-3.49l-.888 4.445h4.378V4.222zm4.601 0h-3.49v4.445h4.379l-.889-4.445zM2.555 8.667h3.379l.889-4.445H3.389a.557.557 0 00-.538.42l-.834 3.334a.556.556 0 00.539.69zm18.89 2.222h-.695l.139-1.111H3.11l.139 1.11h-.694a.557.557 0 00-.556.556v1.111c0 .306.25.556.556.556h.972l.694 5.556v.555c0 .306.25.556.556.556h1.11c.306 0 .556-.25.556-.556v-.555h11.112v.555c0 .306.25.556.555.556h1.111c.306 0 .556-.25.556-.556v-.555l.694-5.556h.972c.306 0 .556-.25.556-.556v-1.11a.557.557 0 00-.556-.556z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDumpsterSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
