import * as React from "react";

function SvgIndustrySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.56 8.398l-5.435 3.458V9.189a.938.938 0 00-1.44-.791L8.25 11.856V4.188a.937.937 0 00-.938-.938H2.938A.937.937 0 002 4.188v15.625c0 .517.42.937.938.937h18.125c.517 0 .937-.42.937-.938V9.19a.938.938 0 00-1.44-.791z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgIndustrySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
