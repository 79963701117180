import * as React from "react";

function SvgMonumentSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.875 19.5H5.125a.625.625 0 00-.625.625v1.25c0 .345.28.625.625.625h13.75c.345 0 .625-.28.625-.625v-1.25a.625.625 0 00-.625-.625zm-3.08-13.565a1.249 1.249 0 00-.36-.76l-2.993-2.992a.625.625 0 00-.884 0L8.566 5.176a1.249 1.249 0 00-.36.76L7 18.25h10L15.794 5.935zM13.874 14c0 .25-.25.5-.5.5h-2.75c-.25 0-.5-.25-.5-.5v-1.5c0-.25.25-.5.5-.5h2.75c.25 0 .5.25.5.5V14z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMonumentSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
