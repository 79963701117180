import * as React from "react";

function SvgRulerVerticalSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.563 18.25a.312.312 0 01-.313-.313v-.625c0-.172.14-.312.313-.312H17v-2.5h-3.438a.312.312 0 01-.312-.313v-.624c0-.173.14-.313.313-.313H17v-2.5h-3.438a.312.312 0 01-.312-.313v-.624c0-.173.14-.313.313-.313H17V7h-3.438a.312.312 0 01-.312-.313v-.625c0-.172.14-.312.313-.312H17v-2.5C17 2.56 16.44 2 15.75 2h-7.5C7.56 2 7 2.56 7 3.25v17.5c0 .69.56 1.25 1.25 1.25h7.5c.69 0 1.25-.56 1.25-1.25v-2.5h-3.438z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgRulerVerticalSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
