import * as React from "react";

function SvgFlagSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.499 5.859c-2.094 0-3.822-1.359-6.454-1.359-.975 0-1.848.171-2.658.47a2.185 2.185 0 00-1.952-2.968 2.188 2.188 0 00-1.341 3.98v15.08c0 .518.42.938.937.938h.625c.518 0 .938-.42.938-.938v-3.687c1.106-.471 2.483-.864 4.47-.864 2.093 0 3.822 1.359 6.454 1.359 1.881 0 3.385-.637 4.785-1.596.34-.233.54-.619.54-1.03V5.748a1.25 1.25 0 00-1.776-1.134c-1.341.623-2.986 1.245-4.568 1.245z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFlagSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
