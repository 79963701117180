import * as React from "react";

function SvgHorizontalOneColumnsOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.795 5H4.205C3.54 5 3 5.54 3 6.205v11.411c0 .666.54 1.205 1.205 1.205h15.59c.665 0 1.205-.54 1.205-1.205V6.206C21 5.54 20.46 5 19.795 5zm-.151 12.616H4.356a.15.15 0 01-.15-.15V6.355a.15.15 0 01.15-.15h15.288a.15.15 0 01.15.15v11.11a.15.15 0 01-.15.15z"
        fill="currentColor"
      />
      <path
        d="M15.813 9.124c-.07-.243-.156-.667-.156-.667h-.01s-.087.424-.157.667l-.223.754h.765l-.219-.754zm-1.872 2.007l1.155-3.397a.24.24 0 01.23-.162h.652c.103 0 .195.066.23.162l1.154 3.397a.242.242 0 01-.229.32h-.46a.242.242 0 01-.23-.173l-.19-.64h-1.209l-.183.638a.243.243 0 01-.23.175h-.46a.243.243 0 01-.23-.32zM7.118 7.572h-.602a.301.301 0 00-.302.3v8.378c0 .166.135.301.302.301h.602a.301.301 0 00.302-.3V7.872a.301.301 0 00-.302-.301zM9.69 7.572h-.603a.301.301 0 00-.301.3v8.378c0 .166.135.301.301.301h.603a.301.301 0 00.301-.3V7.872a.301.301 0 00-.301-.301zM12.261 7.572h-.602a.301.301 0 00-.302.3v8.378c0 .166.135.301.302.301h.602a.301.301 0 00.302-.3V7.872a.301.301 0 00-.302-.301zM14.833 12.714h-.603a.301.301 0 00-.301.302v3.234c0 .166.134.301.3.301h.604a.301.301 0 00.3-.3v-3.235a.301.301 0 00-.3-.302zM17.404 12.714h-.603a.301.301 0 00-.301.302v3.234c0 .166.135.301.301.301h.603a.301.301 0 00.301-.3v-3.235a.301.301 0 00-.301-.302z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHorizontalOneColumnsOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
