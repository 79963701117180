import * as React from "react";

function SvgUserNurseSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.727 14.5L12 18.226 8.273 14.5c-2.792.121-5.023 2.406-5.023 5.226A2.274 2.274 0 005.524 22h12.952a2.274 2.274 0 002.274-2.274c0-2.82-2.23-5.105-5.023-5.226zM12 13.875a5 5 0 005-5V4.571a1.249 1.249 0 00-.811-1.172l-3.311-1.24a2.5 2.5 0 00-1.756 0L7.811 3.401A1.25 1.25 0 007 4.57v4.304a5 5 0 005 5zM10.437 4.8a.195.195 0 01.196-.196h.846v-.846a.195.195 0 01.196-.195h.65a.195.195 0 01.196.195v.846h.846a.195.195 0 01.195.196v.65a.195.195 0 01-.195.196h-.846v.846a.195.195 0 01-.196.196h-.65a.195.195 0 01-.196-.196v-.846h-.846a.195.195 0 01-.195-.195V4.8zM8.876 8.25h6.25v.625a3.125 3.125 0 01-6.25 0V8.25z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgUserNurseSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
