import * as React from "react";

function SvgSortNumericDownAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.214 16.286H8.07V2.714A.714.714 0 007.357 2H5.928a.714.714 0 00-.714.714v13.572H3.07a.715.715 0 00-.504 1.219l3.571 4.286a.714.714 0 001.01 0l3.572-4.286c.447-.449.129-1.22-.506-1.22zm10 2.857H19.5v-5a.714.714 0 00-.715-.714h-2.143a.714.714 0 00-.638.394l-.714 1.428a.714.714 0 00.638 1.035h.714v2.857h-.714a.714.714 0 00-.714.714v1.429a.714.714 0 00.714.714h4.286a.714.714 0 00.714-.714v-1.429a.714.714 0 00-.714-.714zM17.096 2.13a3.527 3.527 0 00-2.455 2.418 3.585 3.585 0 003.073 4.577c-.28.237-.594.431-.931.577-.338.152-.482.556-.365.908l.442.893c.128.385.56.602.933.442 2.59-1.106 3.85-2.75 3.85-5.893v-.48a3.582 3.582 0 00-4.547-3.442zm.975 4.334a.893.893 0 110-1.785.893.893 0 010 1.785z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSortNumericDownAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
