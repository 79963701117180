import * as React from "react";

function SvgHandHoldingHeartSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.559 11.81a.605.605 0 00.885 0l3.78-3.966c1.098-1.152 1.036-3.062-.194-4.124-1.069-.927-2.663-.76-3.642.267L12 4.393l-.385-.403c-.98-1.03-2.573-1.197-3.642-.27-1.226 1.062-1.292 2.972-.195 4.124l3.782 3.965zm10.068 2.694c-.41-.372-1.048-.348-1.479 0L16.94 17.07a2.21 2.21 0 01-1.389.486h-4.107a.557.557 0 01-.556-.556c0-.305.25-.555.556-.555h2.718c.553 0 1.066-.379 1.157-.924a1.112 1.112 0 00-1.098-1.299H8.667c-.937 0-1.843.323-2.572.914l-1.615 1.309H2.556A.557.557 0 002 17v3.333c0 .306.25.556.556.556h12.388c.503 0 .993-.17 1.388-.486l5.25-4.201a1.11 1.11 0 00.045-1.698z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHandHoldingHeartSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
