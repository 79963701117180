import * as React from "react";

function SvgHandScissorsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.438 19.188c0-.863.7-1.563 1.562-1.563v-.313h-1.25a1.562 1.562 0 110-3.125H12v-.312H3.875a1.875 1.875 0 110-3.75H12v-.53L5.064 6.87a1.875 1.875 0 011.372-3.49L13.87 6.3l.971-1.215c.481-.6 1.328-.762 1.996-.38l4.375 2.5A1.562 1.562 0 0122 8.563v9.374c0 .726-.499 1.355-1.205 1.521l-5.312 1.25a1.563 1.563 0 01-.358.042H12c-.863 0-1.563-.7-1.563-1.563z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHandScissorsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
