import * as React from "react";

function SvgObjectUngroupOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.583 10.889c.23 0 .417-.187.417-.417v-2.5a.417.417 0 00-.417-.417h-2.5a.417.417 0 00-.416.417v.417H15.61v-.833h.417c.23 0 .416-.187.416-.417v-2.5a.417.417 0 00-.416-.417h-2.5a.417.417 0 00-.417.417v.417H5.333v-.417a.417.417 0 00-.416-.417h-2.5A.417.417 0 002 4.64v2.5c0 .23.187.417.417.417h.416v5.555h-.416a.417.417 0 00-.417.417v2.5c0 .23.187.416.417.416h2.5c.23 0 .416-.186.416-.416v-.417H8.39v.833h-.417a.417.417 0 00-.416.417v2.5c0 .23.186.417.416.417h2.5c.23 0 .417-.187.417-.417v-.417h7.778v.417c0 .23.186.417.416.417h2.5c.23 0 .417-.187.417-.417v-2.5a.417.417 0 00-.417-.417h-.416V10.89h.416zm-7.36-5.556h1.11v1.111h-1.11v-1.11zm0 8.89h1.11v1.11h-1.11v-1.11zm-10 1.11H3.11v-1.11h1.111v1.11zm0-8.889H3.11v-1.11h1.111v1.11zm1.11 7.5v-.416a.417.417 0 00-.416-.417H4.5V7.555h.417c.23 0 .416-.186.416-.416v-.417h7.778v.417c0 .23.187.417.417.417h.416v5.555h-.416a.417.417 0 00-.417.417v.416H5.333zm4.445 4.723H8.667v-1.112h1.11v1.112zm9.722-2.223h-.417a.417.417 0 00-.416.417v.417h-7.778v-.417a.417.417 0 00-.417-.417h-.416v-.833h3.055v.417c0 .23.187.416.417.416h2.5c.23 0 .416-.186.416-.416v-2.5a.417.417 0 00-.416-.417h-.417v-3.056h3.056v.417c0 .23.186.417.416.417h.417v5.555zm1.389 2.223h-1.111v-1.112h1.11v1.112zm0-8.89h-1.111v-1.11h1.11v1.11z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgObjectUngroupOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
