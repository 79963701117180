import * as React from "react";

function SvgBuildingSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.281 20.75H19.5V2.937A.937.937 0 0018.562 2H5.438a.937.937 0 00-.937.938V20.75h-.781a.469.469 0 00-.469.469V22h17.5v-.781a.469.469 0 00-.469-.469zM8.25 4.969c0-.26.21-.469.469-.469h1.562c.26 0 .469.21.469.469V6.53c0 .26-.21.469-.469.469H8.72a.469.469 0 01-.469-.469V4.97zm0 3.75c0-.26.21-.469.469-.469h1.562c.26 0 .469.21.469.469v1.562c0 .26-.21.469-.469.469H8.72a.469.469 0 01-.469-.469V8.72zm2.031 5.781H8.72a.469.469 0 01-.469-.469V12.47c0-.26.21-.469.469-.469h1.562c.26 0 .469.21.469.469v1.562c0 .26-.21.469-.469.469zm2.969 6.25h-2.5v-3.281c0-.26.21-.469.469-.469h1.562c.26 0 .469.21.469.469v3.281zm2.5-6.719c0 .26-.21.469-.469.469H13.72a.469.469 0 01-.469-.469V12.47c0-.26.21-.469.469-.469h1.562c.26 0 .469.21.469.469v1.562zm0-3.75c0 .26-.21.469-.469.469H13.72a.469.469 0 01-.469-.469V8.72c0-.26.21-.469.469-.469h1.562c.26 0 .469.21.469.469v1.562zm0-3.75c0 .26-.21.469-.469.469H13.72a.469.469 0 01-.469-.469V4.97c0-.26.21-.469.469-.469h1.562c.26 0 .469.21.469.469V6.53z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBuildingSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
