import * as React from "react";

function SvgGemOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.111 3.11H5.89c-.14 0-.271.07-.348.188L2.07 8.409c-.1.153-.09.354.024.493l9.584 11.834c.167.205.48.205.646 0l9.584-11.834a.417.417 0 00.024-.493l-3.469-5.111a.42.42 0 00-.35-.188zm-.67 1.667l2.198 3.334h-2.375L15.47 4.777h1.972zm-7.017 0h3.149l1.795 3.334H8.632l1.792-3.334zm-3.865 0H8.53L6.736 8.111H4.36l2.198-3.334zm-1.493 5H6.85l2.371 5.556-4.156-5.556zm3.573 0h6.722L12 18.226 8.639 9.777zm6.139 5.556l2.368-5.556h1.785l-4.153 5.556z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGemOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
