import * as React from "react";

function SvgPlaneArrivalSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.5 18h-19a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h19a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zM3.4 10.427l2.773 2.5c.228.205.5.354.796.435l8.988 2.449c.827.225 1.705.272 2.531.042.927-.258 1.358-.663 1.477-1.116.12-.453-.054-1.022-.73-1.717-.603-.62-1.387-1.025-2.214-1.25l-3.047-.83-3.136-5.996a.52.52 0 00-.365-.372L8.44 4.018a.516.516 0 00-.647.514l1.497 5.131-3.193-.87-.863-2.12a.517.517 0 00-.344-.311l-1.242-.339A.516.516 0 003 6.52l.007 3.18c.006.279.189.542.393.727z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPlaneArrivalSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
