import * as React from "react";

function SvgClosedCaptioningSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 4.5H3.875C2.84 4.5 2 5.34 2 6.375v11.25c0 1.035.84 1.875 1.875 1.875h16.25c1.035 0 1.875-.84 1.875-1.875V6.375C22 5.34 21.16 4.5 20.125 4.5zm-9.605 8.738a.241.241 0 01.359.035l.762 1.082a.236.236 0 01-.02.301c-2.094 2.219-6.75 1.254-6.75-2.652 0-3.8 4.754-4.668 6.738-2.738.082.078.098.125.04.222l-.684 1.192a.243.243 0 01-.356.066c-1.593-1.25-3.695-.582-3.695 1.219.004 1.875 1.996 2.754 3.606 1.273zm7.437 0a.241.241 0 01.36.035l.761 1.082a.236.236 0 01-.02.301c-2.09 2.223-6.745 1.254-6.745-2.652 0-3.8 4.753-4.668 6.738-2.738.082.078.097.125.039.222l-.684 1.192a.243.243 0 01-.355.066c-1.594-1.25-3.695-.582-3.695 1.219 0 1.875 1.992 2.754 3.601 1.273z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgClosedCaptioningSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
