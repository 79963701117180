import * as React from "react";

function SvgMegaphoneSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.986 17.692c0 1.157.854 2.012 2.002 2.012 1.157 0 2.012-.855 2.012-2.012V4.02C22 2.863 21.145 2 19.988 2c-1.148 0-2.002.863-2.002 2.02v13.672zm-9.088-2.448c2.6.32 5.243 1.13 7.77 2.457-.017-.098-.017-.196-.017-.303V4.305c0-.07 0-.151.009-.222-2.44 1.3-5.368 2.19-7.762 2.456v8.705zM5.881 6.54C3.37 6.54 2 7.866 2 10.287v1.21c0 2.412 1.37 3.738 3.88 3.738h1.683V6.54H5.881zm3.685 14.9c1.192 0 2.091-.925 1.744-2.332l-.578-2.394c-.614-.24-1.95-.374-3.071-.374H5.64l1.735 3.498c.507 1.024 1.06 1.602 2.19 1.602z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMegaphoneSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
