import * as React from "react";

function SvgMicrophoneSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 15.75A3.75 3.75 0 0015.75 12V5.75a3.75 3.75 0 10-7.5 0V12A3.75 3.75 0 0012 15.75zm6.25-6.25h-.625a.625.625 0 00-.625.625V12a5.006 5.006 0 01-5.5 4.976C8.903 16.722 7 14.387 7 11.777v-1.652a.625.625 0 00-.625-.625H5.75a.625.625 0 00-.625.625v1.569c0 3.501 2.499 6.623 5.938 7.097v1.334H8.874a.625.625 0 00-.625.625v.625c0 .345.28.625.625.625h6.25c.345 0 .625-.28.625-.625v-.625a.625.625 0 00-.625-.625h-2.188v-1.32c3.348-.459 5.938-3.332 5.938-6.805v-1.875a.625.625 0 00-.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMicrophoneSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
