import * as React from "react";

function SvgHandPointRightSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 9.799c0 .923-.807 1.712-1.75 1.712h-3.9c.638.666.716 1.944-.247 2.771.559.892.084 2.071-.642 2.434.35 1.92-.857 2.784-2.836 2.784-.107 0-.519-.008-.625-.008-2.42.007-3.004-1.213-4.833-1.496a.936.936 0 01-.792-.926v-6.7c0-.718.414-1.402 1.112-1.713 1.128-.508 3.727-1.916 4.2-3.02.305-.711.836-1.137 1.563-1.137 1.337 0 2.256 1.371 1.723 2.614a5.31 5.31 0 01-.553.973h5.83c.916 0 1.75.802 1.75 1.712zm-16.25.014v7.5c0 .517-.42.937-.938.937H2.938A.937.937 0 012 17.312v-7.5c0-.517.42-.937.938-.937h1.874c.518 0 .938.42.938.938zm-1.094 6.562a.781.781 0 10-1.562 0 .781.781 0 001.562 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHandPointRightSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
