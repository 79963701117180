import * as React from "react";

function SvgCaretSquareDownSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 4.143v15.714C22 21.04 21.04 22 19.857 22H4.143A2.143 2.143 0 012 19.857V4.143C2 2.96 2.96 2 4.143 2h15.714C21.04 2 22 2.96 22 4.143zM6.13 10.415l5.49 5.491c.21.21.55.21.76 0l5.49-5.49a.536.536 0 00-.379-.916H6.51a.536.536 0 00-.38.915z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCaretSquareDownSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
