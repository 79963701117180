import * as React from "react";

function SvgJediSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.116 15.53c-1.568 3.462-5.06 5.882-9.117 5.882-.157 0-.315-.003-.473-.01-3.822-.176-7.127-2.555-8.644-5.873H4.35l-2.155-2.154a9.739 9.739 0 01-.188-1.45 10.06 10.06 0 01.019-1.102h1.736L2.24 9.303a9.92 9.92 0 013.97-5.96.59.59 0 01.837.157.59.59 0 01.06.555c-.24.6-.362 1.239-.362 1.884 0 1.659.774 3.183 2.122 4.182a.6.6 0 01.044.933c-.976.882-1.513 2.078-1.513 3.367 0 2.207 1.579 4.055 3.672 4.483l.092-2.4-.998.68a.307.307 0 01-.368-.028.295.295 0 01-.051-.365l.74-1.241-1.547-.322a.294.294 0 010-.575l1.547-.32-.74-1.24a.294.294 0 01.418-.395l1.117.76.424-10.588a.293.293 0 01.294-.282H12a.295.295 0 01.292.283l.424 10.586 1.117-.76a.298.298 0 01.368.03.294.294 0 01.051.364l-.74 1.241 1.547.321a.294.294 0 010 .576l-1.547.32.739 1.24a.293.293 0 01-.049.366.304.304 0 01-.369.029l-.999-.679.092 2.402a4.569 4.569 0 003.578-3.547 4.541 4.541 0 00-1.418-4.305.599.599 0 01.044-.935 5.165 5.165 0 001.759-6.068.588.588 0 01.06-.555.602.602 0 01.495-.266c.122 0 .241.04.34.11a9.973 9.973 0 013.968 5.966l-1.517 1.516h1.727c.013.213.038.422.038.635a9.93 9.93 0 01-.188 1.907l-2.166 2.165h1.47z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgJediSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
