import * as React from "react";

function SvgMapPinSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.75 14.38v6.121l.86 1.29a.469.469 0 00.78 0l.86-1.29v-6.12c-.406.075-.823.12-1.25.12-.427 0-.844-.045-1.25-.12zM12 2a5.625 5.625 0 100 11.25A5.625 5.625 0 0012 2zm0 2.969a2.66 2.66 0 00-2.656 2.656.47.47 0 01-.938 0A3.598 3.598 0 0112 4.031a.47.47 0 010 .938z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMapPinSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
