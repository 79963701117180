import * as React from "react";

function SvgUserTagSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.707 15.404l-2.822-2.82a2.001 2.001 0 00-1.415-.583H14.99c-.553 0-1 .446-1 1v2.475c0 .531.21 1.037.585 1.412l2.822 2.82c.39.39 1.025.39 1.416 0l2.89-2.892a.995.995 0 00.003-1.412zm-5.712-.656a.748.748 0 01-.75-.75c0-.416.334-.75.75-.75a.75.75 0 010 1.5zM9 11.998a4 4 0 10.001-8 4 4 0 000 8zm3.994 3.475v-2.285a4.205 4.205 0 00-1.193-.194h-.522a5.447 5.447 0 01-4.557 0H6.2A4.206 4.206 0 002 17.198v1.3a1.5 1.5 0 001.5 1.5h11c.485 0 .91-.235 1.185-.59l-1.812-1.814a2.98 2.98 0 01-.879-2.122z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgUserTagSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
