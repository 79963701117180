import * as React from "react";

function SvgMicrophoneSlashSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.807 18.316l-4.931-3.812A5.37 5.37 0 0017.5 12v-1.5a.5.5 0 00-.5-.5h-.5a.5.5 0 00-.5.5V12c0 .56-.124 1.087-.335 1.569l-.83-.642c.097-.293.165-.6.165-.927V7a3 3 0 10-6 0v1.417L3.42 4.105a.5.5 0 00-.701.088l-.614.79a.5.5 0 00.088.701l18.386 14.21a.5.5 0 00.702-.087l.614-.79a.5.5 0 00-.088-.701zM14.5 18.5h-1.75v-1.055c.364-.05.714-.142 1.052-.26l-1.566-1.21c-.21.012-.419.027-.636.006-1.745-.17-3.085-1.52-3.474-3.183L6.5 11.541v.214c0 2.801 1.999 5.298 4.75 5.678V18.5H9.5a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V19a.5.5 0 00-.5-.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMicrophoneSlashSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
