import * as React from "react";

function SvgSaveSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.372 6.372l-3.744-3.744A2.143 2.143 0 0016.112 2H4.142C2.96 2 2 2.96 2 4.143v15.714C2 21.041 2.96 22 4.143 22h15.714C21.041 22 22 21.04 22 19.857V7.887c0-.568-.226-1.113-.628-1.515zM12 19.142a2.857 2.857 0 110-5.713 2.857 2.857 0 010 5.714zm4.286-13.594v4.488c0 .296-.24.535-.536.535H5.393a.536.536 0 01-.536-.535V5.393c0-.296.24-.536.536-.536h10.202c.142 0 .278.057.378.157l.156.155a.536.536 0 01.157.38z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSaveSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
