import * as React from "react";

function SvgItalicSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.143 2.714v1.429a.714.714 0 01-.714.714h-2.802l-3.572 14.286h2.088a.714.714 0 01.714.714v1.429a.715.715 0 01-.714.714H5.57a.714.714 0 01-.714-.714v-1.429a.714.714 0 01.714-.714h2.802l3.572-14.286H9.857a.714.714 0 01-.714-.714V2.714A.714.714 0 019.857 2h8.572a.714.714 0 01.714.714z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgItalicSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
