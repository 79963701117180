import * as React from "react";

function SvgChildSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.187 4.813a2.812 2.812 0 115.625 0 2.812 2.812 0 01-5.625 0zm9.947.053a1.25 1.25 0 00-1.768 0L13.982 8.25h-3.964L6.634 4.866a1.25 1.25 0 10-1.768 1.768l3.696 3.696v10.42c0 .69.56 1.25 1.25 1.25h.626c.69 0 1.25-.56 1.25-1.25v-4.375h.624v4.375c0 .69.56 1.25 1.25 1.25h.626c.69 0 1.25-.56 1.25-1.25V10.33l3.696-3.696a1.25 1.25 0 000-1.768z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChildSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
