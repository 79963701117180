import * as React from "react";

function SvgThSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.833 4.188v3.125c0 .517-.42.937-.937.937H2.938A.937.937 0 012 7.312V4.188c0-.517.42-.937.938-.937h3.958c.518 0 .937.42.937.938zm7.084 9.375v-3.126a.937.937 0 00-.938-.937h-3.958a.937.937 0 00-.938.938v3.124c0 .518.42.938.938.938h3.958c.518 0 .938-.42.938-.938zm1.25-9.376v3.125c0 .518.42.938.937.938h3.959c.517 0 .937-.42.937-.938V4.188a.937.937 0 00-.938-.937h-3.958a.937.937 0 00-.937.938zm-1.25 3.125V4.188a.937.937 0 00-.938-.937h-3.958a.937.937 0 00-.938.938v3.125c0 .517.42.937.938.937h3.958c.518 0 .938-.42.938-.938zM6.896 9.5H2.938a.937.937 0 00-.938.938v3.124c0 .518.42.938.938.938h3.958c.518 0 .937-.42.937-.938v-3.124a.937.937 0 00-.937-.938zM2 16.688v3.125c0 .517.42.937.938.937h3.958c.518 0 .937-.42.937-.938v-3.125a.937.937 0 00-.937-.937H2.938a.937.937 0 00-.938.938zM17.104 14.5h3.959c.517 0 .937-.42.937-.938v-3.124a.937.937 0 00-.938-.938h-3.958a.937.937 0 00-.937.938v3.124c0 .518.42.938.937.938zm0 6.25h3.959c.517 0 .937-.42.937-.938v-3.125a.937.937 0 00-.938-.937h-3.958a.937.937 0 00-.937.938v3.125c0 .517.42.937.937.937zm-8.02-4.063v3.125c0 .518.42.938.937.938h3.958c.518 0 .938-.42.938-.938v-3.125a.937.937 0 00-.938-.937h-3.958a.937.937 0 00-.938.938z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgThSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
