import * as React from "react";

function SvgChessRookSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.333 2H17a.667.667 0 00-.667.667v2h-2v-2A.667.667 0 0013.667 2h-3.334a.667.667 0 00-.666.667v2H7.67v-2A.667.667 0 007.004 2H4.667A.667.667 0 004 2.667V10l2.667 1.333c0 2.014-.064 3.959-.55 6.667h11.767c-.486-2.708-.55-4.678-.55-6.667L20 10V2.667A.666.666 0 0019.333 2zm-6 12h-2.666v-2.667a1.333 1.333 0 112.666 0V14zm6 5.333H4.667A.667.667 0 004 20v1.333a.667.667 0 00.667.667h14.666a.666.666 0 00.667-.667V20a.666.666 0 00-.667-.667z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChessRookSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
