import * as React from "react";

function SvgDumbbellSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M5.25 7h-1.5a.748.748 0 00-.75.75V11h-.75a.25.25 0 00-.25.25v1.5c0 .137.112.25.25.25H3v3.25c0 .416.334.75.75.75h1.5c.416 0 .75-.334.75-.75v-8.5A.748.748 0 005.25 7zm16.5 4H21V7.75a.748.748 0 00-.75-.75h-1.5a.748.748 0 00-.75.75v8.5c0 .416.334.75.75.75h1.5c.416 0 .75-.334.75-.75V13h.75a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25zm-5.5-6h-1.5a.748.748 0 00-.75.75V11h-4V5.75A.748.748 0 009.25 5h-1.5a.748.748 0 00-.75.75v12.5c0 .416.334.75.75.75h1.5c.416 0 .75-.334.75-.75V13h4v5.25c0 .416.334.75.75.75h1.5c.416 0 .75-.334.75-.75V5.75a.748.748 0 00-.75-.75z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDumbbellSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
