import * as React from "react";

function SvgOnlinetestSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.923 17H21.5c.275 0 .5.225.5.5v.5c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2v-.5c0-.275.225-.5.5-.5h7.476c-.008.454.44 1 1.024 1h1.9c.563 0 1-.38 1.023-1zM18.5 4c.825 0 1.5.675 1.5 1.5V16H4V5.5C4 4.675 4.675 4 5.5 4h13zm-7.197 9.113l4.582-4.544a.385.385 0 00.004-.544l-.904-.91a.385.385 0 00-.545-.004l-3.396 3.372-1.475-1.487a.385.385 0 00-.544-.004l-.91.904a.385.385 0 00-.004.545l2.647 2.67c.15.15.394.153.545.002z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgOnlinetestSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
