import * as React from "react";

function SvgStarAndCrescentSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.3 20.217c-.057 0-.27.018-.359.018-4.541 0-8.235-3.694-8.235-8.235 0-4.541 3.694-8.235 8.235-8.235.09 0 .3.018.359.018a.588.588 0 00.206-1.14A9.954 9.954 0 0012 2C6.486 2 2 6.486 2 12s4.486 10 10 10c1.18 0 2.355-.215 3.49-.637a.59.59 0 00.4-.558.586.586 0 00-.59-.588zm6.366-9.863l-2.983-.434-1.334-2.703a.385.385 0 00-.35-.217.385.385 0 00-.348.217L15.316 9.92l-2.983.434a.39.39 0 00-.216.664l2.16 2.104-.51 2.971a.39.39 0 00.564.41L17 15.1l2.668 1.403a.39.39 0 00.564-.41l-.509-2.971 2.16-2.104a.39.39 0 00-.217-.664z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgStarAndCrescentSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
