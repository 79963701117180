import * as React from "react";

function SvgTrailerSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.5 13.5H19V6a.5.5 0 00-.5-.5h-16A.5.5 0 002 6v9a.5.5 0 00.5.5h1.55a3.49 3.49 0 013.45-3 3.49 3.49 0 013.45 3H21.5a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zM5 11.115a5.514 5.514 0 00-1 .647V7.75a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v3.365zm3-.58c-.166-.015-.33-.035-.5-.035-.17 0-.334.02-.5.036V7.75a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v2.786zm3 1.227a5.516 5.516 0 00-1-.647V7.75a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v4.012zm3 1.738h-1V7.75a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v5.75zm3 0h-1V7.75a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v5.75zm-9.5 0a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm0 3.5a1 1 0 110-2 1 1 0 010 2z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTrailerSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
