import * as React from "react";

function SvgMotorcycleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.026 10a3.954 3.954 0 00-1.325.216L15.673 8.5h2.574c.416 0 .75-.335.75-.75v-1a.748.748 0 00-.75-.75h-1.415a.75.75 0 00-.556.247l-1.171 1.303-.713-1.187A.755.755 0 0013.75 6h-2.5c-.275 0-.5.225-.5.5v.5c0 .275.225.5.5.5h2.075l.6 1H9.12c-.553-.722-1.402-1.25-3.12-1.25H4.265a.76.76 0 00-.765.765c.006.407.34.735.75.735H6c.765 0 1.208.34 1.493.774l-.353.64a4 4 0 00-1.29-.162A4.01 4.01 0 002 13.906a4 4 0 007.873 1.093h2.631a.75.75 0 00.75-.784 4.732 4.732 0 011.756-3.905l.39.65a3.994 3.994 0 00-1.4 3.068 4.01 4.01 0 003.962 3.97A3.999 3.999 0 0022 13.966 4.017 4.017 0 0018.026 10zM6 16.5a2.503 2.503 0 01-2.5-2.5 2.503 2.503 0 012.89-2.468l-1.296 2.356a.75.75 0 00.656 1.112h2.54A2.506 2.506 0 016 16.5zm14.494-2.362a2.504 2.504 0 01-2.359 2.359 2.502 2.502 0 01-2.637-2.497c0-.668.263-1.274.69-1.721l1.544 2.574c.14.238.45.313.687.172l.428-.256a.502.502 0 00.172-.687l-1.518-2.528a2.5 2.5 0 012.993 2.584z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMotorcycleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
