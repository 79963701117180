import * as React from "react";

function SvgHandPointLeftOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 10.625c0 1.782 1.475 3.25 3.25 3.25h1.392c.067.3.171.589.31.862-.098.86.137 1.757.625 2.453.044 2.05 1.46 3.56 3.903 3.56h.832c2.347 0 3.849-1.563 4.97-1.563h.104c.224.194.529.313.864.313h2.5c.69 0 1.25-.504 1.25-1.125v-9c0-.621-.56-1.125-1.25-1.125h-2.5c-.335 0-.64.12-.864.313h-.105c-.272 0-.58-.242-1.179-.925l-.005-.006-.005-.006c-.346-.388-.708-.814-1.01-1.298-.73-1.182-1.187-3.078-2.957-3.078-2.224 0-3.594 1.378-3.594 3.25 0 .314.032.605.085.875H5.25C3.488 7.375 2 8.863 2 10.625zm1.875 0c0-.73.655-1.375 1.375-1.375h6.188c0-.677-1.032-1.375-1.032-2.75 0-1.031.806-1.375 1.719-1.375.344 0 .799 1.278 1.364 2.191.355.57.763 1.064 1.203 1.558.63.718 1.322 1.431 2.308 1.547v6.904c-1.671.147-2.91 1.55-4.688 1.55h-.832c-1.582 0-2.23-.867-1.976-2.395-.57-.313-.943-1.328-.505-2.095-.756-.712-.695-1.812-.193-2.385H5.25a1.399 1.399 0 01-1.375-1.375zM19.5 16.063a.938.938 0 110 1.875.938.938 0 010-1.875z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHandPointLeftOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
