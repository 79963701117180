import * as React from "react";

function SvgHandPaperOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.803 6.4v-.423c0-1.703-1.582-2.996-3.243-2.56-1.001-1.934-3.676-1.854-4.609.029-1.612-.413-3.219.817-3.219 2.538v4.927a2.558 2.558 0 00-2.425.347 2.635 2.635 0 00-.568 3.639l4.686 6.578A1.25 1.25 0 009.443 22h8.707a1.25 1.25 0 001.218-.97l1.179-5.115a7.95 7.95 0 00.203-1.784V8.992c0-1.587-1.388-2.812-2.947-2.592zm1.072 7.731c0 .459-.052.917-.155 1.363l-1.067 4.631H9.765l-4.5-6.316c-.564-.792.587-1.671 1.148-.883l1.06 1.487c.352.493 1.134.246 1.134-.363V5.984c0-1.001 1.429-.969 1.429.027V12c0 .345.28.625.625.625h.268c.345 0 .624-.28.624-.625V4.617c0-1.002 1.43-.969 1.43.027V12c0 .345.279.625.624.625h.268c.345 0 .625-.28.625-.625V5.95c0-1.003 1.428-.969 1.428.027V12c0 .345.28.625.625.625h.268c.345 0 .625-.28.625-.625V9.02c0-1.026 1.429-1.002 1.429-.028v5.14z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHandPaperOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
