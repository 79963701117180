import * as React from "react";

function SvgSkullCrossbonesSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.404 19.697L14.858 17l5.546-2.698a.625.625 0 00.28-.838l-.56-1.118a.625.625 0 00-.838-.28L12 15.61l-7.286-3.544a.625.625 0 00-.839.28l-.559 1.118a.625.625 0 00.28.838L9.141 17l-5.546 2.697a.625.625 0 00-.28.84l.56 1.117c.154.309.53.434.838.28L12 18.39l7.286 3.544c.309.154.684.029.839-.28l.559-1.118a.625.625 0 00-.28-.839zM9.11 11.27l-.214 1.01c-.104.493.212.97.643.97h4.925c.43 0 .747-.477.642-.97l-.215-1.01c1.633-.876 2.735-2.452 2.735-4.27C17.625 4.24 15.107 2 12 2S6.375 4.239 6.375 7c0 1.817 1.102 3.393 2.734 4.269zm5.078-4.894a1.251 1.251 0 010 2.5 1.251 1.251 0 010-2.5zm-4.375 0a1.251 1.251 0 010 2.5 1.251 1.251 0 010-2.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSkullCrossbonesSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
