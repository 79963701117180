import * as React from "react";

function SvgChalkboardSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <rect x={2} y={18} width={20} height={2} rx={1} fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4a1 1 0 00-1 1v11a1 1 0 001 1h10v-2.5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5V17h1a1 1 0 001-1V5a1 1 0 00-1-1H4z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChalkboardSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
