import * as React from "react";

function SvgChevronDownOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.006 17.347c.326 0 .628-.145.858-.386l8.798-9.003c.217-.218.338-.496.338-.81C22 6.495 21.505 6 20.852 6c-.302 0-.604.12-.822.338l-8.024 8.218L3.97 6.338A1.13 1.13 0 003.148 6C2.508 6 2 6.495 2 7.148c0 .314.12.592.338.81l8.798 9.003c.254.254.532.386.87.386z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChevronDownOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
