import * as React from "react";

function SvgInboxSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.72 11.58l-3.67-5.505a1.667 1.667 0 00-1.386-.742H7.336a1.667 1.667 0 00-1.386.742L2.28 11.58c-.183.274-.28.596-.28.925V17c0 .92.746 1.667 1.667 1.667h16.666c.92 0 1.667-.747 1.667-1.667v-4.495c0-.33-.097-.651-.28-.925zM7.634 7.556h8.732L19.33 12h-4.273l-1.112 2.222h-3.888L8.944 12H4.671l2.963-4.444z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgInboxSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
