import * as React from "react";

function SvgEnvelopeOpenOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.32 8.426c-.184-.151-4.364-3.513-5.284-4.244C15.174 3.492 13.697 2 12 2c-1.688 0-3.15 1.473-4.037 2.182-.955.76-5.12 4.11-5.279 4.24A1.875 1.875 0 002 9.871v10.254C2 21.16 2.84 22 3.875 22h16.25C21.16 22 22 21.16 22 20.125V9.871a1.875 1.875 0 00-.68-1.445zm-1.195 11.465a.235.235 0 01-.234.234H4.109a.235.235 0 01-.234-.234V9.982c0-.07.032-.137.087-.182.62-.5 4.25-3.42 5.17-4.152.71-.567 1.946-1.773 2.868-1.773.925 0 2.182 1.225 2.868 1.773.92.732 4.55 3.652 5.17 4.153a.233.233 0 01.087.182v9.908zm-1.25-7.333c.166.202.136.5-.068.664-1.132.91-2.315 1.859-2.77 2.221-.887.709-2.35 2.182-4.037 2.182-1.697 0-3.175-1.494-4.037-2.182-.44-.35-1.63-1.305-2.77-2.221a.469.469 0 01-.068-.664l.596-.723a.469.469 0 01.655-.068c1.118.899 2.288 1.838 2.756 2.21.686.548 1.943 1.773 2.868 1.773.922 0 2.158-1.206 2.868-1.773.468-.373 1.638-1.311 2.756-2.21.2-.16.492-.13.655.068l.596.723z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgEnvelopeOpenOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
