import * as React from "react";

function SvgBusSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.063 7h-.313V5.125C20.75 3.375 16.875 2 12 2S3.25 3.375 3.25 5.125V7h-.313A.937.937 0 002 7.938v3.125c0 .517.42.937.938.937h.312v6.25c0 .69.56 1.25 1.25 1.25v1.25c0 .69.56 1.25 1.25 1.25H7c.69 0 1.25-.56 1.25-1.25V19.5h7.5v1.25c0 .69.56 1.25 1.25 1.25h1.25c.69 0 1.25-.56 1.25-1.25V19.5h.25c.625 0 1-.5 1-1V12h.313c.517 0 .937-.42.937-.938V7.938A.937.937 0 0021.062 7zM6.374 17.625a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zM7 13.25c-.69 0-1.25-.56-1.25-1.25V7c0-.69.56-1.25 1.25-1.25h10c.69 0 1.25.56 1.25 1.25v5c0 .69-.56 1.25-1.25 1.25H7zm10.625 4.375a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBusSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
