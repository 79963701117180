import * as React from "react";

function SvgGenderlessSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 6.444A5.563 5.563 0 0117.556 12 5.563 5.563 0 0112 17.556 5.563 5.563 0 016.444 12 5.563 5.563 0 0112 6.444zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGenderlessSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
