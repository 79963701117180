import * as React from "react";

function SvgTrashRestoreAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M4.5 20.125A1.875 1.875 0 006.375 22h11.25a1.875 1.875 0 001.875-1.875V7h-15v13.125zm3.567-6.75l3.491-3.682a.603.603 0 01.884 0l3.491 3.682c.394.415.115 1.125-.442 1.125H13.25v4.375a.625.625 0 01-.625.625h-1.25a.624.624 0 01-.625-.625V14.5H8.509c-.557 0-.836-.71-.442-1.125zM20.125 3.25h-4.688l-.367-.73a.938.938 0 00-.84-.52H9.766a.927.927 0 00-.836.52l-.367.73H3.875a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h16.25a.625.625 0 00.625-.625v-1.25a.625.625 0 00-.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTrashRestoreAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
