import * as React from "react";

function SvgGlobeAmericasSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm3.318 14.42l-.456.454a.964.964 0 00-.249.432c-.06.228-.11.459-.192.68l-.701 1.89c-.555.12-1.13.188-1.72.188V18.96c.068-.508-.308-1.462-.912-2.066a1.29 1.29 0 01-.378-.912V14.69c0-.47-.253-.901-.664-1.128a66.54 66.54 0 00-1.968-1.053 5.817 5.817 0 01-1.276-.877l-.033-.029a4.624 4.624 0 01-.728-.836c-.378-.556-.994-1.469-1.395-2.062a8.108 8.108 0 014.162-4.109l.968.484a.645.645 0 00.934-.577V4.05c.322-.052.65-.085.983-.098l1.141 1.141a.645.645 0 010 .913l-.189.189-.417.416a.323.323 0 000 .457l.19.189a.323.323 0 010 .456l-.323.322a.323.323 0 01-.229.095h-.362a.323.323 0 00-.225.091l-.4.39a.323.323 0 00-.064.375l.629 1.257a.323.323 0 01-.288.467h-.228a.322.322 0 01-.211-.079l-.374-.325a.646.646 0 00-.627-.125l-1.257.42a.482.482 0 00-.063.888l.447.223c.38.19.798.289 1.222.289.424 0 .91 1.1 1.29 1.29h2.692c.342 0 .67.136.912.378l.552.552c.23.23.36.543.36.87a1.875 1.875 0 01-.553 1.33zm3.496-3.684a.968.968 0 01-.57-.402l-.725-1.088a.967.967 0 010-1.073l.79-1.185a.966.966 0 01.372-.328l.524-.262a8 8 0 01.86 3.602c0 .35-.03.692-.074 1.03l-1.177-.294z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGlobeAmericasSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
