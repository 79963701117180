import * as React from "react";

function SvgMoonOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.904 22a9.984 9.984 0 007.767-3.702c1.104-1.36-.1-3.349-1.806-3.024-3.217.613-6.183-1.846-6.183-5.109a5.2 5.2 0 012.634-4.525c1.514-.86 1.133-3.155-.587-3.473A10.075 10.075 0 0012.904 2c-5.522 0-10 4.476-10 10 0 5.522 4.475 10 10 10zm0-18.125c.507 0 1.003.047 1.485.136a7.074 7.074 0 00-3.582 6.154c0 4.448 4.048 7.782 8.408 6.951a8.125 8.125 0 11-6.311-13.241z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMoonOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
