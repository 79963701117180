import * as React from "react";

function SvgPencilRulerSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6.276 11.533l5.257-5.256-1.724-1.724L7.4 6.963a.31.31 0 01-.438 0l-.438-.438a.31.31 0 010-.438l2.41-2.41L7.62 2.364a1.239 1.239 0 00-1.753 0L2.363 5.867a1.24 1.24 0 000 1.753l3.913 3.913zM21.45 6.971a1.876 1.876 0 000-2.654L19.683 2.55a1.877 1.877 0 00-2.654 0L15.23 4.347l4.422 4.422L21.45 6.97zm-7.103-1.74l-11.601 11.6-.733 4.197a.83.83 0 00.96.96l4.197-.737L18.77 9.653 14.347 5.23zm7.29 11.15l-1.314-1.315-2.41 2.41a.31.31 0 01-.437 0l-.438-.438a.31.31 0 010-.438l2.41-2.41-1.725-1.723-5.257 5.256 3.914 3.914a1.239 1.239 0 001.753 0l3.504-3.504a1.239 1.239 0 000-1.752z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPencilRulerSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
