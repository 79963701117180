import * as React from "react";

function SvgUserCircleOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 5.871a3.872 3.872 0 000 7.742 3.872 3.872 0 000-7.742zm0 5.806a1.936 1.936 0 11.001-3.872A1.936 1.936 0 0112 11.677zM12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 18.064a8.019 8.019 0 01-5.246-1.951A3.474 3.474 0 019.56 16.52a8.206 8.206 0 002.44.387c.802 0 1.6-.125 2.44-.387a3.484 3.484 0 012.806 1.593A8.019 8.019 0 0112 20.064zm6.56-3.39c-.983-1.267-2.503-2.093-4.237-2.093-.412 0-1.049.387-2.323.387-1.27 0-1.911-.387-2.323-.387-1.73 0-3.25.826-4.237 2.092A8.007 8.007 0 013.935 12c0-4.448 3.617-8.065 8.065-8.065 4.448 0 8.064 3.617 8.064 8.065 0 1.742-.56 3.355-1.503 4.673z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgUserCircleOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
