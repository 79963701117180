import * as React from "react";

function SvgAddressCardSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.333 4.222H3.667C2.747 4.222 2 4.97 2 5.89V18.11c0 .92.747 1.667 1.667 1.667h16.666c.92 0 1.667-.747 1.667-1.667V5.89c0-.92-.747-1.667-1.667-1.667zM8.111 7.555c1.226 0 2.222.997 2.222 2.223A2.224 2.224 0 018.111 12 2.224 2.224 0 015.89 9.778c0-1.226.996-2.223 2.222-2.223zM12 15.779c0 .368-.347.666-.778.666H5c-.43 0-.778-.298-.778-.666v-.667c0-1.104 1.045-2 2.334-2h.173a3.585 3.585 0 002.764 0h.174c1.288 0 2.333.896 2.333 2v.667zm7.778-1.834a.279.279 0 01-.278.278h-5a.279.279 0 01-.278-.278v-.555c0-.153.125-.278.278-.278h5c.153 0 .278.125.278.278v.555zm0-2.222A.279.279 0 0119.5 12h-5a.279.279 0 01-.278-.278v-.555c0-.153.125-.278.278-.278h5c.153 0 .278.125.278.278v.555zm0-2.222a.279.279 0 01-.278.278h-5a.279.279 0 01-.278-.278v-.556c0-.152.125-.277.278-.277h5c.153 0 .278.125.278.277V9.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAddressCardSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
