import * as React from "react";

function SvgFlagCheckeredSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.346 9.42v2.659c1.02.23 1.925.61 2.874.871v-2.664c-1.015-.226-1.929-.605-2.874-.867zm8.722-4.805c-1.34.62-2.988 1.246-4.57 1.246-2.09 0-3.82-1.36-6.453-1.36-.976 0-1.847.172-2.656.47.11-.286.16-.594.14-.922-.07-1.11-.98-2-2.093-2.047a2.188 2.188 0 00-1.34 3.98v15.08c0 .52.418.938.938.938h.625c.52 0 .937-.418.937-.937v-3.688c1.106-.472 2.484-.863 4.469-.863 2.093 0 3.82 1.36 6.452 1.36 1.883 0 3.387-.637 4.785-1.598.34-.234.539-.617.539-1.031V5.748a1.248 1.248 0 00-1.773-1.133zM8.47 14.715c-1.008.106-1.953.32-2.875.649V12.61c1.024-.363 1.856-.586 2.875-.68v2.786zM19.97 9.462c-.922.383-1.808.762-2.875.934v2.777c.969-.133 2.008-.461 2.875-1.016v2.754c-.98.629-1.894.965-2.875 1.058v-2.796c-1.054.144-1.87.058-2.874-.219v2.632c-.934-.289-1.848-.652-2.875-.831v-2.676c-.77-.172-1.594-.266-2.875-.148V9.197c-.875.12-1.742.398-2.875.816V7.259c1.297-.476 1.957-.773 2.875-.86v2.798c1.055-.145 1.89-.051 2.875.222V6.787c.925.289 1.843.652 2.874.832v2.671c.926.207 1.86.27 2.875.106V7.587c1.055-.187 2.043-.53 2.875-.879v2.754z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFlagCheckeredSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
