import * as React from "react";

function SvgWaterSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.52 16.442c-.746-.084-1.461-.365-2.01-.796a1.346 1.346 0 00-1.674 0c-1.316 1.056-3.723 1.056-5.06-.052-.469-.389-1.146-.316-1.622.063-1.32 1.045-3.712 1.042-5.042-.06-.469-.388-1.157-.308-1.636.07-.538.424-1.25.698-2.004.778A.54.54 0 002 16.99v1.119c0 .316.264.583.58.555a6.24 6.24 0 002.757-.9c1.963 1.202 4.758 1.185 6.668 0 1.962 1.202 4.758 1.185 6.668 0a6.42 6.42 0 002.747.897c.316.027.58-.24.58-.556v-1.097a.558.558 0 00-.48-.566zm0-5.001c-.746-.084-1.461-.365-2.01-.796a1.346 1.346 0 00-1.674 0c-1.316 1.056-3.723 1.056-5.06-.052-.469-.389-1.146-.316-1.622.063-1.32 1.045-3.712 1.042-5.042-.06-.469-.388-1.157-.308-1.636.07-.538.424-1.25.698-2.004.778A.54.54 0 002 11.99v1.119c0 .316.264.583.58.555 1-.087 1.948-.396 2.757-.9 1.963 1.203 4.758 1.185 6.668 0 1.962 1.203 4.758 1.185 6.668 0a6.42 6.42 0 002.747.897c.316.028.58-.24.58-.556v-1.097a.558.558 0 00-.48-.566zm0-5.001c-.746-.087-1.461-.365-2.01-.795a1.346 1.346 0 00-1.674 0C16.52 6.7 14.113 6.7 12.776 5.593c-.469-.39-1.146-.316-1.622.062-1.32 1.045-3.712 1.042-5.042-.059-.469-.389-1.157-.31-1.636.07-.538.423-1.25.697-2.004.777A.54.54 0 002 6.99v1.118c0 .316.264.583.58.555 1-.086 1.948-.395 2.757-.899 1.963 1.202 4.758 1.184 6.668 0 1.962 1.202 4.758 1.184 6.668 0a6.42 6.42 0 002.747.896c.316.028.58-.24.58-.556V7.006a.558.558 0 00-.48-.566z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgWaterSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
