import * as React from "react";

function SvgChessSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M4.89 10.125H4.5a.625.625 0 00-.625.625v.625A.625.625 0 004.5 12h.623a20.929 20.929 0 01-.623 5h5a20.929 20.929 0 01-.623-5H9.5a.625.625 0 00.625-.625v-.625a.625.625 0 00-.625-.625h-.39l1.323-3.53a.625.625 0 00-.586-.845H7.625V4.5h.938a.312.312 0 00.312-.313v-.625a.312.312 0 00-.313-.312h-.937v-.938A.312.312 0 007.312 2h-.625a.312.312 0 00-.312.313v.937h-.938a.312.312 0 00-.312.313v.624a.312.312 0 00.313.313h.937v1.25H4.152a.625.625 0 00-.586.845l1.325 3.53zm6.765 9.827l-.905-.452v-.625a.624.624 0 00-.625-.625h-6.25a.625.625 0 00-.625.625v.625l-.904.452a.625.625 0 00-.346.56v.863a.625.625 0 00.625.625h8.75a.624.624 0 00.625-.625v-.864a.625.625 0 00-.345-.559zm3.623-6.163L15.15 17h4.95l-.129-3.211.961-.812a1.25 1.25 0 00.443-.956V9.734a.235.235 0 00-.234-.234h-1.03a.235.235 0 00-.235.234v1.016h-.966V9.734a.234.234 0 00-.234-.234h-2.102a.234.234 0 00-.234.234v1.016h-.965V9.734a.235.235 0 00-.235-.234h-1.03a.235.235 0 00-.235.234v2.29a1.25 1.25 0 00.444.953l.96.812zm1.722.086a.624.624 0 111.25 0v1.25H17v-1.25zm4.655 6.077l-.905-.452v-.625a.624.624 0 00-.625-.625h-5a.624.624 0 00-.625.625v.625l-.904.452a.626.626 0 00-.346.56v.863a.624.624 0 00.625.625h7.5a.624.624 0 00.625-.625v-.864a.625.625 0 00-.345-.559z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChessSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
