import * as React from "react";

function SvgPenAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.45 4.898L19.104 2.55a1.876 1.876 0 00-2.653 0l-2.209 2.208 5 5.001 2.21-2.209a1.876 1.876 0 000-2.652zm-9.64-.802c-.61-.61-1.599-.61-2.209 0l-4.64 4.64a.625.625 0 000 .883l.884.884c.244.244.64.244.884 0l3.977-3.977.884.884-5.92 5.92a12.775 12.775 0 00-3.663 7.62l-.001.008a.938.938 0 001.035 1.036 12.774 12.774 0 007.63-3.664l7.687-7.687-3.233-3.233-3.315-3.314z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPenAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
