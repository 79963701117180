import * as React from "react";

function SvgChevronLeftOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6 12c0 .395.147.733.462 1.026l8.771 8.59c.248.249.564.384.936.384a1.33 1.33 0 001.342-1.342c0-.372-.158-.699-.406-.958L9.202 12l7.903-7.7c.248-.26.406-.598.406-.958A1.33 1.33 0 0016.169 2c-.372 0-.688.135-.936.383l-8.77 8.58C6.146 11.267 6 11.605 6 12z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChevronLeftOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
