import * as React from "react";

function SvgIntroductionSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.333 4H3.667C2.747 4 2 4.747 2 5.667v12.222c0 .92.747 1.667 1.667 1.667h16.666c.92 0 1.667-.747 1.667-1.667V5.667C22 4.747 21.253 4 20.333 4zm0 13.778H12.5 12 8.5 3.667v-9.89h16.666v9.89zM14.5 15.11h3.889a.279.279 0 00.278-.278v-.555a.279.279 0 00-.278-.278H14.5a.279.279 0 00-.278.278v.555c0 .153.125.278.278.278zm0-2.222h3.889a.279.279 0 00.278-.278v-.555a.279.279 0 00-.278-.278H14.5a.279.279 0 00-.278.278v.555c0 .153.125.278.278.278zm0-2.222h3.889a.279.279 0 00.278-.278v-.556a.279.279 0 00-.278-.277H14.5a.279.279 0 00-.278.277v.556c0 .153.125.278.278.278z"
        fill="currentColor"
      />
      <path
        d="M10.464 9.5H6.536c-.296 0-.536.288-.536.643v4.571c0 .355.24.643.536.643h3.928c.296 0 .536-.288.536-.643v-4.571c0-.355-.24-.643-.536-.643z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgIntroductionSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
