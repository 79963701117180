import * as React from "react";

function SvgObjectUngroupSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M4.222 14.222v.903a.208.208 0 01-.208.208H2.208A.208.208 0 012 15.125v-1.806a.209.209 0 01.208-.208h.903V6.444h-.903A.208.208 0 012 6.236V4.431a.208.208 0 01.208-.209h1.806a.208.208 0 01.208.209v.902h10v-.902a.209.209 0 01.209-.209h1.805a.208.208 0 01.208.209v1.805a.208.208 0 01-.208.208h-.903v6.667h.903a.208.208 0 01.208.208v1.806a.208.208 0 01-.208.208h-1.805a.208.208 0 01-.209-.208v-.903h-10zM20.89 12v-1.111h.903A.209.209 0 0022 10.68V8.875a.208.208 0 00-.208-.208h-1.806a.208.208 0 00-.208.208v.903h-3.611v2.5h.277c.46 0 .834.373.834.833v2.222c0 .46-.373.834-.834.834h-2.222a.833.833 0 01-.833-.834v-.277H8.667v2.5h-.903a.208.208 0 00-.208.208v1.806a.208.208 0 00.208.208h1.805a.209.209 0 00.209-.208v-.903h10v.902a.208.208 0 00.208.209h1.806A.209.209 0 0022 19.57v-1.806a.208.208 0 00-.208-.208h-.903V12z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgObjectUngroupSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
