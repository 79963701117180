import * as React from "react";

function SvgWrenchSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.833 6.262a.468.468 0 00-.786-.216l-2.904 2.905-2.652-.442-.442-2.651 2.905-2.905a.47.47 0 00-.221-.787 5.622 5.622 0 00-5.335 1.481c-1.549 1.549-1.975 3.793-1.33 5.75l-8.336 8.336a2.5 2.5 0 003.535 3.535l8.329-8.329c1.958.653 4.198.222 5.756-1.336a5.623 5.623 0 001.481-5.341zM4.5 20.437a.938.938 0 110-1.875.938.938 0 010 1.875z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgWrenchSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
