import * as React from "react";

function SvgHandSpockOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.571 6.538c-.757-1.23-2.001-1.396-2.59-1.368-.551-1.985-2.422-2.112-2.763-2.112a2.892 2.892 0 00-2.822 2.301l-.181.885-.535-2.078c-.355-1.382-1.813-2.502-3.502-2.073a2.887 2.887 0 00-2.153 3.084A2.878 2.878 0 004.49 8.865l.952 3.21c-1.494-.294-2.453.633-2.66.854A2.877 2.877 0 002.906 17l3.57 3.359A6.022 6.022 0 0010.617 22h4.197a4.972 4.972 0 004.854-3.834l2.251-9.451a2.86 2.86 0 00-.349-2.177zM20.096 8.28l-2.25 9.452a3.104 3.104 0 01-3.03 2.393h-4.198a4.154 4.154 0 01-2.857-1.133l-3.569-3.358c-.968-.91.41-2.379 1.378-1.465l1.997 1.88c.212.2.527.028.527-.228a9.64 9.64 0 00-.398-2.74L6.29 8.333C5.91 7.05 7.84 6.487 8.22 7.762l1.224 4.132c.218.738 1.32.418 1.131-.313L8.917 5.134c-.333-1.293 1.618-1.783 1.947-.501l1.849 7.189c.173.673 1.14.644 1.278-.032l1.242-6.053c.266-1.294 2.246-.942 1.957.467l-1.115 5.436c-.145.707.925.962 1.096.243l.969-4.068c.311-1.303 2.264-.823 1.956.465z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHandSpockOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
