import * as React from "react";

function SvgWindowMinimizeOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.75 13.25H3.25C2.559 13.25 2 12.691 2 12s.559-1.25 1.25-1.25h17.5c.691 0 1.25.559 1.25 1.25s-.559 1.25-1.25 1.25z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgWindowMinimizeOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
