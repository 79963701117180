import * as React from "react";

function SvgGrinSquintSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm1.363 7.65l3.226-1.936c.468-.279.968.31.62.726l-1.354 1.625 1.355 1.625c.35.419-.158 1-.621.725l-3.226-1.935a.487.487 0 010-.83zM6.79 8.44c-.346-.416.154-1.005.621-.726l3.226 1.935a.484.484 0 010 .83l-3.226 1.936c-.463.275-.967-.306-.62-.725l1.354-1.625L6.79 8.44zM12 19.097c-2.444 0-5.423-1.545-5.798-3.762a.646.646 0 01.834-.722c1.218.391 3.029.613 4.964.613 1.935 0 3.746-.222 4.964-.613.463-.15.911.25.834.722-.375 2.217-3.354 3.762-5.798 3.762z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGrinSquintSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
