import * as React from "react";

function SvgSnowflakeOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.44 15.875l-1.531-.898 1.332-.364a.625.625 0 00.433-.765l-.16-.606a.614.614 0 00-.754-.441l-3.113.847L13.842 12l2.809-1.648 3.113.847c.328.09.664-.11.754-.441l.16-.606a.628.628 0 00-.433-.765l-1.332-.364 1.53-.898a.635.635 0 00.227-.855l-.308-.543a.609.609 0 00-.84-.23l-1.531.898.355-1.356a.628.628 0 00-.433-.766l-.594-.16a.616.616 0 00-.754.442l-.832 3.164-2.809 1.648v-3.3l2.278-2.317a.634.634 0 000-.883l-.434-.441a.608.608 0 00-.867 0l-.973.992V2.625A.62.62 0 0012.315 2h-.613a.62.62 0 00-.614.625v1.8l-.972-.991a.608.608 0 00-.868 0l-.449.441a.634.634 0 000 .883l2.278 2.316v3.301L8.268 8.727l-.832-3.165a.614.614 0 00-.754-.44l-.594.16a.625.625 0 00-.433.765l.355 1.355-1.531-.898a.61.61 0 00-.84.23l-.309.543c-.167.301-.07.68.227.856l1.531.898-1.332.356a.625.625 0 00-.433.765l.16.606a.614.614 0 00.754.441l3.113-.847L10.159 12 7.35 13.648l-3.113-.847a.616.616 0 00-.754.441l-.16.606a.628.628 0 00.433.765l1.332.364-1.531.898a.635.635 0 00-.227.855l.31.543c.167.301.546.403.839.23l1.531-.898-.355 1.356a.628.628 0 00.433.766l.594.16c.328.09.664-.11.754-.442l.832-3.164 2.809-1.648v3.3L8.799 19.25a.634.634 0 000 .883l.434.441a.608.608 0 00.867 0l.973-.992v1.793a.62.62 0 00.613.625h.613a.62.62 0 00.614-.625v-1.8l.972.991a.608.608 0 00.867 0l.434-.441a.635.635 0 000-.883l-2.277-2.316v-3.301l2.808 1.648.832 3.165a.614.614 0 00.754.44l.594-.16a.625.625 0 00.434-.765l-.356-1.355 1.531.898a.61.61 0 00.84-.23l.309-.543a.61.61 0 00-.215-.848z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSnowflakeOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
