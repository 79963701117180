import * as React from "react";

function SvgHourglassHalfSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.563 2H5.438a.937.937 0 00-.938.938v.624c0 .518.42.938.938.938 0 3.553 1.992 6.552 4.72 7.5-2.728.948-4.72 3.947-4.72 7.5a.937.937 0 00-.938.938v.625c0 .517.42.937.938.937h13.125c.517 0 .937-.42.937-.938v-.625a.937.937 0 00-.938-.937c0-3.553-1.992-6.552-4.72-7.5 2.728-.948 4.72-3.947 4.72-7.5.518 0 .938-.42.938-.938v-.624A.937.937 0 0018.562 2zM15.63 17H8.37c.667-1.828 2.035-3.125 3.63-3.125 1.595 0 2.963 1.297 3.63 3.125zm0-10H8.37a7.329 7.329 0 01-.432-2.5h8.125c0 .89-.156 1.742-.432 2.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHourglassHalfSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
