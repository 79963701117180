import * as React from "react";

function SvgTshirtSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.724 7.016l-6.083-3.015c-.628.869-2.019 1.475-3.64 1.475-1.622 0-3.013-.606-3.64-1.475L2.276 7.016a.504.504 0 00-.225.672l1.787 3.578a.504.504 0 00.672.225l1.768-.866a.5.5 0 01.72.45v7.924c0 .553.446 1 .999 1h7.999c.553 0 1-.447 1-1v-7.927a.5.5 0 01.718-.45l1.769.866a.498.498 0 00.672-.226l1.79-3.574a.499.499 0 00-.222-.672z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTshirtSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
