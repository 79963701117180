import * as React from "react";

function SvgSelectSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.328 2C2.595 2 2 2.595 2 3.328v.713a.615.615 0 001.23 0v-.088c0-.388.316-.703.704-.703h.172a.625.625 0 100-1.25h-.778zm4.566 0a.625.625 0 100 1.25h4.212a.625.625 0 000-1.25H7.894zm8 0a.625.625 0 000 1.25h.172c.388 0 .703.315.703.703v.088a.615.615 0 101.231 0v-.713C18 2.595 17.405 2 16.672 2h-.778zM18 7.46a.615.615 0 10-1.23 0v4.08a.615.615 0 001.23 0V7.46zm0 7.5a.615.615 0 00-1.23 0v.087a.703.703 0 01-.704.703h-.172a.625.625 0 000 1.25h.778c.733 0 1.328-.595 1.328-1.328v-.713zM12.106 17a.625.625 0 000-1.25H7.894a.625.625 0 100 1.25h4.212zm-8 0a.625.625 0 100-1.25h-.172a.703.703 0 01-.703-.703v-.088a.615.615 0 00-1.231 0v.713C2 16.405 2.595 17 3.328 17h.778zM2 11.54a.615.615 0 001.23 0V7.46a.615.615 0 00-1.23 0v4.08z"
        fill="currentColor"
      />
      <path
        d="M21.615 18.082c.329 0 .526-.43.263-.687l-6.22-6.272c-.242-.257-.658-.086-.658.28v9.214c0 .344.394.537.657.28l1.884-1.912 1.161 2.77c.088.194.328.301.526.215l1.073-.451c.197-.086.285-.322.197-.515l-1.205-2.922h2.322z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSelectSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
