import * as React from "react";

function SvgBookSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.85 4.002c-1.912.104-5.703.487-8.033 1.913a.543.543 0 00-.279.452v12.66c0 .417.453.66.835.487 2.4-1.218 5.878-1.566 7.582-1.635.591-.035 1.044-.522 1.044-1.078V5.045c.034-.591-.522-1.078-1.148-1.043zm-9.668 1.913C8.852 4.489 5.06 4.106 3.148 4.002 2.522 3.967 2 4.454 2 5.045v11.756c0 .556.452 1.043 1.043 1.078 1.705.07 5.183.417 7.582 1.635.383.174.835-.07.835-.487V6.367a.543.543 0 00-.278-.452z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBookSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
