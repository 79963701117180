import * as React from "react";

function SvgKissWinkHeartSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.885 17.814c-.317-.826-1.25-1.25-2.107-1.028l-.333.087-.092-.333c-.234-.85-1.071-1.449-1.944-1.31a1.66 1.66 0 00-1.35 2.087l.91 3.278c.059.21.277.338.491.282l3.294-.853a1.655 1.655 0 001.131-2.21zm-7.048-.16a2.947 2.947 0 01.358-2.368c1.178-1.838 3.916-1.806 5.071.17.254.004.5.056.738.116A9.791 9.791 0 0021.683 12a9.84 9.84 0 00-9.842-9.841A9.84 9.84 0 002 12a9.84 9.84 0 009.841 9.841 9.766 9.766 0 003.945-.829c-.012-.028-.949-3.357-.949-3.357zm-6.17-6.289c-.703 0-1.27-.567-1.27-1.27 0-.702.567-1.27 1.27-1.27.702 0 1.27.568 1.27 1.27 0 .703-.568 1.27-1.27 1.27zm4.762 6.19c0 .762-1.14 1.648-2.838 1.747-.337.031-.48-.469-.143-.611l.675-.286c.516-.218.825-.536.825-.853 0-.318-.309-.635-.825-.854l-.675-.285c-.238-.1-.226-.488 0-.588l.675-.285c.516-.218.825-.536.825-.853 0-.318-.309-.635-.825-.854l-.675-.285c-.349-.147-.182-.66.143-.611 1.699.099 2.838.984 2.838 1.746 0 .515-.532 1.083-1.397 1.428.865.361 1.397.929 1.397 1.445zm.635-7.103c-.33.294-.857.016-.786-.428.159-1 1.357-1.67 2.377-1.67 1.02 0 2.218.67 2.377 1.67a.475.475 0 01-.786.428l-.377-.337c-.587-.524-1.833-.524-2.42 0l-.385.337z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgKissWinkHeartSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
