import * as React from "react";

function SvgSkatingSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.874 2a1.876 1.876 0 10.001 3.751A1.876 1.876 0 0018.874 2zm0 17.5a.627.627 0 00-.625.625.627.627 0 01-.625.625h-3.75a.627.627 0 00-.625.625c0 .344.281.625.625.625h3.75c1.035 0 1.875-.84 1.875-1.875a.627.627 0 00-.625-.625zm-11.023.336a.624.624 0 01-.883 0l-2.653-2.652a.627.627 0 00-.882 0 .627.627 0 000 .882l2.652 2.653c.367.367.848.547 1.328.547.48 0 .961-.184 1.324-.547a.627.627 0 000-.883.628.628 0 00-.886 0zm2.191-7.023l-3.66 3.66a1.249 1.249 0 001.766 1.766l3.59-3.59-1.18-1.18a3.675 3.675 0 01-.516-.656zM8.249 8.25h4.121l-.785.672a2.505 2.505 0 00-.871 1.8 2.492 2.492 0 00.73 1.864L14.5 15.64v3.234c0 .691.559 1.25 1.25 1.25s1.25-.559 1.25-1.25v-3.492c0-.492-.2-.977-.55-1.324l-2.384-2.383c.02-.016.047-.024.067-.043l3.215-3.215c.449-.45.582-1.117.34-1.703a1.558 1.558 0 00-1.446-.965H8.25c-.691 0-1.25.559-1.25 1.25s.559 1.25 1.25 1.25z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSkatingSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
