import * as React from "react";

function SvgLaughBeamOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm5.702 15.702A8.01 8.01 0 0112 20.064a8.01 8.01 0 01-5.702-2.362A8.01 8.01 0 013.935 12a8.01 8.01 0 012.363-5.702A8.01 8.01 0 0112 3.935a8.01 8.01 0 015.702 2.363A8.01 8.01 0 0120.064 12a8.01 8.01 0 01-2.362 5.702zm-2.476-9.896c-.96 0-2.125 1.182-2.258 2.88-.028.346.435.48.6.18l.384-.684c.31-.553.774-.872 1.27-.872s.96.319 1.27.871l.383.686c.165.298.629.161.6-.182-.124-1.697-1.29-2.879-2.25-2.879zm-8.105 3.06l.383-.684c.31-.553.774-.872 1.27-.872s.96.319 1.27.871l.383.686c.166.298.63.161.601-.182-.133-1.697-1.298-2.879-2.258-2.879s-2.125 1.182-2.258 2.88c-.024.342.44.48.609.18zm9.492 2.424H7.387a.541.541 0 00-.544.605c.302 2.387 2.375 4.234 4.883 4.234h.548c2.508 0 4.58-1.847 4.883-4.234a.541.541 0 00-.544-.605z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLaughBeamOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
