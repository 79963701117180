import * as React from "react";

function SvgLaughSquintSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm1.363 6.52l3.226-1.935c.468-.279.968.31.62.725l-1.354 1.625 1.355 1.625c.35.42-.158 1-.621.726l-3.226-1.935a.487.487 0 010-.83zM6.79 7.31c-.346-.415.154-1.004.621-.725l3.226 1.935a.484.484 0 010 .83l-3.226 1.936c-.463.274-.967-.306-.62-.726l1.354-1.625L6.79 7.31zm11.295 6.706a5.812 5.812 0 01-5.762 5.08h-.646a5.812 5.812 0 01-5.762-5.08.648.648 0 01.641-.726h10.887c.388 0 .69.339.642.726z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLaughSquintSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
