import * as React from "react";

function SvgShippingFastSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.5 15H21v-3.378a1.5 1.5 0 00-.44-1.06l-3.122-3.121A1.5 1.5 0 0016.378 7H15V5.5A1.5 1.5 0 0013.5 4h-8A1.5 1.5 0 004 5.5V7H2.25a.25.25 0 00-.25.25v.5c0 .138.112.25.25.25h8.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-7.5a.25.25 0 00-.25.25v.5c0 .137.112.25.25.25h6.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-7.5a.25.25 0 00-.25.25v.5c0 .137.112.25.25.25h6.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H4v4a3 3 0 006 0h4a3 3 0 006 0h1.5c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5zM7 18.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm10 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm2.5-6.5H15V8.5h1.378l3.122 3.122V12z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgShippingFastSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
