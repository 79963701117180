import * as React from "react";

function SvgGasPumpSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.125 19.5h-12.5a.627.627 0 00-.625.625v1.25c0 .344.281.625.625.625h12.5a.627.627 0 00.625-.625v-1.25a.627.627 0 00-.625-.625zm6.14-13.309l-3.163-3.164a.627.627 0 00-.883 0l-.442.442a.627.627 0 000 .883l1.473 1.472V8.25c0 1.098.816 2.004 1.875 2.156v6.281a.94.94 0 01-.938.938.94.94 0 01-.937-.938v-1.25A3.438 3.438 0 0014.812 12H14.5V4.5C14.5 3.121 13.379 2 12 2H5.75a2.502 2.502 0 00-2.5 2.5v13.75H14.5v-4.375h.313c.863 0 1.562.7 1.562 1.563v1.085c0 1.473 1.055 2.813 2.52 2.965a2.82 2.82 0 003.105-2.8V7.96c0-.664-.266-1.3-.734-1.77zM12 9.5H5.75v-5H12v5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGasPumpSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
