import * as React from "react";

function SvgStethoscopeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.465 6.374a2.51 2.51 0 00-2.461 2.446c-.02.949.488 1.781 1.25 2.219v4.398c0 2.238-1.961 4.062-4.375 4.062-2.344 0-4.266-1.722-4.371-3.875C12.352 15.04 14.5 12.515 14.5 9.5V3.43a.937.937 0 00-.754-.918L11.29 2.02a.935.935 0 00-1.101.734l-.126.614a.935.935 0 00.735 1.101l1.2.238V9.45c0 2.066-1.65 3.777-3.716 3.796a3.751 3.751 0 01-3.785-3.75V4.71l1.2-.238A.935.935 0 006.43 3.37l-.121-.613a.935.935 0 00-1.102-.734l-2.453.484A.941.941 0 002 3.43V9.5a6.26 6.26 0 005.004 6.125C7.105 19.156 10.148 22 13.875 22c3.79 0 6.875-2.945 6.875-6.562v-4.398a2.5 2.5 0 00-1.285-4.665zM19.5 9.5a.627.627 0 01-.625-.625c0-.343.281-.625.625-.625s.625.282.625.625a.627.627 0 01-.625.625z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgStethoscopeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
