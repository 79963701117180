import * as React from "react";

function SvgWindowRestoreOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 2h-12.5C6.59 2 5.75 2.84 5.75 3.875V5.75H3.875C2.84 5.75 2 6.59 2 7.625v12.5C2 21.16 2.84 22 3.875 22h12.5c1.035 0 1.875-.84 1.875-1.875V18.25h1.875c1.035 0 1.875-.84 1.875-1.875v-12.5C22 2.84 21.16 2 20.125 2zm-3.75 18.125h-12.5V12h12.5v8.125zm3.75-3.75H18.25v-8.75c0-1.035-.84-1.875-1.875-1.875h-8.75V3.875h12.5v12.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgWindowRestoreOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
