import * as React from "react";

function SvgFrogSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.505 6.494a2.775 2.775 0 00-2.727-2.272c-1.362 0-2.49.983-2.727 2.276C6.4 7.033 1.983 11.796 2 17.59c.003 1.212 1.009 2.188 2.221 2.188h10.556a.555.555 0 00.555-.556c0-.613-.497-1.11-1.11-1.11h-2.76l1.242-1.679c.838-1.258.36-3.065-1.17-3.701-.83-.345-1.79-.162-2.509.377l-1.137.854a.555.555 0 11-.667-.889l1.207-.906a3.638 3.638 0 014.345 0c.377.283 1.593 1.357 1.417 3.237l4.115 4.373h3.138a.555.555 0 00.556-.556c0-.613-.498-1.11-1.111-1.11h-1.862l-3.421-3.636 5.362-3.008a2.02 2.02 0 00-.026-3.54c-1.409-.772-3.438-1.434-3.438-1.434zm-2.727 1.34a.833.833 0 110-1.667.833.833 0 010 1.666z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFrogSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
