import * as React from "react";

function SvgSpinnerSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.875 3.875a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zM12 18.25A1.875 1.875 0 1012 22a1.875 1.875 0 000-3.75zm8.125-8.125a1.875 1.875 0 100 3.75 1.875 1.875 0 000-3.75zM5.75 12A1.875 1.875 0 102 12a1.875 1.875 0 003.75 0zm.505 3.87a1.875 1.875 0 100 3.75 1.875 1.875 0 000-3.75zm11.49 0a1.875 1.875 0 100 3.75 1.875 1.875 0 000-3.75zM6.255 4.38a1.875 1.875 0 100 3.75 1.875 1.875 0 000-3.75z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSpinnerSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
