import * as React from "react";

function SvgHandPointUpSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.799 2c.923 0 1.712.807 1.712 1.75v3.9c.666-.638 1.944-.716 2.771.246.892-.558 2.071-.083 2.434.643 1.92-.35 2.784.857 2.784 2.836 0 .107-.008.519-.008.625.007 2.42-1.213 3.004-1.496 4.833a.936.936 0 01-.926.792h-6.7a1.875 1.875 0 01-1.713-1.112c-.508-1.128-1.916-3.727-3.02-4.2-.711-.305-1.137-.836-1.137-1.563 0-1.337 1.371-2.256 2.614-1.723.326.14.65.324.973.552V3.75c0-.916.802-1.75 1.712-1.75zm.014 16.25h7.5c.517 0 .937.42.937.938v1.875c0 .517-.42.937-.938.937h-7.5a.937.937 0 01-.937-.938v-1.875c0-.517.42-.937.938-.937zm6.562 1.094a.781.781 0 100 1.562.781.781 0 000-1.562z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHandPointUpSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
