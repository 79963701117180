import * as React from "react";

function SvgToiletPaperSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6.444 3.111c-1.84 0-3.333 2.985-3.333 6.667v5.974c0 1.428-.34 2.18-1.082 4.406-.12.36.148.73.527.73h9.754c.478 0 .903-.305 1.054-.759.446-1.336.858-2.514.858-4.377V9.778c0-2.903.822-5.33 2.099-6.667H6.444zm-1.11 7.778a.555.555 0 110-1.111.555.555 0 010 1.11zm2.222 0a.555.555 0 110-1.111.555.555 0 010 1.11zm2.222 0a.555.555 0 110-1.111.555.555 0 010 1.11zm2.222 0a.555.555 0 110-1.11.555.555 0 010 1.11zm6.667-7.778c-1.841 0-3.334 2.985-3.334 6.667s1.493 6.666 3.334 6.666c1.84 0 3.333-2.984 3.333-6.666 0-3.682-1.492-6.667-3.333-6.667zm0 8.889c-.614 0-1.111-.995-1.111-2.222 0-1.228.497-2.222 1.11-2.222.614 0 1.112.994 1.112 2.222 0 1.227-.498 2.222-1.111 2.222z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgToiletPaperSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
