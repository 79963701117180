import * as React from "react";

function SvgPercentSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.625 10.75A4.373 4.373 0 0012 6.375 4.373 4.373 0 007.625 2 4.373 4.373 0 003.25 6.375a4.373 4.373 0 004.375 4.375zm0-6.25a1.875 1.875 0 11-.001 3.751A1.875 1.875 0 017.625 4.5zm8.75 8.75A4.373 4.373 0 0012 17.625 4.373 4.373 0 0016.375 22a4.373 4.373 0 004.375-4.375 4.373 4.373 0 00-4.375-4.375zm0 6.25a1.875 1.875 0 110-3.75 1.875 1.875 0 010 3.75zm2.2-17.492l1.234-.004a.937.937 0 01.77 1.476L6.272 21.594a.935.935 0 01-.765.398l-1.305.004a.938.938 0 01-.77-1.476L17.81 2.405a.936.936 0 01.765-.398z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPercentSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
