import * as React from "react";

function SvgFileArchiveOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.512 8.253v1.25h1.25v-1.25h-1.25zm2.5-3.75h-1.25v1.25h1.25v-1.25zm-2.5 1.25v1.25h1.25v-1.25h-1.25zm2.5 1.25h-1.25v1.25h1.25v-1.25zm6.936-1.176L15.67 2.551A1.875 1.875 0 0014.347 2h-7.97A1.881 1.881 0 004.5 3.879v16.246c0 1.035.84 1.875 1.875 1.875h11.248c1.035 0 1.875-.84 1.875-1.875V7.155c0-.496-.2-.976-.551-1.328zm-4.449-1.796l2.973 2.972h-2.973V4.03zm3.125 16.094H6.376V3.88H9.49v.624h1.25V3.88h1.886V7.94c0 .52.418.938.937.938h4.062v11.247zm-5.538-7.744a.469.469 0 00-.46-.379h-.864v-1.25h-1.25v1.25l-.77 3.792a2.048 2.048 0 002.008 2.457 2.049 2.049 0 002.012-2.445l-.676-3.425zm-1.324 4.858c-.7 0-1.265-.472-1.265-1.054 0-.582.566-1.055 1.265-1.055s1.265.473 1.265 1.055-.566 1.054-1.265 1.054zm1.25-7.737h-1.25v1.25h1.25v-1.25z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFileArchiveOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
