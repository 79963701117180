import * as React from "react";

function SvgEyeSlashOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.812 18.718L3.125 4.11a.5.5 0 00-.703.078l-.312.39a.5.5 0 00.078.703l18.687 14.61a.5.5 0 00.703-.079l.312-.39a.5.5 0 00-.078-.704zM11.275 8.578l4.212 3.292a3.49 3.49 0 00-4.212-3.293zm1.45 6.845L8.513 12.13a3.491 3.491 0 004.212 3.293zM12 7.5c3.083 0 5.909 1.719 7.435 4.5a8.921 8.921 0 01-1.375 1.881l1.18.922a10.423 10.423 0 001.652-2.347 1.01 1.01 0 000-.912C19.196 8.237 15.842 6 12 6c-1.147 0-2.24.219-3.27.588l1.45 1.134A8.201 8.201 0 0112 7.5zm0 9c-3.083 0-5.909-1.719-7.435-4.5a8.92 8.92 0 011.376-1.88l-1.179-.923a10.424 10.424 0 00-1.653 2.347 1.01 1.01 0 000 .912C4.804 15.763 8.16 18 12 18c1.147 0 2.24-.22 3.27-.588l-1.45-1.134A8.216 8.216 0 0112 16.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgEyeSlashOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
