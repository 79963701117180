import * as React from "react";

function SvgWindowMinimizeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 9.5H3.875C2.84 9.5 2 10.34 2 11.375v1.25c0 1.035.84 1.875 1.875 1.875h16.25c1.035 0 1.875-.84 1.875-1.875v-1.25c0-1.035-.84-1.875-1.875-1.875z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgWindowMinimizeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
