import * as React from "react";

function SvgDiceD20Solid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6.795 10.4l-4.123 6.09c-.12.195.004.45.232.474l8.135.862-4.244-7.425zm-3.88 3.921l2.94-4.779-2.993-1.796a.156.156 0 00-.237.134v6.36c0 .157.207.216.29.081zm.423 4.226l7.593 3.424a.312.312 0 00.444-.284v-2.565l-7.951-.871c-.174-.02-.244.218-.086.296zM6.51 8.477l3.122-5.583c.17-.276-.14-.596-.421-.435L3.32 6.31a.157.157 0 00.005.265L6.51 8.477zM12 8.875h4.266l-3.734-6.577A.62.62 0 0012 2a.62.62 0 00-.533.298L7.734 8.875H12zm9.138-1.129l-2.994 1.796 2.941 4.78c.083.134.29.076.29-.082V7.88a.156.156 0 00-.237-.134zm-3.649.731l3.185-1.902a.156.156 0 00.005-.265L14.79 2.46c-.282-.161-.591.159-.422.435l3.122 5.583zm3.087 9.774l-7.951.87v2.566c0 .228.236.38.443.283l7.594-3.424c.157-.077.088-.315-.086-.295zm-3.371-7.85l-4.243 7.425 8.135-.862a.312.312 0 00.231-.474l-4.123-6.09zM12 10.125H8.077L12 16.99l3.923-6.865H12z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDiceD20Solid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
