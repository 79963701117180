import * as React from "react";

function SvgHandRockOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.971 5.088a2.902 2.902 0 00-3.86-.921 2.902 2.902 0 00-4.231-.001C8.037 3.11 5.633 4.363 5.556 6.54 3.752 6.117 2 7.469 2 9.32v2.225c0 1.28.558 2.498 1.53 3.345l3.817 3.324c.166.144.122.217.122 1.285 0 .69.56 1.25 1.25 1.25h9.844c.69 0 1.25-.56 1.25-1.25 0-.919-.04-1.201.155-1.655l1.673-3.893c.238-.554.359-1.14.359-1.74V7.74c0-2.064-2.122-3.464-4.029-2.653zm2.154 7.123c0 .344-.07.685-.206 1l-1.674 3.893a3.764 3.764 0 00-.308 1.495v.276H9.345v-.402c0-.64-.28-1.249-.765-1.672l-3.817-3.324a2.564 2.564 0 01-.887-1.93V9.32c0-1.297 2.031-1.31 2.031.027v1.61a.625.625 0 00.215.472l.273.238a.626.626 0 001.036-.472V6.643c0-1.293 2.03-1.318 2.03.026v1.045c0 .345.28.625.626.625h.273c.345 0 .625-.28.625-.625V6.107c0-1.294 2.032-1.315 2.032.027v1.58c0 .345.28.625.625.625h.273c.345 0 .625-.28.625-.625V6.643c0-1.29 2.031-1.32 2.031.026v1.045c0 .345.28.625.625.625h.274c.345 0 .625-.28.625-.625 0-1.295 2.031-1.313 2.031.027v4.47z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHandRockOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
