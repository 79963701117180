import * as React from "react";

function SvgTintSlashSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.807 18.316l-4.34-3.354c.017-.174.033-.349.033-.527 0-3.493-3.118-4.792-4.587-9.745-.248-.9-1.545-.94-1.826 0-.485 1.636-1.152 2.874-1.828 3.928L3.421 4.105a.5.5 0 00-.702.088l-.614.79a.5.5 0 00.088.701l18.386 14.21a.5.5 0 00.702-.087l.614-.79a.5.5 0 00-.088-.701zM6.5 14.435C6.5 17.51 8.96 20 12 20c1.397 0 2.668-.53 3.637-1.397l-8.552-6.61c-.357.734-.585 1.511-.585 2.442z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTintSlashSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
