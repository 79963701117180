import * as React from "react";

function SvgBarcodeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 19.5v-15h.703v15H2zm1.05-.01V4.5h.356v14.99H3.05zm1.06 0V4.5h.345v14.99H4.11zm1.752 0V4.5h.346v14.99h-.346zm1.406 0V4.5h.692v14.99h-.692zm1.752 0V4.5h.346v14.99H9.02zm.703 0V4.5h.346v14.99h-.346zm.703 0V4.5h.346v14.99h-.346zm1.395 0V4.5h.704v14.99h-.704zm1.753 0V4.5h.703v14.99h-.703zm1.406 0V4.5h.703v14.99h-.703zm1.406 0V4.5h.703v14.99h-.703zm1.05 0V4.5h.702v14.99h-.703zm1.763 0V4.5h1.049v14.99h-1.05zm1.395 0V4.5h.357v14.99h-.357zm.703.01v-15H22v15h-.703z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBarcodeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
