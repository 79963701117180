import * as React from "react";

function SvgUserNinjaSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.96 13.297L12 17.257l-3.96-3.96c-2.68.238-4.79 2.46-4.79 5.203v1.625C3.25 21.16 4.09 22 5.125 22h13.75c1.035 0 1.875-.84 1.875-1.875V18.5c0-2.742-2.11-4.965-4.79-5.203zM4.5 9.5a3.73 3.73 0 002.703-1.16C7.793 10.445 9.703 12 12 12a5 5 0 100-10c-1.969 0-3.656 1.148-4.473 2.805A3.732 3.732 0 004.5 3.25a3.74 3.74 0 001.684 3.125A3.74 3.74 0 004.5 9.5zm5.625-3.75h3.75c.691 0 1.25.559 1.25 1.25h-6.25c0-.691.559-1.25 1.25-1.25z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgUserNinjaSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
