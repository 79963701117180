import * as React from "react";

function SvgHelicopterSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.5 16H20a1 1 0 001-1 7 7 0 00-7-7V6h5.5a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-13a.5.5 0 00-.5.5v1a.5.5 0 00.5.5H12v2H5.5L4.15 6.2a.5.5 0 00-.4-.2H2.5a.5.5 0 00-.485.621L3 10.001l5 2 2.7 3.6a1 1 0 00.8.4zm3.5-5.89A5.01 5.01 0 0118.9 14H15v-3.89zm6.855 8.228l-.692-.694a.504.504 0 00-.707 0c-.222.212-.433.352-.77.352H9.5a.5.5 0 00-.5.501V19.5a.5.5 0 00.5.5h10.186c.465 0 1.103-.014 2.139-.922a.509.509 0 00.03-.739z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHelicopterSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
