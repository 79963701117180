import * as React from "react";

function SvgGrinHeartsOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.258 13.96c-1.044.334-2.597.528-4.258.528s-3.21-.194-4.258-.528a.554.554 0 00-.714.617c.319 1.903 2.875 3.225 4.972 3.225 2.097 0 4.65-1.326 4.972-3.225a.555.555 0 00-.714-.617zm-6.161-1.972a.35.35 0 00.423-.242l.782-2.819a1.427 1.427 0 00-1.16-1.794c-.75-.12-1.469.395-1.674 1.125l-.08.286-.287-.076c-.734-.19-1.54.173-1.81.887a1.43 1.43 0 00.975 1.903l2.83.73zm7.613-2.633c-.27-.71-1.077-1.077-1.81-.887l-.287.076-.08-.286c-.202-.73-.92-1.246-1.674-1.125a1.427 1.427 0 00-1.161 1.794l.782 2.819c.048.181.238.29.423.242l2.83-.734a1.425 1.425 0 00.977-1.9zM12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 18.064c-4.448 0-8.065-3.616-8.065-8.064S7.552 3.935 12 3.935c4.448 0 8.064 3.617 8.064 8.065 0 4.448-3.616 8.064-8.064 8.064z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGrinHeartsOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
