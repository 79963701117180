import * as React from "react";

function SvgThumbsUpOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.839 13.199c.342-.58.536-1.199.536-1.947 0-1.72-1.454-3.343-3.352-3.343h-1.425a4.99 4.99 0 00.345-1.818C16.943 3.247 15.471 2 13.222 2c-2.407 0-2.27 3.708-2.803 4.242C9.53 7.131 8.48 8.838 7.733 9.5H3.875c-.69 0-1.25.56-1.25 1.25v9.375c0 .69.56 1.25 1.25 1.25h2.5a1.25 1.25 0 001.21-.936c1.739.04 2.932 1.56 6.946 1.56l.867.001c3.013 0 4.375-1.54 4.412-3.724.52-.72.793-1.684.678-2.617.384-.72.533-1.575.35-2.46zm-2.412 2.103c.49.825.049 1.93-.545 2.248.3 1.906-.688 2.575-2.075 2.575H14.33c-2.799 0-4.61-1.478-6.705-1.478v-7.272h.427c1.107 0 2.655-2.77 3.693-3.807 1.107-1.108.738-2.954 1.477-3.693 1.846 0 1.846 1.288 1.846 2.216 0 1.53-1.107 2.216-1.107 3.693h4.062c.824 0 1.474.739 1.477 1.477.003.739-.5 1.477-.87 1.477.527.569.64 1.767-.203 2.564zM6.063 18.875a.937.937 0 11-1.875 0 .937.937 0 011.875 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgThumbsUpOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
