import * as React from "react";

function SvgGrinAltOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.077 11.677c.5-.754.608-1.504.633-2.258-.02-.754-.133-1.504-.633-2.258a.791.791 0 00-1.319 0c-.5.754-.609 1.504-.633 2.258.02.754.133 1.504.633 2.258a.791.791 0 001.319 0zm5.16 0c.5-.754.61-1.504.634-2.258-.02-.754-.133-1.504-.633-2.258a.791.791 0 00-1.319 0c-.5.754-.609 1.504-.633 2.258.02.754.133 1.504.633 2.258a.791.791 0 001.319 0zM12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 18.064c-4.448 0-8.065-3.616-8.065-8.064S7.552 3.935 12 3.935c4.448 0 8.064 3.617 8.064 8.065 0 4.448-3.616 8.064-8.064 8.064zm4.258-6.104c-1.044.334-2.597.528-4.258.528s-3.21-.194-4.258-.528a.553.553 0 00-.714.617c.319 1.903 2.875 3.225 4.972 3.225 2.097 0 4.65-1.326 4.972-3.225a.555.555 0 00-.714-.617z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGrinAltOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
