import * as React from "react";

function SvgKaabaSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.24 6.01l-8.186-2.728a3.332 3.332 0 00-2.108 0L2.76 6.011A1.111 1.111 0 002 7.065v1.702l9.202-2.761a2.777 2.777 0 011.596 0L22 8.766V7.066c0-.478-.306-.903-.76-1.054zM11.52 7.07L2 9.927v7.93c0 .52.362.971.87 1.084l8.407 1.868c.476.106.97.106 1.446 0l8.407-1.868c.508-.113.87-.563.87-1.084v-7.93L12.48 7.07a1.655 1.655 0 00-.959 0zM6.444 11.1a.278.278 0 01-.204.269l-2.778.758a.278.278 0 01-.35-.269v-.575c0-.125.083-.235.204-.268l2.777-.758a.278.278 0 01.351.268v.576zm5-1.363a.278.278 0 01-.204.268l-3.333.91a.278.278 0 01-.352-.269v-.576c0-.125.084-.235.205-.268l3.333-.909a.278.278 0 01.351.268v.576zm6.111.788c0-.183.175-.316.351-.268l2.778.758c.12.033.205.143.205.268v.575a.278.278 0 01-.351.269l-2.778-.758a.278.278 0 01-.205-.268v-.576zm-5-1.363c0-.184.175-.317.351-.268l3.334.909c.12.033.204.142.204.268v.575a.278.278 0 01-.35.268l-3.334-.909a.277.277 0 01-.205-.268v-.575z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgKaabaSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
