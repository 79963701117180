import * as React from "react";

function SvgPollSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.857 2H4.143C2.96 2 2 2.96 2 4.143v15.714C2 21.04 2.96 22 4.143 22h15.714C21.04 22 22 21.04 22 19.857V4.143C22 2.96 21.04 2 19.857 2zM9.143 17c0 .395-.32.714-.714.714H7A.714.714 0 016.286 17v-5.714c0-.395.32-.715.714-.715h1.429c.394 0 .714.32.714.715V17zm4.286 0c0 .395-.32.714-.715.714h-1.428A.714.714 0 0110.57 17V7c0-.395.32-.714.715-.714h1.428c.395 0 .715.32.715.714v10zm4.285 0c0 .395-.32.714-.714.714h-1.429a.714.714 0 01-.714-.714v-2.857c0-.395.32-.714.714-.714H17c.395 0 .714.32.714.714V17z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPollSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
