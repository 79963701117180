import * as React from "react";

function SvgGrinSquintTearsOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6.576 17.003c-1.008.144-3.28.535-3.94 1.195-.856.855-.84 2.26.034 3.136.875.874 2.277.89 3.136.035.66-.66 1.05-2.933 1.195-3.94a.378.378 0 00-.425-.426zm-1.61-1.629c-1.39-2.905-.894-6.494 1.512-8.896A7.757 7.757 0 0112 4.19c1.414 0 2.765.382 3.952 1.081.149-.792.313-1.41.469-1.886A9.673 9.673 0 0012 2.315c-2.48 0-4.956.945-6.85 2.835-2.894 2.894-3.565 7.162-2.03 10.7.476-.16 1.081-.324 1.847-.476zm13.759-7.326c1.757 2.991 1.363 6.908-1.203 9.474A7.757 7.757 0 0112 19.811a7.76 7.76 0 01-3.374-.774 14.454 14.454 0 01-.477 1.844 9.664 9.664 0 0010.704-2.03c3.05-3.051 3.636-7.632 1.766-11.272-.48.156-1.101.317-1.894.469zm-1.3-1.05c1.007-.145 3.28-.536 3.94-1.196.855-.855.84-2.26-.035-3.136-.875-.874-2.277-.89-3.136-.035-.66.66-1.05 2.933-1.195 3.94-.031.25.18.462.425.426zm-7.303 4.229a.468.468 0 00-.446-.125l-3.534.886a.47.47 0 00-.355.492c.02.227.199.41.425.43l2.043.187.187 2.043c.02.226.203.406.43.425h.035a.471.471 0 00.457-.355l.882-3.534a.475.475 0 00-.124-.45zm1.55-.98l3.534-.883a.47.47 0 00.356-.492.471.471 0 00-.426-.43l-2.042-.187-.188-2.043a.471.471 0 00-.43-.425c-.218-.004-.437.133-.492.355l-.882 3.534a.47.47 0 00.125.446c.195.195.441.124.445.124zm2.03 3.456c-1.136 1.136-2.33 2.066-3.276 2.554a.537.537 0 00-.066.913c1.519 1.082 4.178.243 5.612-1.195 1.433-1.437 2.276-4.092 1.195-5.611a.537.537 0 00-.914.066c-.48.945-1.414 2.136-2.55 3.273z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGrinSquintTearsOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
