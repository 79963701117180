import * as React from "react";

function SvgCopyrightOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18.064A8.06 8.06 0 013.935 12 8.06 8.06 0 0112 3.935 8.06 8.06 0 0120.064 12 8.06 8.06 0 0112 20.064zm4.329-4.075c-.388.392-1.836 1.67-4.197 1.67-3.323 0-5.664-2.477-5.664-5.709 0-3.191 2.43-5.62 5.635-5.62 2.24 0 3.579 1.073 3.936 1.402a.482.482 0 01.078.617l-.732 1.134a.484.484 0 01-.706.118c-.347-.273-1.283-.909-2.488-.909-1.948 0-3.142 1.425-3.142 3.23 0 1.676 1.084 3.374 3.156 3.374 1.317 0 2.292-.768 2.65-1.098a.484.484 0 01.72.07l.8 1.096a.481.481 0 01-.046.625z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCopyrightOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
