import * as React from "react";

function SvgTruckMovingSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.416 11.416l-1.829-1.829a2 2 0 00-1.415-.584H17V6c0-.553-.447-1-1-1H3c-.553 0-1 .447-1 1v10.5A2.5 2.5 0 004.5 19c.822 0 1.544-.403 2-1.012A2.487 2.487 0 008.5 19a2.5 2.5 0 002.45-3h5.1a2.5 2.5 0 104.9 0h.55c.275 0 .5-.225.5-.5v-2.672c0-.531-.21-1.037-.584-1.412zM4.5 17.5c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm4 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm8.5-7h1.172c.134 0 .26.053.353.147L19.878 12H17v-1.5zm1.5 7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTruckMovingSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
