import * as React from "react";

function SvgSignInAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.25 19.343h-3.281a.47.47 0 01-.469-.469v-1.562a.47.47 0 01.469-.47h3.281c.691 0 1.25-.558 1.25-1.25v-7.5c0-.69-.559-1.25-1.25-1.25h-3.281a.47.47 0 01-.469-.468V4.812a.47.47 0 01.469-.47h3.281c2.07 0 3.75 1.68 3.75 3.75v7.5c0 2.071-1.68 3.75-3.75 3.75zm-1.836-7.852L9.852 4.93c-.586-.586-1.602-.176-1.602.664v3.75H2.937A.935.935 0 002 10.28v3.75c0 .52.418.938.938.938H8.25v3.75c0 .84 1.016 1.25 1.602.664l6.562-6.563a.946.946 0 000-1.328z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSignInAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
