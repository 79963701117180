import * as React from "react";

function SvgMouseSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M4.5 15.75A6.25 6.25 0 0010.75 22h2.5a6.25 6.25 0 006.25-6.25v-5h-15v5zM11.375 2h-.625A6.25 6.25 0 004.5 8.25V9.5h6.875V2zm1.875 0h-.625v7.5H19.5V8.25A6.25 6.25 0 0013.25 2z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMouseSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
