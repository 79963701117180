import * as React from "react";

function SvgAssistiveListeningSystemsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.438 12.156a1.094 1.094 0 11-2.188 0 3.129 3.129 0 013.125-3.125 3.129 3.129 0 013.125 3.125 1.094 1.094 0 11-2.188 0 .939.939 0 00-1.874 0zm.937-6.875A6.883 6.883 0 004.5 12.156a1.094 1.094 0 102.188 0 4.693 4.693 0 014.687-4.687 4.693 4.693 0 014.688 4.687c0 2.936-2.774 2.747-2.813 5.61v.015c0 1.12-.911 2.032-2.031 2.032a1.094 1.094 0 100 2.187 4.224 4.224 0 004.219-4.21c.023-1.345 2.812-1.884 2.812-5.634a6.883 6.883 0 00-6.875-6.875zM8.25 14.5a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm-5 5a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zM22 12.156c0-.059 0-.118-.002-.177-.073-4.49-2.96-8.476-7.186-9.92a1.094 1.094 0 00-.707 2.07c3.356 1.147 5.649 4.317 5.706 7.889v.007l.002.131a1.094 1.094 0 102.187 0zM7.975 19.15L4.85 16.025 3.525 17.35l3.125 3.125 1.325-1.325z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAssistiveListeningSystemsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
