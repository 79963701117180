import * as React from "react";

function SvgThermometerFullSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.75 5.75a3.75 3.75 0 00-7.5 0v7.943A4.98 4.98 0 007 16.973C6.986 19.72 9.218 21.982 11.964 22H12a5 5 0 003.75-8.307V5.75zM12 20.125h-.023a3.138 3.138 0 01-3.102-3.142c.007-1.327.753-2.017 1.25-2.58V5.75c0-1.034.841-1.875 1.875-1.875s1.875.841 1.875 1.875v8.652c.493.56 1.25 1.257 1.25 2.598A3.129 3.129 0 0112 20.125zM14.5 17a2.5 2.5 0 11-3.75-2.165V5.75a1.25 1.25 0 112.5 0v9.085A2.498 2.498 0 0114.5 17z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgThermometerFullSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
