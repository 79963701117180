import * as React from "react";

function SvgPraySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M14.5 7a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm-1.196 6.63c.549.654 1.523.747 2.186.205l3.437-2.814a1.563 1.563 0 00-1.98-2.417l-2.243 1.836-1.52-1.81a2.856 2.856 0 00-2.5-.989c-.945.097-1.768.636-2.202 1.442l-1.929 3.595c-.914 1.705-.34 3.765 1.336 4.834l1.75 1.363H6.063a1.563 1.563 0 000 3.125h8.125c1.33 0 2.1-1.672 1.104-2.667l-4.291-4.292 1.36-2.534.943 1.124z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPraySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
