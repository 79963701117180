import * as React from "react";

function SvgGrinBeamSweatOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.62 8.349c1.17 0 2.115-1.044 2.115-2.33 0-.992-1.258-2.996-1.834-3.861-.143-.21-.424-.21-.563 0-.576.865-1.834 2.869-1.834 3.861 0 1.286.945 2.33 2.115 2.33zM12 17.873c2.06 0 4.576-1.306 4.893-3.175a.543.543 0 00-.702-.607c-1.028.33-2.556.52-4.191.52-1.635 0-3.159-.19-4.19-.52a.545.545 0 00-.703.607c.317 1.87 2.833 3.175 4.893 3.175zm5.17-6.68a.318.318 0 00.227-.329c-.13-1.67-1.278-2.833-2.222-2.833-.945 0-2.092 1.163-2.223 2.833a.321.321 0 00.227.33.323.323 0 00.369-.147l.377-.675c.305-.543.762-.857 1.25-.857s.944.314 1.25.857l.377.675a.325.325 0 00.369.147zm4.18-2.098c-.977.623-1.826.512-1.842.512.274.801.429 1.658.429 2.551 0 4.377-3.56 7.937-7.937 7.937-4.377 0-7.937-3.56-7.937-7.937 0-4.377 3.56-7.937 7.937-7.937 1.58 0 3.048.469 4.286 1.266.067-.377.25-.956.682-1.813A9.782 9.782 0 0012 2.316a9.84 9.84 0 00-9.842 9.842A9.84 9.84 0 0012 22a9.84 9.84 0 009.35-12.905zm-12.525.42c.488 0 .945.314 1.25.857l.377.675a.314.314 0 00.37.147.318.318 0 00.226-.33c-.131-1.67-1.278-2.833-2.223-2.833-.944 0-2.091 1.163-2.222 2.833a.32.32 0 00.226.33.323.323 0 00.37-.147l.376-.675c.306-.547.762-.857 1.25-.857z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGrinBeamSweatOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
