import * as React from "react";

function SvgPhoneSquareSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.857 2H4.143C2.959 2 2 2.96 2 4.143v15.714C2 21.041 2.96 22 4.143 22h15.714C21.041 22 22 21.04 22 19.857V4.143C22 2.959 21.04 2 19.857 2zM6.197 19.143a.67.67 0 01-.653-.52l-.67-2.901a.67.67 0 01.39-.766l3.124-1.34a.67.67 0 01.782.192l1.384 1.691a10.35 10.35 0 004.945-4.945L13.81 9.17a.67.67 0 01-.192-.782l1.339-3.125a.67.67 0 01.766-.389l2.902.67a.67.67 0 01.519.652c0 7.156-5.801 12.947-12.947 12.947z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPhoneSquareSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
