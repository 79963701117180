import * as React from "react";

function SvgChartAreaSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.531 17a.47.47 0 01.469.469v1.562a.47.47 0 01-.469.469H2.47A.47.47 0 012 19.031V4.97a.47.47 0 01.469-.469H4.03a.47.47 0 01.469.469V17h17.031zM16.56 8.23l-3.309 2.207-3.332-4.44a.47.47 0 00-.777.038L5.75 11.687v4.063h15l-3.512-7.336a.469.469 0 00-.68-.184z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChartAreaSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
