import * as React from "react";

function SvgSquareFullSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path d="M22 22H2V2h20v20z" fill="currentColor" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSquareFullSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
