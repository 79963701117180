import * as React from "react";

function SvgUserSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 12a5 5 0 100-10 5 5 0 000 10zm3.5 1.25h-.652a6.808 6.808 0 01-5.696 0H8.5a5.251 5.251 0 00-5.25 5.25v1.625C3.25 21.16 4.09 22 5.125 22h13.75c1.035 0 1.875-.84 1.875-1.875V18.5a5.251 5.251 0 00-5.25-5.25z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgUserSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
