import * as React from "react";

function SvgOrignalsizeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.59 11.579h5.492c1.474 0 2.332-.875 2.332-2.379V3.867c0-.907-.68-1.547-1.58-1.547-.899 0-1.579.64-1.579 1.547v.405l.23 2.101-1.454-1.557-2.28-2.347C4.458 2.16 4.051 2 3.631 2 2.66 2 2 2.715 2 3.696c0 .427.188.8.481 1.099L4.782 7.13 6.32 8.603l-2.123-.235H3.59c-.89 0-1.527.693-1.527 1.61 0 .918.627 1.6 1.527 1.6zM14.144 21.7c.9 0 1.58-.64 1.58-1.557v-.437l-.23-2.102 1.443 1.558 2.301 2.368c.304.309.712.469 1.13.469.973 0 1.632-.715 1.632-1.696 0-.427-.188-.8-.481-1.099l-2.322-2.357-1.538-1.472 2.113.235h.649c.9 0 1.527-.694 1.527-1.611s-.628-1.6-1.527-1.6h-5.523c-1.475 0-2.333.875-2.333 2.379v5.365c0 .917.68 1.557 1.58 1.557z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgOrignalsizeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
