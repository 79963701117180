import * as React from "react";

function SvgFileExportSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.335 7.343a.83.83 0 00-.244-.587l-3.4-3.403a.833.833 0 00-.59-.243h-.211v4.445h4.444v-.212zm6.493 6.463l-3.323-3.348a.557.557 0 00-.952.393v2.26h-2.222v2.223h2.222v2.264c0 .496.601.743.952.392l3.323-3.35a.591.591 0 000-.834zm-13.16.972v-1.111c0-.306.25-.556.555-.556h6.111V8.666h-4.722a.836.836 0 01-.834-.833V3.11H2.833A.831.831 0 002 3.944v16.112c0 .462.372.834.833.834h11.668a.831.831 0 00.834-.834v-4.722H9.223a.557.557 0 01-.556-.556z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFileExportSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
