import * as React from "react";

function SvgPortraitSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.625 2H6.375C5.34 2 4.5 2.84 4.5 3.875v16.25C4.5 21.16 5.34 22 6.375 22h11.25c1.035 0 1.875-.84 1.875-1.875V3.875C19.5 2.84 18.66 2 17.625 2zM12 7c1.379 0 2.5 1.121 2.5 2.5S13.379 12 12 12a2.502 2.502 0 01-2.5-2.5C9.5 8.121 10.621 7 12 7zm4.375 9.25c0 .414-.39.75-.875.75h-7c-.484 0-.875-.336-.875-.75v-.75c0-1.242 1.176-2.25 2.625-2.25h.195a4.032 4.032 0 003.11 0h.195c1.45 0 2.625 1.008 2.625 2.25v.75z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPortraitSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
