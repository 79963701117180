import * as React from "react";

function SvgMarsDoubleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.281 2h-3.086a.47.47 0 00-.332.8l.66.66-1.902 1.903A5.626 5.626 0 002 10.125a5.626 5.626 0 005.625 5.625 5.626 5.626 0 004.762-8.621l1.902-1.902.66.66a.468.468 0 00.8-.332V2.469A.47.47 0 0015.282 2zM7.625 13.25A3.13 3.13 0 014.5 10.125 3.13 3.13 0 017.625 7a3.13 3.13 0 013.125 3.125 3.13 3.13 0 01-3.125 3.125zm13.906-5.004h-3.086a.47.47 0 00-.332.8l.66.661-1.902 1.902a5.637 5.637 0 00-2.402-.832 6.895 6.895 0 01-.719 2.473c.043 0 .086-.004.125-.004A3.13 3.13 0 0117 16.371a3.13 3.13 0 01-3.125 3.125 3.13 3.13 0 01-3.125-3.125c0-.043 0-.086.004-.125a6.816 6.816 0 01-2.473.719 5.625 5.625 0 1010.356-3.586l1.902-1.902.66.66a.468.468 0 00.8-.332V8.719a.471.471 0 00-.468-.473z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMarsDoubleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
