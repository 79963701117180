import * as React from "react";

function SvgChessPawnSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.712 10H8.667a.666.666 0 00-.667.667V12a.667.667 0 00.667.667h.666v.228c0 1.834-.172 3.609-1 5.105h7.334c-.83-1.496-1-3.271-1-5.105v-.228h.666A.667.667 0 0016 12v-1.333a.667.667 0 00-.667-.667h-1.046a4.319 4.319 0 00.777-6.73 4.333 4.333 0 00-7.397 3.063c0 1.551.82 2.901 2.045 3.667zM18 19.333H6a.667.667 0 00-.667.667v1.333A.667.667 0 006 22h12a.666.666 0 00.667-.667V20a.667.667 0 00-.667-.667z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChessPawnSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
