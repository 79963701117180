import * as React from "react";

function SvgChessQueenSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 6.375a2.187 2.187 0 100-4.374 2.187 2.187 0 000 4.374zM18.875 19.5H5.125a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h13.75a.625.625 0 00.625-.625v-1.25a.625.625 0 00-.625-.625zm2.846-10.306l-1.113-.622c-.29-.195-.66-.096-.87.183a1.858 1.858 0 01-1.846.712c-.904-.168-1.517-1.024-1.517-1.944A.523.523 0 0015.852 7h-1.515a.508.508 0 00-.502.387 1.876 1.876 0 01-3.67 0A.506.506 0 009.663 7H8.148a.523.523 0 00-.523.523c0 1.004-.742 1.905-1.745 1.973a1.855 1.855 0 01-1.623-.748c-.206-.277-.575-.37-.862-.178l-1.116.625a.625.625 0 00-.213.8l4.006 8.255h11.856l4.006-8.257a.625.625 0 00-.213-.8z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChessQueenSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
