import * as React from "react";

function SvgNotEqualSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.5 10.125c.69 0 1.25-.56 1.25-1.25v-1.25c0-.69-.56-1.25-1.25-1.25h-.933l2.026-2.61a.625.625 0 00-.116-.876l-.992-.76a.625.625 0 00-.877.116l-3.206 4.13H4.5c-.69 0-1.25.56-1.25 1.25v1.25c0 .69.56 1.25 1.25 1.25h7.99l-2.91 3.75H4.5c-.69 0-1.25.56-1.25 1.25v1.25c0 .69.56 1.25 1.25 1.25h2.168l-2.027 2.61a.625.625 0 00.116.876l.993.76c.274.21.666.158.876-.116l3.207-4.13H19.5c.69 0 1.25-.56 1.25-1.25v-1.25c0-.69-.56-1.25-1.25-1.25h-6.756l2.911-3.75H19.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgNotEqualSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
