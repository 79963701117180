import * as React from "react";

function SvgPillsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M5.889 4.222A3.887 3.887 0 002 8.111v7.778c0 2.15 1.74 3.889 3.889 3.889 2.15 0 3.889-1.74 3.889-3.889V8.111c0-2.15-1.74-3.889-3.889-3.889zM7.556 12H4.222V8.111a1.667 1.667 0 013.334 0V12zm4.85-1.031a.285.285 0 00-.427.027c-1.572 2.17-1.402 5.213.553 7.167 1.954 1.955 4.996 2.125 7.166.552.14-.1.15-.305.028-.427l-7.32-7.32zm7.98-.66c-1.955-1.955-4.997-2.125-7.167-.552a.283.283 0 00-.028.427l7.32 7.32a.285.285 0 00.427-.028c1.573-2.174 1.406-5.212-.552-7.167z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPillsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
