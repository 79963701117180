import * as React from "react";

function SvgPrescriptionSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M16.877 16.285l3.485-3.484a.714.714 0 000-1.01l-1.01-1.01a.714.714 0 00-1.01 0l-3.485 3.484-3.748-3.748c2.11-.267 3.748-2.049 3.748-4.231A4.286 4.286 0 0010.57 2H4.143a.714.714 0 00-.714.714v11.429c0 .394.32.714.714.714H5.57c.395 0 .715-.32.715-.714V10.57h.837l5.714 5.714-3.485 3.485a.714.714 0 000 1.01l1.01 1.01c.28.28.732.28 1.01 0l3.485-3.485 3.485 3.485a.714.714 0 001.01 0l1.01-1.01a.714.714 0 000-1.01l-3.485-3.485zM6.286 4.857h4.285A1.43 1.43 0 0112 6.286a1.43 1.43 0 01-1.429 1.428H6.286V4.857z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPrescriptionSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
