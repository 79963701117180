import * as React from "react";

function SvgCameraSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 7.625v11.25c0 1.035-.84 1.875-1.875 1.875H3.875A1.875 1.875 0 012 18.875V7.625C2 6.59 2.84 5.75 3.875 5.75h3.438l.48-1.285A1.872 1.872 0 019.547 3.25h4.902c.781 0 1.48.484 1.754 1.215l.485 1.285h3.437C21.16 5.75 22 6.59 22 7.625zm-5.313 5.625A4.691 4.691 0 0012 8.562a4.691 4.691 0 00-4.688 4.688A4.691 4.691 0 0012 17.938a4.691 4.691 0 004.688-4.688zm-1.25 0A3.443 3.443 0 0112 16.688a3.443 3.443 0 01-3.438-3.438A3.443 3.443 0 0112 9.812a3.443 3.443 0 013.438 3.438z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCameraSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
