import * as React from "react";

function SvgUserCircleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 3.871a3.548 3.548 0 110 7.097 3.548 3.548 0 010-7.097zm0 13.87a7.727 7.727 0 01-5.907-2.75c.758-1.426 2.242-2.41 3.972-2.41.096 0 .193.016.286.044.524.17 1.072.278 1.649.278s1.129-.109 1.65-.278a.987.987 0 01.285-.044c1.73 0 3.214.984 3.972 2.41A7.727 7.727 0 0112 19.742z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgUserCircleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
