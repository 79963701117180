import * as React from "react";

function SvgBreadSliceSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 3.111c-6.25 0-10 3.243-10 5.873 0 1.052.842 1.905 2.222 1.905v8.889c0 .613.56 1.11 1.25 1.11h13.056c.69 0 1.25-.497 1.25-1.11v-8.89c1.38 0 2.222-.852 2.222-1.904 0-2.63-3.75-5.873-10-5.873z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBreadSliceSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
