import * as React from "react";

function SvgFolderOpenSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.885 13.253l-2.515 4.311a2.223 2.223 0 01-1.919 1.103H3.563a.833.833 0 01-.72-1.254l2.515-4.31A2.222 2.222 0 017.278 12h13.887a.833.833 0 01.72 1.253zM7.278 10.89h11.389V9.222c0-.92-.747-1.666-1.667-1.666h-5.556L9.222 5.333H3.667C2.747 5.333 2 6.08 2 7v9.654l2.399-4.111a3.345 3.345 0 012.879-1.654z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFolderOpenSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
