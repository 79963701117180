import * as React from "react";

function SvgUserMdSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 12a5 5 0 100-10 5 5 0 000 10zm-4.688 6.563c0 .519.418.937.938.937s.938-.418.938-.938a.935.935 0 00-.938-.937.935.935 0 00-.938.938zm8.438-5.29v1.915a3.13 3.13 0 012.5 3.062v1.629c0 .297-.21.555-.504.613l-1.258.25a.31.31 0 01-.367-.246L16 19.883a.309.309 0 01.246-.367l.754-.153V18.25c0-2.453-3.75-2.543-3.75.074v1.043l.754.152c.168.036.277.2.246.368l-.121.613a.317.317 0 01-.367.246l-1.219-.164a.624.624 0 01-.54-.621V18.25a3.133 3.133 0 012.5-3.063v-1.765c-.085.027-.171.043-.257.074a6.773 6.773 0 01-4.484 0 4.31 4.31 0 00-.883-.203v3.188a2.18 2.18 0 011.562 2.085c0 1.207-.98 2.188-2.187 2.188a2.189 2.189 0 01-2.188-2.188c0-.988.66-1.816 1.563-2.085V13.34c-2.484.418-4.379 2.558-4.379 5.16v1.75c0 .965.785 1.75 1.75 1.75h14c.965 0 1.75-.785 1.75-1.75V18.5c0-2.813-2.219-5.09-5-5.227z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgUserMdSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
