import * as React from "react";

function SvgStarOfDavidSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.784 12l2.079-3.492c.563-.945-.133-2.133-1.25-2.133h-4.177L13.25 2.704A1.44 1.44 0 0012 2a1.44 1.44 0 00-1.25.704L8.564 6.375H4.387c-1.117 0-1.813 1.188-1.25 2.133L5.216 12l-2.08 3.492c-.562.945.134 2.133 1.251 2.133h4.177l2.186 3.671c.279.47.764.704 1.25.704a1.44 1.44 0 001.25-.704l2.186-3.671h4.177c1.117 0 1.813-1.188 1.25-2.133L18.784 12zm-.499-3.438l-.773 1.3-.774-1.3h1.547zM16.24 12l-2.047 3.438H9.808L7.76 12l2.046-3.438h4.385L16.24 12zM12 4.88l.89 1.495h-1.78L12 4.88zM5.715 8.563h1.547l-.774 1.299-.773-1.3zm0 6.874l.773-1.299.774 1.3H5.715zM12 19.12l-.89-1.495h1.78L12 19.12zm4.738-3.683l.773-1.299.774 1.3h-1.547z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgStarOfDavidSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
