import * as React from "react";

function SvgBarsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2.714 6.464h18.572c.394 0 .714-.32.714-.714V3.964a.714.714 0 00-.714-.714H2.714A.714.714 0 002 3.964V5.75c0 .395.32.714.714.714zm0 7.143h18.572c.394 0 .714-.32.714-.714v-1.786a.714.714 0 00-.714-.714H2.714a.714.714 0 00-.714.714v1.786c0 .394.32.714.714.714zm0 7.143h18.572c.394 0 .714-.32.714-.714V18.25a.714.714 0 00-.714-.714H2.714A.714.714 0 002 18.25v1.786c0 .394.32.714.714.714z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBarsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
