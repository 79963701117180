import * as React from "react";

function SvgLaughBeamSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm.968 8.04c.133-1.697 1.298-2.879 2.258-2.879s2.125 1.182 2.258 2.88c.028.346-.436.48-.6.18l-.384-.685c-.31-.552-.774-.87-1.27-.87s-.96.318-1.27.87l-.383.686c-.17.298-.637.165-.61-.182zm-6.452 0c.133-1.697 1.299-2.879 2.258-2.879.96 0 2.125 1.182 2.258 2.88.028.346-.435.48-.6.18l-.384-.685c-.31-.552-.774-.87-1.27-.87s-.96.318-1.27.87l-.383.686c-.173.298-.637.161-.609-.182zm11.569 3.976a5.812 5.812 0 01-5.762 5.08h-.646a5.812 5.812 0 01-5.762-5.08.648.648 0 01.641-.726h10.887c.388 0 .69.339.642.726z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLaughBeamSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
