import * as React from "react";

function SvgStickyNoteWrittenSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 14.857h-6.071c-.59 0-1.072.482-1.072 1.072V22H3.071A1.069 1.069 0 012 20.929V3.07C2 2.478 2.478 2 3.071 2H20.93C21.522 2 22 2.478 22 3.071v11.786zm-4.688 6.83l4.375-4.375c.201-.2.313-.473.313-.754v-.272h-5.714V22h.268c.285 0 .558-.112.759-.313zM6 10.5c0-.4.333-.5.5-.5h7c.167 0 .5.1.5.5s-.333.5-.5.5h-7c-.167 0-.5-.1-.5-.5zm.5 2.5c-.167 0-.5.1-.5.5s.333.5.5.5h3c.167 0 .5-.1.5-.5s-.333-.5-.5-.5h-3zM6 7.5c0-.4.333-.5.5-.5h11c.167 0 .5.1.5.5s-.333.5-.5.5h-11c-.167 0-.5-.1-.5-.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgStickyNoteWrittenSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
