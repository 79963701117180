import * as React from "react";

function SvgChalkboardOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M5 6h14v11h2V5.25C21 4.56 20.44 4 19.75 4H4.25C3.56 4 3 4.56 3 5.25V17h2V6zm16.5 12H17v-2h-6v2H2.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h19a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChalkboardOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
