import * as React from "react";

function SvgArrowCircleUpSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 12C2 6.476 6.476 2 12 2s10 4.476 10 10-4.476 10-10 10S2 17.524 2 12zm5.79 1.165l2.92-3.044v7.363c0 .536.431.968.967.968h.646a.965.965 0 00.967-.968V10.12l2.92 3.044c.375.392 1 .4 1.383.017l.44-.444a.964.964 0 000-1.367l-5.348-5.35a.964.964 0 00-1.367 0l-5.354 5.35a.964.964 0 000 1.367l.44.444a.974.974 0 001.386-.017z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgArrowCircleUpSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
