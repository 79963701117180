import * as React from "react";

function SvgFlushedSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.871 9.742a.965.965 0 00-.968.968c0 .536.432.967.968.967a.965.965 0 00.968-.967.965.965 0 00-.968-.968zm-7.742 0a.965.965 0 00-.968.968c0 .536.432.967.968.967a.965.965 0 00.968-.967.965.965 0 00-.968-.968zM12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm-6.774 8.71a2.901 2.901 0 012.903-2.904 2.901 2.901 0 012.903 2.904 2.901 2.901 0 01-2.903 2.903 2.901 2.901 0 01-2.903-2.903zm9.355 7.096H9.419c-.854 0-.854-1.29 0-1.29h5.162c.854 0 .854 1.29 0 1.29zm1.29-4.193a2.901 2.901 0 01-2.903-2.903 2.901 2.901 0 012.903-2.904 2.901 2.901 0 012.903 2.904 2.901 2.901 0 01-2.903 2.903z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFlushedSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
