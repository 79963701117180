import * as React from "react";

function SvgListOlSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M4.413 17.664l.683-.787a.778.778 0 00.199-.554v-.13c0-.287-.15-.443-.443-.443H2.625a.312.312 0 00-.313.313v.625a.313.313 0 00.313.312h.892a6.156 6.156 0 00-.43.48l-.219.274c-.156.198-.205.396-.11.581l.042.076c.117.225.245.308.478.308h.185c.403 0 .623.095.623.355 0 .184-.165.32-.561.32a1.621 1.621 0 01-.605-.121c-.253-.151-.458-.137-.61.122l-.217.364c-.146.239-.125.457.102.622.301.183.796.369 1.446.369 1.334 0 1.894-.889 1.894-1.723-.001-.562-.356-1.163-1.122-1.363zm16.962-6.914h-12.5a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h12.5a.624.624 0 00.625-.625v-1.25a.624.624 0 00-.625-.625zm0-6.25h-12.5a.625.625 0 00-.625.625v1.25A.625.625 0 008.875 7h12.5A.625.625 0 0022 6.375v-1.25a.625.625 0 00-.625-.625zm0 12.5h-12.5a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h12.5a.624.624 0 00.625-.625v-1.25a.624.624 0 00-.625-.625zM2.625 8.25h2.5a.312.312 0 00.313-.313v-.625A.312.312 0 005.125 7H4.5V3.562a.312.312 0 00-.313-.312H3.25a.312.312 0 00-.279.173l-.312.625a.313.313 0 00.279.452h.312V7h-.625a.312.312 0 00-.313.313v.625a.312.312 0 00.313.312zm-.153 6.25h2.653a.312.312 0 00.313-.313v-.624a.313.313 0 00-.313-.313h-1.51c.128-.402 1.887-.73 1.887-2.205 0-1.135-.976-1.545-1.737-1.545-.834 0-1.32.39-1.58.732-.17.219-.117.424.11.6l.334.27c.22.178.43.096.63-.096a.526.526 0 01.37-.15c.13 0 .362.061.362.342.001.497-1.991.853-1.991 2.7v.156c0 .29.198.446.472.446z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgListOlSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
