import * as React from "react";

function SvgPrescriptionBottleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M5.75 9.5h4.688c.171 0 .312.14.312.313v.624c0 .172-.14.313-.313.313H5.75v2.5h4.688c.171 0 .312.14.312.313v.624c0 .172-.14.313-.313.313H5.75V17h4.688c.171 0 .312.14.312.313v.625c0 .171-.14.312-.313.312H5.75v2.5c0 .688.563 1.25 1.25 1.25h10c.688 0 1.25-.563 1.25-1.25V7H5.75v2.5zM18.563 2H5.438a.94.94 0 00-.938.938v1.874a.94.94 0 00.938.938h13.125a.94.94 0 00.937-.938V2.938A.94.94 0 0018.562 2z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPrescriptionBottleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
