import * as React from "react";

function SvgAngleDoubleUpSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.853 11.985l6.822 6.822a1.199 1.199 0 010 1.7l-1.134 1.134a1.199 1.199 0 01-1.7 0L12 16.811l-4.836 4.835a1.199 1.199 0 01-1.7 0l-1.139-1.133a1.199 1.199 0 010-1.7l6.822-6.823a1.2 1.2 0 011.706-.005zm-1.706-9.631L4.325 9.176a1.199 1.199 0 000 1.7l1.134 1.134a1.199 1.199 0 001.7 0l4.836-4.836 4.836 4.836a1.199 1.199 0 001.7 0l1.134-1.134a1.199 1.199 0 000-1.7l-6.822-6.822a1.193 1.193 0 00-1.696 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAngleDoubleUpSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
