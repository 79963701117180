import * as React from "react";

function SvgEjectSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 17.714v2.857c0 .79-.64 1.429-1.429 1.429H3.43C2.639 22 2 21.36 2 20.571v-2.857c0-.789.64-1.428 1.429-1.428H20.57c.79 0 1.429.64 1.429 1.428zM4.145 14.857h15.71c1.859 0 2.838-2.217 1.579-3.59L13.58 2.694a2.144 2.144 0 00-3.16 0l-7.854 8.571c-1.257 1.37-.284 3.591 1.58 3.591z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgEjectSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
