import * as React from "react";

function SvgHandsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.4 10.2A1.001 1.001 0 007 10c-.44.331-.531.96-.2 1.4l1.19 1.588a.5.5 0 01-.046.653l-.4.4a.497.497 0 01-.738-.035L4 10.637V6a.999.999 0 10-2 0v6.825c0 .34.116.672.328.938l3.253 4.196c.156.204.263.435.325.678a.49.49 0 00.478.363H10.5c.275 0 .5-.225.5-.5V15c0-.866-.281-1.706-.8-2.4l-1.8-2.4zM21 5c-.553 0-1 .447-1 1v4.637l-2.806 3.37a.502.502 0 01-.738.034l-.4-.4a.5.5 0 01-.047-.653L17.2 11.4c.331-.44.24-1.069-.2-1.4a1.001 1.001 0 00-1.4.2l-1.8 2.4A4.006 4.006 0 0013 15v3.5c0 .275.225.5.5.5h4.116a.492.492 0 00.478-.363c.062-.243.168-.474.325-.678l3.253-4.197c.212-.265.328-.596.328-.937V6c0-.553-.447-1-1-1z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHandsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
