import * as React from "react";

function SvgSkullSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.477 2 2 5.918 2 10.75c0 2.738 1.441 5.18 3.691 6.785.375.27.594.707.528 1.168l-.367 2.586a.62.62 0 00.613.711H9.5v-2.188c0-.171.14-.312.313-.312h.624c.172 0 .313.14.313.313V22h2.5v-2.188c0-.171.14-.312.313-.312h.624c.172 0 .313.14.313.313V22h3.035a.62.62 0 00.613-.71l-.367-2.587c-.066-.457.149-.898.528-1.168C20.559 15.93 22 13.488 22 10.75 22 5.918 17.523 2 12 2zM8.25 14.5a2.502 2.502 0 01-2.5-2.5c0-1.379 1.121-2.5 2.5-2.5s2.5 1.121 2.5 2.5-1.121 2.5-2.5 2.5zm7.5 0a2.502 2.502 0 01-2.5-2.5c0-1.379 1.121-2.5 2.5-2.5s2.5 1.121 2.5 2.5-1.121 2.5-2.5 2.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSkullSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
