import * as React from "react";

function SvgCalculatorSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.875 2H5.125c-1 0-1.875.875-1.875 1.875v16.25c0 1 .875 1.875 1.875 1.875h13.75c1 0 1.875-.875 1.875-1.875V3.875c0-1-.875-1.875-1.875-1.875zM8.25 19c0 .25-.25.5-.5.5h-1.5c-.25 0-.5-.25-.5-.5v-1.5c0-.25.25-.5.5-.5h1.5c.25 0 .5.25.5.5V19zm0-5c0 .25-.25.5-.5.5h-1.5c-.25 0-.5-.25-.5-.5v-1.5c0-.25.25-.5.5-.5h1.5c.25 0 .5.25.5.5V14zm5 5c0 .25-.25.5-.5.5h-1.5c-.25 0-.5-.25-.5-.5v-1.5c0-.25.25-.5.5-.5h1.5c.25 0 .5.25.5.5V19zm0-5c0 .25-.25.5-.5.5h-1.5c-.25 0-.5-.25-.5-.5v-1.5c0-.25.25-.5.5-.5h1.5c.25 0 .5.25.5.5V14zm5 5c0 .25-.25.5-.5.5h-1.5c-.25 0-.5-.25-.5-.5v-6.5c0-.25.25-.5.5-.5h1.5c.25 0 .5.25.5.5V19zm0-10c0 .25-.25.5-.5.5H6.25c-.25 0-.5-.25-.5-.5V5c0-.25.25-.5.5-.5h11.5c.25 0 .5.25.5.5v4z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCalculatorSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
