import * as React from "react";

function SvgDogSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.393 10.126l5.857 2.091v8.533a.624.624 0 01-.625.625h-2.5a.624.624 0 01-.625-.625v-4.375H8.25v4.375a.625.625 0 01-.625.625h-2.5a.625.625 0 01-.625-.625v-8.355C3.048 11.877 2 10.503 2 8.875a1.25 1.25 0 012.5 0 1.252 1.252 0 001.25 1.25h6.643zM22 5.75V7a2.5 2.5 0 01-2.5 2.5h-1.25v1.39l-5-1.786V3.25a.625.625 0 011.067-.442l1.065 1.067h2.095c.427 0 .928.309 1.118.69l.28.56h2.5A.625.625 0 0122 5.75zm-4.375 0a.626.626 0 10-1.251 0 .626.626 0 001.251 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDogSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
