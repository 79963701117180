import * as React from "react";

function SvgCopySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.75 19.5v1.563c0 .517-.42.937-.938.937H4.188a.937.937 0 01-.938-.938V6.688c0-.517.42-.937.938-.937H7v11.563A2.19 2.19 0 009.188 19.5h6.562zm0-13.438V2H9.187a.937.937 0 00-.937.938v14.374c0 .518.42.938.938.938h10.624c.518 0 .938-.42.938-.938V7h-4.063a.94.94 0 01-.937-.938zm4.725-1.212L17.9 2.275A.938.938 0 0017.237 2H17v3.75h3.75v-.237a.938.938 0 00-.275-.663z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCopySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
