import * as React from "react";

function SvgNewspaperOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.167 5.333H5.889a1.67 1.67 0 00-1.571 1.111H2.833A.833.833 0 002 7.278v9.444c0 1.074.87 1.945 1.944 1.945h17.223c.46 0 .833-.373.833-.834V6.167a.833.833 0 00-.833-.834zm-17.5 11.39V8.11h.555v8.611a.278.278 0 01-.555 0zM20.333 17H5.87c.013-.09.02-.183.02-.278V7h14.444v10zm-12.36-4.167h4.721c.23 0 .417-.186.417-.416V9.083a.417.417 0 00-.417-.416H7.972a.417.417 0 00-.416.416v3.334c0 .23.186.416.416.416zm.971-2.777h2.778v1.388H8.944v-1.389zm-1.388 4.86v-.833c0-.23.186-.416.416-.416h4.722c.23 0 .417.186.417.416v.834c0 .23-.187.416-.417.416H7.972a.417.417 0 01-.416-.416zm6.666 0v-.833c0-.23.187-.416.417-.416h3.611c.23 0 .417.186.417.416v.834c0 .23-.187.416-.417.416h-3.611a.417.417 0 01-.417-.416zm0-5v-.833c0-.23.187-.416.417-.416h3.611c.23 0 .417.186.417.416v.834c0 .23-.187.416-.417.416h-3.611a.417.417 0 01-.417-.416zm0 2.5v-.833c0-.23.187-.416.417-.416h3.611c.23 0 .417.186.417.416v.834c0 .23-.187.416-.417.416h-3.611a.417.417 0 01-.417-.416z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgNewspaperOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
