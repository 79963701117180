import * as React from "react";

function SvgLanguageSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6.753 11.381c-.11-.378-.244-1.037-.244-1.037h-.015s-.135.66-.244 1.037l-.347 1.172h1.19l-.34-1.172zM21.25 7H12.5v10h8.75c.416 0 .75-.334.75-.75v-8.5a.748.748 0 00-.75-.75zm-.75 3.75a.376.376 0 01-.375.375h-.356c-.216.738-.678 1.481-1.335 2.184.263.2.535.391.816.563a.376.376 0 01.128.506l-.247.434a.376.376 0 01-.522.135 10.885 10.885 0 01-1.106-.778c-.34.272-.71.534-1.106.778a.376.376 0 01-.522-.134l-.247-.435a.375.375 0 01.131-.506c.291-.178.563-.366.816-.563a7.264 7.264 0 01-.656-.803.374.374 0 01.115-.534l.204-.122.228-.134a.378.378 0 01.5.106c.156.219.337.437.543.653.422-.444.744-.903.938-1.35h-3.572a.376.376 0 01-.375-.375v-.5c0-.206.169-.375.375-.375h2v-.5c0-.206.169-.375.375-.375h.5c.206 0 .375.169.375.375v.5h2c.206 0 .375.169.375.375v.5zM2 7.75v8.5c0 .416.334.75.75.75h8.75V7H2.75a.748.748 0 00-.75.75zm1.84 6.753L5.639 9.22a.374.374 0 01.356-.253h1.015a.38.38 0 01.357.253l1.796 5.284a.376.376 0 01-.356.497h-.715a.376.376 0 01-.36-.269l-.293-.997H5.556l-.284.994a.378.378 0 01-.36.272h-.715a.377.377 0 01-.356-.497z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLanguageSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
