import * as React from "react";

function SvgFilePowerpointSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.066 12.594c.344 0 .606.105.793.316.375.426.383 1.277-.007 1.723-.192.219-.465.332-.825.332h-1.05v-2.371h1.09zm7.16-6.492l-3.828-3.829A.937.937 0 0014.734 2H14.5v5h5v-.238a.935.935 0 00-.273-.66zm-5.976 1.21V2H5.437a.935.935 0 00-.937.938v18.125c0 .519.418.937.938.937h13.125c.519 0 .937-.418.937-.938V8.25h-5.313a.94.94 0 01-.937-.938zm2.07 6.454c0 3.527-3.468 3.03-4.34 3.03v2.235a.47.47 0 01-.468.469H9.309a.47.47 0 01-.47-.469v-7.804a.47.47 0 01.47-.47h3.164c1.738 0 2.847 1.282 2.847 3.009z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFilePowerpointSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
