import * as React from "react";

function SvgChartPieSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.404 13.176H12.68l5.81 5.81a.59.59 0 00.816.025 8.805 8.805 0 002.688-5.178c.05-.348-.239-.657-.59-.657zm-.582-2.382a8.845 8.845 0 00-8.204-8.204.592.592 0 00-.618.595v8.227h8.227a.591.591 0 00.595-.618zm-10.587 2.382V4.453c0-.352-.308-.64-.656-.59-4.38.618-7.73 4.446-7.574 9.033.16 4.71 4.217 8.574 8.93 8.515a8.755 8.755 0 004.973-1.619c.29-.206.31-.633.058-.885l-5.73-5.73z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChartPieSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
