import * as React from "react";

function SvgDraftingCompassSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.852 15.454a12.378 12.378 0 01-3.224 1.916l2.124 3.679 2.107.9a.625.625 0 00.866-.5L22 19.174l-2.148-3.72zm1.66-3.694a.627.627 0 00-.236-.875l-1.097-.608a.626.626 0 00-.83.224A8.758 8.758 0 0112 14.5a8.685 8.685 0 01-2.711-.45l2.631-4.558c.027 0 .053.008.08.008.027 0 .053-.008.08-.008l1.995 3.457a7.441 7.441 0 003.227-1.911L15.28 7.534a3.702 3.702 0 00.471-1.784 3.75 3.75 0 10-7.5 0c0 .65.18 1.251.471 1.784l-2.668 4.62a8.733 8.733 0 01-1.316-1.522.627.627 0 00-.835-.209l-1.084.628a.626.626 0 00-.22.878 11.209 11.209 0 002.187 2.422L2 19.174l.275 2.275c.05.414.482.664.866.5l2.107-.9 2.766-4.79c1.257.478 2.604.74 3.986.74 3.87 0 7.456-1.992 9.512-5.239zM12 4.5A1.25 1.25 0 1112 7a1.25 1.25 0 010-2.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDraftingCompassSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
