import * as React from "react";

function SvgDollarSignSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M14.547 11.117l-4.22-1.234a1.152 1.152 0 01.324-2.258h2.591c.476 0 .945.145 1.336.41.238.16.559.121.762-.078l1.359-1.328a.633.633 0 00-.07-.957 5.523 5.523 0 00-3.38-1.172V2.625A.627.627 0 0012.626 2h-1.25a.627.627 0 00-.625.625V4.5h-.098a4.28 4.28 0 00-4.258 4.672c.164 1.8 1.54 3.265 3.274 3.773l4.004 1.172a1.152 1.152 0 01-.324 2.258h-2.59c-.477 0-.946-.145-1.336-.41a.61.61 0 00-.762.078L7.3 17.371a.633.633 0 00.07.957 5.524 5.524 0 003.38 1.172v1.875c0 .344.281.625.625.625h1.25a.627.627 0 00.625-.625v-1.883c1.82-.035 3.527-1.117 4.129-2.84.84-2.406-.57-4.875-2.832-5.535z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDollarSignSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
