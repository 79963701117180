import * as React from "react";

function SvgVenusDoubleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.25 8.875A5.626 5.626 0 007.625 3.25a5.626 5.626 0 00-1.25 11.11v2.015H4.969a.47.47 0 00-.469.469v1.562c0 .258.21.469.469.469h1.406v1.406c0 .258.21.469.469.469h1.562a.47.47 0 00.469-.469v-1.406h1.406a.47.47 0 00.469-.469v-1.562a.47.47 0 00-.469-.469H8.875v-2.016a5.626 5.626 0 004.375-5.484zm-8.75 0A3.13 3.13 0 017.625 5.75a3.13 3.13 0 013.125 3.125A3.13 3.13 0 017.625 12 3.13 3.13 0 014.5 8.875zm13.125 5.484v2.016h1.406a.47.47 0 01.469.469v1.562a.47.47 0 01-.469.469h-1.406v1.406a.47.47 0 01-.469.469h-1.562a.47.47 0 01-.469-.469v-1.406h-1.406a.47.47 0 01-.469-.469v-1.562a.47.47 0 01.469-.469h1.406v-2.016a5.645 5.645 0 01-2.234-1.066 6.897 6.897 0 001.254-2.23 3.112 3.112 0 002.23.937A3.13 3.13 0 0019.5 8.875a3.13 3.13 0 00-3.125-3.125c-.871 0-1.664.36-2.23.938a6.825 6.825 0 00-1.254-2.231 5.626 5.626 0 114.734 9.902z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgVenusDoubleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
