import * as React from "react";

function SvgArrowAltCircleRightOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 12c0-5.524-4.476-10-10-10S2 6.476 2 12s4.476 10 10 10 10-4.476 10-10zM3.935 12A8.062 8.062 0 0112 3.935 8.062 8.062 0 0120.064 12 8.062 8.062 0 0112 20.064 8.062 8.062 0 013.935 12zm2.904.806v-1.612c0-.267.217-.484.484-.484H12V8.008c0-.431.52-.645.827-.343l3.992 3.992c.19.19.19.496 0 .686l-3.992 3.992a.484.484 0 01-.827-.343V13.29H7.323a.485.485 0 01-.484-.483z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgArrowAltCircleRightOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
