import * as React from "react";

function SvgTextHeightSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.555 4.222h-10A.556.556 0 002 4.778V8.11a.556.556 0 00.556.556h1.11a.556.556 0 00.556-.556v-1.11h1.945v10.554h-1.39a.555.555 0 00-.555.556v1.111a.556.556 0 00.556.556h5.555a.556.556 0 00.556-.556v-1.111a.556.556 0 00-.556-.556H8.944V7h1.945v1.111a.555.555 0 00.555.556h1.111a.556.556 0 00.556-.556V4.778a.555.555 0 00-.556-.556zm8.889 11.667h-1.667V8.11h1.667c.497 0 .74-.6.393-.948l-2.778-2.778a.555.555 0 00-.786 0l-2.777 2.778c-.324.323-.15.948.393.948h1.666v7.778H15.89a.556.556 0 00-.393.948l2.777 2.778a.556.556 0 00.786 0l2.778-2.778c.324-.323.15-.948-.393-.948z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTextHeightSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
