import * as React from "react";

function SvgBaconSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M9.602 14.791c1.211-1.211 1.534-2.072 1.876-2.986.369-.982.75-1.997 2.131-3.38 1.381-1.381 2.397-1.762 3.38-2.13.83-.313 1.62-.614 2.666-1.576l-1.308-1.298a1.076 1.076 0 00-1.395-.098c-.473.35-.873.5-1.423.705-4.953 1.845-6.263 5.725-6.562 6.517-1.033 2.737-3.109 3.288-3.427 3.406-.864.322-1.9.708-3.162 1.749a1.058 1.058 0 00-.063 1.564l1.242 1.233c1.221-1.132 2.159-1.492 3.061-1.83.912-.342 1.773-.665 2.984-1.876zm12.082-8.055l-1.241-1.233c-1.222 1.133-2.159 1.493-3.064 1.833-.912.342-1.773.665-2.984 1.875-1.212 1.21-1.535 2.072-1.875 2.986-.37.984-.751 1.998-2.133 3.38-1.381 1.38-2.395 1.76-3.378 2.128-.828.313-1.618.614-2.664 1.574l1.308 1.3a1.076 1.076 0 001.396.098c.472-.35.87-.498 1.421-.703 4.937-1.84 6.25-5.698 6.56-6.517 1.032-2.735 3.109-3.287 3.428-3.406.864-.322 1.9-.708 3.164-1.75a1.057 1.057 0 00.063-1.564z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBaconSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
