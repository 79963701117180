import * as React from "react";

function SvgServerSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.75 8.25H3.25C2.56 8.25 2 7.69 2 7V4.5c0-.69.56-1.25 1.25-1.25h17.5c.69 0 1.25.56 1.25 1.25V7c0 .69-.56 1.25-1.25 1.25zm-1.875-3.438a.937.937 0 100 1.875.937.937 0 000-1.875zm-2.5 0a.937.937 0 100 1.875.937.937 0 000-1.875zM20.75 14.5H3.25c-.69 0-1.25-.56-1.25-1.25v-2.5c0-.69.56-1.25 1.25-1.25h17.5c.69 0 1.25.56 1.25 1.25v2.5c0 .69-.56 1.25-1.25 1.25zm-1.875-3.438a.937.937 0 100 1.875.937.937 0 000-1.874zm-2.5 0a.937.937 0 100 1.875.937.937 0 000-1.874zm4.375 9.688H3.25c-.69 0-1.25-.56-1.25-1.25V17c0-.69.56-1.25 1.25-1.25h17.5c.69 0 1.25.56 1.25 1.25v2.5c0 .69-.56 1.25-1.25 1.25zm-1.875-3.438a.937.937 0 100 1.875.937.937 0 000-1.875zm-2.5 0a.937.937 0 100 1.875.937.937 0 000-1.875z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgServerSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
