import * as React from "react";

function SvgEnvelopeOpenTextSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.875 10.438h6.25c.345 0 .625-.28.625-.625v-.625a.625.625 0 00-.625-.625h-6.25a.625.625 0 00-.625.625v.625c0 .345.28.625.625.625zm-.625 3.124c0 .346.28.626.625.626h6.25c.345 0 .625-.28.625-.626v-.624a.625.625 0 00-.625-.625h-6.25a.625.625 0 00-.625.625v.624zM12 18.294a3.12 3.12 0 01-1.83-.593L2 11.799v8.326C2 21.161 2.84 22 3.875 22h16.25C21.16 22 22 21.16 22 20.125v-8.326l-8.17 5.902a3.124 3.124 0 01-1.83.593zm9.282-9.929a73.07 73.07 0 00-1.157-.89V5.75c0-1.035-.84-1.875-1.875-1.875h-3.03l-.353-.256C14.211 3.139 12.907 1.986 12 2c-.906-.014-2.21 1.14-2.868 1.619l-.353.256H5.75c-1.036 0-1.875.84-1.875 1.875v1.724c-.483.365-.81.62-1.157.891A1.875 1.875 0 002 9.841v.416l3.75 2.709V5.75h12.5v7.216l3.75-2.71v-.415c0-.576-.265-1.12-.718-1.476z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgEnvelopeOpenTextSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
