import * as React from "react";

function SvgWindowRestoreSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 3.875v11.25C22 16.16 21.16 17 20.125 17H18.25V8.875a3.13 3.13 0 00-3.125-3.125H7V3.875C7 2.84 7.84 2 8.875 2h11.25C21.16 2 22 2.84 22 3.875zm-5 5v11.25C17 21.16 16.16 22 15.125 22H3.875A1.875 1.875 0 012 20.125V8.875C2 7.84 2.84 7 3.875 7h11.25C16.16 7 17 7.84 17 8.875zm-2.656 1.094a.47.47 0 00-.469-.469H4.969a.47.47 0 00-.469.469V12h9.844V9.969z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgWindowRestoreSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
