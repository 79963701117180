import * as React from "react";

function SvgCalendarTimesSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.281 8.25H3.72a.47.47 0 01-.469-.469V6.375c0-1.035.84-1.875 1.875-1.875H7V2.469A.47.47 0 017.469 2H9.03a.47.47 0 01.469.469V4.5h5V2.469A.47.47 0 0114.969 2h1.562a.47.47 0 01.469.469V4.5h1.875c1.035 0 1.875.84 1.875 1.875v1.406a.47.47 0 01-.469.469zM3.72 9.5H20.28a.47.47 0 01.469.469v10.156c0 1.035-.84 1.875-1.875 1.875H5.125a1.875 1.875 0 01-1.875-1.875V9.969a.47.47 0 01.469-.469zm10.05 6.25l1.88-1.879a.47.47 0 000-.664l-1.106-1.105a.47.47 0 00-.664 0L12 13.98l-1.879-1.878a.47.47 0 00-.664 0l-1.105 1.105a.47.47 0 000 .664l1.878 1.879-1.878 1.879a.47.47 0 000 .664l1.105 1.105a.47.47 0 00.664 0L12 17.52l1.879 1.878a.47.47 0 00.664 0l1.105-1.105a.47.47 0 000-.664L13.77 15.75z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCalendarTimesSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
