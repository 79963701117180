import * as React from "react";

function SvgBirthdayCakeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.75 17c-1.095 0-1.221-1.25-2.91-1.25-1.697 0-1.83 1.25-2.92 1.25-1.082 0-1.229-1.25-2.92-1.25-1.674 0-1.845 1.25-2.91 1.25-1.1 0-1.219-1.25-2.92-1.25-1.701 0-1.822 1.25-2.92 1.25v-3.125C3.25 12.84 4.09 12 5.125 12h.625V6.375h2.5V12h2.5V6.375h2.5V12h2.5V6.375h2.5V12h.625c1.035 0 1.875.84 1.875 1.875V17zm0 5H3.25v-3.75c1.694 0 1.827-1.25 2.92-1.25 1.092 0 1.22 1.25 2.92 1.25 1.673 0 1.844-1.25 2.91-1.25 1.1 0 1.219 1.25 2.92 1.25 1.694 0 1.827-1.25 2.92-1.25 1.074 0 1.22 1.25 2.91 1.25V22zM7 5.75c-.693 0-1.25-.557-1.25-1.25C5.75 3.29 7 3.602 7 2c.469 0 1.25 1.152 1.25 2.188C8.25 5.223 7.693 5.75 7 5.75zm5 0c-.693 0-1.25-.557-1.25-1.25C10.75 3.29 12 3.602 12 2c.469 0 1.25 1.152 1.25 2.188 0 1.035-.557 1.562-1.25 1.562zm5 0c-.693 0-1.25-.557-1.25-1.25C15.75 3.29 17 3.602 17 2c.469 0 1.25 1.152 1.25 2.188 0 1.035-.557 1.562-1.25 1.562z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBirthdayCakeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
