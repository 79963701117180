import * as React from "react";

function SvgRingSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 4.5c-5.7 0-10 2.418-10 5.625v3.833c0 3.06 4.477 5.542 10 5.542s10-2.481 10-5.542v-3.833C22 6.918 17.7 4.5 12 4.5zM12 7c4.142 0 7.5 1.4 7.5 3.125 0 .362-.155.708-.426 1.03C17.318 10.134 14.82 9.5 12 9.5c-2.821 0-5.318.633-7.074 1.656-.27-.323-.426-.67-.426-1.031C4.5 8.399 7.858 7 12 7zm-5.296 5.338c1.352-.575 3.173-.963 5.296-.963s3.944.388 5.296.963c-1.357.563-3.228.912-5.296.912s-3.94-.349-5.296-.912z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgRingSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
