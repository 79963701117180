import * as React from "react";

function SvgSignatureSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.48 10c-1.618.109-3.928 1.71-5.098 2.235-.91.41-1.694.762-2.379.762-.706 0-.812-.506-.665-1.622.034-.25.365-2.476-1.335-2.379-.785.047-2.01.775-5.3 3.939l1.298-3.242c.95-2.373-1.663-4.736-4.054-3.213L2.23 7.633a.503.503 0 00-.153.69l.538.845a.497.497 0 00.69.153L5.12 8.105c.575-.366 1.272.225 1.022.847l-3.07 7.678c-.212.528.085 1.372.929 1.372.26 0 .516-.1.706-.293 1.32-1.32 4.836-4.712 6.603-6.121-.07.89-.066 1.84.644 2.62.478.524 1.166.79 2.047.79 1.113 0 2.126-.456 3.198-.938 1.032-.462 3.095-1.957 4.327-2.057A.502.502 0 0022 11.51v-1.003a.496.496 0 00-.52-.506z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSignatureSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
