import * as React from "react";

function SvgStoreSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.546 7.538l-2.107-3.364a1.04 1.04 0 00-.88-.487H5.44a1.04 1.04 0 00-.88.487L2.453 7.538c-1.088 1.738-.124 4.154 1.91 4.43a3.206 3.206 0 002.841-1.046 3.212 3.212 0 004.792 0 3.212 3.212 0 004.793 0 3.217 3.217 0 002.841 1.046c2.04-.273 3.007-2.689 1.916-4.43zm-2.354 5.501c-.325 0-.646-.049-.958-.123v3.24H5.764v-3.24a4.304 4.304 0 01-.957.123c-.195 0-.393-.013-.585-.039a4.089 4.089 0 01-.532-.117v6.39c0 .575.464 1.04 1.039 1.04h14.547c.575 0 1.04-.465 1.04-1.04v-6.39a3.33 3.33 0 01-.533.117 4.512 4.512 0 01-.591.04z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgStoreSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
