import * as React from "react";

function SvgChevronDownToBottomSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.006 15.347c.326 0 .628-.145.858-.386l8.798-9.003c.217-.218.338-.496.338-.81C22 4.495 21.505 4 20.852 4c-.302 0-.604.12-.822.338l-8.024 8.218L3.97 4.338A1.13 1.13 0 003.148 4C2.508 4 2 4.495 2 5.148c0 .314.12.592.338.81l8.798 9.003c.254.253.532.386.87.386z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 18.125c0 .621-.504 1.125-1.125 1.125H3.125a1.125 1.125 0 010-2.25h17.75c.621 0 1.125.504 1.125 1.125z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChevronDownToBottomSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
