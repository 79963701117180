import * as React from "react";

function SvgXmarkCircleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12.005 21.99c5.467 0 9.995-4.528 9.995-9.995S17.462 2 11.995 2C6.528 2 2 6.528 2 11.995s4.538 9.995 10.005 9.995zm-3.404-5.735a.848.848 0 01-.845-.867c0-.216.082-.433.247-.588l2.795-2.795L8.003 9.21a.787.787 0 01-.247-.588c0-.474.37-.836.845-.836.238 0 .434.073.588.238l2.806 2.795 2.816-2.806a.803.803 0 01.598-.247.84.84 0 01.846.835c0 .238-.083.423-.248.609l-2.805 2.795 2.795 2.785a.87.87 0 01.247.598.856.856 0 01-.856.867.885.885 0 01-.608-.258l-2.785-2.795-2.775 2.795a.866.866 0 01-.619.258z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgXmarkCircleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
