import * as React from "react";

function SvgArrowCircleLeftSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 22C6.476 22 2 17.524 2 12S6.476 2 12 2s10 4.476 10 10-4.476 10-10 10zm1.165-5.79l-3.044-2.92h7.363a.965.965 0 00.968-.967v-.646a.965.965 0 00-.968-.967H10.12l3.044-2.92c.392-.375.4-1 .017-1.383l-.444-.44a.964.964 0 00-1.367 0l-5.35 5.348a.964.964 0 000 1.367l5.35 5.35a.964.964 0 001.367 0l.444-.44a.969.969 0 00-.017-1.382z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgArrowCircleLeftSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
