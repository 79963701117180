import * as React from "react";

function SvgAddressBookSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.281 8.25a.47.47 0 00.469-.469V6.22a.47.47 0 00-.469-.469H19.5V3.875C19.5 2.84 18.66 2 17.625 2h-12.5C4.09 2 3.25 2.84 3.25 3.875v16.25C3.25 21.16 4.09 22 5.125 22h12.5c1.035 0 1.875-.84 1.875-1.875V18.25h.781a.47.47 0 00.469-.469V16.22a.47.47 0 00-.469-.469H19.5v-2.5h.781a.47.47 0 00.469-.469V11.22a.47.47 0 00-.469-.469H19.5v-2.5h.781zM11.375 7c1.379 0 2.5 1.121 2.5 2.5s-1.121 2.5-2.5 2.5a2.502 2.502 0 01-2.5-2.5c0-1.379 1.121-2.5 2.5-2.5zm4.375 9.25c0 .414-.39.75-.875.75h-7C7.391 17 7 16.664 7 16.25v-.75c0-1.242 1.176-2.25 2.625-2.25h.195a4.032 4.032 0 003.11 0h.195c1.45 0 2.625 1.008 2.625 2.25v.75z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAddressBookSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
