import * as React from "react";

function SvgHeartSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.059 4.447c-2.14-1.825-5.324-1.496-7.29.53L12 5.772l-.77-.793C9.27 2.95 6.083 2.622 3.943 4.447c-2.453 2.093-2.582 5.851-.387 8.12l7.559 7.805a1.225 1.225 0 001.77 0l7.558-7.804c2.199-2.27 2.07-6.028-.383-8.121z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHeartSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
