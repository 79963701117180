import * as React from "react";

function SvgHeartbeatSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M14.508 11.525l-1.942 3.883a.625.625 0 01-1.129-.024l-2.222-4.933-1.172 2.8H4.368l7.128 7.285a.696.696 0 001.004 0l7.132-7.285h-4.261l-.863-1.726zm5.995-6.636l-.093-.098a5.117 5.117 0 00-7.32 0L12 5.908l-1.09-1.113a5.11 5.11 0 00-7.32 0l-.093.094C1.594 6.834 1.512 9.93 3.21 12h4l1.402-3.367a.626.626 0 011.149-.015l2.273 5.05 1.914-3.824a.624.624 0 011.117 0l1.078 2.156h4.645c1.699-2.07 1.617-5.167-.286-7.112z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHeartbeatSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
