import * as React from "react";

function SvgBathSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.25 17a3.726 3.726 0 001.25 2.777v1.598a.625.625 0 00.625.625h1.25A.625.625 0 007 21.375v-.625h10v.625a.625.625 0 00.625.625h1.25a.625.625 0 00.625-.625v-1.598A3.726 3.726 0 0020.75 17v-1.875H3.25V17zm18.125-5H5.125V4.705a.83.83 0 011.417-.586l.753.753c-.513 1.167-.297 2.309.337 3.114l-.007.007a.625.625 0 000 .882l.442.442a.625.625 0 00.884 0L13.067 5.2a.625.625 0 000-.884l-.442-.442a.625.625 0 00-.884 0l-.006.007c-.806-.634-1.947-.85-3.115-.337l-.752-.753A2.705 2.705 0 003.25 4.705V12h-.625a.625.625 0 00-.625.625v.625a.625.625 0 00.625.625h18.75A.625.625 0 0022 13.25v-.625a.624.624 0 00-.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBathSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
