import * as React from "react";

function SvgCloudRainSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.25 7c-.023 0-.043.008-.063.008a3.124 3.124 0 00-3.063-3.758c-.96 0-1.808.441-2.382 1.125C12.016 2.969 10.566 2 8.875 2A4.373 4.373 0 004.5 6.375c0 .285.031.559.082.828A3.738 3.738 0 002 10.75c0 2.07 1.68 3.75 3.75 3.75h12.5a3.751 3.751 0 000-7.5zM5.437 16.617c-.5 1.735-1.562 2.203-1.562 3.426 0 1.082.84 1.957 1.875 1.957 1.035 0 1.875-.875 1.875-1.957 0-1.227-1.063-1.684-1.563-3.426a.322.322 0 00-.625 0zm6.25 0c-.5 1.735-1.562 2.203-1.562 3.426 0 1.082.84 1.957 1.875 1.957 1.035 0 1.875-.875 1.875-1.957 0-1.227-1.063-1.684-1.563-3.426a.322.322 0 00-.624 0zm6.25 0c-.5 1.735-1.562 2.203-1.562 3.426 0 1.082.84 1.957 1.875 1.957 1.035 0 1.875-.875 1.875-1.957 0-1.227-1.063-1.684-1.563-3.426a.322.322 0 00-.625 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCloudRainSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
