import * as React from "react";

function SvgLemonSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.103 2.897c-.902-.902-2.125-1.129-2.933-.658C15.869 3.58 11.09.166 5.628 5.628S3.58 15.868 2.239 18.17c-.47.808-.244 2.03.658 2.933.902.902 2.125 1.129 2.933.657 2.301-1.34 7.08 2.074 12.542-3.388S20.42 8.132 21.761 5.83c.47-.808.244-2.03-.658-2.933zm-9.576 2.834C9.254 6.3 6.3 9.253 5.73 11.527a.625.625 0 01-1.212-.304c.68-2.722 3.978-6.023 6.704-6.704a.625.625 0 11.304 1.212z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLemonSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
