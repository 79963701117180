import * as React from "react";

function SvgBoneSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.715 11.643A2.325 2.325 0 0022 9.563v-.238a2.324 2.324 0 00-4.53-.736c-.24.72-.362 1.411-1.19 1.411H7.72c-.855 0-.986-.798-1.19-1.41A2.324 2.324 0 002 9.324v.238c0 .88.498 1.686 1.285 2.08a.4.4 0 010 .714A2.325 2.325 0 002 14.438v.238a2.324 2.324 0 004.53.736C6.77 14.69 6.892 14 7.72 14h8.56c.855 0 .986.798 1.19 1.41a2.325 2.325 0 004.53-.735v-.238c0-.881-.498-1.686-1.285-2.08a.4.4 0 010-.714z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBoneSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
