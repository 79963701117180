import * as React from "react";

function SvgChevronDownSolid2(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.79 5h-1.974a.418.418 0 00-.34.18l-7.475 10.708L4.524 5.18a.418.418 0 00-.34-.18H2.212c-.171 0-.271.202-.171.347l9.279 13.292a.822.822 0 001.36 0l9.28-13.292C22.06 5.202 21.96 5 21.79 5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChevronDownSolid2);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
