import * as React from "react";

function SvgUserMinusSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.5 10.5h-6c-.275 0-.5.225-.5.5v1c0 .275.225.5.5.5h6c.275 0 .5-.225.5-.5v-1c0-.275-.225-.5-.5-.5zM9 12a4 4 0 100-8 4 4 0 000 8zm2.8 1h-.522a5.445 5.445 0 01-4.556 0H6.2A4.201 4.201 0 002 17.2v1.3A1.5 1.5 0 003.5 20h11a1.5 1.5 0 001.5-1.5v-1.3c0-2.319-1.881-4.2-4.2-4.2z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgUserMinusSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
