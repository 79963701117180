import * as React from "react";

function SvgSignSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.375 4.5H7V2.625A.627.627 0 006.375 2h-1.25a.627.627 0 00-.625.625V4.5H2.625A.627.627 0 002 5.125v1.25c0 .344.281.625.625.625H4.5v14.375c0 .344.281.625.625.625h1.25A.627.627 0 007 21.375V7h14.375A.627.627 0 0022 6.375v-1.25a.627.627 0 00-.625-.625zM8.25 17h12.5V8.25H8.25V17z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSignSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
