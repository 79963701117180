import * as React from "react";

function SvgPrayingHandsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.5 9.997c-.55 0-1 .45-1 1v2.5a.5.5 0 11-1 0v-2.392c0-.543.148-1.077.428-1.543l2.43-4.05a.993.993 0 00-1.665-1.084c-.006.007-.02.006-.025.015L6.004 9.94a2.99 2.99 0 00-.504 1.663v2.507l-2.816.939a1 1 0 00-.684.949v3c0 .338.266 1 1 1 .084 0 .17-.011.252-.033l5.6-1.457a3.667 3.667 0 002.648-3.51v-4c0-.55-.45-1-1-1zm10.816 5.055l-2.816-.94v-2.506a2.99 2.99 0 00-.504-1.664l-3.664-5.496c-.006-.009-.019-.008-.025-.015a.993.993 0 00-1.665 1.084l2.43 4.05c.28.466.428 1 .428 1.543V13.5a.5.5 0 11-1 0V11c0-.55-.45-1-1-1s-1 .45-1 1v4a3.666 3.666 0 002.648 3.51l5.6 1.458c.083.021.168.032.252.032.734 0 1-.662 1-1v-3a1 1 0 00-.684-.948z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPrayingHandsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
