import * as React from "react";

function SvgDeafSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M10.438 12.156a1.094 1.094 0 11-2.188 0 3.129 3.129 0 013.125-3.125 3.129 3.129 0 013.125 3.125 1.094 1.094 0 11-2.188 0 .939.939 0 00-1.874 0zm.937-6.875A6.883 6.883 0 004.5 12.156a1.094 1.094 0 102.187 0 4.693 4.693 0 014.688-4.687 4.693 4.693 0 014.688 4.687c0 2.936-2.774 2.747-2.813 5.61v.015c0 1.12-.911 2.032-2.031 2.032a1.094 1.094 0 000 2.187 4.224 4.224 0 004.219-4.21c.023-1.345 2.812-1.884 2.812-5.634a6.883 6.883 0 00-6.875-6.875zm10.488-2.039l-1.105-1.105a.469.469 0 00-.663 0l-3.4 3.4a.469.469 0 000 .663L17.8 7.305c.183.183.48.183.663 0l3.4-3.4a.469.469 0 000-.663zM8.6 14.295a.469.469 0 00-.663 0l-5.8 5.8a.469.469 0 000 .663l1.105 1.105c.183.183.48.183.663 0l5.8-5.8a.469.469 0 000-.663L8.6 14.295z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDeafSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
