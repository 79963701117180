import * as React from "react";

function SvgMicrochipSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.25 3.875v16.25c0 1.035-.84 1.875-1.875 1.875h-8.75a1.875 1.875 0 01-1.875-1.875V3.875C5.75 2.839 6.59 2 7.625 2h8.75c1.035 0 1.875.84 1.875 1.875zM22 6.141v.468a.235.235 0 01-.234.235h-.703v.234a.235.235 0 01-.235.234h-1.64V5.438h1.64a.235.235 0 01.235.235v.234h.703a.235.235 0 01.234.235zm0 3.75v.468a.235.235 0 01-.234.235h-.703v.234a.235.235 0 01-.235.235h-1.64V9.187h1.64a.235.235 0 01.235.235v.234h.703a.235.235 0 01.234.235zm0 3.75v.468a.235.235 0 01-.234.235h-.703v.234a.235.235 0 01-.235.235h-1.64v-1.876h1.64a.235.235 0 01.235.235v.234h.703a.234.234 0 01.234.235zm0 3.75v.468a.235.235 0 01-.234.235h-.703v.234a.235.235 0 01-.235.235h-1.64v-1.875h1.64a.235.235 0 01.235.234v.234h.703a.234.234 0 01.234.235zm-18.828-.703h1.64v1.875h-1.64a.235.235 0 01-.235-.235v-.234h-.703A.234.234 0 012 17.859v-.468a.234.234 0 01.234-.235h.704v-.234a.235.235 0 01.234-.235zm0-3.75h1.64v1.874h-1.64a.235.235 0 01-.235-.234v-.234h-.703A.234.234 0 012 14.109v-.468a.234.234 0 01.234-.235h.704v-.234a.235.235 0 01.234-.235zm0-3.75h1.64v1.874h-1.64a.235.235 0 01-.235-.234v-.234h-.703A.234.234 0 012 10.359v-.468a.234.234 0 01.234-.235h.704v-.234a.234.234 0 01.234-.235zm0-3.75h1.64v1.875h-1.64a.234.234 0 01-.235-.235v-.234h-.703A.234.234 0 012 6.609v-.468a.234.234 0 01.234-.235h.704v-.234a.234.234 0 01.234-.234z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMicrochipSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
