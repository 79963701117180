import * as React from "react";

function SvgStopwatchSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 13.875A8.124 8.124 0 0111.999 22a8.124 8.124 0 01-8.124-8.125c0-4.063 2.98-7.43 6.874-8.027V4.5H9.656a.47.47 0 01-.469-.469V2.47A.47.47 0 019.656 2h4.687a.47.47 0 01.469.469V4.03a.47.47 0 01-.469.469H13.25v1.348c1.465.226 2.801.843 3.895 1.742l1.074-1.074a.47.47 0 01.664 0l1.106 1.105a.47.47 0 010 .664l-1.149 1.149-.023.023a8.044 8.044 0 011.309 4.418zm-6.876 1.406V9.363a.47.47 0 00-.468-.468h-1.563a.47.47 0 00-.469.468v5.918c0 .258.211.469.47.469h1.562a.47.47 0 00.468-.469z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgStopwatchSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
