import * as React from "react";

function SvgBlenderPhoneSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.611 5.333h5.783L22 3.111H8.667v12.222h10l.606-2.222H15.61a.278.278 0 01-.278-.278v-.555c0-.154.125-.278.278-.278h3.965l.606-2.222h-4.57a.278.278 0 01-.279-.278v-.556c0-.153.125-.277.278-.277h4.874l.606-2.223h-5.48a.278.278 0 01-.278-.277V5.61c0-.153.125-.278.278-.278zm-8.097 9.41l-.895-2.196a.583.583 0 00-.599-.357l-1.563.154a7.458 7.458 0 010-5.13l1.563.154a.584.584 0 00.599-.356l.895-2.196a.566.566 0 00-.232-.697l-1.364-.837c-.497-.305-1.137-.188-1.524.245a9.363 9.363 0 00.073 12.586c.343.375 1.011.433 1.446.167l1.369-.84a.566.566 0 00.232-.697zm11.153 1.701h-10a2.222 2.222 0 00-2.222 2.223v1.11c0 .614.497 1.112 1.11 1.112h12.223c.613 0 1.11-.498 1.11-1.111v-1.111a2.222 2.222 0 00-2.221-2.223zm-5 3.334a1.111 1.111 0 110-2.223 1.111 1.111 0 010 2.223z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBlenderPhoneSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
