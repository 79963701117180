import * as React from "react";

function SvgGlassCheersSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.981 17.55a1.246 1.246 0 00-1.631-.675l-.69.288-1.21-3.184c1.497-1.094 2.025-3.135 1.078-4.775L16.822 4.5a1.003 1.003 0 00-1.25-.425L12 5.557 8.43 4.076a1 1 0 00-1.25.425L4.472 9.204c-.943 1.64-.418 3.68 1.079 4.771L4.34 17.16l-.69-.288a1.247 1.247 0 00-1.632.675.25.25 0 00.135.328l5.071 2.106a.247.247 0 00.325-.134 1.253 1.253 0 00-.675-1.634l-.69-.288 1.231-3.237c.138.015.275.04.41.04 1.615 0 3.106-1.034 3.543-2.665L12 9.707l.631 2.356c.438 1.631 1.928 2.665 3.544 2.665.134 0 .272-.024.41-.04l1.227 3.237-.69.288a1.25 1.25 0 00-.675 1.634c.053.128.2.188.325.134l5.075-2.106a.25.25 0 00.134-.325zM10.622 9.066L7.12 7.613l1.14-1.98 2.954 1.224-.59 2.21zm2.756 0l-.59-2.209 2.953-1.225 1.14 1.981-3.503 1.453z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGlassCheersSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
