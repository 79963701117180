import * as React from "react";

function SvgPlusSquareSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.857 2H4.143C2.96 2 2 2.96 2 4.143v15.714C2 21.04 2.96 22 4.143 22h15.714C21.04 22 22 21.04 22 19.857V4.143C22 2.96 21.04 2 19.857 2zM18.43 13.25a.537.537 0 01-.536.536h-4.107v4.107a.537.537 0 01-.536.536h-2.5a.537.537 0 01-.536-.536v-4.107H6.107a.537.537 0 01-.536-.536v-2.5c0-.295.242-.536.536-.536h4.107V6.107c0-.295.241-.536.536-.536h2.5c.295 0 .536.242.536.536v4.107h4.107c.294 0 .536.241.536.536v2.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPlusSquareSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
