import * as React from "react";

function SvgArrowsAltVSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.362 16.763h-1.8V7.237h1.8c.835 0 1.253-1.01.663-1.6l-3.362-3.362a.937.937 0 00-1.326 0L7.975 5.636c-.59.59-.172 1.6.663 1.6h1.8v9.527h-1.8c-.835 0-1.253 1.01-.663 1.6l3.362 3.362a.938.938 0 001.326 0l3.361-3.361c.591-.59.173-1.6-.662-1.6z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgArrowsAltVSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
