import * as React from "react";

function SvgCalendarMinusSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.281 8.25H3.72a.47.47 0 01-.469-.469V6.375c0-1.035.84-1.875 1.875-1.875H7V2.469A.47.47 0 017.469 2H9.03a.47.47 0 01.469.469V4.5h5V2.469A.47.47 0 0114.969 2h1.562a.47.47 0 01.469.469V4.5h1.875c1.035 0 1.875.84 1.875 1.875v1.406a.47.47 0 01-.469.469zM3.72 9.5H20.28a.47.47 0 01.469.469v10.156c0 1.035-.84 1.875-1.875 1.875H5.125a1.875 1.875 0 01-1.875-1.875V9.969a.47.47 0 01.469-.469zM15.594 17a.47.47 0 00.469-.469V14.97a.47.47 0 00-.47-.469H8.407a.47.47 0 00-.469.469v1.562c0 .258.211.469.47.469h7.187z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCalendarMinusSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
