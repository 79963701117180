import * as React from "react";

function SvgBriefcaseMedicalSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.125 6.375H17V4.5c0-1.035-.84-1.875-1.875-1.875h-6.25C7.84 2.625 7 3.465 7 4.5v1.875H3.875C2.84 6.375 2 7.215 2 8.25V19.5c0 1.035.84 1.875 1.875 1.875h16.25c1.035 0 1.875-.84 1.875-1.875V8.25c0-1.035-.84-1.875-1.875-1.875zM9.5 5.125h5v1.25h-5v-1.25zm6.25 9.688c0 .171-.14.312-.313.312H13.25v2.188c0 .171-.14.312-.313.312h-1.874a.313.313 0 01-.313-.313v-2.187H8.562a.313.313 0 01-.312-.313v-1.874c0-.172.14-.313.313-.313h2.187v-2.188c0-.171.14-.312.313-.312h1.874c.172 0 .313.14.313.313v2.187h2.188c.171 0 .312.14.312.313v1.874z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBriefcaseMedicalSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
