import * as React from "react";

function SvgAngleUpSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.062 5.983l8.498 8.497a1.493 1.493 0 010 2.118l-1.412 1.412a1.493 1.493 0 01-2.119 0L12 11.993l-6.023 6.023a1.493 1.493 0 01-2.118 0L2.44 16.604a1.493 1.493 0 010-2.118l8.498-8.497a1.495 1.495 0 012.124-.006z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAngleUpSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
