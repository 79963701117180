import * as React from "react";

function SvgBoxSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.902 9.21l-1.976-5.929A1.87 1.87 0 0018.148 2h-5.523v7.5h9.324c-.015-.098-.015-.195-.047-.29zM11.375 2H5.852a1.87 1.87 0 00-1.778 1.281l-1.976 5.93c-.032.094-.032.191-.047.289h9.324V2zM2 10.75v9.375C2 21.16 2.84 22 3.875 22h16.25C21.16 22 22 21.16 22 20.125V10.75H2z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBoxSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
