import * as React from "react";

function SvgFileCodeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.5 6.763V7h-5V2h.237c.248 0 .487.099.663.275L19.225 6.1a.938.938 0 01.275.663zM14.187 8.25a.94.94 0 01-.937-.938V2H5.437a.937.937 0 00-.937.938v18.125c0 .517.42.937.938.937h13.125c.517 0 .937-.42.937-.938V8.25h-5.313zm-4.874 9.395a.21.21 0 01-.298.01L6.48 15.278a.212.212 0 010-.308l2.534-2.375a.212.212 0 01.298.01l.764.815a.209.209 0 01.037.236.21.21 0 01-.051.067l-1.592 1.401 1.592 1.401a.21.21 0 01.014.303l-.764.816zm2.003 1.972l-1.072-.312a.211.211 0 01-.144-.261l2.4-8.267a.21.21 0 01.262-.144l1.072.312a.212.212 0 01.144.261l-2.4 8.267a.21.21 0 01-.262.144zm6.281-4.338l-2.533 2.375a.21.21 0 01-.299-.01l-.764-.815a.21.21 0 01.014-.303l1.592-1.401-1.592-1.401a.212.212 0 01-.014-.303l.764-.816a.211.211 0 01.299-.01l2.533 2.376a.21.21 0 010 .308z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFileCodeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
