import * as React from "react";

function SvgFilePdfOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.948 5.827L15.67 2.551A1.875 1.875 0 0014.347 2h-7.97A1.881 1.881 0 004.5 3.879v16.246c0 1.035.84 1.875 1.875 1.875h11.248c1.035 0 1.875-.84 1.875-1.875V7.155c0-.496-.2-.976-.551-1.328zm-1.476 1.176h-2.973V4.03l2.973 2.972zM6.376 20.125V3.88h6.249V7.94c0 .52.418.938.937.938h4.062v11.247H6.376zm9.772-5.612c-.477-.469-1.836-.34-2.515-.254-.672-.41-1.121-.976-1.438-1.808.153-.629.395-1.585.211-2.187-.164-1.023-1.476-.922-1.664-.23-.171.628-.015 1.503.274 2.62-.39.934-.973 2.187-1.383 2.906-.78.402-1.835 1.023-1.992 1.804-.128.617 1.016 2.156 2.973-1.218.874-.29 1.827-.645 2.67-.785.739.398 1.602.664 2.18.664.996 0 1.094-1.102.684-1.512zM8.41 17.552c.199-.535.957-1.152 1.187-1.367-.742 1.183-1.187 1.394-1.187 1.367zm3.187-7.444c.289 0 .261 1.253.07 1.593-.172-.543-.168-1.593-.07-1.593zm-.953 5.335c.379-.66.703-1.445.965-2.137.324.59.738 1.063 1.175 1.387-.812.168-1.52.511-2.14.75zm5.14-.196s-.196.235-1.457-.304c1.37-.102 1.597.21 1.456.304z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFilePdfOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
