import * as React from "react";

function SvgAlignCenterSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.286 7.714H2.714A.714.714 0 002 8.43v1.428a.714.714 0 00.714.714h18.572A.714.714 0 0022 9.857V8.43a.714.714 0 00-.714-.715zm0 11.429H2.714a.714.714 0 00-.714.714v1.429a.714.714 0 00.714.714h18.572a.715.715 0 00.714-.714v-1.429a.714.714 0 00-.714-.714zM6.826 4.857h10.349a.54.54 0 00.54-.54V2.54a.54.54 0 00-.54-.54H6.825a.54.54 0 00-.54.54v1.777a.54.54 0 00.54.54zm10.349 11.429a.54.54 0 00.54-.54v-1.778a.54.54 0 00-.54-.54H6.825a.539.539 0 00-.54.54v1.777a.54.54 0 00.54.54h10.35z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAlignCenterSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
