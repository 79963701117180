import * as React from "react";

function SvgSynagogueSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M4.188 10.141l-1.98 2.243a.833.833 0 00-.208.551V20h4v-8.513l-1.188-1.346a.417.417 0 00-.625 0zm17.604 2.243l-1.98-2.243a.417.417 0 00-.625 0L18 11.487V20h4v-7.065c0-.203-.074-.399-.208-.55zM12.625 4.22a1 1 0 00-1.25 0l-4 3.2A1 1 0 007 8.2V20h3v-2.893c0-.996.68-1.92 1.664-2.08A2.001 2.001 0 0114 17v3h3V8.2a1 1 0 00-.375-.78l-4-3.2zm1.627 6.736a.148.148 0 01-.125.226H12.91l-.785 1.25a.148.148 0 01-.25 0l-.785-1.25H9.873a.148.148 0 01-.125-.226l.6-.955-.6-.955a.148.148 0 01.125-.226h1.217l.785-1.25a.148.148 0 01.25 0l.785 1.25h1.217c.116 0 .187.127.125.226l-.6.955.6.955z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSynagogueSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
