import * as React from "react";

function SvgLevelDownAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.998 17.325l-4.061 4.376a.938.938 0 01-1.374 0l-4.062-4.376c-.554-.597-.131-1.575.687-1.575h2.5V5.125h-3.28a.468.468 0 01-.332-.137L5.888 2.8A.469.469 0 016.22 2h7.655c.518 0 .938.42.938.938V15.75h2.498c.816 0 1.244.975.687 1.575z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgLevelDownAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
