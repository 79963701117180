import * as React from "react";

function SvgPaintBrushSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.524 14.084c-1.567.1-2.989.697-3.796 2.824a.596.596 0 01-.57.39c-.434 0-1.776-1.081-2.158-1.342C2 19.172 3.482 22 7 22c2.964 0 5-1.71 5-4.695 0-.122-.025-.238-.038-.357l-3.438-2.864zM19.886 2c-.592 0-1.147.262-1.57.643-7.985 7.132-8.816 7.3-8.816 9.4 0 .535.127 1.045.341 1.511l2.493 2.078c.282.07.572.118.875.118 2.426 0 3.832-1.776 8.248-10.018.288-.56.543-1.166.543-1.796C22 2.806 20.984 2 19.886 2z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPaintBrushSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
