import * as React from "react";

function SvgUserInjuredSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M14.085 2.468A4.96 4.96 0 0012 2a4.996 4.996 0 00-4.63 3.125h3.172l3.543-2.657zm2.544 2.657a4.988 4.988 0 00-1.425-1.934l-2.58 1.934h4.005zM12 12a5 5 0 005-5c0-.214-.037-.418-.063-.625H7.063C7.037 6.582 7 6.785 7 7a5 5 0 005 5zm-5.625 1.707V22h5.01L7.54 13.347a5.188 5.188 0 00-1.164.36zM3.25 20.125C3.25 21.16 4.09 22 5.125 22v-7.49c-1.138.962-1.875 2.383-1.875 3.99v1.625zm10-1.875h-2.163L12.754 22h.496a1.877 1.877 0 001.875-1.875 1.877 1.877 0 00-1.875-1.875zm2.25-5h-.653a6.821 6.821 0 01-2.847.625 6.821 6.821 0 01-2.847-.625h-.288L10.532 17h2.718a3.129 3.129 0 013.125 3.125c0 .706-.244 1.351-.641 1.875h3.141c1.035 0 1.875-.84 1.875-1.875V18.5c0-2.9-2.35-5.25-5.25-5.25z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgUserInjuredSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
