import * as React from "react";

function SvgStarHalfSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.225 2c-.445 0-.89.23-1.12.695l-2.552 5.173-5.708.828c-1.023.149-1.434 1.41-.691 2.133l4.13 4.024-.978 5.685c-.175 1.02.899 1.797 1.813 1.316l5.106-2.68V2z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgStarHalfSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
