import * as React from "react";

function SvgChevronLeftSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M6.253 11.223l8.901-8.901a1.1 1.1 0 011.555 0l1.038 1.038a1.1 1.1 0 01.002 1.553L10.694 12l7.054 7.087c.428.43.427 1.124-.002 1.553l-1.038 1.038a1.1 1.1 0 01-1.554 0l-8.901-8.901a1.1 1.1 0 010-1.554z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChevronLeftSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
