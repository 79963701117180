import * as React from "react";

function SvgPauseSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.429 22H4.143A2.143 2.143 0 012 19.857V4.143C2 2.96 2.96 2 4.143 2h4.286c1.183 0 2.142.96 2.142 2.143v15.714c0 1.183-.96 2.143-2.142 2.143zM22 19.857V4.143C22 2.96 21.04 2 19.857 2h-4.286c-1.183 0-2.142.96-2.142 2.143v15.714c0 1.183.96 2.143 2.142 2.143h4.286C21.04 22 22 21.04 22 19.857z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPauseSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
