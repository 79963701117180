import * as React from "react";

function SvgRadiationAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M14.58 12h3.19c.371 0 .682-.31.645-.677a6.436 6.436 0 00-2.399-4.347.654.654 0 00-.968.153l-1.681 2.694A2.565 2.565 0 0114.58 12zm-3.943 2.181L8.952 16.88c-.198.314-.097.742.233.907.851.42 1.803.666 2.815.666a6.375 6.375 0 002.819-.666c.33-.165.427-.593.233-.907l-1.685-2.698c-.395.25-.863.4-1.363.4s-.972-.15-1.367-.4zM6.23 12h3.19c0-.92.487-1.726 1.217-2.181L8.956 7.125a.654.654 0 00-.968-.153 6.42 6.42 0 00-2.4 4.346c-.044.372.27.682.642.682zM12 22c5.524 0 10-4.476 10-10S17.524 2 12 2 2 6.476 2 12s4.476 10 10 10zm0-17.42A7.426 7.426 0 0119.42 12 7.426 7.426 0 0112 19.42 7.426 7.426 0 014.58 12 7.426 7.426 0 0112 4.58zm0 8.71c.714 0 1.29-.576 1.29-1.29 0-.714-.576-1.29-1.29-1.29-.714 0-1.29.576-1.29 1.29 0 .714.576 1.29 1.29 1.29z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgRadiationAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
