import * as React from "react";

function SvgArrowAltCircleUpSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2 12C2 6.476 6.476 2 12 2s10 4.476 10 10-4.476 10-10 10S2 17.524 2 12zm11.774 4.677V12h2.86c.43 0 .648-.524.342-.827l-4.633-4.608a.48.48 0 00-.682 0l-4.637 4.608a.484.484 0 00.343.827h2.859v4.677c0 .267.217.484.484.484h2.58a.485.485 0 00.484-.484z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgArrowAltCircleUpSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
