import * as React from "react";

function SvgChartBarOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.5 15.75h.875c.25 0 .5-.25.5-.5v-9c0-.25-.25-.5-.5-.5H17.5c-.25 0-.5.25-.5.5v9c0 .25.25.5.5.5zm-7.5 0h.875c.25 0 .5-.25.5-.5V7.5c0-.25-.25-.5-.5-.5H10c-.25 0-.5.25-.5.5v7.75c0 .25.25.5.5.5zm3.75 0h.875c.25 0 .5-.25.5-.5V10c0-.25-.25-.5-.5-.5h-.875c-.25 0-.5.25-.5.5v5.25c0 .25.25.5.5.5zm7.625 1.875h-17.5v-12.5A.625.625 0 003.25 4.5h-.625A.625.625 0 002 5.125V18.25c0 .69.56 1.25 1.25 1.25h18.125c.345 0 .625-.28.625-.625v-.625a.625.625 0 00-.625-.625zM6.25 15.75h.875c.25 0 .5-.25.5-.5V12.5c0-.25-.25-.5-.5-.5H6.25c-.25 0-.5.25-.5.5v2.75c0 .25.25.5.5.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChartBarOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
