import * as React from "react";

function SvgIglooSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M13.111 4.288A9.793 9.793 0 0012 4.222a9.982 9.982 0 00-8.313 4.445h9.424V4.288zm-7.778 5.49h-2.28A9.953 9.953 0 002 14.222h3.333V9.778zm8.89-5.299v4.188h6.09a9.994 9.994 0 00-6.09-4.188zm4.444 9.743H22a9.952 9.952 0 00-1.052-4.444h-2.281v4.444zm-2.223 2.222v3.334h4.445A1.11 1.11 0 0022 18.667v-3.334h-5.712c.09.358.156.726.156 1.111zm1.112-6.666H6.444v4.444h1.73C8.944 12.9 10.36 12 12 12c1.639 0 3.056.9 3.826 2.222h1.73V9.778zM2 18.667a1.11 1.11 0 001.111 1.11h4.445v-3.333c0-.385.066-.753.156-1.11H2v3.333zm10-5.556a3.334 3.334 0 00-3.333 3.333v3.334h6.666v-3.334A3.334 3.334 0 0012 13.111z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgIglooSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
