import * as React from "react";

function SvgICursorSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17 4.033V2.471a.468.468 0 00-.463-.468C15.267 1.987 13.503 2 12 3.483c-1.474-1.454-3.165-1.487-4.535-1.479a.469.469 0 00-.465.47v1.548c0 .261.213.472.474.468 1.015-.012 3.276.137 3.276 1.892v4.368H9.344a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469h1.406v4.375c0 1.755-2.19 1.876-3.28 1.873-.259 0-.47.21-.47.469v1.562c0 .256.206.465.463.468 1.27.016 3.034.003 4.537-1.48 1.474 1.454 3.165 1.487 4.535 1.479a.468.468 0 00.465-.47v-1.548a.469.469 0 00-.474-.469c-1.015.013-3.276-.128-3.276-1.884V13.25h1.406c.26 0 .469-.21.469-.469V11.22a.469.469 0 00-.469-.469H13.25V6.382c0-1.755 2.19-1.883 3.28-1.88.259 0 .47-.21.47-.469z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgICursorSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
