import * as React from "react";

function SvgFireAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.89 4a23.335 23.335 0 00-2.197 2.343A30.312 30.312 0 009.813 2C5.973 5.561 3.25 10.202 3.25 13c0 4.97 3.918 9 8.75 9s8.75-4.03 8.75-9c0-2.08-2.03-6.373-4.86-9zm-.761 13.307a5.25 5.25 0 01-3.017.943C9.293 18.25 7 16.385 7 13.357c0-1.508.95-2.837 2.843-5.107.271.312 3.86 4.896 3.86 4.896l2.291-2.612c.162.267.31.529.44.78 1.069 2.038.618 4.647-1.306 5.993z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFireAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
