import * as React from "react";

function SvgRulerHorizontalSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.889 7.556h-1.667v3.055a.278.278 0 01-.278.278h-.555a.278.278 0 01-.278-.278V7.556H15.89v3.055a.278.278 0 01-.278.278h-.555a.278.278 0 01-.278-.278V7.556h-2.222v3.055a.278.278 0 01-.278.278h-.556a.278.278 0 01-.278-.278V7.556H9.222v3.055a.278.278 0 01-.278.278H8.39a.278.278 0 01-.278-.278V7.556H5.89v3.055a.278.278 0 01-.278.278h-.555a.278.278 0 01-.278-.278V7.556H3.11C2.498 7.556 2 8.053 2 8.666v6.667c0 .614.498 1.111 1.111 1.111H20.89c.613 0 1.111-.497 1.111-1.11V8.666c0-.614-.498-1.111-1.111-1.111z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgRulerHorizontalSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
