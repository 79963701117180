import * as React from "react";

function SvgBellSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 22a2.5 2.5 0 002.499-2.5H9.5A2.5 2.5 0 0012 22zm8.414-5.848c-.755-.811-2.167-2.03-2.167-6.027 0-3.035-2.128-5.465-4.998-6.06V3.25a1.25 1.25 0 10-2.498 0v.814c-2.87.596-4.998 3.026-4.998 6.061 0 3.996-1.412 5.216-2.167 6.027A1.22 1.22 0 003.25 17c.004.64.507 1.25 1.254 1.25h14.992c.747 0 1.25-.61 1.254-1.25a1.22 1.22 0 00-.336-.848z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBellSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
