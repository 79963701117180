import * as React from "react";

function SvgPooStormSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.283 15.125H13.03l.676-2.535a.47.47 0 00-.453-.59h-2.656a.471.471 0 00-.465.406l-.625 4.688c-.04.281.18.531.465.531h2.316l-.898 3.797a.47.47 0 00.863.344l3.438-5.938a.47.47 0 00-.407-.703zm2.594-4.348c.23-.375.375-.804.375-1.277 0-1.379-1.121-2.5-2.5-2.5h-.23c.14-.395.23-.809.23-1.25 0-2.07-1.68-3.75-3.75-3.75-.203 0-.399.027-.59.059.367.511.59 1.136.59 1.816A3.124 3.124 0 018.877 7h-.625a2.502 2.502 0 00-2.5 2.5c0 .473.144.902.375 1.277-1.602.13-2.875 1.465-2.875 3.098A3.134 3.134 0 006.377 17h1.887c.004-.023 0-.047 0-.07l.625-4.688a1.729 1.729 0 011.707-1.492h2.656a1.71 1.71 0 011.363.672c.328.426.438.969.297 1.488l-.258.965h.625a1.718 1.718 0 011.489 2.578L16.45 17h1.172a3.134 3.134 0 003.125-3.125c.004-1.633-1.27-2.969-2.871-3.098z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPooStormSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
