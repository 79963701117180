import * as React from "react";

function SvgPastafarianismSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.517 14.865c-1.022-.392-1.793.133-2.356.514-.533.36-.726.45-.981.355-.254-.097-.339-.293-.497-.918-.104-.411-.232-.917-.56-1.333.07-.091.138-.181.199-.268.318.299.731.535 1.303.535 1.06 0 1.59-.806 1.94-1.338.33-.505.468-.662.685-.662a.75.75 0 000-1.5c-1.061 0-1.59.806-1.94 1.338-.33.505-.468.662-.685.662-.541 0-1.171-1.92-3.04-3.185l.54-1.078A1.994 1.994 0 0016 4a2 2 0 00-1.668 3.1l-.567 1.135A6.681 6.681 0 0012 8c-.652 0-1.234.091-1.764.235L9.669 7.1A2 2 0 106 6c0 1.062.83 1.922 1.876 1.988l.539 1.078c-1.863 1.26-2.504 3.184-3.04 3.184-.217 0-.354-.157-.686-.662-.349-.532-.878-1.338-1.939-1.338a.75.75 0 000 1.5c.217 0 .354.157.686.662.35.532.878 1.338 1.939 1.338.572 0 .985-.236 1.303-.535.061.088.13.177.2.268-.329.417-.457.922-.561 1.333-.159.625-.243.821-.497.918-.253.096-.448.005-.982-.355-.563-.381-1.335-.904-2.355-.514a.75.75 0 10.534 1.402c.255-.097.449-.006.981.355.424.286.964.651 1.639.65.224 0 .463-.04.717-.136 1.021-.39 1.25-1.292 1.417-1.952.069-.272.125-.452.186-.59.52.426 1.154.81 1.926 1.069C9.572 16.819 8.877 18.5 8 18.5A.75.75 0 008 20c2.085 0 3.033-2.77 3.357-4.036.209.02.42.036.643.036.224 0 .434-.017.643-.036C12.967 17.23 13.914 20 16 20a.75.75 0 000-1.5c-.873 0-1.57-1.682-1.882-2.838a6.078 6.078 0 001.926-1.067c.06.136.116.317.186.59.166.659.395 1.562 1.416 1.95.255.098.494.138.718.138.675 0 1.215-.366 1.638-.651.533-.361.728-.452.98-.355a.75.75 0 00.534-1.402zM16 5.5a.5.5 0 11-.001 1.001.5.5 0 010-1.001zm-8 0a.5.5 0 11-.001 1.001.5.5 0 010-1.001z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgPastafarianismSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
