import * as React from "react";

function SvgDotCircleSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm3.226 10A3.23 3.23 0 0112 15.226 3.23 3.23 0 018.774 12 3.23 3.23 0 0112 8.774 3.23 3.23 0 0115.226 12z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDotCircleSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
