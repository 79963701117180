import * as React from "react";

function SvgAddressBookOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.281 8.25a.47.47 0 00.469-.469V6.22a.47.47 0 00-.469-.469H19.5V3.875C19.5 2.84 18.66 2 17.625 2h-12.5C4.09 2 3.25 2.84 3.25 3.875v16.25C3.25 21.16 4.09 22 5.125 22h12.5c1.035 0 1.875-.84 1.875-1.875V18.25h.781a.47.47 0 00.469-.469V16.22a.47.47 0 00-.469-.469H19.5v-2.5h.781a.47.47 0 00.469-.469V11.22a.47.47 0 00-.469-.469H19.5v-2.5h.781zm-2.656 11.875h-12.5V3.875h12.5v16.25zM11.375 12c1.379 0 2.5-1.121 2.5-2.5S12.754 7 11.375 7a2.502 2.502 0 00-2.5 2.5c0 1.379 1.121 2.5 2.5 2.5zm-3.5 5h7c.484 0 .875-.336.875-.75v-.75c0-1.242-1.176-2.25-2.625-2.25-.422 0-.73.313-1.75.313-1.05 0-1.305-.313-1.75-.313C8.175 13.25 7 14.258 7 15.5v.75c0 .414.39.75.875.75z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAddressBookOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
