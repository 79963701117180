import * as React from "react";

function SvgGrimaceSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zM7.806 17.806h-.322c-.714 0-1.29-.576-1.29-1.29v-.322h1.612v1.613zm0-2.258H6.194v-.322c0-.714.576-1.29 1.29-1.29h.322v1.612zm-.322-5.483c0-.714.576-1.29 1.29-1.29.714 0 1.29.576 1.29 1.29 0 .713-.576 1.29-1.29 1.29-.714 0-1.29-.577-1.29-1.29zm2.903 7.742H8.452v-1.613h1.935v1.613zm0-2.259H8.452v-1.613h1.935v1.613zm2.58 2.258h-1.935v-1.613h1.936v1.613zm0-2.258h-1.935v-1.613h1.936v1.613zm2.581 2.258h-1.935v-1.613h1.935v1.613zm0-2.258h-1.935v-1.613h1.935v1.613zm-.322-4.193c-.714 0-1.29-.577-1.29-1.29 0-.714.576-1.29 1.29-1.29.713 0 1.29.576 1.29 1.29 0 .713-.576 1.29-1.29 1.29zm2.58 5.161a1.29 1.29 0 01-1.29 1.29h-.322v-1.613h1.613v.323zm0-.968h-1.613v-1.613h.323c.714 0 1.29.577 1.29 1.29v.323z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGrimaceSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
