import * as React from "react";

function SvgTreeSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.277 16.785l-3.12-3.535h1.196a.888.888 0 00.812-.51.861.861 0 00-.128-.94l-3.052-3.55h1.128a.885.885 0 00.815-.532.87.87 0 00-.165-.94l-4.308-4.589a.643.643 0 00-.91 0L7.236 6.778a.87.87 0 00-.166.94c.14.323.46.532.815.532h1.129l-3.052 3.551a.862.862 0 00-.128.94c.148.314.46.509.812.509h1.196l-3.12 3.535a.863.863 0 00-.14.947.906.906 0 00.821.518h5.346v.955l-1.183 1.89a.625.625 0 00.559.905h3.748a.625.625 0 00.56-.905l-1.184-1.89v-.955h5.346a.906.906 0 00.82-.518.863.863 0 00-.139-.947z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTreeSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
