import * as React from "react";

function SvgCommentSmileSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 2.5h14.999c1.379 0 2.5 1.121 2.5 2.5v10.25c0 1.378-1.121 2.499-2.5 2.499h-5.625l-4.878 3.66a.47.47 0 01-.746-.379V17.75H4.5a2.502 2.502 0 01-2.5-2.5V5C2 3.622 3.121 2.5 4.5 2.5zm11.274 5.078a1.077 1.077 0 10-2.156 0 1.077 1.077 0 102.156 0zm-5.39 0a1.077 1.077 0 10-2.156 0 1.077 1.077 0 102.156 0zm1.617 6.468c1.499 0 2.907-.66 3.867-1.813.455-.549-.377-1.236-.829-.69a3.947 3.947 0 01-3.038 1.421 3.936 3.936 0 01-3.039-1.421c-.458-.546-1.286.141-.828.69A5.018 5.018 0 0012 14.046z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCommentSmileSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
