import * as React from "react";

function SvgEditSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.98 5.998L19.11 9.13a.34.34 0 010 .48l-7.583 7.583-3.222.357a.675.675 0 01-.747-.746l.358-3.222L15.5 5.998a.34.34 0 01.48 0zm5.624-.795L19.91 3.51a1.358 1.358 0 00-1.917 0l-1.23 1.229a.34.34 0 000 .479l3.133 3.132a.34.34 0 00.479 0l1.23-1.23a1.358 1.358 0 000-1.916zm-6.27 9.927v3.535H4.221V7.554h7.98a.427.427 0 00.294-.122l1.39-1.389a.417.417 0 00-.296-.711H3.667C2.747 5.332 2 6.078 2 6.998V19.22c0 .92.747 1.667 1.667 1.667h12.222c.92 0 1.667-.746 1.667-1.667v-5.479a.417.417 0 00-.712-.295l-1.39 1.389a.427.427 0 00-.12.295z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgEditSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
