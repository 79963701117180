import * as React from "react";

function SvgBinocularsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.25 3.875a.625.625 0 00-.625-.625h-2.5a.625.625 0 00-.625.625V5.75h3.75V3.875zM4.496 8.25C4.398 11.916 2.136 12.712 2 17.78V19.5c0 .69.56 1.25 1.25 1.25H7c.69 0 1.25-.56 1.25-1.25v-6.25H9.5V7H5.744c-.689 0-1.229.561-1.248 1.25zm15.007 0C19.485 7.56 18.945 7 18.256 7H14.5v6.25h1.25v6.25c0 .69.56 1.25 1.25 1.25h3.75c.69 0 1.25-.56 1.25-1.25v-1.719c-.135-5.07-2.398-5.865-2.497-9.531zm-10.628-5h-2.5a.625.625 0 00-.625.625V5.75H9.5V3.875a.625.625 0 00-.625-.625zm1.875 10h2.5V7h-2.5v6.25z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBinocularsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
