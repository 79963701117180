import * as React from "react";

function SvgHandPeaceOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.396 9.5a2.579 2.579 0 00-2.539-1.186V4.89C14.857 3.297 13.575 2 12 2c-1.575 0-2.857 1.297-2.857 2.89L9.5 8.564l-.73-3.08a2.847 2.847 0 00-3.715-1.639C3.591 4.431 2.876 6.1 3.445 7.58l2.179 5.661c-1.194.975-1.719 2.258-.966 3.603L6.8 20.672A2.589 2.589 0 009.054 22h8.035c1.2 0 2.248-.837 2.522-2.023l1.071-4.649a2.65 2.65 0 00.068-.594V12c0-1.756-1.69-3.021-3.354-2.5zm1.479 5.234a.77.77 0 01-.02.173l-1.072 4.649a.718.718 0 01-.694.569H9.054a.712.712 0 01-.618-.37l-2.142-3.827a.765.765 0 01.176-.957l1.04-.85a.625.625 0 00.189-.709L5.195 6.907c-.49-1.276 1.35-1.984 1.825-.751l2.335 6.069a.625.625 0 00.584.4h.454c.345 0 .625-.28.625-.625V4.89c0-1.342 1.964-1.344 1.964 0V12c0 .345.28.625.625.625h.268c.345 0 .625-.28.625-.625v-1.094c0-.981 1.428-.983 1.428 0V12c0 .345.28.625.625.625h.268c.345 0 .625-.28.625-.625 0-.981 1.428-.983 1.428 0v2.734z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHandPeaceOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
