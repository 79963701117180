import * as React from "react";

function SvgBowlingBallSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zM6.839 9.42c-.714 0-1.29-.577-1.29-1.291s.576-1.29 1.29-1.29c.713 0 1.29.576 1.29 1.29 0 .714-.577 1.29-1.29 1.29zm2.58-3.872a1.289 1.289 0 112.58 0c.001.714-.576 1.29-1.29 1.29-.713 0-1.29-.576-1.29-1.29zm1.936 5.807c-.714 0-1.29-.577-1.29-1.29 0-.714.576-1.29 1.29-1.29a1.289 1.289 0 110 2.58z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBowlingBallSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
