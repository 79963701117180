import * as React from "react";

function SvgSuitcaseRollingSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.625 8.25H6.375c-1.036 0-1.875.84-1.875 1.875v8.75c0 1.035.84 1.875 1.875 1.875H7v.625c0 .345.28.625.625.625h1.25c.345 0 .625-.28.625-.625v-.625h5v.625c0 .345.28.625.625.625h1.25c.345 0 .625-.28.625-.625v-.625h.625c1.035 0 1.875-.84 1.875-1.875v-8.75c0-1.036-.84-1.875-1.875-1.875zM17 16.688c0 .172-.14.312-.313.312H7.313A.312.312 0 017 16.687v-.625c0-.172.14-.312.313-.312h9.375c.172 0 .312.14.312.313v.625zm0-3.75c0 .172-.14.312-.313.312H7.313A.312.312 0 017 12.937v-.624c0-.173.14-.313.313-.313h9.375c.172 0 .312.14.312.313v.624zm-6.875-9.063h3.75V7h1.875V3.875C15.75 2.839 14.91 2 13.875 2h-3.75C9.089 2 8.25 2.84 8.25 3.875V7h1.875V3.875z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSuitcaseRollingSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
