import * as React from "react";

function SvgCrutchSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.818 9.253l-7.07-7.07a.625.625 0 00-.883 0l-.887.883a.626.626 0 000 .884l7.07 7.07a.625.625 0 00.884 0l.884-.884a.625.625 0 00.002-.883zm-7.013 2.594l-2.652-2.652 2.152-2.152-1.767-1.768L8.254 9.56a3.753 3.753 0 00-1.003 1.809l-1.083 4.697-3.985 3.984a.625.625 0 000 .884l.884.884a.625.625 0 00.884 0l3.984-3.984 4.698-1.084a3.745 3.745 0 001.808-1.002l4.284-4.285-1.768-1.768-2.152 2.153zm-2.131 2.132a1.25 1.25 0 01-.604.333l-3.098.716.715-3.098c.053-.228.17-.437.335-.603l.363-.364 2.652 2.652-.364.364z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCrutchSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
