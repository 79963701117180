import * as React from "react";

function SvgTtySolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M2.21 6.056C7.62.644 16.387.652 21.79 6.056a.716.716 0 01.101.886l-1.688 2.7a.716.716 0 01-.873.286l-3.376-1.35a.716.716 0 01-.447-.737l.233-2.325a11.068 11.068 0 00-7.48 0l.233 2.325a.716.716 0 01-.447.736L4.67 9.927a.716.716 0 01-.873-.285l-1.688-2.7a.716.716 0 01.1-.886zm3.54 7.975V12.47A.469.469 0 005.281 12H3.72a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469H5.28c.26 0 .469-.21.469-.469zm3.75 0V12.47A.469.469 0 009.031 12H7.47a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469H9.03c.26 0 .469-.21.469-.469zm3.75 0V12.47a.469.469 0 00-.469-.469H11.22a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469h1.562c.26 0 .469-.21.469-.469zm3.75 0V12.47a.469.469 0 00-.469-.469H14.97a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469h1.562c.26 0 .469-.21.469-.469zm3.75 0V12.47a.469.469 0 00-.469-.469H18.72a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469h1.562c.26 0 .469-.21.469-.469zm-13.125 3.75V16.22a.469.469 0 00-.469-.469H5.594a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469h1.562c.26 0 .469-.21.469-.469zm3.75 0V16.22a.469.469 0 00-.469-.469H9.344a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469h1.562c.26 0 .469-.21.469-.469zm3.75 0V16.22a.469.469 0 00-.469-.469h-1.562a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469h1.562c.26 0 .469-.21.469-.469zm3.75 0V16.22a.469.469 0 00-.469-.469h-1.562a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469h1.562c.26 0 .469-.21.469-.469zM5.75 21.531V19.97a.469.469 0 00-.469-.469H3.72a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469H5.28c.26 0 .469-.21.469-.469zm11.25 0V19.97a.469.469 0 00-.469-.469H7.47a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469h9.062c.26 0 .469-.21.469-.469zm3.75 0V19.97a.469.469 0 00-.469-.469H18.72a.469.469 0 00-.469.469v1.562c0 .26.21.469.469.469h1.562c.26 0 .469-.21.469-.469z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgTtySolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
