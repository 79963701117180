import * as React from "react";

function SvgStoreAltSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 16H5.998v-5h-2v8c0 .554.447 1 1 1h8c.554 0 1-.446 1-1v-8h-2v5zm9.831-7.557l-2.665-4A1.005 1.005 0 0018.33 4H5.668a.996.996 0 00-.832.444l-2.666 4A1 1 0 003.002 10H21c.797 0 1.272-.89.831-1.557zM18 19.501c0 .275.225.5.5.5h1c.275 0 .5-.225.5-.5V11h-2v8.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgStoreAltSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
