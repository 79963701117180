import * as React from "react";

function SvgHandPointDownSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M8.087 20.25v-5.83a5.308 5.308 0 01-.973.553c-1.243.533-2.615-.386-2.614-1.723 0-.727.426-1.258 1.136-1.563 1.105-.473 2.513-3.072 3.02-4.2a1.875 1.875 0 011.713-1.112h6.7c.462 0 .856.336.927.792.283 1.83 1.503 2.412 1.496 4.833 0 .106.008.518.008.625 0 1.979-.864 3.187-2.784 2.836-.363.726-1.542 1.2-2.434.643-.827.962-2.105.884-2.771.246v3.9c0 .943-.79 1.75-1.712 1.75-.91 0-1.712-.834-1.712-1.75zm.788-15.438V2.938c0-.517.42-.937.938-.937h7.5c.517 0 .937.42.937.938v1.874c0 .518-.42.938-.938.938h-7.5a.937.937 0 01-.937-.938zm8.281-.937a.781.781 0 10-1.562 0 .781.781 0 001.562 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHandPointDownSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
