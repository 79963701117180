import * as React from "react";

function SvgBahaiSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.385 9.911l-4.297-.603 1.633-4.076c.261-.65-.453-1.257-1.038-.884l-3.674 2.34L12.678 2.5a.7.7 0 00-.678-.5.7.7 0 00-.678.5L9.991 6.688l-3.674-2.34c-.585-.372-1.299.235-1.038.885l1.633 4.075-4.297.603c-.685.096-.847 1.026-.235 1.354l3.834 2.057-2.91 3.264c-.426.478-.066 1.208.52 1.208a.734.734 0 00.159-.018l4.24-.924-.16 4.397a.715.715 0 00.72.75.69.69 0 00.554-.28L12 18.247l2.664 3.472a.69.69 0 00.554.28.715.715 0 00.72-.75l-.161-4.396 4.24.923c.679.147 1.142-.672.679-1.19l-2.91-3.264 3.835-2.057c.61-.328.449-1.258-.236-1.354zm-6.162 4.264l-2.027-.441.077 2.1L12 14.176l-1.273 1.66.076-2.101-2.026.441 1.39-1.56-1.833-.983 2.054-.288-.781-1.948 1.756 1.118L12 8.513l.636 2 1.756-1.117-.78 1.948 2.053.288-1.833.983 1.391 1.56z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgBahaiSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
