import * as React from "react";

function SvgGopuramSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.375 15.75h-.625v-4.375a.627.627 0 00-.625-.625H19.5V7.625A.627.627 0 0018.875 7h-.625V2.625A.627.627 0 0017.625 2a.627.627 0 00-.625.625v.625h-2.5v-.625A.627.627 0 0013.875 2a.627.627 0 00-.625.625v.625h-2.5v-.625A.627.627 0 0010.125 2a.627.627 0 00-.625.625v.625H7v-.625A.627.627 0 006.375 2a.627.627 0 00-.625.625V7h-.625a.627.627 0 00-.625.625v3.125h-.625a.627.627 0 00-.625.625v4.375h-.625a.627.627 0 00-.625.625v5c0 .344.281.625.625.625H5.75v-6.25H7v-5h1.25V7H9.5v3.75H8.25v5H7V22h3.125v-3.125c0-.344.281-.625.625-.625h2.5c.344 0 .625.281.625.625V22H17v-6.25h-1.25v-5H14.5V7h1.25v3.75H17v5h1.25V22h3.125a.627.627 0 00.625-.625v-5a.627.627 0 00-.625-.625zM11.062 8.875c0-.344.282-.625.626-.625h.624c.344 0 .626.281.626.625v1.875h-1.876V8.875zm2.188 6.875h-2.5v-2.5c0-.344.281-.625.625-.625h1.25c.344 0 .625.281.625.625v2.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGopuramSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
