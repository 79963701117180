import * as React from "react";

function SvgFileEditSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M15.334 10.283l-4.445 4.413v3.967l4.444.004v1.388a.831.831 0 01-.833.834H2.833A.831.831 0 012 20.055V3.945c0-.462.372-.834.833-.834h6.945v4.722c0 .459.375.834.833.834h4.723v1.616z"
        fill="currentColor"
      />
      <path
        d="M15.09 6.757a.83.83 0 01.243.587v.212H10.89V3.11h.212c.222 0 .434.087.59.243l3.4 3.403zM12 17.555v-2.397l5.652-5.611 2.357 2.356-5.614 5.652H12zM20.634 7.815l1.107 1.106a.884.884 0 010 1.25l-.946.947-2.357-2.357.946-.946a.884.884 0 011.25 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFileEditSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
