import * as React from "react";

function SvgChevronCircleDownSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M22 12c0 5.524-4.476 10-10 10S2 17.524 2 12 6.476 2 12 2s10 4.476 10 10zm-9.315 4.593l5.464-5.464a.964.964 0 000-1.367l-.685-.685a.964.964 0 00-1.367 0L12 13.173 7.903 9.077a.964.964 0 00-1.367 0l-.685.685a.964.964 0 000 1.367l5.464 5.464c.379.379.992.379 1.37 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChevronCircleDownSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
