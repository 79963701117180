import * as React from "react";

function SvgFileInvoiceDollarSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.227 6.102l-3.825-3.829A.937.937 0 0014.738 2H14.5v5h5v-.238a.935.935 0 00-.273-.66zm-5.977 1.21V2H5.437a.935.935 0 00-.937.938v18.125c0 .519.418.937.938.937h13.125c.519 0 .937-.418.937-.938V8.25h-5.313a.94.94 0 01-.937-.938zM7 4.813c0-.172.14-.312.313-.312h3.125c.172 0 .312.14.312.313v.625c0 .172-.14.312-.313.312H7.313A.312.312 0 017 5.437v-.625zm0 3.125v-.625C7 7.14 7.14 7 7.313 7h3.125c.172 0 .312.14.312.313v.625c0 .172-.14.312-.313.312H7.313A.312.312 0 017 7.937zm5.625 10.308v.942c0 .173-.14.313-.313.313h-.624a.312.312 0 01-.313-.313v-.948a2.237 2.237 0 01-1.225-.444.313.313 0 01-.023-.474l.46-.438a.32.32 0 01.395-.028.94.94 0 00.5.145h1.099c.254 0 .46-.231.46-.515 0-.233-.14-.437-.342-.497l-1.758-.528c-.726-.218-1.233-.915-1.233-1.695 0-.958.744-1.736 1.667-1.76v-.943c0-.172.14-.312.312-.312h.625c.173 0 .313.14.313.313v.948c.44.023.87.177 1.225.444.152.114.16.342.022.474l-.459.438a.32.32 0 01-.395.028.937.937 0 00-.501-.145h-1.098c-.254 0-.461.231-.461.515 0 .233.14.437.342.497l1.758.528c.726.218 1.234.915 1.234 1.695 0 .958-.744 1.736-1.667 1.76z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFileInvoiceDollarSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
