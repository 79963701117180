import * as React from "react";

function SvgAllergiesSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.498 6.374c-.687 0-1.25.563-1.25 1.25v2.812c0 .172-.14.312-.312.312h-.625a.313.313 0 01-.313-.312V4.5c0-.688-.562-1.25-1.25-1.25-.687 0-1.249.562-1.249 1.25v5.936c0 .172-.14.312-.313.312h-.624a.313.313 0 01-.313-.312V3.25C13.25 2.562 12.687 2 12 2c-.687 0-1.25.562-1.25 1.25v7.186c0 .172-.14.312-.312.312h-.625a.313.313 0 01-.312-.312V4.5c0-.688-.562-1.25-1.25-1.25C7.563 3.25 7 3.812 7 4.5v9.412l-.921-1.27a1.562 1.562 0 10-2.527 1.84l4.905 6.745c.352.484.918.773 1.516.773h7.717c.87 0 1.624-.598 1.824-1.445l1.035-4.401a7.874 7.874 0 00.199-1.652V7.623c0-.687-.563-1.25-1.25-1.25zm-9.373 11.873a.627.627 0 01-.625-.625c0-.344.281-.625.625-.625s.625.281.625.625a.627.627 0 01-.625.625zm0-3.75a.627.627 0 01-.625-.624c0-.344.281-.625.625-.625s.625.281.625.625a.627.627 0 01-.625.625zm2.5 5a.627.627 0 01-.626-.625c0-.344.282-.625.625-.625.344 0 .625.281.625.625a.627.627 0 01-.625.625zm0-3.75a.627.627 0 01-.626-.625c0-.343.282-.624.625-.624.344 0 .625.28.625.624a.627.627 0 01-.625.625zm2.499 1.25a.627.627 0 01-.625-.625c0-.343.281-.625.625-.625.343 0 .625.282.625.625a.627.627 0 01-.625.625zm1.25 2.5a.627.627 0 01-.625-.625c0-.344.28-.625.625-.625.343 0 .625.281.625.625a.627.627 0 01-.625.625zm1.25-5a.627.627 0 01-.625-.624c0-.344.28-.625.624-.625s.625.281.625.625a.627.627 0 01-.625.625z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgAllergiesSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
