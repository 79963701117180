import * as React from "react";

function SvgFileAudioOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.95 5.826l-3.276-3.277A1.875 1.875 0 0014.348 2H6.375C5.339 2 4.5 2.84 4.5 3.875v16.25C4.5 21.16 5.34 22 6.375 22h11.25c1.035 0 1.875-.84 1.875-1.875V7.152c0-.498-.198-.975-.55-1.326zM17.474 7H14.5V4.027L17.473 7zM6.375 20.125V3.875h6.25v4.063c0 .517.42.937.938.937h4.062v11.25H6.375zM12 17.155a.469.469 0 01-.8.332l-1.387-1.405H8.719a.469.469 0 01-.469-.47v-2.187c0-.259.21-.469.469-.469h1.094l1.387-1.443a.469.469 0 01.8.332v5.31zm1.61-1.84a.937.937 0 000-1.307c-.866-.889.477-2.197 1.343-1.308a2.81 2.81 0 010 3.922c-.851.875-2.224-.403-1.344-1.308z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgFileAudioOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
