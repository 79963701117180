import * as React from "react";

function SvgCaretSquareDownOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M7.585 9.857h8.83c.478 0 .719.58.38.915l-4.416 4.389a.53.53 0 01-.754 0L7.21 10.772a.534.534 0 01.375-.915zM22 4.143v15.714C22 21.04 21.04 22 19.857 22H4.143A2.143 2.143 0 012 19.857V4.143C2 2.96 2.96 2 4.143 2h15.714C21.04 2 22 2.96 22 4.143zm-2.143 15.446V4.411a.269.269 0 00-.268-.268H4.411a.269.269 0 00-.268.268v15.178c0 .148.12.268.268.268h15.178c.148 0 .268-.12.268-.268z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgCaretSquareDownOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
