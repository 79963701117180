import * as React from "react";

function SvgHandPeaceSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.188 10.438c-.863 0-1.563.7-1.563 1.562h-.313v-1.25a1.562 1.562 0 10-3.125 0V12h-.312V3.875a1.875 1.875 0 00-3.75 0V12h-.53L6.87 5.064a1.875 1.875 0 10-3.49 1.372L6.3 13.87l-1.215.971c-.6.481-.762 1.328-.38 1.996l2.5 4.375A1.562 1.562 0 008.562 22h9.376c.725 0 1.354-.499 1.52-1.205l1.25-5.312c.028-.117.042-.238.042-.358V12c0-.863-.7-1.563-1.563-1.563z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHandPeaceSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
