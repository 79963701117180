import * as React from "react";

function SvgGlobeAsiaSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-.457 9.687a.645.645 0 01-.553.313h-.013a.645.645 0 00-.456.19l-.228.227a.323.323 0 000 .456l.228.229c.12.12.19.285.19.456v.377a.645.645 0 01-.646.646h-.246a.645.645 0 01-.577-.357L8.329 12.4a.323.323 0 00-.516-.084l-.785.785a.645.645 0 01-.456.189H4.049A8.045 8.045 0 013.935 12c0-4.447 3.618-8.065 8.065-8.065a8.03 8.03 0 012.485.396l-2.022 1.554a.323.323 0 00.034.557l.437.218c.218.11.356.333.356.577v3.15a.322.322 0 01-.322.323h-.124a.322.322 0 01-.288-.179.179.179 0 00-.313-.012l-.7 1.168zm6.909 4.443a.645.645 0 01-.19.456l-.385.386a.646.646 0 01-.456.19h-.612a.646.646 0 01-.456-.19l-.524-.524a1.08 1.08 0 00-1.025-.284l-.858.214a.64.64 0 01-.156.02h-.417a.646.646 0 01-.457-.19l-.48-.48a.323.323 0 01-.094-.228v-.411c0-.132.08-.25.203-.3l1.586-.635c.08-.031.156-.073.226-.123l.956-.68a.322.322 0 01.187-.06h.487c.13 0 .248.078.298.198l.216.519a.162.162 0 00.149.099h.153a.161.161 0 00.155-.116l.169-.584a.161.161 0 01.155-.116h.244c.09 0 .162.072.162.161v.522c0 .085.033.168.094.228l.48.48c.121.121.19.285.19.456v.992z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGlobeAsiaSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
