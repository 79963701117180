import * as React from "react";

function SvgHashtagSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.673 8.701l.319-1.785a.536.536 0 00-.528-.63h-3.34l.653-3.656A.536.536 0 0018.25 2h-1.814a.536.536 0 00-.527.442l-.687 3.844H10.82l.653-3.656a.536.536 0 00-.528-.63H9.13a.536.536 0 00-.527.442l-.687 3.844h-3.53a.536.536 0 00-.528.441l-.319 1.786a.536.536 0 00.527.63h3.34l-1.02 5.714H2.855a.536.536 0 00-.528.442l-.319 1.785a.536.536 0 00.528.63h3.34l-.653 3.656a.536.536 0 00.527.63h1.814a.536.536 0 00.527-.442l.687-3.844h4.403l-.653 3.656a.536.536 0 00.528.63h1.814a.536.536 0 00.527-.442l.687-3.844h3.53a.536.536 0 00.528-.441l.319-1.786a.536.536 0 00-.527-.63h-3.34l1.02-5.714h3.531a.536.536 0 00.528-.442zm-7.981 6.156H9.287l1.02-5.714h4.404l-1.02 5.714z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHashtagSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
