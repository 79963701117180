import * as React from "react";

function SvgDrawPolygonSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M19.143 16.286c-.016 0-.03.004-.046.004l-1.75-2.916c.227-.41.367-.873.367-1.374 0-.5-.14-.965-.367-1.374l1.75-2.916c.016 0 .03.004.046.004a2.857 2.857 0 100-5.714 2.844 2.844 0 00-2.46 1.429H7.317A2.844 2.844 0 004.858 2 2.857 2.857 0 002 4.857c0 1.055.578 1.966 1.429 2.46v9.365A2.845 2.845 0 002 19.142 2.857 2.857 0 004.857 22a2.844 2.844 0 002.46-1.429h9.365A2.845 2.845 0 0019.142 22a2.857 2.857 0 000-5.714zm-12.857.396V7.318a2.85 2.85 0 001.032-1.032h9.302l-1.717 2.861c-.016 0-.03-.004-.046-.004a2.857 2.857 0 100 5.714c.016 0 .03-.004.046-.004l1.717 2.861H7.318a2.847 2.847 0 00-1.032-1.032zM14.143 12a.715.715 0 011.428 0 .715.715 0 01-1.428 0zm5.714-7.143a.715.715 0 01-1.428 0 .715.715 0 011.428 0zm-15-.714a.715.715 0 010 1.428.715.715 0 010-1.428zm-.714 15a.715.715 0 011.428 0 .715.715 0 01-1.428 0zm15 .714a.715.715 0 010-1.428.715.715 0 010 1.428z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDrawPolygonSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
