import * as React from "react";

function SvgClipboardListSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M17.625 4.5H14.5C14.5 3.121 13.379 2 12 2a2.502 2.502 0 00-2.5 2.5H6.375C5.34 4.5 4.5 5.34 4.5 6.375v13.75C4.5 21.16 5.34 22 6.375 22h11.25c1.035 0 1.875-.84 1.875-1.875V6.375c0-1.035-.84-1.875-1.875-1.875zM8.25 18.563a.935.935 0 01-.938-.938c0-.52.418-.938.938-.938s.938.418.938.938-.418.938-.938.938zm0-3.75a.935.935 0 01-.938-.938c0-.52.418-.938.938-.938s.938.418.938.938-.418.938-.938.938zm0-3.75a.935.935 0 01-.938-.938c0-.52.418-.938.938-.938s.938.418.938.938-.418.938-.938.938zm3.75-7.5c.52 0 .938.417.938.937s-.418.938-.938.938a.935.935 0 01-.938-.938c0-.52.418-.938.938-.938zm5 14.374c0 .172-.14.313-.313.313h-5.625a.313.313 0 01-.312-.313v-.625c0-.171.14-.312.313-.312h5.624c.172 0 .313.14.313.313v.625zm0-3.75c0 .172-.14.313-.313.313h-5.625a.313.313 0 01-.312-.313v-.624c0-.172.14-.313.313-.313h5.624c.172 0 .313.14.313.313v.624zm0-3.75c0 .172-.14.313-.313.313h-5.625a.313.313 0 01-.312-.313v-.624c0-.172.14-.313.313-.313h5.624c.172 0 .313.14.313.313v.624z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgClipboardListSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
