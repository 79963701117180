import * as React from "react";

function SvgChessBoardSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.999 2.001h-2.5v2.5h2.5v-2.5zM2 4.501V7h2.5v-2.5H2zM7.001 2h-2.5v2.5H7v-2.5zM9.502 12v2.5h2.5V12h-2.5zM2 9.5V12h2.5V9.5H2zm14.998-7.499h-2.5v2.5h2.5v-2.5zm5.002 0h-2.5v2.5H22v-2.5zM7.001 12h-2.5v2.5H7V12zm14.997 7.498v-2.5h-2.5v2.5h2.5zm0-5.001v-2.5h-2.5v2.5h2.5zm-5 7.502H19.5v-2.5H17v2.5zM22 9.5V7h-2.5v2.5H22zM7.001 22h2.501v-2.5h-2.5v2.5zm-5.001 0h2.5v-2.5H2v2.5zm9.999 0h2.5v-2.5H12v2.5zM2 14.499V17h2.5v-2.5H2zM14.499 7V4.5h-2.5V7h2.5zm-2.5 5.002h2.5v-2.5h-2.5v2.5zm-2.501 5.001v2.501h2.5v-2.5h-2.5zm5.001-2.5h2.5v-2.501h-2.5v2.5zm0-5h2.5v-2.5h-2.5v2.5zm0 7.5v2.5h2.5v-2.5h-2.5zM4.501 17v2.5H7V17h-2.5zM9.502 7V4.5h-2.5V7h2.5zm7.5 10h2.5v-2.501h-2.5v2.5zM12 9.5V7H9.5v2.5H12zM17 4.5v2.501h2.501v-2.5h-2.5zM12 14.5V17h2.5v-2.5H12zm-2.5-5H7V12h2.501L9.5 9.5zm7.498 2.5h2.5V9.5h-2.5V12zM7.001 7h-2.5v2.501H7v-2.5zm0 7.5V17h2.501v-2.5h-2.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChessBoardSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
