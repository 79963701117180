import * as React from "react";

function SvgChevronRightOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18 12c0-.395-.147-.733-.462-1.026l-8.771-8.59A1.284 1.284 0 007.83 2a1.33 1.33 0 00-1.342 1.342c0 .372.158.699.406.958l7.903 7.7-7.903 7.7c-.248.26-.406.598-.406.958A1.33 1.33 0 007.831 22c.372 0 .688-.135.936-.383l8.77-8.58c.316-.304.463-.642.463-1.037z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChevronRightOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
