import * as React from "react";

function SvgDnaSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M3.254 21.3a.623.623 0 00.621.696L5.137 22c.316 0 .582-.23.625-.543.027-.191.07-.434.133-.707h12.199c.062.27.113.516.136.707a.625.625 0 00.625.543l1.262-.004a.621.621 0 00.621-.695c-.18-1.48-1-5.04-4.644-8.113-.688.484-1.45.945-2.285 1.382.242.18.445.367.664.555H9.488a15.117 15.117 0 013.075-2.008C19.285 9.777 20.52 4.57 20.745 2.7a.623.623 0 00-.621-.695L18.859 2a.628.628 0 00-.625.543c-.027.191-.07.434-.133.707H5.899a8.507 8.507 0 01-.132-.707A.625.625 0 005.14 2l-1.262.004a.626.626 0 00-.625.695C3.457 4.375 4.477 8.711 9.5 12c-5.02 3.289-6.043 7.625-6.246 9.3zM12 10.579a14.876 14.876 0 01-2.512-1.703h5.02A14.456 14.456 0 0112 10.578zm5.121-4.828c-.226.406-.5.824-.82 1.25H7.703a10.043 10.043 0 01-.82-1.25H17.12zM6.88 18.25c.226-.406.5-.824.82-1.25h8.57c.325.426.602.844.829 1.25H6.878z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgDnaSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
