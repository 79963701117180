import * as React from "react";

function SvgWalletSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M20.016 7H5.125a.625.625 0 110-1.25h15c.345 0 .625-.28.625-.625 0-1.036-.84-1.875-1.875-1.875H4.5A2.5 2.5 0 002 5.75v12.5a2.5 2.5 0 002.5 2.5h15.516c1.094 0 1.984-.841 1.984-1.875v-10C22 7.841 21.11 7 20.016 7zm-1.766 8.125a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgWalletSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
