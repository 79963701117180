import * as React from "react";

function SvgSleighSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.146 14.96l-.29-.232a.5.5 0 00-.704.078l-.312.39a.5.5 0 00.078.704l.29.231a.768.768 0 01-.481 1.369H3.5c-.275 0-.5.225-.5.5v.5c0 .275.225.5.5.5h16.124c1.219 0 2.303-.916 2.372-2.134a2.252 2.252 0 00-.85-1.907zM3 11a3.992 3.992 0 003 3.86v1.64h2V15h6v1.5h2V15a3 3 0 003-3V9a.999.999 0 100-2h-3v2c0 1.103-.898 2-2 2h-.647A5.426 5.426 0 018.5 8a5.43 5.43 0 00-4.853-3H3a.999.999 0 100 2v4z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgSleighSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
