import * as React from "react";

function SvgHandPointRightOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.75 7.375h-3.366a4.51 4.51 0 00.085-.875c0-1.872-1.37-3.25-3.594-3.25-1.77 0-2.227 1.896-2.957 3.077-.302.485-.664.911-1.01 1.3l-.005.005-.005.006c-.599.683-.907.925-1.18.925h-.104a1.322 1.322 0 00-.864-.313h-2.5C2.56 8.25 2 8.754 2 9.375v9c0 .621.56 1.125 1.25 1.125h2.5c.335 0 .64-.12.864-.313h.105c1.12 0 2.622 1.563 4.968 1.563h.833c2.443 0 3.859-1.51 3.903-3.56a3.627 3.627 0 00.625-2.453c.139-.273.243-.563.31-.862h1.392c1.775 0 3.25-1.468 3.25-3.25 0-1.762-1.488-3.25-3.25-3.25zm0 4.625h-3.556c.502.573.563 1.673-.193 2.385.439.767.066 1.782-.505 2.095.255 1.528-.394 2.395-1.976 2.395h-.832c-1.777 0-3.017-1.403-4.688-1.55v-6.904c.986-.116 1.678-.83 2.308-1.547.44-.494.848-.988 1.203-1.558.565-.913 1.02-2.191 1.364-2.191.913 0 1.719.344 1.719 1.375 0 1.375-1.031 2.073-1.031 2.75h6.187c.72 0 1.375.645 1.375 1.375 0 .741-.634 1.375-1.375 1.375zM5.437 17a.937.937 0 11-1.874 0 .937.937 0 011.874 0z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgHandPointRightOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
