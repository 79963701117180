import * as React from "react";

function SvgMarsSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M21.375 2H17.26a.626.626 0 00-.442 1.068l.88.88-4.203 4.203A7.502 7.502 0 002 14.5C2 18.64 5.36 22 9.5 22a7.502 7.502 0 006.349-11.495l4.203-4.203.88.88A.625.625 0 0022 6.74V2.625A.627.627 0 0021.375 2zM9.5 18.667A4.172 4.172 0 015.333 14.5 4.172 4.172 0 019.5 10.333a4.172 4.172 0 014.167 4.167A4.172 4.172 0 019.5 18.667z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgMarsSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
