import * as React from "react";

function SvgGrinOutline(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M12 2C6.476 2 2 6.476 2 12s4.476 10 10 10 10-4.476 10-10S17.524 2 12 2zm0 18.064c-4.448 0-8.065-3.616-8.065-8.064S7.552 3.935 12 3.935c4.448 0 8.064 3.617 8.064 8.065 0 4.448-3.616 8.064-8.064 8.064zm4.258-6.104c-1.044.334-2.597.528-4.258.528s-3.21-.194-4.258-.528a.554.554 0 00-.714.617c.319 1.899 2.875 3.225 4.972 3.225 2.097 0 4.65-1.326 4.972-3.225a.555.555 0 00-.714-.617zm-7.484-2.605c.714 0 1.29-.577 1.29-1.29 0-.714-.576-1.29-1.29-1.29-.714 0-1.29.576-1.29 1.29 0 .713.576 1.29 1.29 1.29zm6.452 0a1.289 1.289 0 100-2.58c-.714 0-1.29.576-1.29 1.29 0 .713.576 1.29 1.29 1.29z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgGrinOutline);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
