import * as React from "react";

function SvgReceiptSolid(
  props: React.SVGProps<SVGSVGElement>,
  svgRef?: React.Ref<SVGSVGElement>
) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M18.5 2.126L17 3.876l-2.125-1.75a.621.621 0 00-.75 0L12 3.876l-2.125-1.75a.621.621 0 00-.75 0l-2.124 1.75-1.5-1.75a.625.625 0 00-1 .5v18.748c0 .516.586.809 1 .5l1.5-1.75 2.124 1.75a.621.621 0 00.75 0L12 20.124l2.125 1.75a.621.621 0 00.75 0L17 20.124l1.5 1.75c.41.309 1 .016 1-.5V2.626a.625.625 0 00-1-.5zM17 16.062c0 .172-.141.313-.313.313H7.313a.313.313 0 01-.312-.313v-.625c0-.172.14-.312.312-.312h9.374c.172 0 .313.14.313.312v.625zm0-3.75c0 .172-.141.313-.313.313H7.313a.313.313 0 01-.312-.313v-.624c0-.172.14-.313.312-.313h9.374c.172 0 .313.14.313.313v.624zm0-3.75c0 .173-.141.313-.313.313H7.313a.313.313 0 01-.312-.312v-.625c0-.172.14-.313.312-.313h9.374c.172 0 .313.141.313.313v.625z"
        fill="currentColor"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgReceiptSolid);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
